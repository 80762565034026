<template>
    <div class="content_wrapper_card">
        <div class="staticPages">
            <div class="page_title">
                <h4>{{ $t("titles.ask_for_question") }}</h4>
            </div>
            <div class="form_wrapper">
                <form @submit.prevent="submitContactForm">
                    <!-- START:: NAME INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.name')"
                            v-model="contactForm.name"
                            name="fullname"
                        />
                    </div>
                    <!-- END:: NAME INPUT -->

                    <!-- START:: EMAIL INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="email"
                            class="form-control"
                            :placeholder="$t('placeholders.email')"
                            v-model="contactForm.email"
                            name="email"
                        />
                    </div>
                    <!-- END:: EMAIL INPUT -->

                    <!-- START:: EMAIL INPUT -->
                    <div class="wrapper mb-3">
                        <textarea
                            class="form-control"
                            :placeholder="$t('placeholders.message')"
                            v-model="contactForm.message"
                        ></textarea>
                    </div>
                    <!-- END:: EMAIL INPUT -->

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="wrapper justify-content-center">
                        <button class="btn">
                            {{ $t("buttons.send") }}
                            <span
                                class="btn_loader"
                                v-if="isWaitingRequest"
                            ></span>
                        </button>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "contactForm",

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA
            contactForm: {
                name: "",
                email: "",
                message: "",
            },
        };
    },

    methods: {
        submitContactForm() {
            this.isWaitingRequest = true;

            if (this.contactForm.name == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.nameValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (this.contactForm.email == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.emailValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (this.contactForm.message == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.message"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }

            this.$axios
                .post("settings/contact", this.contactForm)
                .then((res) => {
                    if (res.data.status == "success") {
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("frequently_words.message"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                        this.contactForm.name = "";
                        this.contactForm.email = "";
                        this.contactForm.message = "";
                    }
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.buttonIsDisabled = false;
                    this.isWaitingRequest = false;
                });
        },
    },

    mounted() {
        this.getStaticContent();
    },
};
</script>

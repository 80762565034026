<template>
    <div class="contract_details_wrapper" :key="$route.params.id">
        <Loader v-if="loadMessages" class="fadeIn"></Loader>
        <section v-else class="fadeIn">
            <div
                class="contract_header_wrapper ignore_print"
                v-if="!this.$route.path.includes('/view-created')"
            >
                <div class="title_wraper">
                    <h3 v-if="userType == 'investor'">
                        {{ $t("titles.influencer_informations") }}
                    </h3>
                    <h3 v-else>
                        {{ $t("titles.investor_informations") }}
                    </h3>
                    <div class="btn_wrapper">
                        <router-link
                            :to="
                                userType == 'investor'
                                    ? '/contracts'
                                    : '/influincer-contracts'
                            "
                        >
                            <XIcon />
                        </router-link>
                    </div>
                </div>

                <div class="influincers_details_wrapper">
                    <div class="influincers_details">
                        <div class="wrapper">
                            <span class="profile_route">
                                <div class="avatar_wrapper">
                                    <img
                                        :src="
                                            userType == 'investor'
                                                ? details.influencer.image
                                                : details.investor.image
                                        "
                                        alt="Influincer Avatar"
                                        width="100"
                                        height="100"
                                    />
                                </div>

                                <div class="details_wrappe">
                                    <div class="name">
                                        <router-link
                                            :to="{
                                                name: 'InfluincerProfile',
                                                params: {
                                                    influincer_id:
                                                        details.influencer.id,
                                                },
                                            }"
                                            v-if="userType == 'investor'"
                                            class="profile_route"
                                        >
                                            <span>
                                                {{
                                                    details.influencer.fullname
                                                }}
                                            </span>
                                        </router-link>
                                        <span
                                            v-else
                                            @click="
                                                toggleInvestorProfileSideMenu
                                            "
                                        >
                                            {{ details.investor.fullname }}
                                        </span>
                                    </div>

                                    <div
                                        class="fields"
                                        v-if="userType == 'investor'"
                                    >
                                        <span
                                            v-for="feild in details.influencer
                                                .social_areas"
                                            :key="feild.id"
                                            >{{ feild.name }}
                                        </span>
                                    </div>
                                </div>
                            </span>

                            <router-link
                                :to="
                                    userType == 'investor'
                                        ? `/chat/${details.influencer.id}`
                                        : `/influincer-chat/${details.investor.id}`
                                "
                            >
                                <span class="influincer_chat_route">
                                    <img
                                        src="../../assets/media/icons/chat_blue.svg"
                                        alt="Icon"
                                        width="100"
                                        height="100"
                                    />
                                </span>
                            </router-link>
                        </div>

                        <div class="contract_actions_wrapper">
                            <span
                                class="contract_status processing"
                                v-if="details.status == 'pending'"
                            >
                                {{ $t("states.pending") }}
                            </span>
                            <span
                                class="contract_status processing"
                                v-if="
                                    details.status == 'influencer_accept' &&
                                    details.cancel_request == null
                                "
                            >
                                {{ $t("states.accept") }}
                            </span>
                            <span
                                class="contract_status refuced-text"
                                v-if="
                                    userType == 'investor' &&
                                    details.status == 'influencer_accept' &&
                                    details.cancel_request !== null &&
                                    details.cancel_request.made_by == 'investor'
                                "
                            >
                                {{
                                    $t(
                                        "states.waiting_for_accept_cancel_request"
                                    )
                                }}
                            </span>
                            <span
                                class="contract_status refuced-text"
                                v-if="
                                    userType == 'influincer' &&
                                    details.status == 'influencer_accept' &&
                                    details.cancel_request !== null &&
                                    details.cancel_request.made_by ==
                                        'influencer'
                                "
                            >
                                {{
                                    $t(
                                        "states.waiting_for_accept_cancel_request"
                                    )
                                }}
                            </span>
                            <span
                                class="contract_status refuced-text"
                                v-if="
                                    userType == 'influincer' &&
                                    details.status == 'influencer_accept' &&
                                    details.cancel_request !== null &&
                                    details.cancel_request.made_by == 'investor'
                                "
                            >
                                {{ $t("states.there_is_cancel_request") }}
                            </span>

                            <span
                                class="contract_status refuced-text"
                                v-if="
                                    userType == 'investor' &&
                                    details.status == 'influencer_accept' &&
                                    details.cancel_request !== null &&
                                    details.cancel_request.made_by ==
                                        'influencer'
                                "
                            >
                                {{ $t("states.there_is_cancel_request") }}
                            </span>
                            <span
                                class="contract_status refuced-text"
                                v-if="details.status == 'influencer_reject'"
                            >
                                {{ $t("states.reject") }}
                            </span>
                            <span
                                class="contract_status refuced-text"
                                v-if="details.status == 'influencer_cancel'"
                            >
                                {{ $t("states.cancel") }}
                                {{ $t("frequently_words.by_influincer") }}
                            </span>
                            <span
                                class="contract_status refuced-text"
                                v-if="
                                    details.status == 'investor_cancel_contract'
                                "
                            >
                                {{ $t("states.cancel") }}
                                {{ $t("frequently_words.by_investor") }}
                            </span>
                            <span
                                class="contract_status processing"
                                v-if="details.status == 'in_progress'"
                            >
                                {{ $t("states.processing") }}
                            </span>
                            <span
                                class="contract_status accept-text"
                                v-if="details.status == 'closed_contract'"
                            >
                                {{ $t("states.closed") }}
                            </span>
                            <span
                                class="contract_status done"
                                v-if="details.status == 'done'"
                            >
                                {{ $t("states.done") }}
                            </span>
                            <button
                                class="btn contract_menu_toggeler"
                                @click="toggleContractActionsMenu"
                            >
                                <MoreVerticalIcon size="1.5x" />
                            </button>

                            <transition mode="out-in" name="fade">
                                <div
                                    class="contract_menu_wrapper"
                                    v-if="contractMenuIsActive"
                                >
                                    <ul class="contract_menu">
                                        <li class="contract_menu_item">
                                            <button
                                                class="btn print_btn"
                                                @click="
                                                    toggleContractActionsMenu();
                                                    printScreen();
                                                "
                                            >
                                                <span>
                                                    <img
                                                        src="../../assets/media/icons/print_blue.svg"
                                                        width="50"
                                                        height="50"
                                                        alt="icon"
                                                    />
                                                </span>
                                                {{
                                                    $t("buttons.print_contract")
                                                }}
                                            </button>
                                        </li>

                                        <li
                                            class="contract_menu_item"
                                            v-if="
                                                (userType == 'investor' &&
                                                    ((details.cancel_request ==
                                                        null &&
                                                        details.status ==
                                                            'pending') ||
                                                        (details.cancel_request ==
                                                            null &&
                                                            details.status ==
                                                                'in_progress') ||
                                                        (details.cancel_request ==
                                                            null &&
                                                            details.status ==
                                                                'influencer_accept'))) ||
                                                (userType == 'influincer' &&
                                                    ((details.cancel_request ==
                                                        null &&
                                                        details.status ==
                                                            'in_progress') ||
                                                        (details.cancel_request ==
                                                            null &&
                                                            details.status ==
                                                                'influencer_accept')))
                                            "
                                        >
                                            <button
                                                class="btn"
                                                @click="
                                                    toggleContractActionsMenu(),
                                                        (showCancelContracts = true)
                                                "
                                            >
                                                <span>
                                                    <img
                                                        src="../../assets/media/icons/close_square.svg"
                                                        alt="icon"
                                                        width="50"
                                                        height="50"
                                                    />
                                                </span>
                                                {{
                                                    $t(
                                                        "buttons.cancel_contract"
                                                    )
                                                }}
                                            </button>
                                        </li>

                                        <li class="contract_menu_item">
                                            <button
                                                class="btn"
                                                @click="
                                                    toggleContractActionsMenu(),
                                                        (show = !show)
                                                "
                                            >
                                                <span>
                                                    <img
                                                        src="../../assets/media/icons/alert_triangle.svg"
                                                        alt="icon"
                                                        width="50"
                                                        height="50"
                                                    />
                                                </span>
                                                {{ $t("buttons.report") }}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>

            <div class="contract_header_wrapper ignore_print" v-else>
                <div class="title_wraper justify-content-start">
                    <div class="btn_wrapper mx-2">
                        <button @click="$router.back()">
                            <img
                                src="../../assets/media/icons/arrow_left.svg"
                                alt="icon"
                                width="20px"
                                height="20px"
                            />
                        </button>
                    </div>
                    <h3 class="m-0">{{ $t("titles.contract_information") }}</h3>
                </div>
            </div>

            <div
                class="btns_wrapper mt-4"
                v-if="
                    (this.$route.path.includes('/view-created') &&
                        details.status == 'pending') ||
                    (this.$route.path.includes('/influincer-contract') &&
                        details.status == 'pending')
                "
            >
                <button class="reject" @click="rejectContract">
                    {{ $t("buttons.reject") }}
                </button>
                <button class="accept" @click="acceptContract">
                    {{ $t("buttons.accept") }}
                </button>
            </div>

            <div class="contract_body_wrapper">
                <img
                    src="../../assets/logo/logo.svg"
                    class="show-print logo-print"
                />
                <h3 class="show-print title-print">
                    <span>
                        {{ $t("titles.contract_information") }}
                    </span>
                </h3>
                <div class="data_wrapper apply_print">
                    <h3>
                        {{ $t("titles.create_at") }}
                    </h3>
                    <p>
                        <span>
                            {{ details.created_at }}
                        </span>
                    </p>
                </div>
                <div class="data_wrapper apply_print">
                    <h3 v-if="userType == 'investor'">
                        {{ $t("titles.influincer_name") }}
                    </h3>
                    <h3 v-else>
                        {{ $t("titles.investor_name") }}
                    </h3>
                    <p>
                        <span v-if="userType == 'investor'">
                            {{ details.influencer.fullname }}
                        </span>
                        <span v-else> {{ details.investor.fullname }} </span>
                    </p>
                </div>

                <div class="data_wrapper apply_print">
                    <h3 v-if="userType == 'investor'">
                        {{ $t("titles.investor_name") }}
                    </h3>
                    <h3 v-else>
                        {{ $t("titles.influincer_name") }}
                    </h3>
                    <p>
                        <span v-if="userType == 'investor'">
                            {{ details.investor.fullname }}
                        </span>
                        <span v-else>
                            {{ details.influencer.fullname }}
                        </span>
                    </p>
                </div>

                <div class="data_wrapper apply_print">
                    <h3>
                        {{ $t("titles.contract_status") }}
                    </h3>
                    <p>
                        <span
                            class="contract_status processing"
                            v-if="details.status == 'pending'"
                        >
                            {{ $t("states.pending") }}
                        </span>
                        <span
                            class="contract_status processing"
                            v-if="
                                details.status == 'influencer_accept' &&
                                details.cancel_request == null
                            "
                        >
                            {{ $t("states.accept") }}
                        </span>
                        <span
                            class="contract_status refuced-text"
                            v-if="
                                userType == 'investor' &&
                                details.status == 'influencer_accept' &&
                                details.cancel_request !== null &&
                                details.cancel_request.made_by == 'investor'
                            "
                        >
                            {{ $t("states.waiting_for_accept_cancel_request") }}
                        </span>
                        <span
                            class="contract_status refuced-text"
                            v-if="
                                userType == 'influincer' &&
                                details.status == 'influencer_accept' &&
                                details.cancel_request !== null &&
                                details.cancel_request.made_by == 'influencer'
                            "
                        >
                            {{ $t("states.waiting_for_accept_cancel_request") }}
                        </span>
                        <span
                            class="contract_status refuced-text"
                            v-if="
                                userType == 'influincer' &&
                                details.status == 'influencer_accept' &&
                                details.cancel_request !== null &&
                                details.cancel_request.made_by == 'investor'
                            "
                        >
                            {{ $t("states.there_is_cancel_request") }}
                        </span>

                        <span
                            class="contract_status refuced-text"
                            v-if="
                                userType == 'investor' &&
                                details.status == 'influencer_accept' &&
                                details.cancel_request !== null &&
                                details.cancel_request.made_by == 'influencer'
                            "
                        >
                            {{ $t("states.there_is_cancel_request") }}
                        </span>
                        <span
                            class="contract_status refuced-text"
                            v-if="details.status == 'influencer_reject'"
                        >
                            {{ $t("states.reject") }}
                        </span>
                        <span
                            class="contract_status refuced-text"
                            v-if="details.status == 'influencer_cancel'"
                        >
                            {{ $t("states.cancel") }}
                            {{ $t("frequently_words.by_influincer") }}
                        </span>
                        <span
                            class="contract_status refuced-text"
                            v-if="details.status == 'investor_cancel_contract'"
                        >
                            {{ $t("states.cancel") }}
                            {{ $t("frequently_words.by_investor") }}
                        </span>
                        <span
                            class="contract_status processing"
                            v-if="details.status == 'in_progress'"
                        >
                            {{ $t("states.processing") }}
                        </span>
                        <span
                            class="contract_status accept-text"
                            v-if="details.status == 'closed_contract'"
                        >
                            {{ $t("states.closed") }}
                        </span>
                        <span
                            class="contract_status done"
                            v-if="details.status == 'done'"
                        >
                            {{ $t("states.done") }}
                        </span>
                    </p>
                </div>

                <div class="data_wrapper">
                    <h3 v-if="!this.$route.path.includes('/view-created')">
                        {{ $t("titles.contract_information") }}
                    </h3>
                    <p>
                        <span> {{ $t("titles.contract_num") }} </span>
                        <span> #{{ details.contract_number }} </span>
                    </p>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.campaign_title") }}</p>
                    <h4>{{ details.campaign_title }}</h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.campaign_description") }}</p>
                    <h4>{{ details.campaign_desc }}</h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.campaign_type") }}</p>
                    <h4>
                        <span
                            class="campaign_type_item"
                            v-for="type in details.campaign_type_trans"
                            :key="type"
                        >
                            {{ type }}
                        </span>
                    </h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.campaign_duration") }}</p>
                    <h4
                        v-if="
                            details.campaign_duration.name == 'hour' &&
                            userLang == 'en'
                        "
                    >
                        {{ details.campaign_duration.num_of_duration }} hours
                    </h4>
                    <h4
                        v-else-if="
                            details.campaign_duration.name == 'hour' &&
                            userLang == 'ar'
                        "
                    >
                        {{ details.campaign_duration.num_of_duration }} ساعة
                    </h4>
                    <h4 v-else>
                        {{ details.campaign_duration.num_of_duration }}
                        {{ details.campaign_duration.name }}
                    </h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.requested_numbers_of_ads") }}</p>
                    <h4>
                        <span>
                            {{ details.number_ads }}
                            {{ $t("frequently_words.ads") }}
                        </span>
                        <span class="status approved">
                            ( {{ details.number_ads - details.remaining_ads }}
                            {{ $t("frequently_words.approved") }} )
                        </span>
                    </h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.execution_platform") }}</p>
                    <h4>
                        <span
                            class="social_platform"
                            v-for="social_platform in details.social_platforms"
                            :key="social_platform.id"
                        >
                            {{ social_platform.name }}
                        </span>
                    </h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.number_of_followers_target") }}</p>
                    <h4>
                        {{ details.follower_from }} - {{ details.follower_to }}
                        {{ $t("frequently_words.follower") }}
                    </h4>
                </div>

                <!-- <div class="data_wrapper">
                    <p>{{ $t("titles.country") }}</p>
                    <h4>{{ details.country.name }}</h4>
                </div> -->
                <div class="data_wrapper">
                    <p>{{ $t("titles.agreed_price") }}</p>
                    <h4>{{ details.price }}$</h4>
                </div>

                <div class="data_wrapper">
                    <p>{{ $t("titles.app_commission") }}</p>
                    <h4>
                        {{ details.app_commission }}% -
                        {{ details.app_amount }}$
                    </h4>
                </div>
                <div class="data_wrapper">
                    <p>{{ $t("titles.final_price") }}</p>
                    <h4>{{ details.influencer_contract_price }}$</h4>
                </div>
            </div>
        </section>
        <!-- START:: MODAL REPORT -->
        <ReportingModule
            :show="show"
            :isWaitingRequest="isWaitingRequest"
            :showSuccessfully="showSuccessfully"
            @closeModule="closeModule"
            @sendReport="sendReport"
            @continueReport="continueReport"
        >
        </ReportingModule>
        <!-- END:: MODAL REPORT -->

        <!-- START:: MODAL CANCEL -->
        <CancelingModule
            :showCancelContracts="showCancelContracts"
            :showAreYouSure="showAreYouSure"
            :showSuccessCancel="showSuccessCancel"
            :showRate="showRate"
            :showFinished="showFinished"
            :isWaitingRequest="isWaitingRequest"
            @closeModule="closeModule"
            @sendCancel="sendCancel"
            @AreYouSure="AreYouSure"
            @continueCancel="continueCancel"
            @addRate="addRate"
            @finished="finished"
        ></CancelingModule>
    </div>
</template>

<script>
// START:: IMPORTING MODAL
import { XIcon, MoreVerticalIcon } from "vue-feather-icons";
import ReportingModule from "./../models/reporting.vue";
import CancelingModule from "./../models/canceling.vue";
import Loader from "./../loader/main-loader.vue";
// END:: IMPORTING MODAL
export default {
    name: "ContractDetails",

    components: {
        XIcon,
        MoreVerticalIcon,
        ReportingModule,
        CancelingModule,
        Loader,
    },

    data() {
        return {
            // START:: CURRENT CONTRACT ID DATA
            contractId: this.$route.params.id,
            // END:: CURRENT CONTRACT ID DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: CONTRACT ACTIONS MENU HANDLING DATA
            contractMenuIsActive: false,
            // END:: CONTRACT ACTIONS MENU HANDLING DATA

            // START:: MODAL HANDLING DATA
            isWaitingRequest: false,

            show: false,

            showSuccessfully: false,

            showCancelContracts: false,

            showAreYouSure: false,

            showSuccessCancel: false,

            showRate: false,

            showFinished: false,

            // END:: MODAL HANDLING DATA
            //START:: OF CONTRACT DATA
            details: "",

            //END:: OF CONTRACT DATA
            loadMessages: false,
            userLang: "",
        };
    },

    methods: {
        //   START:: TOGGLE INVESTOR PROFILE
        toggleInvestorProfileSideMenu() {
            this.$store.dispatch("toggleInvestorProfileSideMenu");
        },
        //   END:: TOGGLE INVESTOR PROFILE

        // START:: TOGGLE CONTRACT ACTIONS MENU
        toggleContractActionsMenu() {
            this.contractMenuIsActive = !this.contractMenuIsActive;
        },
        // END:: TOGGLE CONTRACT ACTIONS MENU

        // START:: PRINT SCREEN
        printScreen() {
            window.print();
        },
        // END:: PRINT SCREEN

        // START:: MODAL REPORT
        sendReport() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.show = !this.show;
                this.showSuccessfully = !this.showSuccessfully;
            }, 1500);
        },

        continueReport() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showSuccessfully = !this.showSuccessfully;
            }, 1500);
        },
        // END:: MODAL REPORT

        // START:: MODAL CANCL
        sendCancel() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showCancelContracts = !this.showCancelContracts;
                this.showAreYouSure = !this.showAreYouSure;
            }, 1500);
        },

        AreYouSure() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showAreYouSure = !this.showAreYouSure;
                this.showSuccessCancel = !this.showSuccessCancel;
            }, 1500);
        },

        continueCancel() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showSuccessCancel = !this.showSuccessCancel;
                this.showRate = !this.showRate;
            }, 1500);
        },

        addRate() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showRate = !this.showRate;
                this.showFinished = !this.showFinished;
            }, 1500);
        },

        finished() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showRate = false;
                this.showFinished = false;
            }, 1500);
        },
        closeModule() {
            this.isWaitingRequest = false;
            this.show = false;
            this.showSuccessfully = false;
            this.showCancelContracts = false;
            this.showAreYouSure = false;
            this.showSuccessCancel = false;
            this.showRate = false;
            this.showFinished = false;
        },
        // END:: MODAL CANCL
        // START:: GET  DATA
        getContractsInnvestor() {
            this.loadMessages = true;
            this.$axios
                .get("/investor/contract/" + this.contractId, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.details = res.data.data;
                    this.loadMessages = false;
                });
        },

        getContractsInfluincer() {
            this.loadMessages = true;
            this.$axios
                .get("/influencer/contract/" + this.contractId, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.details = res.data.data;
                    this.$store.commit("setInvestorProfileData", {
                        investorData: res.data.data.investor,
                    });
                    this.loadMessages = false;
                });
        },
        acceptContract() {
            this.loadMessages = true;
            const data = new FormData();
            data.append("id", this.$route.params.id);
            data.append("contract_status", "influencer_accept");
            this.$axios
                .post("influencer/contract/change_status", data, {
                    headers: {
                        "Content-type": `multipart/form-data;`,
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                    },
                })
                .then(() => {
                    this.$iziToast.success({
                        timeout: 5000,
                        message: this.$t("validation.contract_accept"),
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.loadMessages = false;
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.loadMessages = false;
                });
        },
        // END:: GET CHAT DATA
        rejectContract() {
            this.loadMessages = true;
            const data = new FormData();
            data.append("id", this.$route.params.id);
            data.append("contract_status", "influencer_reject");
            this.$axios
                .post("influencer/contract/change_status", data, {
                    headers: {
                        "Content-type": `multipart/form-data;`,
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                    },
                })
                .then(() => {
                    this.$iziToast.success({
                        timeout: 5000,
                        message: this.$t("validation.contract_reject"),
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.loadMessages = false;
                    setTimeout(() => {
                        location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.loadMessages = false;
                });
        },
    },
    watch: {
        "$route.params.id": {
            handler: function (value) {
                this.contractId = value;
                if (this.userType == "investor") {
                    this.getContractsInnvestor();
                } else {
                    this.getContractsInfluincer();
                }
            },
        },
    },
    mounted() {
        // START:: AXIOS GET CONTRACTS
        if (this.userType == "investor") {
            this.getContractsInnvestor();
        } else {
            this.getContractsInfluincer();
        }
        this.userLang = localStorage.getItem("inflco_app_lang");
    },
};
</script>

<template>
    <div class="all_contracts_list_wrapper">
        <div class="title_wrapper">
            <h3>
                <router-link to="/chats"
                    ><img
                        src="../../assets/media/icons/arrow_left.svg"
                        alt="Arrow Left"
                        width="30px"
                        height="15px"
                /></router-link>
                {{ $t("titles.create_contract") }}
            </h3>
        </div>

        <div class="form_wrapper">
            <form @submit.prevent="submitRegisterForm">
                <div class="wrapper mb-3">
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('placeholders.campaign_title')"
                        v-model="createContract.compaign_title"
                    />
                </div>

                <div class="wrapper mb-3">
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('placeholders.campaign_description')"
                        v-model="createContract.compaign_description"
                    />
                </div>
                <div class="wrapper mb-3">
                    <select
                        class="form-select"
                        aria-label="compaign_Duration select"
                        v-model="createContract.compaign_duration"
                    >
                        <option selected disabled value="">
                            {{ $t("titles.campaign_duration") }}
                        </option>
                        <option
                            v-for="duration in $store.state.allDurations"
                            :key="duration.id"
                            :value="duration.id"
                        >
                            {{ duration.num_of_duration }}
                            <span
                                v-if="
                                    duration.name == 'hour' && userLang == 'en'
                                "
                            >
                                hours
                            </span>
                            <span
                                v-else-if="
                                    duration.name == 'hour' && userLang == 'ar'
                                "
                            >
                                ساعة
                            </span>
                            <span
                                v-else-if="
                                    duration.name == 'week' && userLang == 'en'
                                "
                            >
                                week
                            </span>
                            <span
                                v-else-if="
                                    duration.name == 'week' && userLang == 'ar'
                                "
                            >
                                أسبوع
                            </span>
                            <span v-else> {{ duration.name }} </span>
                        </option>
                    </select>
                </div>
                <Multiselect
                    class=""
                    v-model="createContract.compaign_type"
                    :placeholder="$t('placeholders.campaign_type')"
                    :label="userLang == 'ar' ? 'nameAr' : 'nameEn'"
                    track-by="key_name"
                    :options="campaignTypes"
                    :option-height="104"
                    :show-labels="false"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    @select="
                        createContract.requested_numbers =
                            createContract.compaign_type.length + 1
                    "
                    @remove="
                        createContract.requested_numbers =
                            createContract.compaign_type.length - 1
                    "
                >
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span
                                class="option__title"
                                v-if="userLang == 'ar'"
                                >{{ props.option.nameAr }}</span
                            >
                            <span class="option__title" v-else>{{
                                props.option.nameEn
                            }}</span>
                        </div>
                        <div class="otions__icon">
                            <i class="fas fa-check"></i>
                        </div>
                    </template>
                </Multiselect>
                <div class="wrapper mb-3">
                    <input
                        type="number"
                        min="1"
                        onwheel="this.blur()"
                        class="form-control"
                        :placeholder="
                            $t('placeholders.requested_numbers_of_ads')
                        "
                        v-model="createContract.requested_numbers"
                        @input="requestedInput()"
                    />
                </div>

                <Multiselect
                    class=""
                    v-model="createContract.socialMediaPlatForm"
                    :placeholder="$t('placeholders.select_social_platform')"
                    label="name"
                    track-by="name"
                    :options="$store.state.socialMediaPlatforms"
                    :option-height="104"
                    :show-labels="false"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                >
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <img
                                class="option__image"
                                :src="props.option.icon"
                            />
                            <span class="option__title">{{
                                props.option.name
                            }}</span>
                        </div>
                        <div class="otions__icon">
                            <i class="fas fa-check"></i>
                        </div>
                    </template>
                </Multiselect>

                <!-- <div class="wrapper mb-3">
          <select
            class="form-select"
            aria-label="compaign_type select"
            v-model="createContract.compaign_type"
          >
            <option selected disabled value="">
              {{ $t("placeholders.campaign_type") }}
            </option>
            <option value="video">{{ $t("placeholders.the_video") }}</option>
            <option value="link">{{ $t("placeholders.live") }}</option>
            <option value="content">{{ $t("placeholders.post") }}</option>
            <option value="image">{{ $t("placeholders.image") }}</option>
          </select>
        </div> -->

                <!-- <div class="wrapper mb-3">
                    <select
                        class="form-select"
                        aria-label="country select"
                        v-model="createContract.country"
                    >
                        <option selected disabled value="">
                            {{ $t("placeholders.country") }}
                        </option>
                        <option
                            v-for="country in $store.state.allCountries"
                            :key="country.id"
                            :value="country.id"
                        >
                            {{ country.name }}
                        </option>
                    </select>
                </div> -->

                <div class="wrapper multi mb-3">
                    <label>{{ $t("titles.number_of_followers_target") }}</label>
                    <input
                        type="number"
                        class="form-control"
                        onwheel="this.blur()"
                        :placeholder="$t('placeholders.from')"
                        min="1"
                        v-model="createContract.follower_from"
                        @input="fromFollowers()"
                    />
                    <input
                        type="number"
                        onwheel="this.blur()"
                        class="form-control"
                        :placeholder="$t('placeholders.to')"
                        min="1"
                        v-model="createContract.follower_to"
                        @input="toFollowers()"
                    />
                </div>

                <div class="wrapper mb-3">
                    <input
                        type="number"
                        class="form-control"
                        onwheel="this.blur()"
                        :placeholder="$t('placeholders.agreed_price')"
                        min="1"
                        v-model="createContract.price"
                        @input="priceMethod()"
                    />
                </div>

                <!-- START:: SUBMIT BUTTON -->
                <div class="wrapper justify-content-center">
                    <button class="btn" :class="{ disabled: buttonIsDisabled }">
                        {{ $t("buttons.send") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON -->
            </form>
        </div>
    </div>
</template>

<script>
// import flag1 from "../../assets/media/flag/flag1.png";
// import flag2 from "../../assets/media/flag/flag2.png";

import Multiselect from "vue-multiselect";

import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "ContractsList",
    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA
            // START:: BUTTON DISABLED
            buttonIsDisabled: false,
            // END:: BUTTON DISABLED

            // START:: USER TYPE DATA
            userType: this.$route.params.type,
            userLang: "",
            // END:: USER TYPE DATA

            // START:: COUNTRIES KYES MENU HANDLING DATA
            // countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KYES MENU HANDLING DATA

            // START:: CAMPAIGN TYPES DATA
            campaignTypes: [
                {
                    id: "campaign_type_1",
                    value: "video",
                    key_name: "video",
                    nameEn: "Video",
                    nameAr: "فيديو",
                },
                {
                    id: "campaign_type_2",
                    value: "link",
                    key_name: "link",
                    nameEn: "Live",
                    nameAr: "بث مباشر",
                },
                {
                    id: "campaign_type_3",
                    value: "content",
                    key_name: "content",
                    nameEn: "Post",
                    nameAr: "منشور",
                },
                {
                    id: "campaign_type_4",
                    value: "image",
                    key_name: "image",
                    nameEn: "Image",
                    nameAr: "صورة",
                },
                {
                    id: "campaign_type_5",
                    value: "story",
                    key_name: "story",
                    nameEn: "Story",
                    nameAr: "حالة",
                },
            ],
            // END:: CAMPAIGN TYPES DATA

            // START:: COUNTRIES KEYS DATA
            // countryKies: [
            //     {
            //         flag: flag1,
            //         key: "+971",
            //     },
            //     {
            //         flag: flag2,
            //         key: "+966",
            //     },
            // ],

            // selectedCountry: {
            //     flag: flag1,
            //     key: "+971",
            // },
            // END:: COUNTRIES KEYS DATA

            // START:: REGISTER DATA
            createContract: {
                compaign_title: "",
                compaign_description: "",
                // compaign_type: "",
                compaign_duration: "",
                requested_numbers: "",
                socialMediaPlatForm: [],
                compaign_type: [],
                // country: "",
                follower_from: "",
                follower_to: "",
                price: "",
            },
            // END:: REGISTER DATA

            // START:: HANDLING UPLOADED FILE DATA
            selectedFile: null,
            selectedFileName: null,
        };
    },
    methods: {
        async userLocation() {
            const {
                data: { loc },
            } = await this.$axios.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                {
                    responseType: "text",
                    transformResponse: (data) =>
                        Object.fromEntries(
                            data
                                .trim()
                                .split("\n")
                                .map((line) => line.split("="))
                        ),
                }
            );

            this.$store.state.currentLocation = loc;
            this.$store.dispatch("getCountries");
        },
        submitRegisterForm() {
            // console.log("selected platforms", this.createContract.socialMediaPlatForm);
            // console.log("selected types", this.createContract.compaign_type);
            this.isWaitingRequest = true;
            this.buttonIsDisabled = true;
            if (
                this.createContract.compaign_title == "" ||
                this.createContract.compaign_description == "" ||
                this.createContract.compaign_duration == "" ||
                this.createContract.compaign_type == "" ||
                this.createContract.requested_numbers == "" ||
                this.createContract.socialMediaPlatForm.length == 0 ||
                // this.createContract.country == "" ||
                this.createContract.follower_from == "" ||
                this.createContract.follower_to == "" ||
                this.createContract.price == ""
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.empty_input"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                this.buttonIsDisabled = false;
            } else {
                const data = new FormData();
                data.append("chat_id", this.$route.params.chat_id);
                data.append("influencer_id", this.$route.params.influincer_id);
                data.append(
                    "campaign_title",
                    this.createContract.compaign_title
                );
                data.append(
                    "campaign_desc",
                    this.createContract.compaign_description
                );

                this.createContract.compaign_type.forEach((item, index) => {
                    data.append(
                        `campaign_type[${index}]`,
                        item.key_name.toLowerCase()
                    );
                });

                data.append(
                    "campaign_duration",
                    this.createContract.compaign_duration
                );
                data.append(
                    "number_ads",
                    this.createContract.requested_numbers
                );
                data.append("follower_from", this.createContract.follower_from);
                data.append("follower_to", this.createContract.follower_to);
                // data.append("country_id", this.createContract.country);
                data.append("price", this.createContract.price);
                this.createContract.socialMediaPlatForm.forEach(
                    (item, index) => {
                        data.append(`social_platform_id[${index}]`, item.id);
                    }
                );
                data.append("currency", "usd");
                this.$axios
                    .post("investor/contract", data, {
                        headers: {
                            "Content-type": `multipart/form-data;`,
                            Authorization:
                                "Bearer" +
                                localStorage.getItem("inflco_user_token"),
                        },
                    })
                    .then(() => {
                        this.isWaitingRequest = false;
                        this.buttonIsDisabled = false;
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("validation.contract_success"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        setTimeout(() => {
                            this.$router.back();
                        }, 500);
                        setTimeout(() => {
                            location.reload();
                        }, 1500);
                    })
                    .catch((error) => {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: error.response.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                        this.buttonIsDisabled = false;
                    });
            }
        },
        requestedInput() {
            if (
                this.createContract.requested_numbers < 1 &&
                this.createContract.requested_numbers != ""
            ) {
                this.createContract.requested_numbers = 1;
            }
            if (
                this.createContract.requested_numbers <
                    this.createContract.compaign_type.length + 1 &&
                this.createContract.requested_numbers != ""
            ) {
                this.createContract.requested_numbers =
                    this.createContract.compaign_type.length;
            }
        },
        fromFollowers() {
            if (
                this.createContract.follower_from < 1 &&
                this.createContract.follower_from != ""
            ) {
                this.createContract.follower_from = 1;
            }
        },
        toFollowers() {
            if (
                this.createContract.follower_to < 1 &&
                this.createContract.follower_to != ""
            ) {
                this.createContract.follower_to = 1;
            }
        },
        priceMethod() {
            if (
                this.createContract.price < 1 &&
                this.createContract.price != ""
            ) {
                this.createContract.price = 1;
            }
        },
    },
    components: {
        Multiselect,
    },
    mounted() {
        // START:: AXIOS GET COUNTRIES
        this.userLocation();
        // START:: AXIOS GET DURATIONS
        this.$store.dispatch("getDurations");
        // START:: AXIOS GET SOCIAL PLATFORMS
        this.$store.dispatch("getSocialPlatforms");
        this.userLang = localStorage.getItem("inflco_app_lang");
    },
};
</script>

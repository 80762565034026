<template>
  <div class="home_wrapper" :class="{ contactPage: $route.path == '/contact' }">
    <!-- START:: HEADER -->
    <TheHeader />
    <!-- END:: HEADER -->

    <div class="row">
      <div
        :class="$route.path == '/contact' ? 'col-md-8 mt-1 mb-1' : 'col-md-12'"
      >
        <ContentWrapper>
          <template #main>
            <transition mode="out-in" name="fade">
              <!-- START:: STATIC PAGES ROUTER VIEW -->
              <router-view
                v-if="$route.path == '/contact'"
                name="contact_large_view"
                :key="$route.path"
              ></router-view>
              <router-view :key="$route.path" v-else></router-view>
              <!-- END:: STATIC PAGES ROUTER VIEW -->
            </transition>
          </template>
        </ContentWrapper>
      </div>
      <div class="col-md-4 mt-1 mb-1" v-if="$route.path == '/contact'">
        <transition mode="out-in" name="fade">
          <!-- START:: STATIC PAGES ROUTER VIEW -->
          <router-view name="contact_small_view"></router-view>
          <!-- END:: STATIC PAGES ROUTER VIEW -->
        </transition>
      </div>
    </div>

    <!-- START:: FOOTER -->
    <TheFooter />
    <!-- END:: FOOTER -->
  </div>
</template>

<script>
import TheHeader from "../../components/layouts/TheHeader.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";
import ContentWrapper from "../../components/ui/ContentWrapper.vue";
export default {
  name: "About",

  components: {
    TheHeader,
    TheFooter,
    ContentWrapper,
  },
};
</script>

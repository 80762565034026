<template>
    <div class="register_form_wrapper" v-if="this.$store.state.selectedCountry">
        <div class="register_header_wrapper">
            <div class="logo_wrapper">
                <router-link to="/">
                    <img
                        src="../../assets/logo/small_logo.svg"
                        alt="Inflico Logo"
                        width="100"
                        height="100"
                    />
                </router-link>
            </div>

            <div class="title_wrapper">
                <h2 v-if="userType == 'investor'">
                    {{ $t("titles.investor_sign_up") }}
                </h2>
                <h2 v-if="userType == 'influincer'">
                    {{ $t("titles.influincer_sign_up") }}
                </h2>
            </div>

            <div class="slogan_wrapper">
                <h5>{{ $t("titles.sign_up_description") }}</h5>
            </div>

            <div class="title_wrapper">
                <h5>{{ $t("titles.personal_information") }}</h5>
            </div>

            <div class="form_wrapper">
                <form @submit.prevent="validateRegisterForm">
                    <!-- START:: NAME INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.name')"
                            v-model="registerData.fullname"
                            name="fullname"
                        />
                    </div>
                    <!-- END:: NAME INPUT -->

                    <!-- START:: PHONE INPUT -->
                    <div class="outer_wrapper">
                        <div
                            class="wrapper mb-3"
                            @click="toggleCountryKeysMenu"
                        >
                            <div class="flag_wrapper">
                                <img
                                    :src="
                                        this.$store.state.selectedCountry.flag
                                    "
                                />
                            </div>
                            <div class="key_wrapper">
                                {{
                                    this.$store.state.selectedCountry.phonecode
                                }}
                            </div>

                            <transition mode="out-in" name="fade">
                                <div
                                    class="country_keys_menu_wrapper"
                                    v-if="countryKiesMenuIsOpen"
                                >
                                    <ul class="country_keys_menu">
                                        <li
                                            class="menu_item"
                                            v-for="item in $store.state
                                                .allCountries"
                                            :key="item.phonecode"
                                            @click="selectCountry(item)"
                                        >
                                            <div class="flag_wrapper">
                                                <img :src="item.flag" />
                                            </div>
                                            <div class="key_wrapper">
                                                {{ item.phonecode }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                type="tel"
                                class="form-control"
                                :placeholder="$t('placeholders.phone')"
                                v-model="registerData.phone"
                            />
                        </div>
                    </div>
                    <!-- END:: PHONE INPUT -->

                    <!-- START:: EMAIL INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="email"
                            class="form-control"
                            :placeholder="$t('placeholders.email')"
                            v-model="registerData.email"
                        />
                    </div>
                    <!-- END:: EMAIL INPUT -->

                    <!-- START:: DATA OF BIRTH -->
                    <div
                        class="wrapper date_picker mb-3"
                        v-if="userType == 'influincer'"
                    >
                        <Datepicker
                            class=""
                            v-model="registerData.date_of_birth"
                            :open-date="new Date()"
                            :disabledDates="disabledDates"
                            name="uniquename"
                            input-class="form-control"
                            :placeholder="$t('placeholders.date_of_birth')"
                        ></Datepicker>
                    </div>
                    <!-- END:: DATA OF BIRTH -->

                    <!-- START:: COUNTRY SELECT INPUT -->
                    <div class="wrapper mb-3">
                        <select
                            class="form-select"
                            aria-label="country select"
                            v-model="registerData.country_id"
                            @change="getCitiesByCountryID()"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.country") }}
                            </option>
                            <option
                                v-for="country in this.$store.state
                                    .allCountries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <!-- END:: COUNTRY SELECT INPUT -->

                    <!-- START:: CITY SELECT INPUT -->
                    <div class="wrapper mb-3">
                        <select
                            class="form-select"
                            aria-label="city select"
                            v-model="registerData.city_id"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.city") }}
                            </option>
                            <option
                                v-for="city in this.$store.state.cities"
                                :key="city.id"
                                :value="city.id"
                            >
                                {{ city.name }}
                            </option>
                        </select>
                    </div>
                    <!-- END:: CITY SELECT INPUT -->

                    <!-- START:: PASSWORD INPUT -->
                    <div class="wrapper password_wrapper mb-3">
                        <input
                            id="password"
                            type="password"
                            class="form-control"
                            :placeholder="$t('placeholders.password')"
                            v-model="registerData.password"
                        />
                        <div class="icon_wrapper">
                            <button
                                type="button"
                                v-if="passwordIsVisible"
                                @click="togglePasswordVisibility('password')"
                            >
                                <EyeOffIcon />
                            </button>
                            <button
                                type="button"
                                v-else
                                @click="togglePasswordVisibility('password')"
                            >
                                <EyeIcon />
                            </button>
                        </div>
                    </div>
                    <!-- END:: PASSWORD INPUT -->

                    <!-- START:: CONFIRM PASSWORD INPUT -->
                    <div class="wrapper password_wrapper mb-3">
                        <input
                            id="confirm_password"
                            type="password"
                            class="form-control"
                            :placeholder="$t('placeholders.confirm_password')"
                            v-model="registerData.confirmPassword"
                        />
                        <div class="icon_wrapper">
                            <button
                                type="button"
                                v-if="confirmPasswordIsVisible"
                                @click="
                                    togglePasswordVisibility('confirm_password')
                                "
                            >
                                <EyeOffIcon />
                            </button>
                            <button
                                type="button"
                                v-else
                                @click="
                                    togglePasswordVisibility('confirm_password')
                                "
                            >
                                <EyeIcon />
                            </button>
                        </div>
                    </div>
                    <!-- END:: CONFIRM PASSWORD INPUT -->

                    <!-- START:: GENDER -->
                    <div
                        class="group wrapper reg_gender mt-5"
                        v-if="this.$route.params.type == 'influincer'"
                    >
                        <h6>{{ $t("placeholders.gender") }} :</h6>
                        <div class="inner_wrapper">
                            <div class="wrapper mb-3">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    v-model="registerData.gender"
                                    value="male"
                                />
                                <label class="form-check-label" for="male">
                                    {{ $t("placeholders.male") }}
                                </label>
                            </div>

                            <div class="wrapper mb-3">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    v-model="registerData.gender"
                                />
                                <label class="form-check-label" for="female">
                                    {{ $t("placeholders.female") }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- END:: GENDER -->

                    <!-- START:: TERMS AND CONDITION AGREEMENT -->
                    <div class="wrapper my-5">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="agree"
                            name="tremsAndConditions"
                            v-model="dontAcceptTermsAndConditions"
                        />
                        <label class="form-check-label" for="agree">
                            {{ $t("frequently_words.i_agree_with") }}
                            <!-- <router-link to="/terms">{{
                $t("static_pages.terms_conditions")
              }}</router-link> -->

                            <button
                                type="button"
                                @click="
                                    campaignMediaModalIsOpen =
                                        !campaignMediaModalIsOpen
                                "
                            >
                                {{ $t("static_pages.terms_conditions") }}
                            </button>
                        </label>
                    </div>
                    <!-- END:: TERMS AND CONDITION AGREEMENT -->

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="wrapper justify-content-center">
                        <button
                            class="btn"
                            :disabled="!dontAcceptTermsAndConditions"
                            :class="{ disabled: buttonIsDisabled }"
                        >
                            {{ $t("buttons.next") }}
                            <span
                                class="btn_loader"
                                v-if="isWaitingRequest"
                            ></span>
                        </button>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </form>
            </div>
        </div>
        <BaseModel
            @closeModel="toggleCampaignMediaModal"
            :show="campaignMediaModalIsOpen"
        >
            <template #modal>
                <div class="modal_successfully terms_modal">
                    <span
                        @click="
                            campaignMediaModalIsOpen = !campaignMediaModalIsOpen
                        "
                        class="close"
                        ><i class="fas fa-times"></i
                    ></span>
                    <div class="staticPages">
                        <div class="page_title">
                            <h1>{{ $t("titles.terms") }}</h1>
                        </div>
                        <div class="text_about" v-html="terms"></div>
                    </div>
                </div>
            </template>
        </BaseModel>
    </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";
import Datepicker from "vuejs-datepicker";
import BaseModel from "../ui/BaseModel.vue";
export default {
    name: "Register",

    components: {
        EyeIcon,
        EyeOffIcon,
        Datepicker,
        BaseModel,
    },

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: USER TYPE DATA
            userType: this.$route.params.type,
            // END:: USER TYPE DATA

            // START:: COUNTRIES KYES MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KYES MENU HANDLING DATA

            // START:: AGREE TERMS AND CONDITIONS DATA
            dontAcceptTermsAndConditions: false,
            // END:: AGREE TERMS AND CONDITIONS DATA

            // START:: SHOW PASSWORD HANDLING DATA
            passwordIsVisible: false,
            confirmPasswordIsVisible: false,
            // END:: SHOW PASSWORD HANDLING DATA

            // START:: REGISTER DATA
            registerData: {
                fullname: "",
                phone: "",
                phone_key: null,
                email: "",
                country_id: "",
                city_id: "",
                password: "",
                confirmPassword: "",
                gender: "",
                device_token: "123456789",
                type: "ios",
                // START:: USER ID
                id: null,
                user_id: null,
                // END:: USER ID
                date_of_birth: "",
            },
            // END:: REGISTER DATA
            selectedCountryId: null,

            // START:: BUTTON DISABLED
            buttonIsDisabled: false,
            // END:: BUTTON DISABLED

            // START:: HANDLING VIDEO MODEL DATA
            campaignMediaModalIsOpen: false,
            // END:: HANDLING VIDEO MODEL DATA

            // START:: TERMS DATA
            terms: "",
            // END:: TERMS DATA
            disabledDates: {
                from: new Date(Date.now()),
            },
        };
    },

    // watch: {
    //     'registerData.phone'( newVal) {
    //         if( 
    //             (newVal.length != this.$store.state.phoneDigitNum) && 
    //             newVal[0] != "0" 
    //         ) {
    //             this.submitAfterValidation();
    //         }
    //     }
    // },

    methods: {
        async userLocation() {
            const {
                data: { loc },
            } = await this.$axios.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                {
                    responseType: "text",
                    transformResponse: (data) =>
                        Object.fromEntries(
                            data
                                .trim()
                                .split("\n")
                                .map((line) => line.split("="))
                        ),
                }
            );

            this.$store.state.currentLocation = loc;
            this.$store.dispatch("getCountries");
        },

        // START:: HANDLING MAODALS
        toggleCampaignMediaModal() {
            this.campaignMediaModalIsOpen = !this.campaignMediaModalIsOpen;
        },
        // END:: HANDLING MAODALS

        // START:: GET TERMS
        getStaticContent() {
            this.$axios
                .get("settings/terms", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.terms = res.data.data.terms;
                });
        },
        // END:: GET TERMS

        // validEmail(email) {
            //   var re =
        //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //   return re.test(email);
        // },

        // START:: SUBMIT REGISTER FORM
        validateRegisterForm() {
            this.isWaitingRequest = true;
            this.buttonIsDisabled = true;
            if (this.registerData.fullname == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.nameValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            }
            // else if (this.registerData.phone == "") {
            //     this.$iziToast.error({
            //         timeout: 5000,
            //         message: this.$t("validation.phoneValidation"),
            //         position: this.$t("position"),
            //         rtl: this.$t("dir"),
            //     });
            //     this.buttonIsDisabled = false;
            //     this.isWaitingRequest = false;
            //     return;
            else if (
                (this.registerData.phone.length !=
                this.$store.state.phoneDigitNum)
            ) {
            if (this.registerData.phone[0] == "0" ) {
                let numberArray = [...this.registerData.phone];
                numberArray.splice(0, 1);
                this.registerData.phone = numberArray.join('');
                this.submitAfterValidation();
            } else {
                this.$iziToast.error({
                    timeout: 5000,
                    message:
                        this.$t("validation.phoneValidationLength") +
                        " " +
                        this.$store.state.phoneDigitNum +
                        " " +
                        this.$t("validation.digit"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            }

            // if ( this.registerData.phone[0] == "0" ) {
            //     let numberArray = [...this.registerData.phone];
            //     numberArray.splice(0, 1);
            //     this.registerData.phone = numberArray.join('');
            // }
        }
            // else if (this.registerData.email == "") {
            //   this.$iziToast.error({
            //     timeout: 5000,
            //     message: this.$t("validation.emailValidation"),
            //     position: this.$t("position"),
            //     rtl: this.$t("dir"),
            //   });
            //   this.buttonIsDisabled = false;
            //   this.isWaitingRequest = false;
            //   return;
            // } else if (!this.validEmail(this.registerData.email)) {
            //   this.$iziToast.error({
            //     timeout: 5000,
            //     message: this.$t("validation.emailValidationValid"),
            //     position: this.$t("position"),
            //     rtl: this.$t("dir"),
            //   });
            //   this.buttonIsDisabled = false;
            //   this.isWaitingRequest = false;
            //   return;
            // }
            else if (this.registerData.country_id == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.countryValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            } else if (this.registerData.city_id == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.cityValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            } else if (this.registerData.password == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            } else if (this.registerData.password.length < 6) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidationDigets"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            } else if (
                this.registerData.confirmPassword != this.registerData.password
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.confirmPasswordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            } else {
                this.submitAfterValidation();
            }
        },

        submitAfterValidation() {
                            this.registerData.phone_key =
                    this.$store.state.selectedCountry.phonecode;

                if (this.userType == "investor") {
                    this.$axios
                        .post("investor/signup", this.registerData)
                        .then((res) => {
                            if (res.data.status == "success") {
                                // this.$iziToast.success({
                                //   timeout: 5000,
                                //   message: this.$t("auth.sign_up_success_msg"),
                                //   position: this.$t("position"),
                                //   rtl: this.$t("dir"),
                                // });

                                localStorage.setItem(
                                    "inflco_user_is_active",
                                    res.data.data.is_active
                                );
                                localStorage.setItem(
                                    "inflco_user_type",
                                    "investor"
                                );
                                localStorage.setItem(
                                    "inflco_user_id",
                                    res.data.data.id
                                );
                                localStorage.setItem(
                                    "inflco_user_token",
                                    res.data.data.token.access_token
                                );
                                localStorage.setItem(
                                    "inflco_user_phone",
                                    this.registerData.phone_key +
                                        this.registerData.phone
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_key",
                                    this.registerData.phone_key
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_number",
                                    this.registerData.phone
                                );
                                setTimeout(() => {
                                    this.$router.replace("/company-register");
                                }, 2000);
                            }
                        })
                        .catch((error) => {
                            this.$iziToast.error({
                                timeout: 5000,
                                message: error.response.data.message,
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                            this.buttonIsDisabled = false;
                            this.isWaitingRequest = false;
                        });
                } else if (this.userType == "influincer") {
                    this.$axios
                        .post("influencer/signup", this.registerData)
                        .then((res) => {
                            if (res.data.status == "success") {
                                // this.$iziToast.success({
                                //   timeout: 5000,
                                //   message: res.data.message,
                                //   position: this.$t("position"),
                                //   rtl: this.$t("dir"),
                                // });

                                localStorage.setItem(
                                    "inflco_user_is_active",
                                    res.data.data.is_active
                                );
                                localStorage.setItem(
                                    "inflco_user_type",
                                    "influincer"
                                );
                                localStorage.setItem(
                                    "inflco_user_name",
                                    res.data.data.username
                                );
                                localStorage.setItem(
                                    "inflco_user_id",
                                    res.data.data.id
                                );
                                localStorage.setItem(
                                    "inflco_user_token",
                                    res.data.data.token.access_token
                                );
                                localStorage.setItem(
                                    "inflco_user_phone",
                                    this.registerData.phone_key +
                                        this.registerData.phone
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_key",
                                    this.registerData.phone_key
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_number",
                                    this.registerData.phone
                                );
                                setTimeout(() => {
                                    this.$router.replace("/register-work-info");
                                }, 2000);
                            }
                        })
                        .catch((error) => {
                            this.$iziToast.error({
                                timeout: 5000,
                                message: error.response.data.message,
                                position: "bottomRight",
                                rtl: true,
                            });
                            this.buttonIsDisabled = false;
                            this.isWaitingRequest = false;
                        });
                }
        },
        // END:: SUBMIT REGISTER FORM

        // START:: TOGGLE COUNTRIES KYES MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KYES MENU

        // START:: SELECT COUNTRY
        selectCountry(item) {
            // this.selectedCountry.flag = item.flag;
            // this.selectedCountry.phonecode = item.phonecode;
            this.$store.dispatch("selectCountry", { item });
        },
        // END:: SELECT COUNTRY

        // START:: TOGGLE PASSWORD VISIBILITY
        togglePasswordVisibility(id) {
            // TOGGLE TYPE
            let targetElement = document.getElementById(id);
            if (targetElement.type == "password") {
                targetElement.type = "text";
            } else if (targetElement.type == "text") {
                targetElement.type = "password";
            }

            // TOGGLE ICON
            if (id == "password") {
                this.passwordIsVisible = !this.passwordIsVisible;
            } else {
                this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY

        // START:: GET CITIES BY COUNTRY ID
        getCitiesByCountryID() {
            this.$store.dispatch("getCities", {
                id: this.registerData.country_id,
            });
        },
        // END:: GET CITIES BY COUNTRY ID
    },

    created() {
        // ======== START:: HANDLING API ========
        // START:: AXIOS GET COUNTRIES
        // END:: AXIOS GET COUNTRIES
        this.userLocation();
        this.getStaticContent();
        // ======== END:: HANDLING API ========
    },
};
</script>

<template>
    <div class="chat_wrapper" :key="$route.params.influincer_id">
        <!-- START:: HEAD CHAT -->
        <Loader v-if="loadMessages" class="fadeIn"></Loader>
        <section v-else class="fadeIn">
            <div class="head_Chat" v-if="reciverData">
                <div class="info_influincer">
                    <AvatarName
                        :nameAvatar="reciverData.fullname"
                        v-if="
                            reciverData.image.includes('backgrounds/avatar.png')
                        "
                    />
                    <img
                        :src="reciverData.image"
                        v-if="
                            !reciverData.image.includes(
                                'backgrounds/avatar.png'
                            )
                        "
                    />
                    <div class="name">
                        <router-link
                            :to="{
                                name: 'InfluincerProfile',
                                params: {
                                    influincer_id: reciverData.id,
                                },
                            }"
                            class="profile_route text-decoration-none"
                            v-if="userType == 'investor'"
                        >
                            <h3>
                                {{ reciverData.fullname }}
                            </h3>
                        </router-link>
                        <h3 @click="toggleInvestorProfileSideMenu" v-else>
                            {{ reciverData.fullname }}
                        </h3>

                        <!-- <h3>{{ reciverData.fullname }}</h3> -->
                        <!-- <h6>{{ reciverData.phone }}</h6> -->
                    </div>
                </div>
                <div class="create_contract_route_wrapper">
                    <router-link
                        :to="'/create-contract/' + userId + '/' + chatId"
                        v-if="userType == 'investor' && contractData == null"
                    >
                        <span @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <span>
                                {{ $t("buttons.create_contract_now") }}
                            </span>
                        </span>

                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </router-link>
                    <router-link
                        :to="'/contract/' + contractData.id"
                        v-else-if="
                            userType == 'investor' &&
                            contractData != null &&
                            contractData.status == 'pending'
                        "
                    >
                        <span @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <span>
                                {{
                                    $t(
                                        "buttons.waiting_for_influincer_to_accept"
                                    )
                                }}
                            </span>
                        </span>

                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </router-link>
                    <router-link
                        :to="'/influincer-contract/' + contractData.id"
                        v-else-if="
                            userType == 'influincer' &&
                            contractData != null &&
                            contractData.status == 'pending'
                        "
                    >
                        <span class="accept-button" @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/green_check.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <div>
                                <span>
                                    {{ $t("buttons.investor_create_contract") }}
                                </span>
                                <span class="green-color">
                                    {{ $t("buttons.click_here_to_accept") }}
                                </span>
                            </div>
                        </span>

                        <img
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </router-link>
                    <button
                        class="position-relative overflow-hidden"
                        v-else-if="
                            userType == 'investor' &&
                            contractData != null &&
                            contractData.status == 'influencer_accept' &&
                            contractData.paid == 0 &&
                            contractData.cancel_request == null &&
                            !seeAllPayment
                        "
                        @click="togglePaymentMenu"
                    >
                        <div class="loader-payment" v-if="waiting_payment">
                            <span class="btn_loader_blue"></span>
                        </div>
                        <span class="accept-button" @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/green_check.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <div>
                                <span>
                                    {{
                                        $t(
                                            "buttons.influencer_accepted_your_contract"
                                        )
                                    }}
                                </span>
                                <span class="green-color">
                                    {{ $t("buttons.click_here_to_pay") }}
                                </span>
                            </div>
                        </span>

                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </button>

                    <button
                        class="position-relative overflow-hidden fadeIn cursor-defult"
                        v-else-if="
                            userType == 'investor' &&
                            contractData != null &&
                            contractData.status == 'influencer_accept' &&
                            contractData.paid == 0 &&
                            contractData.cancel_request == null &&
                            seeAllPayment
                        "
                    >
                        <div class="loader-payment" v-if="waiting_payment">
                            <span class="btn_loader_blue"></span>
                        </div>
                        <div class="payment-buttons">
                            <span class="title">
                                {{ $t("buttons.choose_payment_type") }}
                            </span>
                            <ul>
                                <li>
                                    <span @click="Stripepayment">
                                        <img
                                            src="../../assets/media/payment/stripe.png"
                                            alt="icon"
                                            width="20"
                                            height="20"
                                        />
                                    </span>
                                </li>

                                <li>
                                    <span @click="paypalPayment">
                                        <img
                                            src="../../assets/media/payment/paypal.png"
                                            alt="icon"
                                            width="20"
                                            height="20"
                                        />
                                    </span>
                                </li>

                                <li>
                                    <span
                                        @click="walletPayment"
                                        class="my-wallet"
                                    >
                                        <img
                                            src="../../assets/media/payment/wallet.svg"
                                            alt="icon"
                                            width="20"
                                            height="20"
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </button>

                    <router-link
                        :to="'/influincer-contract/' + contractData.id"
                        v-else-if="
                            userType == 'influincer' &&
                            contractData != null &&
                            contractData.paid == 0 &&
                            contractData.status == 'influencer_accept' &&
                            contractData.cancel_request == null
                        "
                    >
                        <span @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <span>
                                {{ $t("buttons.Waiting_for_investor_to_pay") }}
                            </span>
                        </span>

                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </router-link>
                    <button
                        v-if="
                            userType == 'investor' &&
                            contractData != null &&
                            contractData.status == 'influencer_accept' &&
                            contractData.cancel_request !== null &&
                            contractData.cancel_request.made_by == 'investor'
                        "
                    >
                        <span @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <span>
                                {{ $t("buttons.waiting_for_accept_cancel") }}
                            </span>
                        </span>
                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </button>
                    <button
                        v-if="
                            userType == 'influincer' &&
                            contractData != null &&
                            contractData.status == 'influencer_accept' &&
                            contractData.cancel_request !== null &&
                            contractData.cancel_request.made_by == 'influencer'
                        "
                    >
                        <span @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <span>
                                {{ $t("buttons.waiting_for_accept_cancel") }}
                            </span>
                        </span>
                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </button>
                    <button
                        @click="openNotification"
                        v-if="
                            userType == 'influincer' &&
                            contractData != null &&
                            contractData.status == 'influencer_accept' &&
                            contractData.cancel_request !== null &&
                            contractData.cancel_request.made_by == 'investor'
                        "
                    >
                        <span class="accept-button" @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <div>
                                <span>
                                    {{ $t("buttons.there_is_cancel_request") }}
                                </span>
                                <span class="green-color">
                                    {{ $t("buttons.click_to_confirm") }}
                                </span>
                            </div>
                        </span>
                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </button>
                    <button
                        @click="openNotification"
                        v-if="
                            userType == 'investor' &&
                            contractData != null &&
                            contractData.status == 'influencer_accept' &&
                            contractData.cancel_request !== null &&
                            contractData.cancel_request.made_by == 'influencer'
                        "
                    >
                        <span class="accept-button" @click="closeSideMenus">
                            <img
                                src="../../assets/media/icons/document_blue.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                            <div>
                                <span>
                                    {{ $t("buttons.there_is_cancel_request") }}
                                </span>
                                <span class="green-color">
                                    {{ $t("buttons.click_to_confirm") }}
                                </span>
                            </div>
                        </span>
                        <img
                            class="chat_btn_arrow"
                            src="../../assets/media/icons/arrow_right_blue.svg"
                            alt="icon"
                            width="20"
                            height="20"
                        />
                    </button>
                </div>
            </div>
            <!-- END:: HEAD CHAT -->

            <div class="chat_box" ref="messageChat" @scroll="scrollSection">
                <!-- loader  -->
                <div class="loader-messages" v-if="loadChat">
                    <div class="dots">
                        <div class="dot dot1"></div>
                        <div class="dot dot2"></div>
                        <div class="dot dot3"></div>
                        <div class="dot dot4"></div>
                    </div>
                </div>
                <!-- end of vhat  -->
                <div class="end-of-chat" v-if="endOfchat">
                    {{ $t("frequently_words.end_of_chat") }}
                </div>
                <!-- messages  -->

                <div class="all-messages">
                    <div
                        class="message"
                        v-for="message in AllchatMessages"
                        :key="message.message_id"
                        :class="{
                            sender: message.message_position == 'me',
                            receiver: message.message_position != 'me',
                            media_content:
                                message.media_type == 'ad' ||
                                message.media_type == 'image' ||
                                message.media_type == 'file' ||
                                message.media_type == 'video',
                        }"
                    >
                        <span v-if="message.media_type == 'text'">
                            {{ message.message }}
                        </span>

                        <!-- START:: CAMPAIGN LIVE LINK -->
                        <div
                            class="campaign_link_wrapper"
                            v-if="
                                message.media_type == 'ad' &&
                                message.message_type == 'campaign' &&
                                message.campaign_data.ad_type == 'link'
                            "
                        >
                            <span
                                class="mb-2"
                                :data-content-type="message.media_type"
                                :data-content="message.campaign_data.link"
                                :data-content-compainType="
                                    message.campaign_data.ad_type
                                "
                                :data-content-id="message.campaign_data.id"
                                :data-content-status="
                                    message.campaign_data.status
                                "
                                @click="toggleCampaignMediaModal"
                            >
                                {{ message.campaign_data.link }}
                            </span>
                        </div>
                        <!-- START:: CAMPAIGN LIVE LINK -->

                        <!-- START:: CAMPAIGN STORY LINK -->
                        <div
                            class="campaign_link_wrapper"
                            v-if="
                                message.media_type == 'ad' &&
                                message.message_type == 'campaign' &&
                                message.campaign_data.ad_type == 'story'
                            "
                        >
                            <span
                                class="mb-2"
                                :data-content-type="message.media_type"
                                :data-content="message.campaign_data.link"
                                :data-content-compainType="
                                    message.campaign_data.ad_type
                                "
                                :data-content-id="message.campaign_data.id"
                                :data-content-status="
                                    message.campaign_data.status
                                "
                                @click="toggleCampaignMediaModal"
                            >
                                {{ message.campaign_data.link }}
                            </span>
                        </div>
                        <!-- START:: CAMPAIGN STORY LINK -->

                        <!-- START:: CAMPAIGN POST -->
                        <div
                            class="campaign_post_wrapper"
                            v-if="
                                message.media_type == 'ad' &&
                                message.message_type == 'campaign' &&
                                message.campaign_data.ad_type == 'content'
                            "
                        >
                            <div
                                class="mb-2"
                                :data-content-type="message.media_type"
                                :data-content="message.campaign_data.content"
                                :data-content-compainType="
                                    message.campaign_data.ad_type
                                "
                                :data-content-id="message.campaign_data.id"
                                :data-content-status="
                                    message.campaign_data.status
                                "
                                @click="toggleCampaignMediaModal"
                            >
                                <div
                                    v-html="message.campaign_data.content"
                                ></div>
                                <!-- <div class="campaign_post">
                  <p>test test</p>
                  <p>test test</p>
                </div> -->
                            </div>
                        </div>
                        <!-- END:: CAMPAIGN POST -->

                        <!-- START:: CAMPAIGN MEDIA IMAGE -->
                        <div
                            class="campaign_image_wrapper"
                            v-if="
                                message.media_type == 'ad' &&
                                message.message_type == 'campaign' &&
                                message.campaign_data.ad_type == 'image'
                            "
                        >
                            <img
                                :src="message.campaign_data.images[0]"
                                class="mb-2"
                                v-if="message.media_type == 'ad'"
                                :data-content-type="message.media_type"
                                :data-content="message.campaign_data.images[0]"
                                :data-content-compainType="
                                    message.campaign_data.ad_type
                                "
                                :data-content-id="message.campaign_data.id"
                                :data-content-status="
                                    message.campaign_data.status
                                "
                                @click="toggleCampaignMediaModal"
                            />
                        </div>
                        <!-- END:: CAMPAIGN MEDIA IMAGE -->

                        <!-- START:: NORMAL MEDIA IMAGE  -->
                        <img
                            @click="toggleShowImageModal(); setSelectedImage(message.message)"
                            :src="message.message"
                            class="mb-2"
                            v-if="
                                message.media_type == 'image' &&
                                message.message_type == 'normal'
                            "
                        />
                        <!-- END:: NORMAL MEDIA IMAGE  -->

                        <!-- START:: CAMPAIGN VIDEO -->
                        <div
                            class="campaign_video_wrapper"
                            v-if="
                                message.media_type == 'ad' &&
                                message.message_type == 'campaign' &&
                                message.campaign_data.ad_type == 'video'
                            "
                        >
                            <div
                                class="overlay"
                                :data-content-type="message.media_type"
                                :data-content-messageType="message.message_type"
                                :data-content-compainType="
                                    message.campaign_data.ad_type
                                "
                                :data-content="
                                    message.campaign_data.video[0].url
                                "
                                :data-content-id="message.campaign_data.id"
                                :data-content-status="
                                    message.campaign_data.status
                                "
                                @click="toggleCampaignMediaModal"
                            >
                                <button type="button">
                                    <i class="fas fa-play fa-lg"></i>
                                </button>
                            </div>
                            <video>
                                <source
                                    :src="message.campaign_data.video[0].url"
                                    type="video/mp4"
                                />
                            </video>
                        </div>
                        <!-- END:: CAMPAIGN VIDEO -->

                        <!-- START:: MORMAL MEDIA VIDEO -->
                        <video
                            controls
                            v-if="
                                (message.media_type == 'file' &&
                                    message.message_type == 'normal') ||
                                (message.media_type == 'video' &&
                                    message.message_type == 'normal')
                            "
                        >
                            <source :src="message.message" type="video/mp4" />
                        </video>
                        <!-- END:: MORMAL MEDIA VIDEO -->

                        <div
                            class="media_type"
                            v-if="message.message_type == 'campaign'"
                        >
                            <span
                                class="waiting-text mx-1"
                                v-if="message.campaign_data.status == 'pending'"
                            >
                                <i class="far fa-clock"></i>
                            </span>
                            <span
                                class="accept-text mx-1"
                                v-if="
                                    message.campaign_data.status ==
                                    'investor_accept'
                                "
                            >
                                <i class="fas fa-check"></i>
                            </span>
                            <span
                                class="refuced-text mx-1"
                                v-if="
                                    message.campaign_data.status ==
                                    'investor_reject'
                                "
                            >
                                <i class="fas fa-times"></i>
                            </span>
                            {{ $t("placeholders.campaign_media") }}
                        </div>
                        <div class="timestamp">{{ message.created_at }}</div>
                    </div>
                </div>
            </div>

            <div class="input-area">
                <div class="wrapper">
                    <button
                        class="upload_btn"
                        :class="{ active: uploadMenuIsOpen }"
                        @click="
                            toggleUploadMenu();
                            resetFiles();
                            closeAllCampaignMediaMenus();
                        "
                    >
                        <i class="fas fa-plus"></i>
                    </button>

                    <transition mode="in-out" name="fade">
                        <div
                            class="upload_menu_wrapper"
                            v-if="uploadMenuIsOpen"
                        >
                            <!-- <div
                class="d-flex align-items-center"
                v-if="
                  contractData != null &&
                  contractData.paid == 1 &&
                  campaignType == 'content' &&
                  userType == 'influincer'
                "
              >
                <button
                  @click="
                    toggleUploadMenu();
                    toggleTextEditor();
                  "
                >
                  <img src="../../assets/media/icons/mic.svg" />
                </button>
                <span
                  class="media_type"
                  @click="
                    toggleUploadMenu();
                    toggleTextEditor();
                  "
                >
                  {{ $t("buttons.write_campaign_post") }}
                </span>
              </div>

              <div
                class="d-flex align-items-center"
                v-if="
                  contractData != null &&
                  contractData.paid == 1 &&
                  (campaignType == 'link' ||
                    campaignType == 'video' ||
                    campaignType == 'image') &&
                  userType == 'influincer'
                "
              >
                <label for="uploadCampaign" @click="toggleUploadMenu">
                  <img src="../../assets/media/icons/mic.svg" />
                </label>
                <span
                  class="media_type"
                  v-if="campaignType == 'image' || campaignType == 'video'"
                >
                  {{ $t("buttons.campaign_media") }}
                </span>
                <input
                  type="file"
                  id="uploadCampaign"
                  @change="uploadCampaignFile"
                  :accept="campaignType == 'image' ? 'image/*' : 'video/mp4'"
                  v-if="campaignType == 'image' || campaignType == 'video'"
                />
                <input
                  class="campaign_text"
                  type="url"
                  :placeholder="$t('placeholders.enter_campaign_live_link')"
                  v-model="campaignLiveLink"
                  v-else
                />
              </div> -->

                            <!-- START:: CAMBAIGN MEDIA MENU TOGGLE BTN -->
                            <div
                                class="d-flex align-items-center"
                                v-if="
                                    contractData != null &&
                                    contractData.paid == 1 &&
                                    userType == 'influincer'
                                "
                                @click="
                                    toggleUploadMenu();
                                    toggleCampaignBtnsMenu();
                                "
                            >
                                <label for="uploadCampaign">
                                    <img
                                        src="../../assets/media/icons/mic.svg"
                                    />
                                </label>
                                <span class="media_type">
                                    {{ $t("buttons.campaign_media") }}
                                </span>
                            </div>
                            <!-- END:: CAMBAIGN MEDIA MENU TOGGLE BTN -->

                            <!-- START:: NORMAL MEDIA BTN -->
                            <div class="d-flex align-items-center">
                                <label for="upload" @click="toggleUploadMenu">
                                    <img
                                        src="../../assets/media/icons/image.svg"
                                    />
                                </label>
                                <span class="media_type">
                                    {{ $t("buttons.send_media") }}
                                </span>
                                <input
                                    type="file"
                                    id="upload"
                                    @change="uploadFile"
                                />
                            </div>
                            <!-- END:: NORMAL MEDIA BTN -->
                        </div>
                    </transition>
                </div>

                <div class="text_wrapper">
                    <transition mode="in-out" name="fade">
                        <!-- START:: CAMPAIGN MEDIA BUTONS WRAPPER -->
                        <div
                            class="campaign_media_wrapper"
                            v-if="campaignMediaBtnsWrapperIsOpen"
                        >
                            <!--  START:: IMAGE CAMPAIGN BUTTON -->
                            <label
                                for="uploadCampaignImage"
                                class="campaign_media_single_button"
                                v-if="
                                    contractData.campaign_type.includes('image')
                                "
                                @click="toggleCampaignBtnsMenu()"
                            >
                                <div class="campaign_icon">
                                    <img
                                        src="../../assets/media/icons/image.svg"
                                        alt="icon"
                                        width="25px"
                                        height="25px"
                                    />
                                    <span class="campaign_type_title">
                                        {{ $t("placeholders.image") }}
                                    </span>
                                </div>
                            </label>
                            <input
                                type="file"
                                id="uploadCampaignImage"
                                @change="uploadCampaignFile"
                                accept="image/*"
                            />
                            <!--  END:: IMAGE CAMPAIGN BUTTON -->

                            <!--  START:: VIDEO CAMPAIGN BUTTON -->
                            <label
                                for="uploadCampaignVideo"
                                class="campaign_media_single_button"
                                v-if="
                                    contractData.campaign_type.includes('video')
                                "
                                @click="toggleCampaignBtnsMenu()"
                            >
                                <div class="campaign_icon">
                                    <img
                                        src="../../assets/media/icons/video.svg"
                                        alt="icon"
                                        width="25px"
                                        height="25px"
                                    />
                                    <span class="campaign_type_title">
                                        {{ $t("placeholders.the_video") }}
                                    </span>
                                </div>
                            </label>
                            <input
                                type="file"
                                id="uploadCampaignVideo"
                                @change="uploadCampaignVideo"
                                accept="video/mp4"
                            />
                            <!--  END:: VIDEO CAMPAIGN BUTTON -->

                            <!--  START:: LIVE LINK CAMPAIGN BUTTON -->
                            <button
                                class="campaign_media_single_button"
                                v-if="
                                    contractData.campaign_type.includes('link')
                                "
                                @click="
                                    toggleCampaignBtnsMenu();
                                    toggleLiveLinkInput();
                                "
                            >
                                <div class="campaign_icon">
                                    <img
                                        src="../../assets/media/icons/live.svg"
                                        alt="icon"
                                        width="25px"
                                        height="25px"
                                    />
                                    <span class="campaign_type_title">
                                        {{ $t("placeholders.live") }}
                                    </span>
                                </div>
                            </button>
                            <!--  END:: LIVE LINK CAMPAIGN BUTTON -->

                            <!--  START:: STORY LINK CAMPAIGN BUTTON -->
                            <button
                                class="campaign_media_single_button"
                                v-if="
                                    contractData.campaign_type.includes('story')
                                "
                                @click="
                                    toggleCampaignBtnsMenu();
                                    toggleStoryLinkInput();
                                "
                            >
                                <div class="campaign_icon">
                                    <img
                                        src="../../assets/media/icons/story.svg"
                                        alt="icon"
                                        width="25px"
                                        height="25px"
                                    />
                                    <span class="campaign_type_title">
                                        {{ $t("placeholders.story") }}
                                    </span>
                                </div>
                            </button>
                            <!--  END:: STORY LINK CAMPAIGN BUTTON -->

                            <!--  START:: POST CAMPAIGN BUTTON -->
                            <button
                                class="campaign_media_single_button"
                                v-if="
                                    contractData.campaign_type.includes(
                                        'content'
                                    )
                                "
                                @click="
                                    toggleCampaignBtnsMenu();
                                    toggleTextEditor();
                                "
                            >
                                <div class="campaign_icon">
                                    <img
                                        src="../../assets/media/icons/post.svg"
                                        alt="icon"
                                        width="25px"
                                        height="25px"
                                    />
                                    <span class="campaign_type_title">
                                        {{ $t("placeholders.post") }}
                                    </span>
                                </div>
                            </button>
                            <!--  END:: POST CAMPAIGN BUTTON -->
                        </div>
                        <!-- END:: CAMPAIGN MEDIA BUTONS WRAPPER -->
                    </transition>

                    <!-- START:: SHOW CAMPAIGN MEDIA SELECTED FILE -->
                    <transition mode="out-in" name="fade">
                        <div
                            class="upload_file_wrapper"
                            v-if="selectedCampaignFile"
                        >
                            {{ selectedCampaignFileName }}

                            <button
                                class="emoji_btn p-0"
                                @click="deleteCampaignFile"
                            >
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </transition>
                    <!-- START:: SHOW CAMPAIGN MEDIA SELECTED FILE -->

                    <!-- START:: SHOW CAMPAIGN MEDIA SELECTED FILE -->
                    <transition mode="out-in" name="fade">
                        <div
                            class="upload_file_wrapper"
                            v-if="selectedCampaignVideo"
                        >
                            {{ selectedCampaignVideoName }}

                            <button
                                class="emoji_btn p-0"
                                @click="deleteCampaignFile"
                            >
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </transition>
                    <!-- START:: SHOW CAMPAIGN MEDIA SELECTED FILE -->

                    <!-- START:: SHOW NORMAL MEDIA SELECTED FILE -->
                    <transition mode="out-in" name="fade">
                        <div class="upload_file_wrapper" v-if="selectedFile">
                            {{ selectedFileName }}

                            <button class="emoji_btn p-0" @click="deleteFile">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </transition>
                    <!-- END:: SHOW NORMAL MEDIA SELECTED FILE -->

                    <!-- START:: CKEDITOR -->
                    <transition mode="in-out" name="fade">
                        <ckeditor
                            :editor="editor"
                            v-model="campaignPostContent"
                            :config="editorConfig"
                            v-if="campaignPostTextEditorIsOpen"
                        ></ckeditor>
                    </transition>
                    <!-- END:: CKEDITOR -->

                    <!-- START:: LIVE LINK INPUT -->
                    <transition mode="in-out" name="fade">
                        <input
                            class="campaign_link_input"
                            type="url"
                            :placeholder="
                                $t('placeholders.enter_campaign_live_link')
                            "
                            v-model="campaignLiveLink"
                            v-if="campaignLiveLinkIsOpen"
                        />
                    </transition>
                    <!-- END:: LIVE LINK INPUT -->

                    <!-- START:: STORY LINK INPUT -->
                    <transition mode="in-out" name="fade">
                        <input
                            class="campaign_link_input"
                            type="url"
                            :placeholder="
                                $t('placeholders.enter_campaign_story_link')
                            "
                            v-model="campaignStoryLink"
                            v-if="campaignStoryLinkIsOpen"
                        />
                    </transition>
                    <!-- END:: STORY LINK INPUT -->

                    <!-- START:: TEXT MESSAGE INPUT -->
                    <div
                        class="input_wrapper"
                        :class="{
                            disabled_background:
                                uploadMenuIsOpen || selectedFile != null,
                        }"
                    >
                        <input
                            type="text"
                            :placeholder="$t('placeholders.type_your_message')"
                            v-model.trim="chatContent.message"
                            @keyup.enter="sendMessage"
                            @click="closeMenues"
                        />

                        <button type="button" class="emoji_btn">
                            <!-- START:: EMOJI CARD -->
                            <div class="chat-emoji wrapper">
                                <emoji-picker
                                    @emoji="append"
                                    :search="searchEmoji"
                                >
                                    <div
                                        class="emoji-invoker"
                                        slot="emoji-invoker"
                                        slot-scope="{
                                            events: { click: clickEvent },
                                        }"
                                        @click.stop="clickEvent"
                                    >
                                        <i class="far fa-grin-alt"></i>
                                    </div>
                                    <div
                                        slot="emoji-picker"
                                        slot-scope="{ emojis, insert, display }"
                                    >
                                        <div
                                            class="emoji-picker"
                                            :style="{
                                                top: display.y + 'px',
                                                left: display.x + 'px',
                                            }"
                                        >
                                            <div class="emoji-picker__search">
                                                <input
                                                    type="text"
                                                    v-model="searchEmoji"
                                                    :placeholder="
                                                        $t(
                                                            'placeholders.search_for_emoji'
                                                        )
                                                    "
                                                    v-focus
                                                />
                                            </div>
                                            <div>
                                                <div
                                                    v-for="(
                                                        emojiGroup, category
                                                    ) in emojis"
                                                    :key="category"
                                                >
                                                    <h5>{{ category }}</h5>
                                                    <div class="emojis">
                                                        <span
                                                            v-for="(
                                                                emoji, emojiName
                                                            ) in emojiGroup"
                                                            :key="emojiName"
                                                            @click="
                                                                insert(emoji)
                                                            "
                                                            :title="emojiName"
                                                            >{{ emoji }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </emoji-picker>
                            </div>
                            <!-- END:: EMOJI CARD -->
                        </button>
                    </div>
                    <!-- END:: TEXT MESSAGE INPUT -->
                </div>

                <button type="button" class="send_btn" @click="sendMessage">
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                    <span v-else><i class="fas fa-paper-plane"></i></span>
                </button>
            </div>
        </section>

        <!-- START:: MEDIA IMAGE MODAL -->
        <BaseModel
            @closeModelMedia="toggleShowImageModal"
            :show="showImageModalIsOpen"
        >
            <template #modal>
                <div class="modal_successfully">
                    <div
                        v-if="selectedImage"
                        class="p-2"
                    >
                        <img :src="selectedImage" alt="Chat Image" />
                    </div>
                </div>
            </template>
        </BaseModel>
        <!-- END:: MEDIA IMAGE MODAL -->

        <!-- START:: MODAL CANCEL -->
        <RateModule
            v-if="showRate"
            :showRate="showRate"
            :contractData="contractData"
            :showFinished="showFinished"
            :isWaitingRequest="isWaitingRequest"
            @closeModule="closeModule"
            @addRate="addRate"
            @finished="finished"
        ></RateModule>

        <BaseModel
            @closeModelMedia="toggleCampaignMediaModal"
            :show="campaignMediaModalIsOpen"
        >
            <template #modal>
                <div class="modal_successfully">
                    <!-- START:: CAMPAIGN MODAL VIDEO -->
                    <video
                        controls
                        v-if="
                            campaignModalData.type == 'ad' &&
                            campaignModalData.compainType == 'video'
                        "
                    >
                        <source :src="campaignModalData.src" type="video/mp4" />
                    </video>
                    <!-- END:: CAMPAIGN MODAL VIDEO -->

                    <!-- START:: CAMPAIGN MODAL IMAGE -->
                    <div
                        v-if="
                            campaignModalData.type == 'ad' &&
                            campaignModalData.compainType == 'image'
                        "
                        class="p-2"
                    >
                        <img :src="campaignModalData.src" alt="campaign file" />
                    </div>
                    <!-- END:: CAMPAIGN MODAL IMAGE -->

                    <!-- START:: CAMPAIGN MODAL LINK -->
                    <div
                        v-if="
                            campaignModalData.type == 'ad' &&
                            campaignModalData.compainType == 'link'
                        "
                        class="p-2"
                    >
                        <a :href="campaignModalData.src" target="_blank">
                            {{ campaignModalData.src }}
                            <!-- test link -->
                        </a>
                    </div>
                    <!-- END:: CAMPAIGN MODAL LINK -->

                    <!-- START:: CAMPAIGN MODAL STORY -->
                    <div
                        v-if="
                            campaignModalData.type == 'ad' &&
                            campaignModalData.compainType == 'story'
                        "
                        class="p-2"
                    >
                        <a :href="campaignModalData.src" target="_blank">
                            {{ campaignModalData.src }}
                        </a>
                    </div>
                    <!-- END:: CAMPAIGN MODAL STORY -->

                    <!-- START:: CAMPAIGN MODAL LINK -->
                    <div
                        v-if="
                            campaignModalData.type == 'ad' &&
                            campaignModalData.compainType == 'content'
                        "
                        class="p-2"
                    >
                        <div
                            class="post_wrapper"
                            v-html="campaignModalData.src"
                        ></div>
                    </div>
                    <!-- END:: CAMPAIGN MODAL LINK -->

                    <div
                        class="modal_body mt-5"
                        v-if="
                            userType == 'investor' &&
                            campaignModalData.messageStatus == 'pending'
                        "
                    >
                        <!-- START:: BUTTONS GROUP -->
                        <div class="btns_group">
                            <div class="btn_wrapper">
                                <button
                                    type="button"
                                    class="btn cancel_all_button"
                                    @click="
                                        changeMedia(
                                            'investor_reject',
                                            campaignModalData.messageId
                                        )
                                    "
                                >
                                    {{ $t("buttons.reject") }}
                                </button>
                            </div>

                            <div class="btn_wrapper">
                                <button
                                    class="btn accepted_btn"
                                    type="button"
                                    @click="
                                        changeMedia(
                                            'investor_accept',
                                            campaignModalData.messageId
                                        )
                                    "
                                >
                                    {{ $t("buttons.accept") }}
                                </button>
                            </div>
                        </div>
                        <!-- END:: BUTTONS GROUP -->

                        <!-- START:: NOTE -->
                        <div class="note_wrapper">
                            <h5>{{ $t("modals.note") }}</h5>
                            <div class="text">
                                {{ $t("modals.note_text_3") }}
                            </div>
                        </div>
                        <!-- END:: NOTE -->
                    </div>
                </div>
            </template>
        </BaseModel>
        <!-- ----------- stipe check out  ----------- -->
        <stripe-checkout
            ref="checkoutRef"
            :pk="publishableKey"
            :session-id="sessionId"
            :success-url="stripe_session_data.success_url"
            :cancel-url="stripe_session_data.cancel_url"
            :line-items="stripe_session_data.line_items"
            :mode="stripe_session_data.mode"
            :payment_method_types="stripe_session_data.payment_method_types"
        />

        <!-- START:: SUCCESS PAYMENT MODAL -->
        <BaseModel
            v-if="
                ($route.params.payment_status == 'success' &&
                    userType == 'investor') ||
                ($route.query.status == 'success' && userType == 'investor')
            "
            :show="successPaiedModalIsOpen"
        >
            <template #modal>
                <div class="modal_successfully p-5">
                    <div class="image_successfully">
                        <img
                            src="../../assets/media/illustrations/locked.svg"
                            alt="locked"
                            width="100"
                            height="100"
                            style="width: 50%"
                        />
                    </div>
                    <div class="text_successfully">
                        <p>{{ $t("validation.paymentSuccessfully") }}</p>
                    </div>
                    <form>
                        <div class="wrapper justify-content-center">
                            <button
                                class="btn"
                                type="button"
                                @click="toggleSuccessPaiedModal()"
                                :disabled="paymentButtonIsDisabled"
                            >
                                {{ $t("states.done") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequest"
                                ></span>
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </BaseModel>
        <!-- END:: SUCCESS PAYMENT MODAL -->

        <!-- START:: CANCEL PAYMENT MODAL -->
        <BaseModel
            v-if="
                $route.params.payment_status == 'cancled' &&
                userType == 'investor'
            "
            :show="cancledPaiedModalIsOpen"
        >
            <template #modal>
                <div class="modal_successfully p-5">
                    <div class="image_successfully">
                        <img
                            src="../../assets/media/illustrations/locked.svg"
                            alt="locked"
                            width="100"
                            height="100"
                            style="width: 50%"
                        />
                    </div>
                    <div class="text_successfully">
                        <p>{{ $t("validation.paymentCanceled") }}</p>
                    </div>
                    <form>
                        <div class="wrapper justify-content-center">
                            <button
                                class="btn"
                                type="button"
                                @click="toggleCancledPaiedModal()"
                            >
                                {{ $t("states.done") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequest"
                                ></span>
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </BaseModel>
        <!-- END:: CANCEL PAYMENT MODAL -->
    </div>
</template>

<script>
import BaseModel from "../ui/BaseModel.vue";
import EmojiPicker from "vue-emoji-picker";
import Loader from "./../loader/main-loader.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Echo from "laravel-echo";
import RateModule from "./../models/rate.vue";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import AvatarName from "../ui/AvatarName.vue";

export default {
    name: "Chat",
    components: {
        BaseModel,
        EmojiPicker,
        Loader,
        RateModule,
        StripeCheckout,
        AvatarName,
    },

    data() {
        return {
            // START:: STRIPE PUBLISH KEK
            publishableKey: null,
            // END:: STRIPE PUBLISH KEK

            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: PAYMENT BUTTON IS DISABLED
            paymentButtonIsDisabled: false,
            // END:: PAYMENT BUTTON IS DISABLED

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: SENDER ID
            senderId: localStorage.getItem("inflco_user_id"),
            // END:: SENDER ID

            // START:: CHAT ID DATA
            chatId: "",
            // END:: CHAT ID DATA

            // START:: USER ID DATA
            userId: this.$route.params.influincer_id,
            // END:: USER ID DATA

            // START:: CAMPAIGN TYPE DATA
            campaignType: null,
            // END:: CAMPAIGN TYPE DATA

            // START:: CAMPAIGN MEDIA BUTTON HANDLING DATA
            campaignMediaBtnsWrapperIsOpen: false,
            // END:: CAMPAIGN MEDIA BUTTON HANDLING DATA

            // START:: CAMPAIGN LIVE LINK INPUT HANDLING DATA
            campaignLiveLinkIsOpen: false,
            // END:: CAMPAIGN LIVE LINK INPUT HANDLING DATA

            // START:: CAMPAIGN STORY LINK INPUT HANDLING DATA
            campaignStoryLinkIsOpen: false,
            // END:: CAMPAIGN STORY LINK INPUT HANDLING DATA

            // START:: UPLAUD MENU HANDLING DATA
            uploadMenuIsOpen: false,
            // END:: UPLAUD MENU HANDLING DATA

            // START:: TEXT EDITOR HANDLING DATA
            campaignPostTextEditorIsOpen: false,
            // END:: TEXT EDITOR HANDLING DATA

            // START:: this. FILE DATA
            selectedFile: null,
            selectedFileName: null,
            selectedFiletype: null,
            // END:: SELECTED FILE DATA

            // START:: SELECTED CAMPAIGN FILE DATA
            selectedCampaignFile: null,
            selectedCampaignFileName: null,
            selectedCampaignFiletype: null,
            // END:: SELECTED CAMPAIGN FILE DATA

            // START:: SELECTED CAMPAIGN FILE DATA
            selectedCampaignVideo: null,
            selectedCampaignVideoName: null,
            selectedCampaignVideotype: null,
            // END:: SELECTED CAMPAIGN FILE DATA

            // START:: MESSAGES DATA
            AllchatMessages: [],
            // END:: MESSAGES DATA

            // START:: HANDLING VIDEO MODEL DATA
            campaignMediaModalIsOpen: false,
            // END:: HANDLING VIDEO MODEL DATA

            // START:: IMAGE MODAL DATA
            showImageModalIsOpen: false,
            selectedImage: null,
            // END:: IMAGE MODAL DATA

            successPaiedModalIsOpen: true,
            cancledPaiedModalIsOpen: true,

            // START:: CAMPAIGN MODAL DATA
            campaignModalData: {
                type: null,
                src: null,
                text: null,
                messageId: null,
                messageStatus: null,
                messageType: null,
                compainType: null,
            },
            campaignLiveLink: null,
            campaignStoryLink: null,
            // END:: CAMPAIGN MODAL DATA

            // START:: EMOJIS DATA
            SearchActive: false,
            searchEmoji: "",
            properties: false,
            // END:: EMOJIS DATA

            // START:: RECIVER DATA
            reciverData: null,
            // END:: RECIVER DATA

            // START:: KYES API
            chatContent: {
                message: "",
                media_type: "",
                message_type: "",
                receiver_id: this.$route.params.influincer_id,
            },
            // END:: KYES API
            contractData: "",
            // pagenation
            currentPage: null,
            last_page: "",
            loadChat: false,
            endOfchat: false,
            loadMessages: false,
            // socket
            userIdLogin: "",

            // START:: CKEDITOR DATA
            editor: ClassicEditor,
            campaignPostContent: "",
            editorConfig: {
                toolbar: false,
            },
            // END:: CKEDITOR DATA
            // socket
            chat_id: "",
            // rate
            isWaitingRequestRate: false,
            showRate: false,
            showFinished: false,
            showSuccessPaied: false,
            // --------   stripe checkout

            stripe_session_data: {
                success_url: "",
                cancel_url: "",
                payment_method_types: ["card"],
                line_items: [
                    {
                        price: "",
                        quantity: 1,
                    },
                ],
                mode: "payment",
            },

            sessionId: null,
            waiting_payment: false,
            paidStatus: false,
            seeAllPayment: false,
            unique_id: null,
        };
    },

    methods: {
        //   START:: TOGGLE INVESTOR PROFILE
        toggleInvestorProfileSideMenu() {
            this.$store.dispatch("toggleInvestorProfileSideMenu");
        },
        //   END:: TOGGLE INVESTOR PROFILE

        // START:: CLOSE ALL CAMPIGN MEDIA MENUS
        closeAllCampaignMediaMenus() {
            this.campaignMediaBtnsWrapperIsOpen = false;
            this.campaignPostTextEditorIsOpen = false;
            this.campaignLiveLinkIsOpen = false;
            this.campaignStoryLinkIsOpen = false;
            this.campaignPostContent = "";
            this.campaignLiveLink = null;
            this.campaignStoryLink = null;
        },
        // END:: CLOSE ALL CAMPIGN MEDIA MENUS

        // START:: EMOJIS HANDLING METHODS
        toggleClassActive() {
            if (this.SearchActive) {
                this.SearchActive = false;
            } else {
                this.SearchActive = true;
            }
        },
        append(emoji) {
            this.chatContent.message += emoji;
        },
        showProperties() {
            if (this.properties) {
                this.properties = false;
            } else {
                this.properties = true;
            }
        },
        // END:: EMOJIS HANDLING METHODS

        // START:: TOGGLE CAMPAIGN BUTTONS MENU METHOD
        toggleCampaignBtnsMenu() {
            this.campaignMediaBtnsWrapperIsOpen =
                !this.campaignMediaBtnsWrapperIsOpen;
        },
        // END:: TOGGLE CAMPAIGN BUTTONS MENU METHOD

        // START:: TOGGLE UPLOAD MENU
        toggleUploadMenu() {
            this.uploadMenuIsOpen = !this.uploadMenuIsOpen;
            this.chatContent.message = "";
        },
        // END:: TOGGLE UPLOAD MENU

        // START:: HANDLE UPLOAD FILE
        uploadFile(e) {
            this.selectedFile = e.currentTarget.files[0];
            this.selectedFileName = e.currentTarget.files[0].name;
            this.selectedFiletype = e.currentTarget.files[0].type;
        },
        deleteFile() {
            this.selectedFile = null;
            this.selectedFileName = null;
            this.selectedFiletype = null;
        },

        uploadCampaignFile(e) {
            this.selectedCampaignFile = e.currentTarget.files[0];
            this.selectedCampaignFileName = e.currentTarget.files[0].name;
            this.selectedCampaignFiletype = e.currentTarget.files[0].type;
        },
        uploadCampaignVideo(e) {
            this.selectedCampaignVideo = e.currentTarget.files[0];
            this.selectedCampaignVideoName = e.currentTarget.files[0].name;
            this.selectedCampaignVideotype = e.currentTarget.files[0].type;
        },
        deleteCampaignFile() {
            this.selectedCampaignFile = null;
            this.selectedCampaignFileName = null;
            this.selectedCampaignFiletype = null;
            this.selectedCampaignVideo = null;
            this.selectedCampaignVideoName = null;
            this.selectedCampaignVideotype = null;
        },
        // END:: HANDLE UPLOAD FILE

        // START:: RESET FILES VALUES
        resetFiles() {
            this.deleteFile();
            this.deleteCampaignFile();
        },
        // END:: RESET FILES VALUES

        // START:: IMAGE MODAL METHODS
        setSelectedImage(image) {
            this.selectedImage = image;
        },
        toggleShowImageModal() {
            this.showImageModalIsOpen = !this.showImageModalIsOpen;
        },
        // END:: IMAGE MODAL METHODS

        // START:: HANDLING MAODALS
        toggleCampaignMediaModal(e) {
            this.campaignMediaModalIsOpen = !this.campaignMediaModalIsOpen;

            this.campaignModalData.src =
                e.currentTarget.getAttribute("data-content");
            this.campaignModalData.type =
                e.currentTarget.getAttribute("data-content-type");
            this.campaignModalData.messageId =
                e.currentTarget.getAttribute("data-content-id");
            this.campaignModalData.messageStatus = e.currentTarget.getAttribute(
                "data-content-status"
            );
            this.campaignModalData.messageType = e.currentTarget.getAttribute(
                "data-content-messageType"
            );
            this.campaignModalData.compainType = e.currentTarget.getAttribute(
                "data-content-compainType"
            );
        },
        // END:: HANDLING MAODALS

        // START:: TOGGLE TEXT EDITOR
        toggleTextEditor() {
            this.campaignPostTextEditorIsOpen =
                !this.campaignPostTextEditorIsOpen;
        },
        // END:: TOGGLE TEXT EDITOR

        // START:: TOGGLE LIVE LINK INPUT
        toggleLiveLinkInput() {
            this.campaignLiveLinkIsOpen = !this.campaignLiveLinkIsOpen;
        },
        // END:: TOGGLE LIVE LINK INPUT

        // START:: TOGGLE STORY LINK INPUT
        toggleStoryLinkInput() {
            this.campaignStoryLinkIsOpen = !this.campaignStoryLinkIsOpen;
        },
        // START:: TOGGLE STORY LINK INPUT

        // START:: GET CHAT DATA
        getChatData() {
            this.loadMessages = true;
            this.currentPage = 1;
            this.$axios
                .get("/chats/" + this.userId + "?page=" + this.currentPage, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.endOfchat = false;
                    this.AllchatMessages = res.data.data;

                    if(res.data.stripe_publishable_key) {
                        this.publishableKey = res.data.stripe_publishable_key;
                    }

                    if (
                        res.data.contract_data != null &&
                        res.data.contract_data.transaction_id != null &&
                        res.data.contract_data.paid == 0
                    ) {
                        this.paidStatus = true;
                    }
                    this.contractData = res.data.contract_data;
                    setTimeout(() => {
                        this.$refs["messageChat"].scrollTop =
                            this.$refs["messageChat"].scrollHeight -
                            this.$refs["messageChat"].clientHeight;
                    }, 50);
                    this.loadMessages = false;
                    this.loadChat = false;
                    this.last_page = res.data.meta.last_page;
                    this.chatId = res.data.chat_id;
                    this.campaignType = res.data.contract_type;
                    this.reciverData = res.data.user_data;
                    this.$store.commit("setInvestorProfileData", {
                        investorData: res.data.user_data,
                    });
                    this.chat_id = res.data.chat_id;
                    if (
                        res.data.contract_data != null &&
                        res.data.contract_data.is_rated == false &&
                        res.data.contract_data.status == "closed_contract"
                    ) {
                        this.showRate = true;
                    } else {
                        this.showRate = false;
                    }
                    // if (res.data.unique_id != null) {
                    //     this.unique_id = String(res.data.unique_id);
                    // }
                    this.receiveMessage();
                });
        },
        getChatClick() {
            this.currentPage = 1;
            this.$axios
                .get("/chats/" + this.userId + "?page=" + this.currentPage, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.endOfchat = false;
                    this.AllchatMessages = res.data.data;
                    if(res.data.stripe_publishable_key) {
                        this.publishableKey = res.data.stripe_publishable_key;
                    }
                    setTimeout(() => {
                        this.$refs["messageChat"].scrollTop =
                            this.$refs["messageChat"].scrollHeight -
                            this.$refs["messageChat"].clientHeight;
                    }, 50);
                    this.loadMessages = false;
                    this.loadChat = false;
                    this.last_page = res.data.meta.last_page;
                    this.chatId = res.data.chat_id;
                    this.campaignType = res.data.contract_type;
                    this.reciverData = res.data.user_data;
                });
        },
        getChatAgain() {
            this.$axios
                .get("/chats/" + this.userId + "?page=" + this.currentPage, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.loadChat = false;
                    if(res.data.stripe_publishable_key) {
                        this.publishableKey = res.data.stripe_publishable_key;
                    }
                    res.data.data.forEach((element) => {
                        this.AllchatMessages.push(element);
                    });
                    this.last_page = res.data.meta.last_page;
                });
        },
        scrollSection() {
            if (this.currentPage !== this.last_page) {
                this.endOfchat = false;
                var chatSection = document.querySelector(".chat_box");
                if (chatSection.scrollTop == 0) {
                    this.currentPage += 1;
                    this.loadChat = true;
                    this.getChatAgain();
                }
            } else {
                this.endOfchat = true;
            }
        },
        // END:: GET CHAT DATA

        // START:: HANDLING MESSAGES
        sendMessage() {
            this.isWaitingRequest = true;

            if (
                this.chatContent.message == "" &&
                this.selectedFile == null &&
                this.selectedCampaignFile == null &&
                this.selectedCampaignVideo == null &&
                this.campaignLiveLink == null &&
                this.campaignStoryLink == null &&
                this.campaignPostContent == ""
            ) {
                this.isWaitingRequest = false;
                return;
            }

            const data = new FormData();
            data.append("receiver_id", this.chatContent.receiver_id);
            // START: SEND TEXT MESSAGE
            if (this.chatContent.message != "") {
                data.append("media_type", "text");
                data.append("message_type", "normal");
                data.append("message", this.chatContent.message);
                this.$axios
                    .post("chats", data, {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Content-type": `multipart/form-data;`,
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                        params: {
                            filter: null,
                        },
                    })
                    .then(() => {
                        this.getChatClick();
                        this.isWaitingRequest = false;
                        this.$store.dispatch("getChatsListAgain");
                    })
                    .catch(() => {
                        this.isWaitingRequest = false;
                    });
            }
            // END: SEND TEXT MESSAGE

            // START:: SET MEDIA TYPE && MESSAGE TYPE
            if (
                this.selectedFile != null &&
                this.selectedCampaignFile == null &&
                this.selectedCampaignVideo == null
            ) {
                data.append("message_type", "normal");
                if (
                    this.chatContent.message == "" &&
                    this.selectedFile.type.match("image.*")
                ) {
                    data.append("media_type", "image");
                    data.append("message", this.selectedFile);
                } else if (
                    this.chatContent.message == "" &&
                    this.selectedFile.type.match("video.*")
                ) {
                    data.append("media_type", "video");
                    data.append("message", this.selectedFile);
                }

                // START:: SEND NORMAL MEDIA
                this.$axios
                    .post("chats", data, {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Content-type": `multipart/form-data;`,
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                        params: {
                            filter: null,
                        },
                    })
                    .then(() => {
                        this.getChatClick();
                        this.isWaitingRequest = false;
                        this.$store.dispatch("getChatsListAgain");
                    })
                    .catch(() => {
                        this.isWaitingRequest = false;
                    });
                // END:: SEND NORMAL MEDIA
            } else if (
                (this.selectedFile == null &&
                    this.selectedCampaignFile != null) ||
                (this.selectedFile == null &&
                    this.selectedCampaignVideo != null) ||
                (this.selectedFile == null && this.campaignLiveLink != null) ||
                (this.selectedFile == null && this.campaignStoryLink != null) ||
                (this.selectedFile == null && this.campaignPostContent != "")
            ) {
                // data.append("message_type", "campaign");
                const campaignFomData = new FormData();
                campaignFomData.append("contract_id", this.contractData.id);
                if (
                    this.chatContent.message == "" &&
                    this.campaignPostContent != ""
                ) {
                    campaignFomData.append("type", "content");
                    campaignFomData.append("data", this.campaignPostContent);
                } else if (
                    this.chatContent.message == "" &&
                    this.campaignLiveLink != null
                ) {
                    campaignFomData.append("type", "link");
                    campaignFomData.append("data", this.campaignLiveLink);
                } else if (
                    this.chatContent.message == "" &&
                    this.campaignStoryLink != null
                ) {
                    campaignFomData.append("type", "story");
                    campaignFomData.append("data", this.campaignStoryLink);
                } else if (
                    this.chatContent.message == "" &&
                    this.campaignLiveLink == null &&
                    this.campaignStoryLink == null &&
                    this.selectedCampaignFile != null
                ) {
                    campaignFomData.append("type", "image");
                    campaignFomData.append(
                        "data[0]",
                        this.selectedCampaignFile
                    );
                } else if (
                    this.chatContent.message == "" &&
                    this.campaignLiveLink == null &&
                    this.campaignStoryLink == null &&
                    this.selectedCampaignVideo != null
                ) {
                    campaignFomData.append("type", "video");
                    campaignFomData.append(
                        "data[0]",
                        this.selectedCampaignVideo
                    );
                }

                // START:: SEND CAMPAIGN MEDIA
                this.$axios
                    .post("influencer/contract/create_ad", campaignFomData, {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Content-type": `multipart/form-data;`,
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                        params: {
                            filter: null,
                        },
                    })
                    .then(() => {
                        this.getChatClick();
                        this.isWaitingRequest = false;
                        this.$store.dispatch("getChatsListAgain");
                    })
                    .catch(() => {
                        this.isWaitingRequest = false;
                    });
                // END:: SEND CAMPAIGN MEDIA
            }

            // START:: RESET FEALDS
            this.chatContent.message = "";
            this.selectedFile = null;
            this.selectedFileName = null;
            this.selectedFileType = null;
            this.selectedCampaignFile = null;
            this.selectedCampaignVideo = null;
            this.selectedCampaignFileName = null;
            this.selectedCampaignFiletype = null;
            this.campaignPostContent = "";
            this.campaignLiveLink = null;
            this.campaignStoryLink = null;
            // END:: RESET FEALDS

            // START:: CLOSE TEXT EDITOR
            this.campaignPostTextEditorIsOpen = false;
            // END:: CLOSE TEXT EDITOR

            // START:: CLOSE BUTTONS MENU
            this.uploadMenuIsOpen = false;
            // END:: CLOSE BUTTONS MENU

            // START:: LIVE AND STORY LINK INPUTS
            this.campaignLiveLinkIsOpen = false;
            this.campaignStoryLinkIsOpen = false;
            // END:: LIVE AND STORY LINK INPUTS
        },
        // END:: HANDLING MESSAGES
        togglePaymentMenu() {
            this.waiting_payment = true;
            setTimeout(() => {
                this.seeAllPayment = true;
                this.waiting_payment = false;
            }, 500);
        },
        paypalPayment() {
            this.waiting_payment = true;
            window.location.href =
                "https://backend.inflco.com/app/pay_pal/" +
                this.contractData.id +
                "?redi_url=" +
                encodeURIComponent("https://inflco.com/chat/") +
                this.userId;
        },
        Stripepayment() {
            this.waiting_payment = true;
            const data = new FormData();
            // this.unique_id = String(
            //     (Math.random() + 1).toString(13).substring(7)
            // );
            // localStorage.setItem("inflco_unique_id", this.unique_id);
            // data.append("unique_id", this.unique_id);
            data.append(
                "success_url",
                `https://inflco.com/chat/${this.userId}/success`
                // "success_url",
                // `http://localhost:8080/chat/${this.userId}/success`
            );
            data.append(
                "cancel_url",
                `https://inflco.com/chat/${this.userId}/cancled`
                // "cancel_url",
                // `http://localhost:8080/chat/${this.userId}/cancled`
            );
            data.append("contract_id", this.contractData.id);
            // data.append("payment_method_types", ["card"]);

            this.$axios
                .post("investor/contract/generate_session_id", data, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Content-type": `multipart/form-data;`,
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.sessionId = res.data.data.id;
                    this.stripe_session_data.success_url = `https://inflco.com/chat/${this.userId}/success`;
                    this.stripe_session_data.cancel_url = `https://inflco.com/chat/${this.userId}/cancled`;
                    // this.stripe_session_data.success_url = `http://localhost:8080/chat/${this.userId}/success`;
                    // this.stripe_session_data.cancel_url = `http://localhost:8080/chat/${this.userId}/cancled`;
                    this.stripe_session_datapayment_method_types = ["card"];
                    this.stripe_session_dataline_items = [
                        {
                            price: res.data.data.amount_total,
                            quantity: 1,
                        },
                    ];
                    this.stripe_session_datamode = "payment";

                    // START:: REDIRECT TO CHECKOUT
                    this.$refs.checkoutRef.redirectToCheckout();
                    // END:: REDIRECT TO CHECKOUT
                    this.waiting_payment = false;
                });
        },
        walletPayment() {
            this.waiting_payment = true;
            const data = new FormData();
            data.append("id", this.contractData.id);
            data.append("pay_type", "wallet");
            // data.append("payment_method_types", ["card"]);

            this.$axios
                .post("investor/contract/pay_by_wallet", data, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Content-type": `multipart/form-data;`,
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.waiting_payment = false;
                    if (res.data.status == "fail") {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: res.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                    }
                    location.reload();
                })
                .catch((err) => {
                    this.waiting_payment = false;
                    this.$iziToast.error({
                        timeout: 5000,
                        message: err.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                });
        },
        changeMedia(status, id) {
            const data = new FormData();
            data.append("contract_status", status);
            data.append("ad_id", id);
            this.$axios
                .post("investor/contract/ad_change_status", data, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Content-type": `multipart/form-data;`,
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then(() => {
                    // location.reload();
                    this.campaignMediaModalIsOpen =
                        !this.campaignMediaModalIsOpen;
                    this.getChatData();
                });
        },
        // socket chat
        receiveMessage() {
            if (localStorage.getItem("inflco_user_token") != null) {
                window.io = require("socket.io-client");
                window.Echo = new Echo({
                    broadcaster: "socket.io",
                    host: "https://backend.inflco.com:6069",
                    csrfToken:
                        "Bearer " + localStorage.getItem("inflco_user_token"),
                    auth: {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem("inflco_user_token"),
                            Accept: "application/json",
                        },
                    },
                });
                // -----------------
                window.Echo.private(`inflco-chat.${this.chat_id}`).listen(
                    ".NewMessage",
                    (payload) => {
                        if (payload) {
                            this.getChatClick();
                            this.$store.dispatch("getChatsListAgain");
                        }
                    }
                );
            }
        },
        // rate
        closeModule() {
            this.isWaitingRequestRate = false;
            this.showRate = false;
            this.showFinished = false;
            this.showSuccessPaied = false;
        },
        addRate() {
            this.isWaitingRequestRate = true;
            setTimeout(() => {
                this.isWaitingRequestRate = false;
                this.showRate = !this.showRate;
                this.showFinished = !this.showFinished;
            }, 1500);
        },

        finished() {
            this.isWaitingRequestRate = true;
            setTimeout(() => {
                this.isWaitingRequestRate = false;
                this.showRate = false;
                this.showFinished = false;
            }, 1500);
        },
        // END:: MODAL CANCL
        closeSideMenus() {
            this.$store.dispatch("closeSideMenus");
        },
        openNotification() {
            this.$store.dispatch("toggleNotificationsMenu");
        },

        toggleSuccessPaiedModal() {
            this.isWaitingRequest = true;
            this.paymentButtonIsDisabled = true;
            const data = new FormData();
            data.append("id", this.contractData.id);
            data.append("payment_status", "accepted");
            this.$axios
                .post("investor/contract/pay", data, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Content-type": `multipart/form-data;`,
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then(() => {
                    this.successPaiedModalIsOpen =
                        !this.successPaiedModalIsOpen;
                    this.$router.replace("/chat/" + this.userId);
                    this.getChatData();
                    this.isWaitingRequest = false;
                    this.paymentButtonIsDisabled = false;
                });
        },

        toggleCancledPaiedModal() {
            // this.isWaitingRequest = true;
            this.$router.replace("/chat/" + this.userId);
            this.cancledPaiedModalIsOpen = !this.cancledPaiedModalIsOpen;
            // const data = new FormData();
            // data.append("id", this.contractData.id);
            // data.append("payment_status", "rejected");
            // this.$axios
            //     .post("investor/contract/pay", data, {
            //         headers: {
            //             Authorization:
            //                 "bearer" +
            //                 localStorage.getItem("inflco_user_token"),
            //             "cache-control": "no-cache",
            //             Accept: "application/json",
            //             "Content-type": `multipart/form-data;`,
            //             "Accept-language":
            //                 localStorage.getItem("inflco_app_lang"),
            //         },
            //     })
            //     .then(() => {
            //         this.cancledPaiedModalIsOpen =
            //             !this.cancledPaiedModalIsOpen;
            //         this.$router.replace("/chat/" + this.userId);
            //         this.getChatData();
            //         this.isWaitingRequest = false;
            //     });
        },

        closeMenues() {
            this.uploadMenuIsOpen = false;
            this.campaignPostTextEditorIsOpen = false;
            this.campaignMediaBtnsWrapperIsOpen = false;
            this.selectedFile = null;
            this.selectedFileName = null;
            this.selectedFiletype = null;
            this.selectedCampaignFile = null;
            this.selectedCampaignFileName = null;
            this.selectedCampaignFiletype = null;
            this.campaignLiveLink = null;
            this.campaignPostContent = "";
        },

        closeAll(e) {
            if (!this.$el.contains(e.target)) {
                this.uploadMenuIsOpen = false;
                this.campaignPostTextEditorIsOpen = false;
            }
        },
    },

    directives: {
        focus: {
            inserted(el) {
                el.focus();
            },
        },
    },
    watch: {
        "$route.params.influincer_id": {
            handler: function (value) {
                this.userId = value;
                this.chatContent.receiver_id = value;
                this.getChatData();
            },
        },
    },

    beforeDestroy() {
        document.removeEventListener("click", this.closeAll);
    },

    mounted() {
        this.getChatData();
        document.addEventListener("click", this.closeAll);
        // var unique_id = localStorage.getItem("inflco_unique_id");
        // if (unique_id != null) {
        //     this.unique_id = unique_id;
        // }
    },
};
</script>

<template>
    <section>
        <BaseModel @closeModel="$emit('closeModule')" :show="show">
            <template #modal>
                <div class="contracts_modal_report">
                    <div class="head_modal_report">
                        <h3>{{ $t("modals.report_title") }}</h3>
                        <p>{{ $t("modals.report_subtitle") }}</p>
                    </div>
                    <!-- START:: BODY MODAL -->
                    <div class="body_modal_report">
                        <form @submit.prevent="submitReportForm">
                            <div class="group mt-5">
                                <div class="inner_wrapper">
                                    <div
                                        class="wrapper mb-3"
                                        v-for="reason in reportReasons"
                                        :key="reason.id"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            :id="reason.id"
                                            name="reason"
                                            :value="reason.id"
                                            v-model="
                                                reportData.report_reason_id
                                            "
                                            @change="
                                                (showOtherReason = false),
                                                    (reportData.note = null)
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="reason.id"
                                        >
                                            {{ reason.note }}
                                        </label>
                                    </div>

                                    <div class="wrapper mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="addOther"
                                            name="reason"
                                            :value="true"
                                            v-model="showOtherReason"
                                            @change="
                                                reportData.report_reason_id =
                                                    null
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="addOther"
                                        >
                                            {{
                                                $t("placeholders.other_reason")
                                            }}
                                        </label>
                                    </div>

                                    <div
                                        class="wrapper fadeIn mb-3"
                                        v-if="showOtherReason"
                                    >
                                        <textarea
                                            :placeholder="
                                                $t('placeholders.other_reason')
                                            "
                                            v-model="reportData.note"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="note">
                                <h5>{{ $t("modals.note") }}</h5>
                                <p>
                                    {{ $t("modals.note_text_1") }}
                                    <br />
                                    {{ $t("modals.note_text_2") }}
                                </p>
                            </div>
                            <!-- START:: FORM BUTTONS GROUP -->
                            <div class="btns_group">
                                <div class="btn_wrapper">
                                    <button
                                        type="button"
                                        class="btn cancel_all_button"
                                        @click="
                                            $emit('closeModule');
                                            reportData.report_reason_id = null;
                                            reportData.note = null;
                                            showOtherReason = false;
                                        "
                                    >
                                        {{ $t("buttons.cancel") }}
                                    </button>
                                </div>

                                <div class="btn_wrapper">
                                    <button
                                        class="btn send_filter_btn"
                                        :class="{ disabled: emptyButton }"
                                    >
                                        {{ $t("buttons.send") }}
                                        <span
                                            class="btn_loader"
                                            v-if="isWaitingRequest"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                            <!-- END:: FORM BUTTONS GROUP -->
                        </form>
                    </div>
                </div>
            </template>
        </BaseModel>

        <BaseModel @closeModel="$emit('closeModule')" :show="showSuccessfully">
            <template #modal>
                <div class="modal_successfully">
                    <div class="image_successfully">
                        <img
                            src="../../assets/media/illustrations/successfully1.svg"
                            alt="successfully"
                            width="100"
                            height="100"
                        />
                    </div>
                    <div class="text_successfully">
                        <p>{{ $t("modals.request_sent") }}</p>
                    </div>
                    <form>
                        <div class="wrapper justify-content-center">
                            <button
                                class="btn"
                                type="button"
                                @click="$emit('continueReport')"
                            >
                                {{ $t("buttons.continue") }}
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </BaseModel>
    </section>
</template>

<script>
import BaseModel from "../ui/BaseModel.vue";
export default {
    props: ["show", "isWaitingRequest", "showSuccessfully"],
    components: {
        BaseModel,
    },

    data() {
        return {
            // START:: CONTRACT ID DATA
            contractId: this.$route.params.id,
            // END:: CONTRACT ID DATA

            // START:: REPORTT REASONS DATA
            reportReasons: null,
            // END:: REPORTT REASONS DATA

            // START:: REPORT DATA
            reportData: {
                report_reason_id: null,
                note: null,
            },
            // END:: REPORT DATA
            showOtherReason: false,
        };
    },
    computed: {
        emptyButton() {
            if (
                this.reportData.report_reason_id == null &&
                this.reportData.note == null
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        // START:: GET REPORTS REASONS
        getReportReasons() {
            this.$axios
                .get("report", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.reportReasons = res.data.data;
                });
        },
        // END:: GET REPORTS REASONS

        // START:: SUBMIT REPORT FORM
        submitReportForm() {
            // if (this.reportData.report_reason_id == null) {
            //   this.$iziToast.error({
            //     timeout: 5000,
            //     message: this.$t("validation.reason"),
            //     position: this.$t("position"),
            //     rtl: this.$t("dir"),
            //   });
            //   return;
            // }

            // START:: APPEND FORM DATA
            const theData = new FormData();
            theData.append("contract_id", this.contractId);
            if (this.reportData.report_reason_id !== null) {
                theData.append(
                    "report_reason_id",
                    this.reportData.report_reason_id
                );
            }

            theData.append("other_note", this.reportData.note);
            // END:: APPEND FORM DATA

            this.$axios
                .post("report", theData, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.$emit("sendReport");

                    if (res.data.status == "success") {
                        this.reportData.report_reason_id = null;
                        this.reportData.note = null;
                    }
                });
        },
        // END:: SUBMIT REPORT FORM
    },

    mounted() {
        // START:: GET REPORTS REASONS
        this.getReportReasons();
        //END:: GET REPORTS REASONS
    },
};
</script>

<template>
    <div class="reset_pass_form_wrapper">
        <div class="reset_pass_header_wrapper">
            <div class="logo_wrapper">
                <img
                    src="../../assets/logo/small_logo.svg"
                    alt="Inflico Logo"
                    width="100"
                    height="100"
                />
            </div>

            <div class="title_wrapper">
                <h2>{{ $t("titles.new_password") }}</h2>
            </div>

            <div class="subtitle_wrapper">
                <h5>{{ $t("titles.new_password_subtitle") }}</h5>
            </div>
        </div>

        <div class="reset_pass_body_wrapper">
            <form @submit.prevent="submitResetPasswordForm">
                <!-- START:: PASSWORD INPUT -->
                <div class="wrapper password_wrapper mb-3 mt-5">
                    <input
                        id="password"
                        type="password"
                        class="form-control"
                        :placeholder="$t('placeholders.new_password')"
                        v-model="newPasswordData.password"
                    />
                    <div class="icon_wrapper">
                        <button
                            type="button"
                            v-if="passwordIsVisible"
                            @click="togglePasswordVisibility('password')"
                        >
                            <EyeOffIcon />
                        </button>
                        <button
                            type="button"
                            v-else
                            @click="togglePasswordVisibility('password')"
                        >
                            <EyeIcon />
                        </button>
                    </div>
                </div>
                <!-- END:: PASSWORD INPUT -->

                <!-- START:: CONFIRM PASSWORD INPUT -->
                <div class="wrapper password_wrapper mb-5">
                    <input
                        id="confirm_password"
                        type="password"
                        class="form-control"
                        :placeholder="$t('placeholders.confirm_new_password')"
                        v-model="newPasswordData.confirmPassword"
                    />
                    <div class="icon_wrapper">
                        <button
                            type="button"
                            v-if="confirmPasswordIsVisible"
                            @click="
                                togglePasswordVisibility('confirm_password')
                            "
                        >
                            <EyeOffIcon />
                        </button>
                        <button
                            type="button"
                            v-else
                            @click="
                                togglePasswordVisibility('confirm_password')
                            "
                        >
                            <EyeIcon />
                        </button>
                    </div>
                </div>
                <!-- END:: CONFIRM PASSWORD INPUT -->

                <!-- START:: SUBMIT BUTTON -->
                <div class="wrapper justify-content-center">
                    <button class="btn">
                        {{ $t("buttons.change") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON -->
            </form>
        </div>
    </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";

export default {
    name: "ResetPasswordForm",

    components: {
        EyeIcon,
        EyeOffIcon,
    },

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: SHOW PASSWORD HANDLING DATA
            passwordIsVisible: false,
            confirmPasswordIsVisible: false,
            // END:: SHOW PASSWORD HANDLING DATA

            // START:: REGISTER DATA
            newPasswordData: {
                password: null,
                confirmPassword: null,
                phone_key: localStorage.getItem("inflco_user_phone_key"),
                phone: localStorage.getItem("inflco_user_phone_number"),
                code: localStorage.getItem("inflco_verification_code"),
            },
            // END:: REGISTER DATA
        };
    },

    methods: {
        // START:: SUBMIT REGISTER FORM
        submitResetPasswordForm() {
            this.isWaitingRequest = true;

            if (
                this.newPasswordData.password == null ||
                this.newPasswordData.password == ""
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }
            if (this.newPasswordData.password.length < 6) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidationDigets"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }
            if (
                this.newPasswordData.password !=
                this.newPasswordData.confirmPassword
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.confirmPasswordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }

            this.$axios
                .post("auth/reset_password", this.newPasswordData)
                .then((res) => {
                    this.$iziToast.success({
                        timeout: 5000,
                        message: res.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    localStorage.removeItem("inflco_verification_code");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    this.$router.replace("/success-reset");
                    this.isWaitingRequest = false;
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                });

            // setTimeout(() => {
            //   this.isWaitingRequest = false;
            //   this.$router.push("/success-reset");
            // }, 1500);
        },
        // END:: SUBMIT REGISTER FORM

        // START:: TOGGLE COUNTRIES KYES MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KYES MENU

        // START:: TOGGLE PASSWORD VISIBILITY
        togglePasswordVisibility(id) {
            // TOGGLE TYPE
            let targetElement = document.getElementById(id);
            if (targetElement.type == "password") {
                targetElement.type = "text";
            } else if (targetElement.type == "text") {
                targetElement.type = "password";
            }

            // TOGGLE ICON
            if (id == "password") {
                this.passwordIsVisible = !this.passwordIsVisible;
            } else {
                this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY
    },
};
</script>

<template>
    <div class="register_form_wrapper company">
        <div class="register_header_wrapper">
            <div class="logo_wrapper">
                <img
                    src="../../assets/logo/small_logo.svg"
                    alt="Inflico Logo"
                    width="100"
                    height="100"
                />
            </div>

            <div class="title_wrapper">
                <h2>{{ $t("titles.investor_sign_up") }}</h2>
            </div>

            <div class="slogan_wrapper">
                <h5>{{ $t("titles.sign_up_description") }}</h5>
            </div>

            <div class="title_wrapper">
                <h5>{{ $t("titles.company_informations") }}</h5>
            </div>

            <div class="form_wrapper">
                <form @submit.prevent="submitCompanyRegisterForm">
                    <!-- START:: COMPANY NAME INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.company_name')"
                            v-model="companyRegisterData.company_name"
                        />
                    </div>
                    <!-- END:: COMPANY NAME INPUT -->

                    <!-- START:: COMPANY ADDRESS INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.company_address')"
                            v-model="companyRegisterData.company_address"
                        />
                    </div>
                    <!-- END:: COMPANY ADDRESS INPUT -->

                    <!-- START:: COMMERCIAL REGISTER INPUT -->
                    <div class="wrapper mb-3">
                        <label
                            for="Commercial_Register"
                            class="form-label upload_label"
                        >
                            <span v-if="selectedFileName == null">
                                {{
                                    $t(
                                        "placeholders.company_commercial_register"
                                    )
                                }}
                            </span>
                            <span v-else> {{ selectedFileName }} </span>

                            <img
                                src="../../assets/media/icons/upload.svg"
                                alt="icon"
                                width="100"
                                height="100"
                            />
                        </label>
                        <input
                            class="form-control"
                            type="file"
                            id="Commercial_Register"
                            accept="image/png, image/jpeg, image/jpg"
                            @change="handleUploadFile"
                        />
                    </div>
                    <!-- END:: COMMERCIAL REGISTER INPUT -->

                    <!-- START:: ABOUT COMPANY INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.about_company')"
                            v-model="companyRegisterData.company_desc"
                        />
                    </div>
                    <!-- END:: ABOUT COMPANY INPUT -->

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="wrapper justify-content-center">
                        <button
                            class="btn"
                            :class="
                                companyRegisterDataIsEmpty ? 'disabled' : ''
                            "
                        >
                            {{ $t("auth.sign_up") }}
                            <span
                                class="btn_loader"
                                v-if="isWaitingRequest"
                            ></span>
                        </button>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </form>
                <!-- <div class="col-12 wrapper d-flex justify-content-center mt-2">
          <button type="button" class="btn skip" @click="skipNow">
            {{ $t("buttons.skip") }}
          </button>
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompanyRegister",

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: HANDLING UPLOADED FILE DATA
            selectedFile: null,
            selectedFileName: null,
            // END:: HANDLING UPLOADED FILE DATA

            // START:: REGISTER DATA
            companyRegisterData: {
                company_name: null,
                company_address: null,
                company_desc: null,
                company_register: null,
            },
            // END:: REGISTER DATA

            // START:: FORM DATA
            theData: new FormData(),
            // END:: FORM DATA
        };
    },

    computed: {
        // START:: IS REGISTER DATA IS EMPTY COMPUTED PROP
        companyRegisterDataIsEmpty() {
            return (
                !this.companyRegisterData.company_name ||
                !this.companyRegisterData.company_address ||
                !this.companyRegisterData.company_register
            );
        },
        // END:: IS REGISTER DATA IS EMPTY COMPUTED PROP
    },

    methods: {
        // START:: SUBMIT REGISTER FORM
        submitCompanyRegisterForm() {
            this.isWaitingRequest = true;

            if (this.companyRegisterData.company_name == null) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.company_name"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (this.companyRegisterData.company_address == null) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.company_address"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (this.companyRegisterData.company_register == null) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.company_commercial"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }

            this.theData.append(
                "company_name",
                this.companyRegisterData.company_name
            );
            this.theData.append(
                "company_address",
                this.companyRegisterData.company_address
            );
            this.theData.append(
                "company_desc",
                this.companyRegisterData.company_desc
            );

            this.$axios
                .post("investor/signup_second_step", this.theData, {
                    headers: {
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        // this.$iziToast.success({
                        //   timeout: 5000,
                        //   message: res.data.message,
                        //   position: this.$t("position"),
                        //   rtl: this.$t("dir"),
                        // });
                        localStorage.setItem(
                            "inflco_verification_code",
                            res.data.dev_message
                        );
                        this.isWaitingRequest = false;
                        this.$router.replace("/verification/registeration");
                    }
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.status,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                });

            // setTimeout(() => {
            //   this.isWaitingRequest = false;
            //   this.$router.replace("/verification/registeration");
            // }, 1500);
        },
        // END:: SUBMIT REGISTER FORM

        // START:: SKIP NOW
        skipNow() {
            this.$axios
                .post("investor/signup_second_step", this.companyRegisterData, {
                    headers: {
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        // this.$iziToast.success({
                        //   timeout: 5000,
                        //   message: res.data.message,
                        //   position: this.$t("position"),
                        //   rtl: this.$t("dir"),
                        // });
                    }
                    localStorage.setItem(
                        "inflco_user_token",
                        res.data.data.token.access_token
                    );
                    localStorage.setItem(
                        "inflco_verification_code",
                        res.data.dev_message
                    );
                    setTimeout(() => {
                        this.$router.replace("/verification/registeration");
                    }, 1500);
                });
        },
        // END:: SKIP NOW

        // START:: HANDLE UPLOADE FILE
        handleUploadFile(e) {
            this.selectedFile = e.target.files[0];
            this.selectedFileName = e.target.files[0].name;

            this.companyRegisterData.company_register = e.target.files[0];
            this.theData.append("company_register", e.target.files[0]);
        },
        // END:: HANDLE UPLOADE FILE
    },
};
</script>

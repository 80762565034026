<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <!-- <Loader v-if="loading"></Loader> -->
    <div class="landing_wrapper fadeIn">
        <!-- START:: HEADER -->
        <div class="the_header_custom">
            <TheHeader />
        </div>
        <!-- END:: HEADER -->

        <!-- START:: HERO SECTION -->
        <HeroSection
            class="hero_section"
            :autoplay="true"
            :nav="false"
            :dots="false"
            :responsive="{
                0: { items: 1 },
                600: { items: 1 },
                1024: { items: 1 },
            }"
            :center="true"
            :loop="true"
            v-if="items.slider || items.slider.length"
        >
            <!-- START::  SINGLE SLIDE -->
            <div
                class="single_slide_hero"
                v-for="item in items.slider"
                :key="item.id"
                :style="'background-image: url(' + item.image + ')'"
            >
                <div class="text_hero">
                    <h1
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        {{ item.title }}
                    </h1>
                    <h3
                        data-aos="fade-up"
                        data-aos-delay="500"
                        data-aos-duration="1500"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        {{ item.body }}
                    </h3>
                    <div
                        class="btn_wrapper"
                        data-aos="fade-up"
                        data-aos-delay="900"
                        data-aos-duration="2000"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        <router-link v-if="!userType" to="/intro">{{
                            $t("buttons.register_now")
                        }}</router-link>
                        <router-link
                            v-else-if="userType == 'investor'"
                            to="/home"
                            >{{ $t("buttons.start_now") }}</router-link
                        >

                        <router-link
                            v-else-if="userType == 'influincer'"
                            to="/influincer-home"
                            >{{ $t("buttons.start_now") }}</router-link
                        >
                    </div>
                    <h4
                        data-aos="fade-up"
                        data-aos-delay="1300"
                        data-aos-duration="2500"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        <span>{{ $t("frequently_words.or") }}</span>
                        <span>{{ $t("titles.download_the_app") }}</span>
                    </h4>
                    <div
                        class="download_app"
                        data-aos="fade-up"
                        data-aos-delay="1700"
                        data-aos-duration="3000"
                        data-aos-easing="ease-in-out"
                        data-aos-once="true"
                    >
                        <a
                            href="https://play.google.com/store/apps/details?id=com.app.inflco"
                            target="_blank"
                            ><img
                                src="../assets/media/landing/google.svg"
                                alt="Google Play"
                                width="100"
                                height="100"
                        /></a>
                        <a
                            href="https://apps.apple.com/us/app/inflco/id1590994173"
                            target="_blank"
                            ><img
                                src="../assets/media/landing/apple.svg"
                                alt="App Store"
                                width="100"
                                height="100"
                        /></a>
                    </div>
                </div>
            </div>
            <!-- END::  SINGLE SLIDE -->
        </HeroSection>
        <!-- END:: HERO SECTION -->

        <!-- START:: ABOUT SECTION -->
        <section class="about">
            <div class="container">
                <div class="title_section">
                    <h4>{{ $t("titles.about_us") }}</h4>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <div class="section_text">
                            <img src="../assets/logo/logo.svg" alt="" />
                            <h3>{{ $t("titles.inflco_company") }}</h3>
                            <p
                                v-html="
                                    items.about_us.body.substring(0, 400) +
                                    '.....'
                                "
                            ></p>
                            <router-link to="/about">
                                {{ $t("buttons.learn_more") }}
                                <img
                                    src="../assets/media/icons/arrow_right_blue_big.svg"
                                    width="20"
                                    height="20"
                            /></router-link>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="section_img">
                            <img :src="items.about_us.image" alt="About Us" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END:: ABOUT SECTION -->

        <!-- START:: HOW IT WORKS -->
        <section class="howItWorks">
            <div
                class="before-img"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
            ></div>

            <div class="container">
                <div class="title_section">
                    <h4>{{ items.how_it_work_desc.title }}</h4>
                    <p>
                        {{ items.how_it_work_desc.body }}
                    </p>
                </div>
                <div class="content_section">
                    <div class="row">
                        <!-- START:: SINGLE -->
                        <div
                            class="col-md-3"
                            v-for="item in items.how_it_works"
                            :key="item.id"
                        >
                            <div class="single_content">
                                <div class="section_icon">
                                    <img
                                        :src="item.image"
                                        alt="Filter Your Needs"
                                        width="50"
                                        height="50"
                                    />
                                </div>
                                <div class="section_text">
                                    <h3>{{ item.title }}</h3>
                                    <p>
                                        {{ item.body }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn_animation">
                    <button class="btn" type="button" @click="show = !show">
                        <i class="fas fa-play"></i>
                        {{ $t("buttons.watch_video") }}
                    </button>
                </div>
            </div>

            <div
                class="after-img"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
            ></div>
        </section>
        <!-- END:: HOW IT WORKS -->

        <!-- START:: LEARN -->
        <section class="learn_section">
            <div class="container">
                <div class="image_mony_intro">
                    <img
                        src="../assets/media/landing/money.svg"
                        alt="Money"
                        width=""
                        height=""
                    />
                </div>
                <div class="title_section">
                    <h4>{{ items.how_to_invest.title }}</h4>
                    <p>
                        {{ items.how_to_invest.body }}
                    </p>
                    <div class="btn_wrapper">
                        <router-link v-if="!userType" to="/intro">{{
                            $t("buttons.register_now")
                        }}</router-link>
                        <router-link
                            v-else-if="userType == 'investor'"
                            to="/home"
                            >{{ $t("buttons.start_now") }}</router-link
                        >

                        <router-link
                            v-else-if="userType == 'influincer'"
                            to="/influincer-home"
                            >{{ $t("buttons.start_now") }}</router-link
                        >
                    </div>
                </div>
            </div>
        </section>
        <!-- END:: LEARN -->

        <!-- START:: BLOG SECTION -->
        <!-- <section class="blog_section">
            <div class="container">
                <div class="title_section">
                    <h4>{{ $t("titles.blog_title") }}</h4>
                </div>

                <BlogCarousel
                    class="blog_slider"
                    :margin="15"
                    :autoplay="true"
                    :nav="false"
                    :dots="true"
                    :loop="true"
                    :responsive="{
                        0: { items: 1 },
                        600: { items: 2 },
                        1024: { items: 3 },
                    }"
                >
                    <router-link class="article_route" to="/blog/1">
                        <div class="blog_card_wrapper">
                            <div class="img_wrapper">
                                <img
                                    src="../assets/media/imgs/blog1.jpg"
                                    alt="Article Image"
                                    width="150"
                                    height="150"
                                />
                            </div>

                            <div class="article_title_wrapper">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Illum, quis.
                                </h5>
                            </div>

                            <div class="publish_date_wrapper">
                                <h6>2021 - 11 -24</h6>
                            </div>
                        </div>
                    </router-link>

                    <router-link class="article_route" to="/blog/1">
                        <div class="blog_card_wrapper">
                            <div class="img_wrapper">
                                <img
                                    src="../assets/media/imgs/blog2.jpg"
                                    alt="Article Image"
                                    width="150"
                                    height="150"
                                />
                            </div>

                            <div class="article_title_wrapper">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Illum, quis.
                                </h5>
                            </div>

                            <div class="publish_date_wrapper">
                                <h6>2021 - 11 -24</h6>
                            </div>
                        </div>
                    </router-link>
                    <router-link class="article_route" to="/blog/1">
                        <div class="blog_card_wrapper">
                            <div class="img_wrapper">
                                <img
                                    src="../assets/media/imgs/blog1.jpg"
                                    alt="Article Image"
                                    width="150"
                                    height="150"
                                />
                            </div>

                            <div class="article_title_wrapper">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Illum, quis.
                                </h5>
                            </div>

                            <div class="publish_date_wrapper">
                                <h6>2021 - 11 -24</h6>
                            </div>
                        </div>
                    </router-link>

                    <router-link class="article_route" to="/blog/1">
                        <div class="blog_card_wrapper">
                            <div class="img_wrapper">
                                <img
                                    src="../assets/media/imgs/blog2.jpg"
                                    alt="Article Image"
                                    width="150"
                                    height="150"
                                />
                            </div>

                            <div class="article_title_wrapper">
                                <h5>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Illum, quis.
                                </h5>
                            </div>

                            <div class="publish_date_wrapper">
                                <h6>2021 - 11 -24</h6>
                            </div>
                        </div>
                    </router-link>
                </BlogCarousel>

                <div class="route_wrapper">
                    <router-link to="/blog">
                        {{ $t("buttons.show_more") }}
                    </router-link>
                </div>
            </div>
        </section> -->
        <!-- END:: BLOG SECTION -->

        <!-- START:: FAQS SECTION -->
        <div class="faqs_landing_section">
            <div class="section_content_wrapper">
                <div class="map_wrapper">
                    <GmapMap
                        :center="{
                            lat: parseInt(contactData.lat),
                            lng: parseInt(contactData.lng),
                        }"
                        :zoom="7"
                        map-type-id="terrain"
                        style="width: 100%; height: 400px"
                    >
                        <GmapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            :clickable="true"
                            :draggable="true"
                            @click="center = m.position"
                        />
                    </GmapMap>
                </div>

                <div class="cards_wrapper">
                    <div class="row">
                        <div class="col-md-4">
                            <a
                                style="text-decoration: none"
                                :href="
                                    'https://www.google.com/maps?q=' +
                                    contactData.lat +
                                    ',' +
                                    contactData.lng +
                                    '&hl=es;z%3D14&amp'
                                "
                                target="_blank"
                            >
                                <div class="contact_card address">
                                    <h5>
                                        <span>
                                            <img
                                                src="../assets/media/icons/mapMark.svg"
                                                alt="icon"
                                                width="20"
                                                height="20"
                                            />
                                        </span>

                                        <span>
                                            {{ $t("titles.address") }}:
                                            {{ contactData.address }}
                                        </span>
                                    </h5>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-4">
                            <a
                                :href="
                                    'https://api.whatsapp.com/send?phone=' +
                                    contactData.whatsapp
                                "
                                style="text-decoration: none"
                                target="_blank"
                            >
                                <div class="contact_card phone">
                                    <h5>
                                        <span>
                                            <img
                                                src="../assets/media/icons/whatsapp.svg"
                                                alt="icon"
                                                width="20"
                                                height="20"
                                            />
                                        </span>

                                        <span>
                                            {{ contactData.whatsapp }}
                                        </span>
                                    </h5>
                                </div>
                            </a>
                        </div>

                        <div class="col-md-4">
                            <a
                                :href="'mailto:' + contactData.email"
                                style="text-decoration: none"
                                target="_blank"
                            >
                                <div class="contact_card mail">
                                    <h5>
                                        <span>
                                            <img
                                                src="../assets/media/icons/invelop.svg"
                                                alt="icon"
                                                width="20"
                                                height="20"
                                            />
                                        </span>

                                        <span> {{ contactData.email }} </span>
                                    </h5>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="title_section">
                <h4>{{ $t("titles.all_faqs") }}</h4>
            </div>

            <div class="routes_wrapper">
                <router-link to="/faqs/investor">
                    {{ $t("buttons.investor_faq") }}
                </router-link>

                <router-link to="/faqs/influincer">
                    {{ $t("buttons.influincer_faq") }}
                </router-link>
            </div>
        </div>
        <!-- END:: FAQS SECTION -->

        <!-- START:: OUR PARTENERS -->
        <!-- <section class="parteners">
            <div class="title_section">
                <h4>{{ $t("titles.our_partners") }}</h4>
            </div>
            <div class="row">
                <div
                    class="col-6 col-md-2"
                    v-for="item in items.our_partners"
                    :key="item.id"
                >
                    <div class="single_partener">
                        <img :src="item.image" alt="" />
                    </div>
                </div>
            </div>
        </section> -->
        <!-- END:: OUR PARTENERS -->

        <!-- START:: FOOTER -->
        <div class="the_footer_custom">
            <TheFooter />
        </div>
        <!-- END:: FOOTER -->

        <!-- VIDEO -->
        <BaseModel @closeModel="show = !show" :show="show">
            <template #influincer_video>
                <div class="video_wrapper">
                    <span @click="show = !show" class="close"
                        ><i class="fas fa-times"></i
                    ></span>
                    <VuePlyr
                        :poster="intro_video.without_auth.thumbnail"
                        v-if="!userType"
                    >
                        <video class="video-player" controls>
                            <source
                                :src="intro_video.without_auth.url"
                                type="video/mp4"
                                v-if="!userType"
                            />
                        </video>
                    </VuePlyr>
                    <VuePlyr
                        :poster="intro_video.investor_video.thumbnail"
                        v-if="userType == 'investor'"
                    >
                        <video class="video-player" controls>
                            <source
                                :src="intro_video.investor_video.url"
                                type="video/mp4"
                            />
                        </video>
                    </VuePlyr>
                    <VuePlyr
                        :poster="intro_video.infleuncer_video.thumbnail"
                        v-if="userType == 'influincer'"
                    >
                        <video class="video-player" controls>
                            <source
                                :src="intro_video.infleuncer_video.url"
                                type="video/mp4"
                            />
                        </video>
                    </VuePlyr>
                </div>
            </template>
        </BaseModel>
    </div>
</template>

<script>
import TheHeader from "../components/layouts/TheHeader.vue";
import TheFooter from "../components/layouts/TheFooter.vue";
import BaseModel from "../components/ui/BaseModel.vue";
import HeroSection from "vue-owl-carousel";
// import BlogCarousel from "vue-owl-carousel";
// import Loader from "../components/loader/intro.vue";
// START:: IMPORTING VIDEO PLAYER
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// END:: IMPORTING VIDEO PLAYER
export default {
    name: "Landing",

    components: {
        TheHeader,
        TheFooter,
        HeroSection,
        // BlogCarousel,
        BaseModel,
        VuePlyr,
        // Loader,
    },

    data() {
        return {
            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: USER TOKEN DATA
            userToken: localStorage.getItem("inflco_user_token"),
            // END: USER TOKEN DATA

            items: "",
            show: false,
            loading: true,
            intro_video: {
                without_auth: "",
                investor_video: "",
                infleuncer_video: "",
            },

            contactData: {
                email: "",
                phones: [],
                address: "",
                lat: "",
                lng: "",
                location: "",
                whatsapp: "",
            },

            //  START:: GET  FAQS DATA
            investor_faqs: null,
            influener_faqs: null,
            //  END:: GET  FAQS DATA
            metaTags: "",
        };
    },

    methods: {
        // get data
        getData() {
            this.$axios
                .get("home", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    // console.log(res.data.data);
                    this.items = res.data.data;
                });
        }, // get data
        getVideos() {
            this.$axios
                .get("settings/intro_video", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.intro_video.without_auth = res.data.data.intro_video;
                    this.intro_video.investor_video =
                        res.data.data.investor_intro_video;
                    this.intro_video.infleuncer_video =
                        res.data.data.infleuncer_intro_video;
                });
        },

        getStaticContent() {
            this.$axios
                .get("settings/social_info", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.contactData.email = res.data.data.email;
                    this.contactData.address = res.data.data.address;
                    this.contactData.lat = res.data.data.lat;
                    this.contactData.lng = res.data.data.lng;
                    this.contactData.location = res.data.data.location;
                    this.contactData.phones = res.data.data.phones[0];
                    this.contactData.whatsapp = res.data.data.whatsapp_phone;
                });
        },

        // START:: GET FAQS DATA METHODS
        // getFaqsData() {
        //     // START:: INVESTOR FAQS REQUEST
        //     this.$axios
        //         .get("questions", {
        //             params: {
        //                 type: "investor",
        //             },
        //         })
        //         .then((res) => {
        //             this.investor_faqs = res.data.data;
        //         });
        //     // END:: INVESTOR FAQS REQUEST

        //     // START:: INFLUENCER FAQS REQUEST
        //     this.$axios
        //         .get("questions", {
        //             params: {
        //                 type: "influencer",
        //             },
        //         })
        //         .then((res) => {
        //             this.influener_faqs = res.data.data;
        //         });
        //     // END:: INFLUENCER FAQS REQUEST
        // },
        // START:: GET FAQS DATA METHODS

        // START:: TARGET PARENT ELEMENT METHOD
        targetParent(e) {
            let activeItem = e.target.parentElement.parentElement;
            // let allItems = Array.from(e.target.parentElement.parentElement.parentElement.children);
            // console.log(allItems);

            activeItem.classList.toggle("active_item");

            activeItem.nextElementSibling.classList.remove("active_item");
            activeItem.previousElementSibling.classList.remove("active_item");
        },
        // END:: TARGET PARENT ELEMENT METHOD

        //  START:: CHECK IF THE TOKEN EXIST  METHOD
        checkTheToken() {
            if (!this.userToken) {
                return;
            } else {
                if (this.userType == "investor") {
                    this.$router.replace("/home");
                } else {
                    this.$router.replace("/influincer-home");
                }
            }
        },
        //  END: CHECK IF THE TOKEN EXIST  METHOD
    },
    created() {
        var lang = localStorage.getItem("inflco_app_lang");
        if (!lang) {
            localStorage.setItem("inflco_app_lang", "en");
        } else {
            this.$store.dispatch("handelAppDefaultLanguage");
        }
        this.getData();
        // this.getFaqsData();
        this.getVideos();
    },

    mounted() {
        // START:: CHECK IF THE TOKEN EXIST
        this.checkTheToken();
        // END:: CHECK IF THE TOKEN EXIST

        this.getStaticContent();

        // setTimeout(() => {
        //   this.show = !this.show;
        // }, 4000);
        setTimeout(() => {
            this.loading = !this.loading;
        }, 3100);
    },
    // START:: VUE-META
    metaInfo() {
        return {
            title: this.articleTitle,
            meta: [
                {
                    name: "description",
                    content:
                        "اسهل طريقة للتواصل مع الانفلونسر - كيفية التواصل مع الانفلونسر - كيفية التواصل مع المؤثرين - المستثمر والمؤثر  - وسيط الكتروني - المؤثرين - المستثمرين - انفلونسر - المستثمرين - المستثمرين والمؤثرين" +
                        " , " +
                        "Inflco - Infilco - infilco - inflco  - انفكلو - إنفلكو - انفكلوا - إنفكلوا",
                },
            ],
        };
    },
    // END:: VUE-META
};
</script>

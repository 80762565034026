<template>
    <div class="register_form_wrapper">
        <div class="register_header_wrapper">
            <div class="title_wrapper edit">
                <h3>
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/editProfile'
                                : '/edit-influincer-profile'
                        "
                        ><img
                            src="../../assets/media/icons/arrow_left.svg"
                            alt="Arrow Left"
                            width="30px"
                            height="15px"
                    /></router-link>
                    {{ $t("buttons.edit_password") }}
                </h3>
            </div>

            <div class="form_wrapper">
                <form @submit.prevent="submitRegisterForm">
                    <!-- START:: CURRENT PASSWORD INPUT -->
                    <div class="wrapper password_wrapper mb-3">
                        <input
                            id="current_password"
                            type="password"
                            class="form-control"
                            :placeholder="$t('placeholders.current_password')"
                            v-model="UpdatePassword.old_password"
                        />
                        <div class="icon_wrapper">
                            <button
                                type="button"
                                v-if="currentPasswordIsVisible"
                                @click="
                                    togglePasswordVisibility('current_password')
                                "
                            >
                                <EyeOffIcon />
                            </button>
                            <button
                                type="button"
                                v-else
                                @click="
                                    togglePasswordVisibility('current_password')
                                "
                            >
                                <EyeIcon />
                            </button>
                        </div>
                    </div>
                    <!-- END:: CURRENT PASSWORD INPUT -->

                    <!-- START:: PASSWORD INPUT -->
                    <div class="wrapper password_wrapper mb-3">
                        <input
                            id="password"
                            type="password"
                            class="form-control"
                            :placeholder="$t('placeholders.password')"
                            v-model="UpdatePassword.password"
                        />
                        <div class="icon_wrapper">
                            <button
                                type="button"
                                v-if="passwordIsVisible"
                                @click="togglePasswordVisibility('password')"
                            >
                                <EyeOffIcon />
                            </button>
                            <button
                                type="button"
                                v-else
                                @click="togglePasswordVisibility('password')"
                            >
                                <EyeIcon />
                            </button>
                        </div>
                    </div>
                    <!-- END:: PASSWORD INPUT -->

                    <!-- START:: CONFIRM PASSWORD INPUT -->
                    <div class="wrapper password_wrapper mb-3">
                        <input
                            id="confirm_password"
                            type="password"
                            class="form-control"
                            :placeholder="$t('placeholders.confirm_password')"
                            v-model="UpdatePassword.confirmPassword"
                        />
                        <div class="icon_wrapper">
                            <button
                                type="button"
                                v-if="confirmPasswordIsVisible"
                                @click="
                                    togglePasswordVisibility('confirm_password')
                                "
                            >
                                <EyeOffIcon />
                            </button>
                            <button
                                type="button"
                                v-else
                                @click="
                                    togglePasswordVisibility('confirm_password')
                                "
                            >
                                <EyeIcon />
                            </button>
                        </div>
                    </div>
                    <!-- END:: CONFIRM PASSWORD INPUT -->

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="wrapper justify-content-center">
                        <button class="btn">
                            {{ $t("buttons.save") }}
                            <span
                                class="btn_loader"
                                v-if="isWaitingRequest"
                            ></span>
                        </button>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";

export default {
    name: "Register",

    components: {
        EyeIcon,
        EyeOffIcon,
    },

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: SHOW PASSWORD HANDLING DATA
            passwordIsVisible: false,
            currentPasswordIsVisible: false,
            confirmPasswordIsVisible: false,
            // END:: SHOW PASSWORD HANDLING DATA

            // START:: REGISTER DATA
            UpdatePassword: {
                old_password: "",
                password: "",
                confirmPassword: "",
            },
            // END:: REGISTER DATA
        };
    },

    methods: {
        // START:: SUBMIT REGISTER FORM
        submitRegisterForm() {
            this.isWaitingRequest = true;

            if (this.UpdatePassword.old_password == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.currentPasswordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }
            if (this.UpdatePassword.password == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }
            if (this.UpdatePassword.password.length < 6) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidationDigets"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }
            if (
                this.UpdatePassword.password !=
                this.UpdatePassword.confirmPassword
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.confirmPasswordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }

            this.$axios
                .post("investor/edit_password", this.UpdatePassword, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    if (res.data.status) {
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("validation.password_updated"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                        this.$router.back();
                    }
                });
        },
        // END:: SUBMIT REGISTER FORM

        // START:: TOGGLE PASSWORD VISIBILITY
        togglePasswordVisibility(id) {
            // TOGGLE TYPE
            let targetElement = document.getElementById(id);
            if (targetElement.type == "password") {
                targetElement.type = "text";
            } else if (targetElement.type == "text") {
                targetElement.type = "password";
            }

            // TOGGLE ICON
            if (id == "password") {
                this.passwordIsVisible = !this.passwordIsVisible;
            }
            if (id == "confirm_password") {
                this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
            }
            if (id == "current_password") {
                this.currentPasswordIsVisible = !this.currentPasswordIsVisible;
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY
    },
};
</script>

<template>
  <div class="register_form_wrapper">
    <div class="register_header_wrapper">
      <div class="logo_wrapper">
        <img
          src="../../assets/logo/small_logo.svg"
          alt="Inflico Logo"
          width="100"
          height="100"
        />
      </div>

      <div class="successfully_wrapper">
        <img
          src="../../assets/media/illustrations/run_skate.svg"
          alt="Inflico Logo"
        />
      </div>

      <div class="title_wrapper successfully_title_wrapper">
        <h5>{{ $t("titles.account_created_successfully") }}</h5>
      </div>

      <div class="title_wrapper successfully_title_wrapper">
        <h5>{{ $t("titles.start_your_on_path") }}</h5>
      </div>
      <div class="title_wrapper successfully_title_wrapper mt-5 mb-0">
        <h5>{{ $t("titles.profile_link") }}</h5>
      </div>

      <div class="copyMyLink mt-3">
        <input
          type="text"
          :value="link_influencer.short_link"
          id="linkID"
          readonly
        />

        <button type="button" class="btn" @click="copyLinkMethod">
          <img src="../../assets/media/icons/copy.svg" />
          <span id="copied" class="fadeIn" v-if="copied == false">
            {{ $t("buttons.copy_link") }}
          </span>
          <span id="copied" class="fadeIn" v-else>
            {{ $t("buttons.copied") }}
          </span>
        </button>
      </div>

      <div class="form_wrapper">
        <form @submit.prevent="submitRegisterForm">
          <!-- START:: SUBMIT BUTTON -->
          <div class="wrapper mt-5 justify-content-center">
            <button class="btn">
              {{ $t("buttons.continue") }}
              <span class="btn_loader" v-if="isWaitingRequest"></span>
            </button>
          </div>
          <!-- END:: SUBMIT BUTTON -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessVerificationLink",

  components: {},

  data() {
    return {
      // START:: BUTTON LOADER DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER DATA

      // START:: USER TYPE DATA
      userType: this.$route.params.type,
      // END:: USER TYPE DATA

      copied: false,
      link_influencer: {
        short_link: "",
      },
    };
  },

  methods: {
    // START:: GET PROFILE DATA
    getProfileData() {
      this.$axios
        .get(`influencer/${localStorage.getItem("inflco_user_name")}`, {
          headers: {
            Authorization: "bearer" + localStorage.getItem("inflco_user_token"),
            "Content-type": "application/json",
            "cache-control": "no-cache",
            Accept: "application/json",
            "Accept-language": localStorage.getItem("inflco_app_lang"),
          },
        })
        .then((res) => {
          this.link_influencer.short_link = res.data.data.short_link;
        });
    },

    // END:: GET PROFILE DATA
    copyLinkMethod() {
      let CopyText = document.querySelector("#linkID");
      CopyText.select();
      // CopyText.setAttribute("type", "text");
      document.execCommand("copy");
      this.copied = !this.copied;
      setTimeout(() => {
        this.copied = !this.copied;
      }, 2000);

      this.$iziToast.success({
        timeout: 1500,
        message: "Copied",
        position: "topRight",
        rtl: true,
      });
    },
    // START:: SUBMIT REGISTER FORM
    submitRegisterForm() {
      this.$router.replace("/login");
      localStorage.removeItem("inflco_user_token");
      localStorage.removeItem("inflco_user_id");
      localStorage.removeItem("inflco_user_phone_key");
      localStorage.removeItem("inflco_user_phone_number");
      localStorage.removeItem("inflco_user_phone");
      localStorage.removeItem("inflco_user_type");
      localStorage.removeItem("inflco_verification_code");
      localStorage.removeItem("inflco_user_is_active");
      localStorage.removeItem("inflco_user_name");

      // this.isWaitingRequest = true;
      // setTimeout(() => {
      //   this.isWaitingRequest = false;
      // }, 1500);
      // setTimeout(() => {
      //   location.reload();
      //   localStorage.setItem("inflco_user_login", "true");
      // }, 1500);
    },
    // END:: SUBMIT REGISTER FORM
  },

  mounted() {
    this.getProfileData();
    localStorage.removeItem("inflco_verification_code");
  },
};
</script>

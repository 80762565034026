<template>
    <section>
        <BaseModel
            @closeModel="$emit('closeModule')"
            :show="showRate"
            v-if="userType == 'investor'"
        >
            <template #modal>
                <div class="modal_custom modal_custom_cantract">
                    <!-- START:: HEAD MOADAL -->
                    <div class="head_modal">
                        <h3>{{ $t("modals.add_your_rate") }}</h3>
                        <img
                            :src="details.image"
                            :alt="details.fullname"
                            width="100"
                            height="100"
                        />
                        <h4>{{ details.fullname }}</h4>
                        <ul class="flex-wrap">
                            <li
                                v-for="interest in details.interests"
                                :key="interest.id"
                            >
                                {{ interest.name }}
                            </li>
                        </ul>
                    </div>
                    <!-- END:: HEAD MODAL -->

                    <!-- START:: FOOTER MODAL -->
                    <div class="footer_modal">
                        <form class="form" @submit.prevent="sendRateMethod">
                            <div class="rating_form">
                                <input
                                    name="stars"
                                    id="rate5"
                                    value="5"
                                    type="radio"
                                    v-model="rate"
                                />
                                <label for="rate5"
                                    ><i class="fas fa-star"></i
                                ></label>
                                <!--  -->
                                <input
                                    name="stars"
                                    value="4"
                                    id="rate4"
                                    type="radio"
                                    v-model="rate"
                                />
                                <label for="rate4"
                                    ><i class="fas fa-star"></i
                                ></label>
                                <!--  -->
                                <input
                                    name="stars"
                                    value="3"
                                    id="rate3"
                                    type="radio"
                                    v-model="rate"
                                />
                                <label for="rate3"
                                    ><i class="fas fa-star"></i
                                ></label>
                                <!--  -->
                                <input
                                    name="stars"
                                    value="2"
                                    id="rate2"
                                    type="radio"
                                    v-model="rate"
                                />
                                <label for="rate2"
                                    ><i class="fas fa-star"></i
                                ></label>
                                <!--  -->
                                <input
                                    name="stars"
                                    value="1"
                                    id="rate1"
                                    type="radio"
                                    v-model="rate"
                                />
                                <label for="rate1"
                                    ><i class="fas fa-star"></i
                                ></label>
                                <!--  -->
                            </div>
                            <div class="comment">
                                <textarea
                                    :placeholder="
                                        $t('placeholders.write_commet')
                                    "
                                    v-model="review"
                                ></textarea>
                            </div>

                            <div class="wrapper justify-content-center">
                                <button class="btn">
                                    {{ $t("buttons.send") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequestRate"
                                    ></span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <!-- END:: FOOTER MODAL -->
                </div>
            </template>
        </BaseModel>

        <BaseModel
            @closeModel="$emit('closeModule')"
            :show="showFinished"
            v-if="userType == 'investor'"
        >
            <template #modal>
                <div class="modal_successfully">
                    <div class="image_successfully">
                        <img
                            src="../../assets/media/illustrations/successfully1.svg"
                            alt="successfully"
                            width="100"
                            height="100"
                        />
                    </div>
                    <div class="text_successfully">
                        <p>{{ $t("modals.rate_sent") }}</p>
                    </div>
                    <form>
                        <div class="wrapper justify-content-center">
                            <button
                                class="btn"
                                type="button"
                                @click="$emit('finished')"
                            >
                                {{ $t("buttons.done") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequestRate"
                                ></span>
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </BaseModel>
    </section>
</template>
<script>
import BaseModel from "../ui/BaseModel.vue";
export default {
    props: ["contractData", "showRate", "showFinished", "isWaitingRequestRate"],
    data() {
        return {
            //START:: OF CONTRACT DATA
            details: {},
            //END:: OF CONTRACT DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            //START:: RATE DATA
            rate: "",
            review: "",
            //END:: RATE DATA

            // START:: CANCLING REASONS
            canclingReasons: null,
            // END:: CANCLING REASONS
            // START:: REPORT DATA
            canclingData: {
                report_reason_id: null,
                note: null,
            },
            // END:: REPORT DATA
        };
    },
    components: {
        BaseModel,
    },
    methods: {
        // START:: METHOD SEND RATE
        sendRateMethod() {
            this.isWaitingRequestRate = true;
            if (this.rate == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.rate"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                setTimeout(() => {
                    this.isWaitingRequestRate = false;
                }, 1000);
                return;
            } else {
                const data = new FormData();
                data.append("contract_id", this.contractData.id);
                data.append("rate", this.rate);
                data.append("review", this.review);

                this.$axios
                    .post("investor/rate", data, {
                        headers: {
                            Authorization:
                                "Bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": `multipart/form-data;`,
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        this.isWaitingRequestRate = true;
                        if (res.data.status == "success") {
                            this.rate = "";
                            this.review = "";
                            this.$iziToast.success({
                                timeout: 5000,
                                message: this.$t(
                                    "frequently_words.messageRate"
                                ),
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                            setTimeout(() => {
                                this.isWaitingRequestRate = false;
                            }, 1000);
                        }
                        this.$emit("addRate");
                        setTimeout(() => {
                            location.reload();
                        }, 500);
                    })
                    .catch(() => {
                        setTimeout(() => {
                            this.isWaitingRequestRate = false;
                        }, 1000);
                    });
            }
        },
        // END:: METHOD SEND RATE

        // START:: METHOD GET INFLUINCER DATA
        getInfluincerData() {
            if (this.userType == "investor") {
                this.loadMessages = true;
                this.$axios
                    .get("/investor/contract/" + this.contractData.id, {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    })
                    .then((res) => {
                        this.details = res.data.data.influencer;

                        this.loadMessages = false;
                    });
            }
        },
        // END:: METHOD GET INFLUINCER DATA
    },
    mounted() {
        this.getInfluincerData();
    },
};
</script>

<template>
    <div class="all_chats_list_wrapper" @scroll="scrollSection">
        <div class="title_wrapper">
            <h3>{{ $t("titles.chats") }}</h3>
        </div>
        <Loader
            v-if="$store.state.loadChat"
            :number="6"
            class="fadeIn"
        ></Loader>
        <section v-else class="fadeIn">
            <!-- START:: EMPTY MESSAGE WRAPPER -->
            <SecondEmptyMessage
                class="py-0"
                v-if="$store.state.chatsList.length == '0'"
            />
            <!-- END:: EMPTY MESSAGE WRAPPER -->

            <!-- START:: CHAT LIST -->
            <div class="chats_list" v-else>
                <div
                    class="chat_data_wrapper"
                    v-for="chat in $store.state.chatsList"
                    :key="chat.chat_id"
                >
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/chat/' + chat.client_data.id
                                : '/influincer-chat/' + chat.client_data.id
                        "
                        class="single_chat_route"
                    >
                        <div class="avatar_wrapper d-flex">
                            <img
                                :src="chat.client_data.image"
                                alt="Influincer Avatar"
                                width="100"
                                height="100"
                            />
                            <div class="wrapper">
                                <div class="name">
                                    <span>
                                        {{ chat.client_data.fullname }}
                                    </span>
                                </div>

                                <div class="last_message">
                                    <span v-if="chat.last_message.length > 18">
                                        {{
                                            chat.last_message.substring(0, 18) +
                                            "..."
                                        }}
                                    </span>
                                    <span v-else>
                                        {{ chat.last_message }}
                                    </span>
                                </div>

                                <!-- <div
                  class="last_message"
                  v-else-if="chat.message_type == 'image'"
                >
                  <span> {{ $t("titles.image") }} </span>
                </div>

                <div
                  class="last_message"
                  v-else-if="chat.message_type == 'video'"
                >
                  <span> {{ $t("titles.the_video") }} </span>
                </div> -->
                            </div>
                        </div>

                        <div class="details_wrapper">
                            <div class="wrapper">
                                <span class="time">
                                    {{ chat.created_at }}
                                </span>
                                <span class="time"> {{ chat.ago }} </span>
                                <!-- <span class="typing_status" v-if="chat.typing"> Typing.. </span> -->
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- END:: CHAT LIST -->
        </section>
        <!-- loader  -->
        <Loader v-if="$store.state.loadChatDots" :number="1"></Loader>
    </div>
</template>

<script>
import SecondEmptyMessage from "../emptyMessages/SecondEmptyMessage.vue";
import Loader from "./../loader/chat.vue";
export default {
    name: "allChats",

    components: {
        SecondEmptyMessage,
        Loader,
    },

    data() {
        return {
            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA
        };
    },
    methods: {
        // START:: AXIOS GET CHATS LIST
        // END:: AXIOS GET CHATS LIST
        scrollSection() {
            if (
                this.$store.state.currentPage_of_pagenation !=
                this.$store.state.last_page_of_pagenation
            ) {
                var chatSection = document.querySelector(".chats_list");
                if (
                    chatSection.offsetHeight + chatSection.scrollTop >=
                    chatSection.scrollHeight
                ) {
                    this.$store.state.currentPage_of_pagenation += 1;
                    this.$store.dispatch("getChatsListScroll");
                }
            }
        },
    },

    mounted() {
        // START:: CHATS LIST
        this.$store.dispatch("getChatsList");
        // END:: CHATS LIST
    },
};
</script>

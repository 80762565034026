<template>
    <section>
        <BaseModel
            @closeModel="$emit('closeModule')"
            :show="showCancelContracts"
        >
            <template #modal>
                <div class="contracts_modal_report">
                    <div class="head_modal_report">
                        <h3>{{ $t("modals.cancel_title") }}</h3>
                        <p>{{ $t("modals.cancel_subtitle") }}</p>
                    </div>
                    <!-- START:: BODY MODAL -->
                    <div class="body_modal_report">
                        <form @submit.prevent="submitCanclingForm">
                            <div class="group mt-5">
                                <div class="inner_wrapper">
                                    <div
                                        class="wrapper mb-3"
                                        v-for="reason in canclingReasons"
                                        :key="reason.id"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            :id="reason.id"
                                            name="reason"
                                            :value="reason.id"
                                            v-model="
                                                canclingData.report_reason_id
                                            "
                                            @change="
                                                (showOtherReason = false),
                                                    (canclingData.note = null)
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="reason.id"
                                        >
                                            {{ reason.note }}
                                        </label>
                                    </div>

                                    <div class="wrapper mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="addOther"
                                            name="reason"
                                            :value="true"
                                            v-model="showOtherReason"
                                            @change="
                                                canclingData.report_reason_id =
                                                    null
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="addOther"
                                        >
                                            {{
                                                $t("placeholders.other_reason")
                                            }}
                                        </label>
                                    </div>

                                    <div
                                        class="wrapper fadeIn mb-3"
                                        v-if="showOtherReason"
                                    >
                                        <textarea
                                            :placeholder="
                                                $t('placeholders.other_reason')
                                            "
                                            v-model="canclingData.note"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="note">
                                <h5>{{ $t("modals.note") }}</h5>
                                <p>
                                    {{ $t("modals.note_text_1") }}
                                    <br />
                                    {{ $t("modals.note_text_2") }}
                                </p>
                            </div>
                            <!-- START:: FORM BUTTONS GROUP -->
                            <div class="btns_group">
                                <div class="btn_wrapper">
                                    <button
                                        type="button"
                                        class="btn cancel_all_button"
                                        @click="
                                            $emit('closeModule');
                                            canclingData.report_reason_id =
                                                null;
                                            canclingData.note = null;
                                            showOtherReason = false;
                                        "
                                    >
                                        {{ $t("buttons.cancel") }}
                                    </button>
                                </div>

                                <div class="btn_wrapper">
                                    <button
                                        class="btn send_filter_btn"
                                        :class="{ disabled: emptyButton }"
                                    >
                                        <!-- @click="$emit('sendCancel')" -->
                                        {{ $t("buttons.send") }}
                                        <span
                                            class="btn_loader"
                                            v-if="isWaitingRequest"
                                        ></span>
                                    </button>
                                </div>
                            </div>
                            <!-- END:: FORM BUTTONS GROUP -->
                        </form>
                    </div>
                </div>
            </template>
        </BaseModel>

        <BaseModel :show="showAreYouSure">
            <template #modal>
                <div class="modal_successfully">
                    <div class="text_successfully">
                        <p>{{ $t("modals.are_you_sure") }}</p>
                    </div>
                    <form>
                        <!-- START:: FORM BUTTONS GROUP -->
                        <div class="btns_group">
                            <div class="btn_wrapper">
                                <button
                                    type="button"
                                    class="btn cancel_all_button"
                                    @click="$emit('closeModule')"
                                >
                                    {{ $t("buttons.cancel") }}
                                </button>
                            </div>

                            <div class="btn_wrapper">
                                <button
                                    class="btn send_filter_btn"
                                    type="button"
                                    @click="$emit('AreYouSure')"
                                >
                                    {{ $t("buttons.yes") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequest"
                                    ></span>
                                </button>
                            </div>
                        </div>
                        <!-- END:: FORM BUTTONS GROUP -->
                    </form>
                </div>
            </template>
        </BaseModel>

        <BaseModel :show="showSuccessCancel">
            <template #modal>
                <div class="modal_successfully">
                    <div class="image_successfully">
                        <img
                            src="../../assets/media/illustrations/successfully1.svg"
                            alt="successfully"
                            width="100"
                            height="100"
                        />
                    </div>
                    <div class="text_successfully">
                        <p>
                            {{ $t("modals.success_request_sent") }}
                        </p>
                    </div>
                    <form>
                        <div class="wrapper justify-content-center">
                            <button
                                class="btn"
                                type="button"
                                @click="closeWithLoad()"
                            >
                                {{ $t("buttons.continue") }}
                                <span
                                    class="btn_loader"
                                    v-if="isWaitingRequest"
                                ></span>
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </BaseModel>
    </section>
</template>
<script>
import BaseModel from "../ui/BaseModel.vue";
export default {
    props: [
        "showCancelContracts",
        "showAreYouSure",
        "showSuccessCancel",
        "showRate",
        "showFinished",
        "isWaitingRequest",
    ],
    data() {
        return {
            //START:: OF CONTRACT DATA
            details: {},
            //END:: OF CONTRACT DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            //START:: RATE DATA
            contract_id: this.$route.params.id,
            rate: "",
            review: "",
            //END:: RATE DATA

            // START:: CANCLING REASONS
            canclingReasons: null,
            // END:: CANCLING REASONS
            // START:: REPORT DATA
            canclingData: {
                report_reason_id: null,
                note: null,
            },
            showOtherReason: false,
            // END:: REPORT DATA
        };
    },
    components: {
        BaseModel,
    },
    computed: {
        emptyButton() {
            if (
                this.canclingData.report_reason_id == null &&
                this.canclingData.note == null
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        // START:: METHOD SEND RATE
        sendRateMethod() {
            if (this.rate == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.rate"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                return;
            } else if (this.review == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.review"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                return;
            } else {
                const data = new FormData();
                data.append("contract_id", this.contract_id);
                data.append("rate", this.rate);
                data.append("review", this.review);

                this.$axios
                    .post("investor/rate", data, {
                        headers: {
                            Authorization:
                                "Bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": `multipart/form-data;`,
                            Accept: "application/json",
                        },
                    })
                    .then((res) => {
                        if (res.data.status == "success") {
                            this.rate = "";
                            this.review = "";
                            this.$iziToast.success({
                                timeout: 5000,
                                message: this.$t(
                                    "frequently_words.messageRate"
                                ),
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                        }
                        this.$emit("addRate");
                    })
                    .catch(() => {});
            }
        },
        // END:: METHOD SEND RATE

        // START:: METHOD GET INFLUINCER DATA
        getInfluincerData() {
            if (this.userType == "investor") {
                this.loadMessages = true;
                this.$axios
                    .get("/investor/contract/" + this.contract_id, {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    })
                    .then((res) => {
                        this.details = res.data.data.influencer;
                        this.loadMessages = false;
                    });
            }
        },
        // END:: METHOD GET INFLUINCER DATA

        // START:: AXIOS GET CANCLING REASONS
        getCanclingReasons() {
            this.$axios
                .get("cancel_reasons", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.canclingReasons = res.data.data;
                });
        },
        // END:: AXIOS GET CANCLING REASONS

        // START:: SUBMIT CANCLE FORM
        submitCanclingForm() {
            this.isWaitingRequest = true;
            // START:: APPEND FORM DATA
            const theData = new FormData();
            theData.append("id", this.contract_id);
            if (this.canclingData.report_reason_id !== null) {
                theData.append(
                    "report_reason_id",
                    this.canclingData.report_reason_id
                );
            }
            theData.append("note", this.canclingData.note);
            if (this.userType == "influincer") {
                theData.append("contract_status", "influencer_cancel");
            } else if (this.userType == "investor") {
                theData.append("contract_status", "investor_cancel_contract");
            }
            // END:: APPEND FORM DATA

            var baseContract;
            if (this.userType == "influincer") {
                baseContract = "influencer/contract/change_status";
            } else if (this.userType == "investor") {
                baseContract = "investor/contract/change_status";
            }
            this.$axios
                .post(baseContract, theData, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        this.canclingData.report_reason_id = null;
                        this.canclingData.note = null;
                    }
                    this.isWaitingRequest = false;
                    this.$emit("sendCancel");
                });
        },
        // END:: SUBMIT CANCLE FORM
        closeWithLoad() {
            this.$emit("closeModule");
            location.reload();
        },
    },
    mounted() {
        this.getInfluincerData();

        // START:: AXIOS GET CANCLING REASONS
        this.getCanclingReasons();
        // END:: AXIOS GET CANCLING REASONS
    },
};
</script>

<template>
    <div class="staticPages">
        <div class="page_title">
            <h1>{{ $t("titles.about") }}</h1>
        </div>
        <Loader v-if="loadPage" class="fadeIn heigth-50vh"></Loader>
        <div class="row fadeIn" v-else>
            <div class="col-md-5">
                <div class="image_about">
                    <img
                        src="../../assets/media/imgs/about.svg"
                        alt="About image"
                        width="100"
                        height="100"
                    />
                </div>
            </div>

            <div class="col-md-7">
                <div class="text_about" v-html="about"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "./../../components/loader/main-loader.vue";
export default {
    name: "About",
    components: {
        Loader,
    },
    data() {
        return {
            about: "",
            loadPage: false,
        };
    },

    methods: {
        getStaticContent() {
            this.loadPage = true;
            this.$axios
                .get("settings/about", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.about = res.data.data.about;
                    this.loadPage = false;
                });
        },
    },

    mounted() {
        this.getStaticContent();
    },
};
</script>

<template>
    <div class="splash_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7 px-0 px-md-2">
                    <div
                        class="video_wrapper"
                        v-animate-css="
                            lang == 'en' ? fadeInLeftBig : fadeInRightBig
                        "
                    >
                        <VuePlyr :poster="introVideoData.thumbnail">
                            <video class="video-player">
                                <source
                                    :src="introVideoData.url"
                                    type="video/mp4"
                                />
                            </video>
                        </VuePlyr>
                    </div>
                </div>

                <div class="col-md-5">
                    <div
                        class="content_wrapper"
                        v-animate-css="
                            lang == 'en' ? fadeInRightBig : fadeInLeftBig
                        "
                    >
                        <div class="content_header_wrapper">
                            <div class="logo_wrapper">
                                <router-link to="/">
                                    <img
                                        src="../assets/logo/small_logo.svg"
                                        alt="Inflico Logo"
                                        width="100"
                                        height="100"
                                    />
                                </router-link>
                            </div>

                            <div class="title_wrapper">
                                <h2>{{ $t("titles.intro") }}</h2>
                            </div>
                        </div>

                        <div class="content_body_wrapper">
                            <div class="btn_wrapper">
                                <router-link to="/register/influincer">
                                    {{ $t("titles.influincer") }}
                                </router-link>
                            </div>

                            <div class="btn_wrapper">
                                <router-link to="/register/investor">
                                    {{ $t("titles.investor") }}
                                </router-link>
                            </div>
                        </div>

                        <div class="content_footer_wrapper">
                            <router-link to="/login">
                                {{ $t("titles.have_an_account") }}
                                <span>{{ $t("auth.login") }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VIDEO PLAYER
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// END:: IMPORTING VIDEO PLAYER

export default {
    name: "Splash",

    components: {
        VuePlyr,
    },

    data() {
        return {
            // START:: APP LANG DATA
            lang: localStorage.getItem("inflco_app_lang"),
            // END:: APP LANG DATA

            // START:: ANIMATIONS DATA
            fadeInRightBig: {
                classes: "fadeInRightBig",
                duration: 2000,
            },
            fadeInLeftBig: {
                classes: "fadeInLeftBig",
                duration: 2000,
            },
            // END:: ANIMATIONS DATA

            // START:: GET INTRO VIDEO DATA
            introVideoData: null,
            // END:: GET INTRO VIDEO DATA
        };
    },

    methods: {
        // START:: AXIOS GET INTRO VIDEO DATA
        getIntroVideo() {
            this.$axios.get("settings/intro_video").then((res) => {
                this.introVideoData = res.data.data.intro_video;
            });
        },
        // END:: AXIOS GET INTRO VIDEO DATA
    },

    mounted() {
        // START:: AXIOS GET INTRO VIDEO DATA
        this.getIntroVideo();
        // END:: AXIOS GET INTRO VIDEO DATA
    },
};
</script>

<template>
    <div class="reset_pass_form_wrapper">
        <div class="reset_pass_header_wrapper">
            <div class="logo_wrapper">
                <img
                    src="../../assets/logo/small_logo.svg"
                    alt="Inflico Logo"
                    width="100"
                    height="100"
                />
            </div>

            <div class="title_wrapper">
                <h2>{{ $t("auth.forgot_password") }}</h2>
            </div>

            <div class="subtitle_wrapper">
                <h5>
                    {{ $t("titles.enter_your_phone") }}
                </h5>
            </div>
        </div>

        <div class="reset_pass_body_wrapper">
            <form @submit.prevent="validateResetPasswordNumber">
                <!-- START:: PHONE INPUT -->
                <div class="outer_wrapper">
                    <div class="wrapper mb-3" @click="toggleCountryKeysMenu">
                        <div class="flag_wrapper">
                            <img
                                :src="this.$store.state.selectedCountry.flag"
                            />
                        </div>
                        <div class="key_wrapper">
                            {{ this.$store.state.selectedCountry.phonecode }}
                        </div>

                        <transition mode="out-in" name="fade">
                            <div
                                class="country_keys_menu_wrapper"
                                v-if="countryKiesMenuIsOpen"
                            >
                                <ul class="country_keys_menu">
                                    <li
                                        class="menu_item"
                                        v-for="item in $store.state
                                            .allCountries"
                                        :key="item.phonecode"
                                        @click="selectCountry(item)"
                                    >
                                        <div class="flag_wrapper">
                                            <img :src="item.flag" />
                                        </div>
                                        <div class="key_wrapper">
                                            {{ item.phonecode }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </transition>
                    </div>

                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.phone')"
                            v-model="resetPasswordData.phone"
                        />
                    </div>
                </div>
                <!-- END:: PHONE INPUT -->

                <!-- START:: SUBMIT BUTTON -->
                <div class="wrapper justify-content-center">
                    <button class="btn">
                        {{ $t("buttons.send") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON -->
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResetPasswordPhoneNumber",

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: COUNTRIES KYES MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KYES MENU HANDLING DATA

            // START:: LOGIN DATA
            resetPasswordData: {
                phone: "",
                phone_key: "",
            },
            // END:: LOGIN DATA
        };
    },

    methods: {
        async userLocation() {
            const {
                data: { loc },
            } = await this.$axios.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                {
                    responseType: "text",
                    transformResponse: (data) =>
                        Object.fromEntries(
                            data
                                .trim()
                                .split("\n")
                                .map((line) => line.split("="))
                        ),
                }
            );

            this.$store.state.currentLocation = loc;
            this.$store.dispatch("getCountries");
        },
        // START:: TOGGLE COUNTRIES KYES MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KYES MENU

        // START:: SELECT COUNTRY
        selectCountry(item) {
            this.$store.dispatch("selectCountry", { item });
        },
        // END:: SELECT COUNTRY

        // START:: SUBMIT LOGIN DATA
        validateResetPasswordNumber() {
            this.isWaitingRequest = true;

            // START:: SET COUNTRY KEY VALUE
            this.resetPasswordData.phone_key = this.$store.state.selectedCountry.phonecode;
            // END:: SET COUNTRY KEY VALUE

            if (
                this.resetPasswordData.phone == null ||
                this.resetPasswordData.phone == ""
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.phoneValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }  else if (
                (this.resetPasswordData.phone.length !=
                this.$store.state.phoneDigitNum)
            ) {
                if (this.resetPasswordData.phone[0] == "0" ) {
                    let numberArray = [...this.resetPasswordData.phone];
                    numberArray.splice(0, 1);
                    this.resetPasswordData.phone = numberArray.join('');
                    this.submitAfterValidation();
                } else {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.phoneValidationLength") +
                            " " +
                            this.$store.state.phoneDigitNum +
                            " " +
                            this.$t("validation.digit"),
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.buttonIsDisabled = false;
                    this.isWaitingRequest = false;
                    return;
                }
                } else {
                this.submitAfterValidation();
            }
        },

        submitAfterValidation() {
            this.$axios
                .post("auth/send_code", this.resetPasswordData)
                .then((res) => {
                    if (res.data.status == "fail") {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: res.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                    } else if (res.data.status == "success") {
                        this.isWaitingRequest = false;
                        localStorage.setItem(
                            "inflco_user_phone",
                            this.resetPasswordData.phone_key +
                                this.resetPasswordData.phone
                        );
                        localStorage.setItem(
                            "inflco_user_phone_key",
                            this.resetPasswordData.phone_key
                        );
                        localStorage.setItem(
                            "inflco_user_phone_number",
                            this.resetPasswordData.phone
                        );
                        localStorage.setItem(
                            "inflco_verification_code",
                            res.data.dev_message
                        );
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("validation.sent_successfully"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.$store.state.verificationCode =
                            res.data.dev_message;
                        this.$router.replace("/verification/reset-password");
                    }
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                });
        }
        // END:: SUBMIT LOGIN DATA
    },

    created() {
        // ======== START:: HANDLING API ========

        // START:: AXIOS GET COUNTRIES
        this.userLocation();
        // END:: AXIOS GET COUNTRIES

        // ======== END:: HANDLING API ========
    },
};
</script>

<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="register_form_wrapper">
    <div class="register_header_wrapper">
      <div class="logo_wrapper">
        <img
          src="../../assets/logo/small_logo.svg"
          alt="Inflico Logo"
          width="100"
          height="100"
        />
      </div>
      <div class="title_wrapper">
        <h2 v-if="userType == 'investor'">
          {{ $t("titles.investor_sign_up") }}
        </h2>
        <h2 v-if="userType == 'influincer'">
          {{ $t("titles.influincer_sign_up") }}
        </h2>
      </div>
      <div class="slogan_wrapper">
        <h5>{{ $t("titles.sign_up_description") }}</h5>
      </div>
      <div class="title_wrapper">
        <h5>{{ $t("titles.you_social_accounts") }}</h5>
      </div>
      <div class="form_wrapper">
        <form>
          <!-- START:: SOCIAL AREA -->
          <div class="links_social_media">
            <div
              class="wrapper_single_link fadeIn"
              v-for="(socialLink, index) in socialPlatForms"
              :key="socialLink.id"
            >
              <div class="single_link py-1">
                <!--  -->
                <img :src="socialLink.icon" :alt="socialLink.name" />
                <!--  -->
                <div class="wrapper col-11">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Social Media Link"
                    v-model="integrateLink[index]"
                    value=""
                    @change="
                      pushSociallinks(index, socialLink.id, socialLink.type)
                    "
                  />
                </div>
                <!-- START:: FOLLOWERS NUMBER INPUT ON ERROR -->

                <!-- END:: FOLLOWERS NUMBER INPUT ON ERROR -->
                <span class="btn_loader_blue" v-if="waitLoader[index]"></span>
                <h5
                  v-for="err in allErrorIcons"
                  :key="err.id"
                  v-if="socialLink.id == err.id"
                  class="fadeIn"
                >
                  <i class="fas fa-times text-danger"></i>
                </h5>
                <h5
                  v-for="succ in allSuccessIcons"
                  :key="succ.id"
                  v-if="socialLink.id == succ.id"
                  class="fadeIn"
                >
                  <i class="fas fa-check"></i>
                </h5>
                <h5
                  v-for="iconNum in allNumberFollowers"
                  :key="iconNum.id"
                  v-if="socialLink.id == iconNum.id"
                  class="fadeIn"
                >
                  <i class="fas fa-exclamation-circle text-primary"></i>
                </h5>
              </div>
              <!-- ------------ add number of followers ------------- -->
              <div
                class="single_link py-1"
                v-for="number_follower in allNumberFollowers"
                :key="number_follower.id"
                v-if="socialLink.id == number_follower.id"
              >
                <img :src="socialLink.icon" :alt="socialLink.name" />
                <div class="wrapper col-11">
                  <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('placeholders.followers_number')"
                    v-model="numberFollowers[index]"
                    @change="
                      pushFollowsNumber(
                        index,
                        socialLink.min_followers_count,
                        socialLink.id
                      )
                    "
                    onwheel="this.blur()"
                  />
                </div>
                <h5
                  v-for="errCount in allErrorCounts"
                  :key="errCount.id"
                  v-if="socialLink.id == errCount.id && showIcon"
                  class="fadeIn"
                >
                  <i class="fas fa-times text-danger"></i>
                </h5>
                <h5
                  v-for="succCount in allSuccessCounts"
                  :key="succCount.id"
                  v-if="socialLink.id == succCount.id && showIcon"
                  class="fadeIn"
                >
                  <i class="fas fa-check"></i>
                </h5>
              </div>
              <div
                class="warning_wrapper"
                v-for="msg in allIntegrateMsgs"
                :key="msg.id"
                v-if="socialLink.id == msg.id"
              >
                <img src="../../assets/media/icons/war.svg" alt="war" />
                <p>
                  {{ msg.text }}
                </p>
              </div>
            </div>
          </div>
          <!-- END:: SOCIAL AREA -->
          <!-- START:: SUBMIT BUTTON -->
          <div class="wrapper mt-5 justify-content-center">
            <button class="btn" type="button" @click="submitSocialLinksForm">
              {{ $t("buttons.done") }}
              <span class="btn_loader" v-if="isWaitingRequest"></span>
            </button>
          </div>
          <!-- END:: SUBMIT BUTTON -->
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "socialAccounts",
  components: {},
  data() {
    return {
      // START:: BUTTON LOADER DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER DATA
      // START:: USER TYPE DATA
      userType: this.$route.params.type,
      // END:: USER TYPE DATA
      socialPlatForms: [],
      // START:: REGISTER DATA
      // END:: REGISTER DATA
      loadAddLink: false,
      //   ------------------------
      integrateLink: [],
      integrateMsg: [],
      allIntegrateMsgs: [],
      waitLoader: [],
      errorIcon: [],
      allErrorIcons: [],
      errorCount: [],
      allErrorCounts: [],
      successIcon: [],
      allSuccessIcons: [],
      successCount: [],
      allSuccessCounts: [],
      numberFollowers: [],
      allNumberFollowers: [],
      showIcon: null,
      social_ids_Array: {
        socila_platform_ids: [],
      },
    };
  },
  methods: {
    getSocialData() {
      this.$axios
        .get("social_platforms", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("inflco_user_token"),
            "Content-type": "application/json",
            "cache-control": "no-cache",
            Accept: "application/json",
            "Accept-language": localStorage.getItem("inflco_app_lang"),
          },
        })
        .then((res) => {
          this.socialPlatForms = res.data.data;
        });
    },
    pushSociallinks(index, id, type) {
      if (
        this.integrateLink[index] !== undefined &&
        this.integrateLink[index].length != 0
      ) {
        // show if link not equal type of social plat form
        if (!this.integrateLink[index].includes(type)) {
          this.$iziToast.error({
            timeout: 3000,
            message: this.$t("validation.errorSocialType") + type,
            position: this.$t("position"),
            rtl: this.$t("dir"),
          });
          this.integrateLink[index] = "";
        } else {
          this.getValidateLink(this.integrateLink[index], index, id);
        }
        // ------------ loader ---------------
      }
      //   ------------ remove icon and messages -------------
      this.allIntegrateMsgs = this.allIntegrateMsgs.filter((e) => e.id !== id);
      // ------------
      this.allErrorIcons = this.allErrorIcons.filter((e) => e.id !== id);
      // -------------
      this.allSuccessIcons = this.allSuccessIcons.filter((e) => e.id !== id);
      // -------------
      this.allNumberFollowers = this.allNumberFollowers.filter(
        (e) => e.id !== id
      );
      // -------------
      this.social_ids_Array.socila_platform_ids =
        this.social_ids_Array.socila_platform_ids.filter(
          (e) => e.social_platform_id !== id
        );
    },
    getValidateLink(link, index, id) {
      this.waitLoader[index] = true;
      this.showIcon = false;
      const data = new FormData();
      data.append("link", link);
      this.$axios
        .post("influencer/link_validate", data, {
          headers: {
            "Content-type": `multipart/form-data;`,
            Authorization: "Bearer" + localStorage.getItem("inflco_user_token"),
          },
        })
        .then((res) => {
          this.waitLoader[index] = false;
          if (res.data.data != null && res.data.data.can_integrate == true) {
            //   success icon
            this.successIcon[index] = true;
            if (
              this.successIcon[index] !== undefined &&
              this.successIcon[index].length != 0
            ) {
              if (this.allSuccessIcons.length != 0) {
                this.allSuccessIcons = this.allSuccessIcons.filter(
                  (e) => e.id !== id
                );
                this.allSuccessIcons.push({
                  id: id,
                });
              } else {
                this.allSuccessIcons.push({
                  id: id,
                });
              }
            }
            // push data on array to send to backend
            if (this.social_ids_Array.socila_platform_ids.length != 0) {
              this.social_ids_Array.socila_platform_ids =
                this.social_ids_Array.socila_platform_ids.filter(
                  (e) => e.social_platform_id !== id
                );
              this.social_ids_Array.socila_platform_ids.push({
                social_platform_id: res.data.data.id,
                total_followers: res.data.data.followers_count.followers_count,
                link: res.data.data.link,
              });
            } else {
              this.social_ids_Array.socila_platform_ids.push({
                social_platform_id: res.data.data.id,
                total_followers: res.data.data.followers_count.followers_count,
                link: res.data.data.link,
              });
            }
          }
          //   ---------- add number of followers  -----------
          else if (
            res.data.data != null &&
            res.data.data.can_integrate == false
          ) {
            if (
              this.integrateLink[index] !== undefined &&
              this.integrateLink[index].length != 0
            ) {
              if (this.allNumberFollowers.length != 0) {
                this.allNumberFollowers = this.allNumberFollowers.filter(
                  (e) => e.id !== id
                );
                this.allNumberFollowers.push({
                  id: id,
                });
              } else {
                this.allNumberFollowers.push({
                  id: id,
                });
              }
            }
          }
        })
        .catch((error) => {
          this.waitLoader[index] = false;
          // ----------- error message --------------
          this.integrateMsg[index] = error.response.data.message;
          if (
            this.integrateMsg[index] !== undefined &&
            this.integrateMsg[index].length != 0
          ) {
            if (this.allIntegrateMsgs.length != 0) {
              this.allIntegrateMsgs = this.allIntegrateMsgs.filter(
                (e) => e.id !== id
              );
              this.allIntegrateMsgs.push({
                id: id,
                text: this.integrateMsg[index],
              });
            } else {
              this.allIntegrateMsgs.push({
                id: id,
                text: this.integrateMsg[index],
              });
            }
          }
          // ----------- error icon --------------
          this.errorIcon[index] = true;
          if (
            this.errorIcon[index] !== undefined &&
            this.errorIcon[index].length != 0
          ) {
            if (this.allErrorIcons.length != 0) {
              this.allErrorIcons = this.allErrorIcons.filter(
                (e) => e.id !== id
              );
              this.allErrorIcons.push({
                id: id,
              });
            } else {
              this.allErrorIcons.push({
                id: id,
              });
            }
          }
        });
    },
    // -----------push number followers on array  ----------
    pushFollowsNumber(index, min, id) {
      this.showIcon = false;
      this.allSuccessCounts = this.allSuccessCounts.filter((e) => e.id !== id);
      this.allErrorCounts = this.allErrorCounts.filter((e) => e.id !== id);
      if (this.numberFollowers[index] < min) {
        setTimeout(() => {
          this.showIcon = true;
        }, 100);

        this.$iziToast.error({
          timeout: 3000,
          message: this.$t("validation.minimumFollowers") + min,
          position: this.$t("position"),
          rtl: this.$t("dir"),
        });
        //   error count
        this.errorCount[index] = true;
        if (
          this.errorCount[index] !== undefined &&
          this.errorCount[index].length != 0
        ) {
          if (this.allErrorCounts.length != 0) {
            this.allErrorCounts = this.allErrorCounts.filter(
              (e) => e.id !== id
            );
            this.allErrorCounts.push({
              id: id,
            });
          } else {
            this.allErrorCounts.push({
              id: id,
            });
          }
        }
      } else {
        //   success Count
        setTimeout(() => {
          this.showIcon = true;
        }, 100);
        this.successCount[index] = true;
        if (
          this.successCount[index] !== undefined &&
          this.successCount[index].length != 0
        ) {
          if (this.allSuccessCounts.length != 0) {
            this.allSuccessCounts = this.allSuccessCounts.filter(
              (e) => e.id !== id
            );
            this.allSuccessCounts.push({
              id: id,
            });
          } else {
            this.allSuccessCounts.push({
              id: id,
            });
          }
        }
        // push data on array to send to backend
        if (this.social_ids_Array.socila_platform_ids.length != 0) {
          this.social_ids_Array.socila_platform_ids =
            this.social_ids_Array.socila_platform_ids.filter(
              (e) => e.social_platform_id !== id
            );
          this.social_ids_Array.socila_platform_ids.push({
            social_platform_id: id,
            total_followers: this.numberFollowers[index],
            link: this.integrateLink[index],
          });
        } else {
          this.social_ids_Array.socila_platform_ids.push({
            social_platform_id: id,
            total_followers: this.numberFollowers[index],
            link: this.integrateLink[index],
          });
        }
      }
    },
    // START:: SUBMIT REGISTER FORM
    submitSocialLinksForm() {
      this.social_ids_Array.socila_platform_ids =
        this.social_ids_Array.socila_platform_ids.filter(
          (e) => e.total_followers !== ""
        );
      this.isWaitingRequest = true;
      this.$axios
        .post("influencer/signup_third_step", this.social_ids_Array, {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("inflco_user_token"),
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          if (res.data.status == "success") {
            localStorage.setItem(
              "inflco_user_token",
              res.data.data.token.access_token
            );
            localStorage.setItem(
              "inflco_verification_code",
              res.data.dev_message
            );
            this.$router.replace("/verification/success-verification-link");
            this.isWaitingRequest = false;
          }
        })
        .catch(() => {
          this.isWaitingRequest = false;
        });
    },
    // END:: SUBMIT REGISTER FORM
  },
  mounted() {
    this.getSocialData();
  },
};
</script>

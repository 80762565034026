<template>
  <div class="rating">
    <!-- START:: ZERO STARS -->
    <ul v-if="rate == '0'">
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: ZERO STARS -->

    <!-- START:: ONE STARS -->
    <ul v-if="rate == '1'">
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: ONE STARS -->

    <!-- START:: ONE AND HALF STARS -->
    <ul v-if="rate == '1.5'">
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="fas fa-star-half-alt"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: ONE AND HALF STARS -->

    <!-- START:: TWO STARS -->
    <ul v-if="rate == '2'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: TWO STARS -->

    <!-- START:: TWO AND HALF STARS -->
    <ul v-if="rate == '2.5'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="fas fa-star-half-alt"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: TWO AND HALF STARS -->

    <!-- START:: THREE STARS -->
    <ul v-if="rate == '3'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: THREE STARS -->

    <!-- START:: THREE AND HALF STARS -->
    <ul v-if="rate == '3.5'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="fas fa-star-half-alt"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: THREE AND HALF STARS -->

    <!-- START:: FOUR STARS -->
    <ul v-if="rate == '4'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="far fa-star"></i></li>
    </ul>
    <!-- END:: FOUR STARS -->

    <!-- START:: FOUR AND HALF STARS -->
    <ul v-if="rate == '4.5'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li class="empty_star"><i class="fas fa-star-half-alt"></i></li>
    </ul>
    <!-- END:: FOUR AND HALF STARS -->

    <!-- START:: FIVE STARS -->
    <ul v-if="rate == '5'">
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
      <li><i class="fas fa-star"></i></li>
    </ul>
    <!-- END:: FIVE STARS -->
  </div>
</template>

<script>
export default {
  props: ["rate"],
};
</script>

<style lang="scss">
@import "../../assets/sass/varibles/_variables.scss";
.rating {
  ul {
    padding: 0;
    margin: 0;
    svg {
      color: $gold;
    }
  }
}
</style>

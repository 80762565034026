<template>
    <div class="all_contracts_list_wrapper" @scroll="scrollSection">
        <div class="title_wrapper">
            <h3>{{ $t("titles.contracts") }}</h3>
        </div>
        <Loader v-if="loadContracts" :number="6" class="fadeIn"></Loader>
        <section v-else class="fadeIn">
            <!-- START:: EMPTY MESSAGE WRAPPER -->
            <SecondEmptyMessage class="py-0" v-if="contractsList.length == 0" />
            <!-- END:: EMPTY MESSAGE WRAPPER -->

            <!-- START:: CHAT LIST -->
            <div class="contracts_list" v-else>
                <div
                    class="contract_data_wrapper"
                    v-for="contract in contractsList"
                    :key="contract.id"
                >
                    <template v-if="contract.investor && contract.influencer">
                        <router-link
                            :to="
                                userType == 'investor'
                                    ? '/contract/' + contract.id
                                    : '/influincer-contract/' + contract.id
                            "
                            class="single_contract_route"
                        >
                            <div class="avatar_wrapper d-flex">
                                <img
                                    :src="
                                        userType == 'investor'
                                            ? contract.influencer.image
                                            : contract.investor.image
                                    "
                                    alt="Influincer Avatar"
                                    width="100"
                                    height="100"
                                />
                                <div class="wrapper">
                                    <div class="name">
                                        <span v-if="userType == 'investor'">
                                            {{ contract.influencer.fullname }}
                                        </span>
                                        <span v-else>
                                            {{ contract.investor.fullname }}
                                        </span>
                                    </div>

                                    <div class="fields">
                                        <span>
                                            {{ contract.campaign_title }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="details_wrapper">
                                <div class="wrapper">
                                    <span class="time">
                                        {{ contract.created_at }}
                                    </span>
                                    <span
                                        class="contract_status processing"
                                        v-if="contract.status == 'pending'"
                                    >
                                        {{ $t("states.pending") }}
                                    </span>
                                    <span
                                        class="contract_status processing"
                                        v-if="
                                            contract.status ==
                                                'influencer_accept' &&
                                            contract.cancel_request == null
                                        "
                                    >
                                        {{ $t("states.accept") }}
                                    </span>
                                    <span
                                        class="contract_status refuced-text"
                                        v-if="
                                            contract.status ==
                                                'influencer_accept' &&
                                            contract.cancel_request !== null
                                        "
                                    >
                                        {{ $t("states.cancel_request") }}
                                    </span>
                                    <span
                                        class="contract_status refuced-text"
                                        v-if="
                                            contract.status ==
                                            'influencer_reject'
                                        "
                                    >
                                        {{ $t("states.reject") }}
                                    </span>
                                    <span
                                        class="contract_status refuced-text"
                                        v-if="
                                            contract.status ==
                                            'influencer_cancel'
                                        "
                                    >
                                        {{ $t("states.cancel") }}
                                        {{
                                            $t("frequently_words.by_influincer")
                                        }}
                                    </span>
                                    <span
                                        class="contract_status refuced-text"
                                        v-if="
                                            contract.status ==
                                            'investor_cancel_contract'
                                        "
                                    >
                                        {{ $t("states.cancel") }}
                                        {{ $t("frequently_words.by_investor") }}
                                    </span>
                                    <span
                                        class="contract_status processing"
                                        v-if="contract.status == 'in_progress'"
                                    >
                                        {{ $t("states.processing") }}
                                    </span>
                                    <span
                                        class="contract_status accept-text"
                                        v-if="
                                            contract.status == 'closed_contract'
                                        "
                                    >
                                        {{ $t("states.closed") }}
                                    </span>
                                    <span
                                        class="contract_status done"
                                        v-if="contract.status == 'done'"
                                    >
                                        {{ $t("states.done") }}
                                    </span>
                                </div>
                            </div>
                        </router-link>
                    </template>
                </div>
            </div>
            <!-- END:: CHAT LIST -->
        </section>
        <Loader v-if="loadContractDots" :number="1"></Loader>
    </div>
</template>

<script>
import SecondEmptyMessage from "../emptyMessages/SecondEmptyMessage.vue";
import Loader from "./../loader/contracts.vue";
export default {
    name: "ContractsList",

    components: {
        SecondEmptyMessage,
        Loader,
    },

    data() {
        return {
            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA
            contractsList: [],
            loadContracts: false,
            // pagenations
            currentPage_of_pagenation: "",
            last_page_of_pagenation: "",
            loadContractDots: false,
        };
    },
    methods: {
        getContractsInnvestor() {
            this.loadContracts = true;
            this.loadContractDots = false;
            this.currentPage_of_pagenation = 1;
            this.$axios
                .get(
                    "investor/contract?page=" + this.currentPage_of_pagenation,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    this.contractsList = res.data.data;
                    this.last_page_of_pagenation = res.data.meta.last_page;
                    this.loadContracts = false;
                });
        },
        getContractsInfluincer() {
            this.loadContracts = true;
            this.loadContractDots = false;
            this.currentPage_of_pagenation = 1;
            this.$axios
                .get(
                    "influencer/contract?page=" +
                        this.currentPage_of_pagenation,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    this.contractsList = res.data.data;
                    this.last_page_of_pagenation = res.data.meta.last_page;
                    this.loadContracts = false;
                });
        },
        getContractsInnvestorScroll() {
            this.loadContractDots = true;
            this.$axios
                .get(
                    "investor/contract?page=" + this.currentPage_of_pagenation,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    res.data.data.forEach((element) => {
                        this.contractsList.push(element);
                    });
                    this.last_page_of_pagenation = res.data.meta.last_page;
                    this.loadContracts = false;
                    this.loadContractDots = false;
                });
        },
        getContractsInfluincerScroll() {
            this.loadContractDots = true;
            this.$axios
                .get(
                    "influencer/contract?page=" +
                        this.currentPage_of_pagenation,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    res.data.data.forEach((element) => {
                        this.contractsList.push(element);
                    });
                    this.last_page_of_pagenation = res.data.meta.last_page;
                    this.loadContractDots = false;
                });
        },
        scrollSection() {
            if (
                this.currentPage_of_pagenation != this.last_page_of_pagenation
            ) {
                var chatSection = document.querySelector(".contracts_list");
                if (
                    chatSection.offsetHeight + chatSection.scrollTop >=
                    chatSection.scrollHeight
                ) {
                    this.currentPage_of_pagenation += 1;
                    if (this.userType == "investor") {
                        this.getContractsInnvestorScroll();
                    } else {
                        this.getContractsInfluincerScroll();
                    }
                }
            }
        },
    },
    mounted() {
        // START:: AXIOS GET CONTRACTS
        if (this.userType == "investor") {
            this.getContractsInnvestor();
        } else {
            this.getContractsInfluincer();
        }
    },
};
</script>

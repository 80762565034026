<template>
  <div class="empty_content_wrapper small_wrapper">
    <div class="vector_shape_wrapper">
      <img
        src="../../assets/media/illustrations/path.svg"
        width="150"
        height="150"
      />

      <div class="note">
        <span> {{ $t("titles.no_content") }} </span>
        <span>
          {{ $t("titles.no_content_subtitle") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondEmptyMessage",
};
</script>

<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="influincer_social_accounts_wrapper">
    <div class="title_wrapper">
      <h3>{{ $t("titles.social_accounts") }}</h3>

      <router-link to="/personal-profile">
        <img
          src="../../assets/media/icons/user.svg"
          alt="icon"
          width="25"
          height="25"
        />
      </router-link>
    </div>

    <div class="subtitle">
      {{ $t("titles.social_account_subtitle") }}
    </div>

    <div class="social_accounts">
      <Loader v-if="loadCards" class="fadeIn heigth-50vh"></Loader>
      <div class="container-fluid p-0 fadeIb" v-else>
        <div class="row justify-content-center" v-if="social_links.length != 0">
          <div
            v-for="account in social_links"
            :key="account.id"
            class="col-md-4 my-3"
          >
            <div class="social_account_card">
              <div class="title_wrapper">
                <!-- START:: SOCIAL MEDIA ICONS -->
                <img
                  :src="account.social_platform.icon"
                  alt="icon"
                  width="25"
                  height="25"
                />
                <!-- START:: SOCIAL MEDIA ICONS -->

                <!-- <span
                  :class="account.link.includes('facebook') ? 'facebook' : ''"
                  v-if="account.link.includes('facebook')"
                >
                  {{ account.total_followers }}
                </span>

                <span
                  :class="account.link.includes('twitter') ? 'twitter' : ''"
                  v-if="account.link.includes('twitter')"
                >
                  {{ account.total_followers }}
                </span>

                <span
                  :class="account.link.includes('instagram') ? 'instagram' : ''"
                  v-if="account.link.includes('instagram')"
                >
                  {{ account.total_followers }}
                </span>
                <span
                  :class="account.link.includes('tiktok') ? 'tiktok' : ''"
                  v-if="account.link.includes('tiktok')"
                >
                  {{ account.total_followers }}
                </span>
                <span
                  :class="account.link.includes('snapchat') ? 'snapchat' : ''"
                  v-if="account.link.includes('snapchat')"
                >
                  {{ account.total_followers }}
                </span> -->
                <!-- <span
                  :class="account.link.includes('youtube') ? 'youtube' : ''"
                  v-if="account.link.includes('youtube')"
                >
                  {{ account.total_followers }}
                </span> -->
                <span
                  :class="account.social_platform.key_name.toLowerCase()"
                  v-if="account.total_followers.toString().length < 4"
                >
                  {{ account.total_followers.toString() }}
                </span>
                <span
                  :class="account.social_platform.key_name.toLowerCase()"
                  v-else-if="
                    account.total_followers.toString().length == 4 ||
                    account.total_followers.toString().length < 7
                  "
                >
                  {{ (account.total_followers / 1000).toFixed() }}K
                </span>
                <span
                  :class="account.social_platform.key_name.toLowerCase()"
                  v-else-if="
                    account.total_followers.toString().length == 7 ||
                    account.total_followers.toString().length < 10
                  "
                >
                  {{ (account.total_followers / 1000000).toFixed(1) }}M
                </span>
              </div>

              <div class="social_card_body">
                <div class="platform">{{ account.social_platform.name }}</div>
                <a
                  :href="account.link.toLowerCase().trim()"
                  class="platform_link"
                  target="_blank"
                  dir="ltr"
                  v-if="account.link.length >= 30"
                >
                  {{
                    account.link.toLowerCase().trim().substring(0, 30) + "..."
                  }}
                </a>
                <a
                  :href="account.link.toLowerCase().trim()"
                  class="platform_link"
                  target="_blank"
                  dir="ltr"
                  v-else
                >
                  {{ account.link.toLowerCase().trim() }}
                </a>
              </div>

              <!-- START:: SOCIAL MEDIA CARD SHAPES -->
              <div
                class="imaeg_animate"
                v-if="account.link.includes('facebook')"
              >
                <img
                  class="card_shape facebook"
                  src="../../assets/media/shapes/social_cards/facebookLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  v-animate-css="slideInUp"
                />
              </div>

              <div
                class="imaeg_animate"
                v-else-if="account.link.includes('twitter')"
              >
                <img
                  class="card_shape"
                  src="../../assets/media/shapes/social_cards/twitterLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  :class="account.link.includes('twitter') ? 'twitter' : ''"
                  v-animate-css="slideInUp"
                />
              </div>

              <div
                class="imaeg_animate"
                v-else-if="account.link.includes('instagram')"
              >
                <img
                  class="card_shape"
                  src="../../assets/media/shapes/social_cards/instagramLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  :class="account.link.includes('instagram') ? 'instagram' : ''"
                  v-animate-css="slideInUp"
                />
              </div>

              <div
                class="imaeg_animate"
                v-else-if="account.link.includes('likee')"
              >
                <img
                  class="card_shape"
                  src="../../assets/media/shapes/social_cards/instagramLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  :class="account.link.includes('likee') ? 'likee' : ''"
                  v-animate-css="slideInUp"
                />
              </div>

              <div
                class="imaeg_animate"
                v-else-if="account.link.includes('tiktok')"
              >
                <img
                  class="card_shape"
                  src="../../assets/media/shapes/social_cards/tiktokLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  :class="account.link.includes('tiktok') ? 'tiktok' : ''"
                  v-animate-css="slideInUp"
                />
              </div>
              <div
                class="imaeg_animate"
                v-else-if="account.link.includes('youtube')"
              >
                <img
                  class="card_shape"
                  src="../../assets/media/shapes/social_cards/youtubeLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  :class="account.link.includes('youtube') ? 'youtube' : ''"
                  v-animate-css="slideInUp"
                />
              </div>
              <div
                class="imaeg_animate"
                v-else-if="account.link.includes('snapchat')"
              >
                <img
                  class="card_shape"
                  src="../../assets/media/shapes/social_cards/snapchatLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  :class="account.link.includes('snapchat') ? 'snapchat' : ''"
                  v-animate-css="slideInUp"
                />
              </div>
              <div class="imaeg_animate" v-else>
                <img
                  class="card_shape facebook"
                  src="../../assets/media/shapes/social_cards/facebookLine.png"
                  alt="line shape"
                  width="100"
                  height="100"
                  v-animate-css="slideInUp"
                />
              </div>
            </div>
          </div>
        </div>
        <SecomdEmptyMessage v-else />
      </div>
    </div>
  </div>
</template>

<script>
import SecomdEmptyMessage from "../../components/emptyMessages/SecondEmptyMessage.vue";
import Loader from "../../components/loader/main-loader.vue";
export default {
  name: "SocialAccounts",

  components: {
    SecomdEmptyMessage,
    Loader,
  },

  data() {
    return {
      isWaitingRequest: false,
      // START:: SOCIAL
      social_links: [],
      // END:: SOCIAL
      show: false,
      loadCards: false,
      slideInUp: {
        classes: "slideInUp",
        duration: 2500,
      },

      // START:: CURRENT INFLUINCER ID DATA
      username: this.$route.params.username,
      // END:: CURRENT INFLUINCER ID DATA

      // START:: REGISTER DATA
      enterSocialLink: "",
      links: [],
      social_ids_Array: {
        socila_platform_ids: [],
      },
      linkCheck: null,
      // END:: REGISTER DATA
    };
  },

  methods: {
    // START:: GET SOCIAL DATA
    getSocialData() {
      this.loadCards = true;
      this.$axios
        .get(`influencer/${this.username}`, {
          headers: {
            Authorization: null,
            "Content-type": "application/json",
            "cache-control": "no-cache",
            Accept: "application/json",
            "Accept-language": localStorage.getItem("inflco_app_lang"),
          },
        })
        .then((res) => {
          this.social_links = res.data.data.social_links;
          this.loadCards = false;
        });
    },
    // END:: GET SOCIAL DATA

    textToLowerCase(val) {
      this.enterSocialLink = val.toLowerCase();
    },
    // START:: SOCIAL MEDIA AREA
    addLink() {
      if (this.enterSocialLink == "") {
        return;
      } else {
        // this.links.push({link: this.enterSocialLink});
        this.getValidateLink(this.enterSocialLink);
        setTimeout(() => {
          this.enterSocialLink = "";
        }, 1000);
      }
    },
  },
  mounted() {
    this.getSocialData();
  },
};
</script>

<template>
  <div class="success_verification_wrapper">
    <div class="success_header_wrapper">
      <div class="logo_wrapper">
        <img
          src="../../assets/logo/small_logo.svg"
          alt="Inflico Logo"
          width="100"
          height="100"
        />
      </div>

      <div class="img_wrapper">
        <img
          src="../../assets/media/illustrations/locked.svg"
          alt="image"
          width="100"
          height="100"
        />
      </div>

      <div class="subtitle_wrapper">
        <h5>{{ $t("titles.password_updated_successfully") }}</h5>
      </div>

      <div class="btn_wrapper">
        <button @click="continueToHome">{{ $t("buttons.continue") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessReset",

  data() {
    return {
      // START:: USER TYPE DATA
      inflco_user_type: localStorage.getItem("inflco_user_type"),
      // END:: USER TYPE DATA
    };
  },

  methods: {
    // START:: CONTINUE TO HOME
    continueToHome() {
      this.$router.replace("/login");
      // if ( this.inflco_user_type == "investor" ) {
      //   this.$router.replace("/home");
      // } else if ( this.inflco_user_type == "influincer" ) {
      //   this.$router.replace("/influincer-home");
      // }
    },
    // END:: CONTINUE TO HOME
  },
};
</script>

<template>
    <div class="empty_content_wrapper large_wrapper">
        <div class="vector_shape_wrapper">
            <img
                src="../../assets/media/illustrations/Blob.svg"
                width="150"
                height="150"
            />

            <div class="note">
                <span> {{ $t("titles.looking_for_influencer") }} </span>
                <span>
                    {{ $t("titles.looking_for_influencer_subtitle") }}
                </span>
            </div>

            <div class="btn_wrapper">
                <router-link to="/explore">
                    {{ $t("buttons.start_now") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FirstEmptyMessage",
    date() {
        return {
            userType: "",
        };
    },
    mounted() {
        this.userType = localStorage.getItem("inflco_user_type");
        // setTimeout(() => {
        //   if (
        //     this.$route.path == "/home" &&
        //     this.userType == "investor" &&
        //     this.$store.state.has_filter == true
        //   ) {
        //     this.$router.replace("/explore");
        //   }
        // }, 500);
    },
};
</script>

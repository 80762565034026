import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store/index";
// START:: IMPORTING AXIOS
// import axios from "axios";
// END:: IMPORTING AXIOS

// START:: IMPORTING ROUTER COMPONENTS
import Landing from "../pages/LandingPage.vue";
import Splash from "../pages/Splash.vue";
import Authentication from "../pages/Authentication.vue";
import Login from "../components/authentication/Login.vue";
import Register from "../components/authentication/Register.vue";
import RegisterWorkInfo from "../components/authentication/RegisterWorkInfo.vue";
import socialAccounts from "../components/authentication/socialAccounts.vue";
import CompanyRegister from "../components/authentication/CompanyRegister.vue";
import PhoneVerification from "../components/authentication/PhoneVerification.vue";
import SuccessVerification from "../components/authentication/SuccessVerificationMessage.vue";
import SuccessVerificationLink from "../components/authentication/success-verification-link.vue";
import ResetPasswordPhoneNumber from "../components/authentication/ResetPasswordPhoneNumber.vue";
import EditPhoneNumber from "../components/authentication/EditPhoneNumber.vue";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm.vue";
import SuccessReset from "../components/authentication/SuccessResetMessage.vue";
import Home from "../pages/Home.vue";
import FirstEmptyMessage from "../components/emptyMessages/FirstEmptyMessage.vue";
import SecondEmptyMessage from "../components/emptyMessages/SecondEmptyMessage.vue";
import TheFilter from "../components/filter/TheFilter.vue";
import SearchResult from "../components/filter/searchResult.vue";
import ExploreInfluincers from "../components/influincers/ExploreInfluincers.vue";
import InfluincerProfile from "../components/influincers/InfluincerProfile.vue";
import EditProfile from "../components/profile/EditProfile.vue";
import UpdatePassword from "../components/profile/UpdatePassword.vue";
import Conversations from "../components/conversations/Conversations.vue";
import TheChat from "../components/conversations/TheChat.vue";
import ContractsList from "../components/contracts/ContractsList.vue";
import ContractDetails from "../components/contracts/ContractDetails.vue";
import CreateContract from "../components/contracts/CreateContract.vue";
import InfluincerSocialAccounts from "../components/influincers/InfluincerSocialAccounts.vue";
import InfluincerPersonalProfile from "../components/influincers/InfluincerPersonalProfile.vue";
import ProfileInfluincerByURL from "../pages/ProfileInfluincerByURL.vue";
import About from "../pages/staticPages/About.vue";
import Terms from "../pages/staticPages/Terms.vue";
import PrivacyPolice from "../pages/staticPages/PrivacyPolice.vue";
import Blog from "../pages/staticPages/Blog.vue";
import BlogArticle from "../pages/staticPages/BlogArticle.vue";
import ContactUs from "../pages/staticPages/ContactUs.vue";
import ContactForm from "../pages/staticPages/ContactForm.vue";
import Faqs from "../pages/staticPages/Faqs.vue";
import AllFaqs from "../pages/staticPages/AllFaqs.vue";
import StaticPages from "../pages/staticPages/StaticPages.vue";
import NotFoundPage from "../pages/NotFound.vue";
// END:: IMPORTING ROUTER COMPONENTS

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "Landing",
        component: Landing,
    },

    {
        path: "/intro",
        name: "Splash",
        component: Splash,
    },

    {
        path: "/auth",
        name: "Authentication",
        component: Authentication,
        children: [
            {
                path: "/login",
                name: "Login",
                component: Login,
            },
            {
                path: "/register/:type",
                name: "Register",
                component: Register,
            },
            {
                path: "/register-work-info",
                name: "RegisterWorkInfo",
                component: RegisterWorkInfo,
            },
            {
                path: "/social-accounts",
                name: "socialAccounts",
                component: socialAccounts,
            },
            {
                path: "/company-register",
                name: "CompanyRegister",
                component: CompanyRegister,
            },
            {
                path: "/verification/:type",
                name: "PhoneVerification",
                component: PhoneVerification,
            },
            {
                path: "/success-verification",
                name: "SuccessVerification",
                component: SuccessVerification,
            },
            {
                path: "/success-verification-link",
                name: "SuccessVerificationLink",
                component: SuccessVerificationLink,
            },
            {
                path: "/edit-number",
                name: "EditPhoneNumber",
                component: EditPhoneNumber,
            },
            {
                path: "/reset-password",
                name: "ResetPasswordPhoneNumber",
                component: ResetPasswordPhoneNumber,
            },
            {
                path: "/reset-password-form",
                name: "ResetPasswordForm",
                component: ResetPasswordForm,
            },
            {
                path: "/success-reset",
                name: "SuccessReset",
                component: SuccessReset,
            },
        ],
    },

    {
        path: "/home",
        name: "Home",
        component: Home,
        children: [
            // START:: INVESTOR ROUTES
            {
                path: "/home",
                components: {
                    large_view: FirstEmptyMessage,
                    small_view: SecondEmptyMessage,
                },
            },
            {
                path: "/filter",
                name: "TheFilter",
                components: {
                    large_view: SecondEmptyMessage,
                    small_view: TheFilter,
                },
            },
            {
                path: "/search-result",
                name: "SearchResult",
                components: {
                    large_view: SearchResult,
                    small_view: SecondEmptyMessage,
                },
            },
            {
                path: "/explore",
                name: "ExploreInfluincers",
                components: {
                    large_view: ExploreInfluincers,
                    small_view: TheFilter,
                },
            },
            {
                path: "/profile/:influincer_id/influencer",
                name: "InfluincerProfile",
                components: {
                    large_view: ExploreInfluincers,
                    small_view: InfluincerProfile,
                },
            },
            {
                path: "/editProfile",
                name: "EditProfile",
                components: {
                    large_view: SecondEmptyMessage,
                    small_view: EditProfile,
                },
            },
            {
                path: "/editProfile/updatePassword",
                name: "UpdatePassword",
                components: {
                    large_view: SecondEmptyMessage,
                    small_view: UpdatePassword,
                },
            },
            {
                path: "/chats",
                name: "Conversations",
                components: {
                    large_view: SecondEmptyMessage,
                    small_view: Conversations,
                },
            },
            {
                path: "/chat/:influincer_id/:payment_status?",
                name: "TheChat",
                components: {
                    large_view: TheChat,
                    small_view: Conversations,
                },
            },
            {
                path: "/contracts",
                name: "ContractsList",
                components: {
                    large_view: SecondEmptyMessage,
                    small_view: ContractsList,
                },
            },
            {
                path: "/contract/:id",
                name: "ContractDetails",
                components: {
                    large_view: ContractDetails,
                    small_view: ContractsList,
                },
            },
            {
                path: "/create-contract/:influincer_id/:chat_id",
                name: "CreateContract",
                components: {
                    large_view: TheChat,
                    small_view: CreateContract,
                },
            },
            // END:: INVESTOR ROUTES

            // START:: INFLUINCER ROUTES
            {
                path: "/influincer-home",
                components: {
                    influincer_large_view: InfluincerSocialAccounts,
                    influincer_small_view: InfluincerPersonalProfile,
                },
            },

            {
                path: "/personal-profile",
                components: {
                    influincer_large_view: InfluincerPersonalProfile,
                    influincer_small_view: SecondEmptyMessage,
                },
            },
            {
                path: "/edit-influincer-profile",
                components: {
                    influincer_large_view: InfluincerSocialAccounts,
                    influincer_small_view: EditProfile,
                },
            },
            {
                path: "/update-influincer-password",
                components: {
                    influincer_large_view: InfluincerSocialAccounts,
                    influincer_small_view: UpdatePassword,
                },
            },
            {
                path: "/influincer-chats",
                components: {
                    influincer_large_view: SecondEmptyMessage,
                    influincer_small_view: Conversations,
                },
            },
            {
                path: "/influincer-chat/:influincer_id/:payment_status?",
                name: "TheChat",
                components: {
                    influincer_large_view: TheChat,
                    influincer_small_view: Conversations,
                },
            },
            {
                path: "/view-created-chat/:id",
                components: {
                    influincer_large_view: TheChat,
                    influincer_small_view: ContractDetails,
                },
            },
            {
                path: "/influincer-contracts",
                components: {
                    influincer_large_view: SecondEmptyMessage,
                    influincer_small_view: ContractsList,
                },
            },
            {
                path: "/influincer-contract/:id",
                components: {
                    influincer_large_view: ContractDetails,
                    influincer_small_view: ContractsList,
                },
            },
            // END:: INFLUINCER ROUTES
        ],
    },

    // START ::
    {
        path: "/influencer/:username",
        name: "influincerProfileByURL",
        component: ProfileInfluincerByURL,
    },
    // START ::

    {
        path: "/about",
        name: "StaticPages",
        component: StaticPages,
        children: [
            {
                path: "/about",
                name: "about",
                component: About,
            },
            {
                path: "/terms",
                name: "Terms",
                component: Terms,
            },
            {
                path: "/police",
                name: "PrivacyPolice",
                component: PrivacyPolice,
            },
            {
                path: "/blog",
                name: "Blog",
                component: Blog,
            },
            {
                path: "/blog/:id",
                name: "BlogArticle",
                component: BlogArticle,
            },
            {
                path: "/contact",
                name: "ContactUs",
                components: {
                    contact_large_view: ContactUs,
                    contact_small_view: ContactForm,
                },
            },
            {
                path: "/faqs/:type",
                name: "Faqs",
                component: Faqs,
            },
            {
                path: "/all-faqs",
                name: "AllFaqs",
                component: AllFaqs,
            },
        ],
    },

    {
        path: "/:notFound(.*)",
        name: "NotFoundPage",
        component: NotFoundPage,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    routes,
    store,
});

// router.beforeEach((_, _2, next) => {
//   // if (
//   //   (to.name != "UserLogin" || to.name != "BabySitterLogin") &&
//   //   !store.getters["auth/token"]
//   // )
//   // {
//   //   next("/");
//   // }
//   // else if (
//   //   (to.name == "UserLogin" || to.name == "BabySitterLogin") &&
//   //   store.getters["auth/token"]
//   // ) {
//   //   next("/");
//   // }
//   // else {
//   //   next();
//   // }

//   // let canPath = false;

//   // START:: GET USER PROFILE INFO
//     axios
//       .get("investor", {
//         headers: {
//           Authorization: "bearer" + localStorage.getItem("inflco_user_token"),
//           "Content-type": "application/json",
//           "cache-control": "no-cache",
//           Accept: "application/json",
//           "Accept-language": localStorage.getItem("inflco_app_lang"),
//         },
//       })
//       .then((res) => {
//         console.log(res);
//       });

//       axios
//       .get(`influencer/${localStorage.getItem("inflco_user_name")}`, {
//         headers: {
//           Authorization: "bearer" + localStorage.getItem("inflco_user_token"),
//           "Content-type": "application/json",
//           "cache-control": "no-cache",
//           Accept: "application/json",
//           "Accept-language": localStorage.getItem("inflco_app_lang"),
//         },
//       })
//       .then((res) => {
//         console.log(res);
//       });

//   // if (canPath) {
//   //   next();
//   // } else {
//   //   // localStorage.removeItem("inflco_user_type");
//   //   // localStorage.removeItem("inflco_user_id");
//   //   // localStorage.removeItem("inflco_user_token");
//   //   // localStorage.removeItem("inflco_user_phone");
//   //   // localStorage.removeItem("inflco_user_phone_key");
//   //   // localStorage.removeItem("inflco_user_phone_number");
//   //   // localStorage.removeItem("inflco_user_name");
//   //   // localStorage.removeItem("inflco_user_is_active");
//   //   // localStorage.removeItem("inflco_user_login");
//   //   next("/login");

//   //   // console.log(canPath);
//   //   // END:: LOGOUT
//   // }

//   // END:: GET USER PROFILE INFO
// });

export default router;

<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="influincer_profile_wrapper" :key="$route.params.influincer_id">
        <Loader v-if="loadProfile" class="fadeIn"></Loader>
        <section v-else class="fadeIn">
            <div class="influincer_profile_mainInfo">
                <!-- START:: MAIN INFO -->
                <div class="influincer_chat">
                    <router-link
                        :to="{
                            name: 'TheChat',
                            params: { influincer_id: profileData.id },
                        }"
                    >
                        <img
                            src="../../assets/media/icons/chat_blue.svg"
                            width="20px"
                            height="20px"
                        />
                    </router-link>
                </div>

                <div class="profile_avatar">
                    <img
                        :src="profileData.image"
                        alt="influincer Avatar"
                        width="180"
                        height="180"
                    />
                    <div class="rate">
                        <span>
                            <i class="fas fa-star"></i>
                            {{ profileData.rate_avg.toFixed(1) }}
                        </span>
                    </div>
                </div>

                <div class="influincer_name">
                    <h3>{{ profileData.fullname }}</h3>
                    <ul>
                        <li
                            v-for="field in profileData.social_areas"
                            :key="field.id"
                        >
                            {{ field.name }}
                        </li>
                    </ul>
                </div>

                <div class="influincer_video_and_bio">
                    <button
                        class="btn influincer_video"
                        type="button"
                        @click="show = !show"
                    >
                        <i class="fas fa-play"></i>
                        {{ $t("buttons.about_me") }}
                    </button>
                    <p v-if="profileData.bio != null">
                        {{ profileData.bio }}
                    </p>
                </div>

                <div class="influincer_related_interested">
                    <button
                        type="button"
                        class="btn"
                        v-for="interest in profileData.interests"
                        :key="interest.id"
                        @click="getInterests(interest.name, interest.id)"
                    >
                        {{ interest.name }}
                    </button>
                </div>
                <div class="influincer_social" v-if="slicedArray.length != 0">
                    <div
                        class="seeMoreClass"
                        :class="seeMoreShow == false ? 'hide' : 'show'"
                    >
                        <transition-group
                            mode="in-out"
                            name="customTransition"
                            class="social_warpper"
                        >
                            <div
                                class="single_social_warpper"
                                :class="seeMoreShow == false ? 'hide' : 'show'"
                                v-for="social in slicedArray"
                                :key="social.id"
                            >
                                <a :href="social.link">
                                    <div class="single_social">
                                        <img
                                            :src="social.social_platform.icon"
                                            width="20"
                                            height="20"
                                            alt=""
                                        />
                                        <h6>
                                            {{ social.social_platform.name }}
                                        </h6>

                                        <h5
                                            v-if="
                                                social.total_followers.toString()
                                                    .length < 4
                                            "
                                        >
                                            {{
                                                social.total_followers.toString()
                                            }}
                                        </h5>
                                        <h5
                                            v-else-if="
                                                social.total_followers.toString()
                                                    .length == 4 ||
                                                social.total_followers.toString()
                                                    .length < 7
                                            "
                                        >
                                            {{
                                                (
                                                    social.total_followers /
                                                    1000
                                                ).toFixed()
                                            }}K
                                        </h5>
                                        <h5
                                            v-else-if="
                                                social.total_followers.toString()
                                                    .length == 7 ||
                                                social.total_followers.toString()
                                                    .length < 10
                                            "
                                        >
                                            {{
                                                (
                                                    social.total_followers /
                                                    1000000
                                                ).toFixed(1)
                                            }}M
                                        </h5>
                                    </div>
                                </a>
                            </div>
                        </transition-group>
                    </div>

                    <div class="btn_see_more" v-if="slicedArray.length > 4">
                        <button type="button" class="btn" @click="toggleMore">
                            <span v-if="seeMoreShow == false">
                                {{ $t("buttons.see_all") }}
                                <img
                                    src="../../assets/media/icons/arrow_down_color.svg"
                                    alt="arrow_down_color"
                                    height="10px"
                                    width="10px"
                            /></span>
                            <span v-else>
                                {{ $t("buttons.see_less") }}
                                <img
                                    src="../../assets/media/icons/arrow_top_color.svg"
                                    alt="arrow_down_color"
                                    height="10px"
                                    width="10px"
                            /></span>
                        </button>
                    </div>
                </div>

                <!-- END:: MAIN INFO -->
                <div class="client_rate">
                    <div class="head_client_rate">
                        <h3>
                            {{ $t("titles.clients_rate") }}
                            <span
                                ><i class="fas fa-star"></i>
                                {{ profileData.rate_avg.toFixed(1) }}
                            </span>
                        </h3>
                        <button
                            v-if="profileData.rates.length != 0"
                            @click="
                                showAllRates(
                                    profileData.id,
                                    profileData.rate_avg
                                )
                            "
                            type="button"
                            class="btn"
                        >
                            {{ $t("buttons.see_all") }}
                        </button>
                    </div>
                    <div
                        class="client_rate_wrapper"
                        v-if="profileData.rates.length != 0"
                        v-for="client in profileData.rates.slice(0, 3)"
                        :key="client.id"
                    >
                        <div class="avatar_wrapper">
                            <img
                                :src="client.user.image"
                                alt="Client Avatar"
                                width="100"
                                height="100"
                            />
                        </div>

                        <div class="details_wrapper">
                            <div class="wrapper">
                                <div class="name">
                                    <span>{{ client.user.fullname }} </span>
                                    <div class="wrapper_rate">
                                        <RatingStars :rate="client.rate" />
                                    </div>
                                </div>

                                <div
                                    class="fields"
                                    v-if="client.review != null"
                                >
                                    <span> {{ client.review }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- START:: MODAL -->

        <!-- RATE -->
        <BaseModel
            @closeModel="showClientsRate = !showClientsRate"
            :show="showClientsRate"
        >
            <template #modal>
                <div class="modal_custom">
                    <span
                        @click="showClientsRate = !showClientsRate"
                        class="close"
                    >
                        <i class="fas fa-times"></i>
                    </span>
                    <!-- START:: HEAD MOADAL -->
                    <div class="head_modal">
                        <h3>
                            {{ $t("titles.clients_rate") }}
                            <span
                                ><i class="fas fa-star"></i>
                                {{ totalRates.toFixed(1) }}</span
                            >
                        </h3>
                    </div>
                    <!-- END:: HEAD MODAL -->

                    <!-- START:: BODY MODAL -->
                    <LoaderCards v-if="loaderRates" :number="3"></LoaderCards>
                    <div class="client_rate" v-else>
                        <div
                            class="client_rate_wrapper"
                            v-for="client in allRates"
                            :key="client.id"
                        >
                            <div class="avatar_wrapper">
                                <img
                                    :src="client.user.image"
                                    alt="Client Avatar"
                                    width="100"
                                    height="100"
                                />
                            </div>

                            <div class="details_wrapper">
                                <div class="wrapper">
                                    <div class="name">
                                        <span>{{ client.user.fullname }} </span>
                                        <div class="wrapper_rate">
                                            <RatingStars :rate="client.rate" />
                                        </div>
                                    </div>

                                    <div
                                        class="fields"
                                        v-if="client.review != null"
                                    >
                                        <span> {{ client.review }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END:: BODY MODAL -->

                    <!-- START:: FOOTER MODAL -->
                    <!-- <div class="footer_modal">
              <form class="form" @submit.prevent="submitRate">
                <div class="rating_form">
                  <input name="stars" id="rate5" value="5" type="radio" />
                  <label for="rate5"><i class="fas fa-star"></i></label>
                  <input name="stars" value="4" id="rate4" type="radio" />
                  <label for="rate4"><i class="fas fa-star"></i></label>
                  <input name="stars" value="3" id="rate3" type="radio" />
                  <label for="rate3"><i class="fas fa-star"></i></label>
                  <input name="stars" value="2" id="rate2" type="radio" />
                  <label for="rate2"><i class="fas fa-star"></i></label>
                  <input name="stars" value="1" id="rate1" type="radio" />
                  <label for="rate1"><i class="fas fa-star"></i></label>
                </div>
                <div class="comment">
                  <textarea placeholder="Write Here"></textarea>
                </div>

                <div class="wrapper justify-content-center">
                  <button class="btn">
                    Send
                    <span class="btn_loader" v-if="isWaitingRequest"></span>
                  </button>
                </div>
              </form>
            </div> -->
                    <!-- END:: FOOTER MODAL -->
                </div>
            </template>
        </BaseModel>

        <!-- INTERESTS -->
        <BaseModel
            @closeModel="showInterests = !showInterests"
            :show="showInterests"
        >
            <template #modal>
                <div class="interests_body_content">
                    <span @click="showInterests = !showInterests" class="close">
                        <i class="fas fa-times"></i>
                    </span>

                    <!-- START:: HEAD MOADAL -->
                    <div class="head_modal">
                        <h3>{{ interestName }}</h3>
                        <p>{{ $t("titles.people_interest") }}</p>
                    </div>
                    <!-- END:: HEAD MODAL -->

                    <!-- START:: BODY MODAL -->
                    <LoaderCards v-if="loaderCards" :number="10"></LoaderCards>
                    <div v-else>
                        <div
                            class="interests_data_wrapper"
                            v-for="influincer in interestData"
                            :key="influincer.id"
                            @click="showInterests = false"
                        >
                            <router-link
                                :to="{
                                    name: 'InfluincerProfile',
                                    params: { influincer_id: influincer.id },
                                }"
                                class="profile_route"
                            >
                                <div class="avatar_wrapper">
                                    <img
                                        :src="influincer.image"
                                        alt="Influincer Avatar"
                                        width="100"
                                        height="100"
                                    />
                                </div>

                                <div class="details_wrappe">
                                    <div class="name">
                                        <span> {{ influincer.fullname }} </span>
                                        <span>
                                            <i class="fas fa-star"></i>
                                            {{ influincer.rate_avg }}
                                        </span>
                                    </div>

                                    <div class="fields">
                                        <span
                                            v-for="field in influincer.social_areas"
                                            :key="field"
                                        >
                                            {{ field.name }}
                                        </span>
                                    </div>
                                </div>
                            </router-link>

                            <router-link
                                :to="{
                                    name: 'TheChat',
                                    params: { influincer_id: influincer.id },
                                }"
                                class="interests_chat_route"
                            >
                                <img
                                    src="../../assets/media/icons/chat_blue.svg"
                                    alt="Icon"
                                    width="20"
                                    height="20"
                                />
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
        </BaseModel>
        <!-- END:: MODAL -->

        <!-- VIDEO -->
        <BaseModel @closeModel="show = !show" :show="show">
            <template #influincer_video>
                <div class="video_wrapper">
                    <span @click="show = !show" class="close"
                        ><i class="fas fa-times"></i
                    ></span>
                    <VuePlyr :poster="img">
                        <video class="video-player">
                            <source
                                :src="profileData.about_video"
                                type="video/mp4"
                            />
                            <source
                                :src="profileData.about_video"
                                type="video/ogg"
                            />
                        </video>
                    </VuePlyr>
                </div>
            </template>
        </BaseModel>
    </div>
</template>

<script>
import BaseModel from "../ui/BaseModel.vue";
// START:: IMPORTING VIDEO PLAYER
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// END:: IMPORTING VIDEO PLAYER

import RatingStars from "../ui/RatingStars.vue";
import img from "../../assets/media/imgs/inf_video.png";
import Loader from "./../loader/profile.vue";
import LoaderCards from "./../loader/explore.vue";
export default {
    name: "InfluincerProfile",

    data() {
        return {
            // START:: CURRENT INFLUINCER ID DATA
            influincerId: this.$route.params.id,
            // END:: CURRENT INFLUINCER ID DATA
            show: false,

            showClientsRate: false,

            img: img,

            slicedArray: [],

            seeMoreShow: false,

            showInterests: false,
            interestName: "",
            interestData: "",
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            testData: {
                name: "test",
            },
            // START:: PROFILE DATA
            profileData: {
                rates: [],
            },
            // END:: PROFILE DATA

            // START::
            userId: this.$route.params.influincer_id,
            // END::
            loadProfile: false,
            loaderCards: false,
            loaderRates: false,
            totalRates: "",
            allRates: "",
        };
    },
    components: {
        BaseModel,
        VuePlyr,
        RatingStars,
        Loader,
        LoaderCards,
    },
    methods: {
        // START:: GET PROFILE DATA
        getProfileData() {
            this.loadProfile = true;
            this.$axios
                .get(`investor/` + this.userId + "/influencer", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.profileData = res.data.data;
                    this.slicedArray = res.data.data.social_links;
                    this.loadProfile = false;
                });
        },
        // END:: GET PROFILE DATA
        toggleMore() {
            this.seeMoreShow = !this.seeMoreShow;
            if (this.slicedArray.length == 4) {
                this.slicedArray.slice(0, this.slicedArray.length);
            } else if (this.slicedArray.length > 4) {
                this.slicedArray.slice(0, 4);
            }
        },
        getInterests(name, id) {
            this.interestName = name;
            this.showInterests = !this.showInterests;
            this.loaderCards = true;
            this.$axios
                .get(`investor/influencers_interests/` + id, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.interestData = res.data.data;
                    this.loaderCards = false;
                });
        },
        showAllRates(id, rates) {
            this.totalRates = rates;
            this.showClientsRate = !this.showClientsRate;
            this.loaderRates = true;
            this.$axios
                .get(`investor/rate/` + id, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.allRates = res.data.data;
                    this.loaderRates = false;
                });
        },
        submitRate() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
            }, 2000);
        },
    },
    watch: {
        "$route.params.influincer_id": {
            handler: function (value) {
                this.userId = value;
                this.getProfileData();
            },
        },
    },
    mounted() {
        this.getProfileData();
    },
};
</script>

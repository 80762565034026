var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staticPages"},[_c('div',{staticClass:"page_title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("titles.contact_us")))])]),_c('div',{staticClass:"map_wrapper"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":{
                lat: parseInt(_vm.contactData.lat),
                lng: parseInt(_vm.contactData.lng),
            },"zoom":7,"map-type-id":"terrain"}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = m.position}}})}),1)],1),_c('div',{staticClass:"cards_wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'https://www.google.com/maps?q=' +
                        _vm.contactData.lat +
                        ',' +
                        _vm.contactData.lng +
                        '&hl=es;z%3D14&amp',"target":"_blank"}},[_c('div',{staticClass:"contact_card address"},[_c('h5',[_vm._m(0),_c('span',[_vm._v(" "+_vm._s(_vm.$t("titles.address"))+": "+_vm._s(_vm.contactData.address)+" ")])])])])]),_c('div',{staticClass:"col-md-4"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'https://api.whatsapp.com/send?phone=' +
                        _vm.contactData.whatsapp,"target":"_blank"}},[_c('div',{staticClass:"contact_card phone"},[_c('h5',[_vm._m(1),_c('span',[_vm._v(" "+_vm._s(_vm.contactData.whatsapp)+" ")])])])])]),_c('div',{staticClass:"col-md-4"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'mailto:' + _vm.contactData.email,"target":"_blank"}},[_c('div',{staticClass:"contact_card mail"},[_c('h5',[_vm._m(2),_c('span',[_vm._v(" "+_vm._s(_vm.contactData.email)+" ")])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("../../assets/media/icons/mapMark.svg"),"alt":"icon","width":"20","height":"20"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("../../assets/media/icons/whatsapp.svg"),"alt":"icon","width":"20","height":"20"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("../../assets/media/icons/invelop.svg"),"alt":"icon","width":"20","height":"20"}})])}]

export { render, staticRenderFns }
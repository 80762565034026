<template>
  <div class="auth_wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-7">
          <div class="small_routes_wrapper">
            <div class="wrapper">
              <router-link
                to="/intro"
                :class="{
                  'router-link-exact-active':
                    $route.name == 'Register' ||
                    $route.name == 'CompanyRegister' ||
                    $route.path == '/verification/registeration' ||
                    $route.name == 'SuccessVerification' ||
                    $route.name == 'RegisterWorkInfo' ||
                    $route.name == 'socialAccounts' ||
                    $route.path == '/verification/success-verification-link' ||
                    $route.path == '/success-verification-link' ||
                    $route.path == '/edit-number',
                }"
              >
                {{ $t("auth.sign_up") }}
              </router-link>
            </div>

            <div class="wrapper">
              <router-link
                to="/login"
                :class="{
                  'router-link-exact-active':
                    $route.name == 'Login' ||
                    $route.name == 'ResetPasswordPhoneNumber' ||
                    $route.name == 'ResetPasswordForm' ||
                    $route.name == 'SuccessReset' ||
                    $route.path == '/verification/reset-password',
                }"
              >
                {{ $t("auth.login") }}
              </router-link>
            </div>
          </div>

          <div
            class="auth_intro_wrapper"
            v-animate-css="lang == 'en' ? fadeInLeftBig : fadeInRightBig"
          >
            <img
              src="../assets/media/imgs/auth_intro.png"
              alt="Authentication Intro Image"
              width="200"
              height="200"
            />

            <div class="overlay">
              <div class="overlay_header_wrapper">
                <div class="icon_wrapper">
                  <router-link to="/">
                    <img
                      src="../assets/logo/logo.svg"
                      alt="Inflco Logo"
                      width="100"
                      height="100"
                    />
                  </router-link>
                </div>

                <div class="title_wrapper">
                  {{ $t("titles.auth_intro") }}
                </div>

                <div class="animated_titles_wrapper">
                  <h3>{{ $t("titles.free_register") }}</h3>
                  <h3>{{ $t("titles.choose") }}</h3>
                  <h3>{{ $t("titles.target") }}</h3>
                  <h3>{{ $t("titles.go") }}</h3>
                </div>

                <div class="routes_wrapper">
                  <div class="wrapper">
                    <router-link
                      to="/intro"
                      :class="{
                        'router-link-exact-active':
                          $route.name == 'Register' ||
                          $route.name == 'CompanyRegister' ||
                          $route.path == '/verification/registeration' ||
                          $route.name == 'SuccessVerification' ||
                          $route.name == 'RegisterWorkInfo' ||
                          $route.name == 'socialAccounts' ||
                          $route.path ==
                            '/verification/success-verification-link' ||
                          $route.path == '/success-verification-link' ||
                          $route.path == '/edit-number',
                      }"
                    >
                      {{ $t("auth.sign_up") }}
                    </router-link>
                  </div>

                  <div class="wrapper">
                    <router-link
                      to="/login"
                      :class="{
                        'router-link-exact-active':
                          $route.name == 'Login' ||
                          $route.name == 'ResetPasswordPhoneNumber' ||
                          $route.name == 'ResetPasswordForm' ||
                          $route.name == 'SuccessReset' ||
                          $route.path == '/verification/reset-password',
                      }"
                    >
                      {{ $t("auth.login") }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div
            class="auth_content_wrapper"
            v-animate-css="lang == 'en' ? fadeInRightBig : fadeInLeftBig"
          >
            <transition mode="out-in" name="slither">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Authentication",

  data() {
    return {
      // START:: APP LANG DATA
      lang: localStorage.getItem("inflco_app_lang"),
      // END:: APP LANG DATA

      // START:: ANIMATIONS DATA
      fadeInRightBig: {
        classes: "fadeInRightBig",
        duration: 2000,
      },
      fadeInLeftBig: {
        classes: "fadeInLeftBig",
        duration: 2000,
      },
      // END:: ANIMATIONS DATA
    };
  },
  mounted() {
    var user_login = localStorage.getItem("inflco_user_login");
    var userType = localStorage.getItem("inflco_user_type");
    if (user_login) {
      if (userType == "investor") {
        this.$router.push("/home");
      }
      if (userType == "influincer") {
        this.$router.push("/influincer-home");
      }
    }
  },
};
</script>

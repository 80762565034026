<template>
    <div class="staticPages blog">
        <!-- START:: TITLE -->
        <div class="page_title">
            <h1>{{ $t("titles.blog_title") }}</h1>
        </div>
        <!-- END:: TITLE -->

        <!-- START:: BLOG CARDS -->
        <Loader v-if="loadPage" class="fadeIn heigth-50vh"></Loader>
        <div class="container fadeIn" v-else>
            <div class="row justify-content-center">
                <!-- START:: BLOG CARD WRAPPER -->
                <div
                    class="col-lg-4 my-2 px-5"
                    v-for="blog in blogsData"
                    :key="blog.id"
                >
                    <router-link class="article_route" :to="'/blog/' + blog.id">
                        <div class="blog_card_wrapper">
                            <div class="img_wrapper">
                                <img
                                    :src="blog.main_image"
                                    alt="Article Image"
                                    width="150"
                                    height="150"
                                />
                            </div>

                            <div class="article_title_wrapper">
                                <h5>{{ blog.name }}</h5>
                            </div>

                            <div class="publish_date_wrapper">
                                <h6>{{ blog.created_at }}</h6>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- END:: BLOG CARD WRAPPER -->
            </div>
        </div>
        <!-- END:: BLOG CARDS -->
    </div>
</template>

<script>
import Loader from "./../../components/loader/main-loader.vue";
export default {
    name: "Blog",
    data() {
        return {
            // START:: BLOGS DATA
            loadPage: false,
            blogsData: null,
            // START:: BLOGS DATA
        };
    },
    components: {
        Loader,
    },
    methods: {
        // START:: GET BLOGS DATA
        getBlogsData() {
            this.loadPage = true;
            this.$axios
                .get("blogs", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.blogsData = res.data.data;
                    this.loadPage = false;
                });
        },
        // END:: GET BLOGS DATA
    },

    mounted() {
        // START:: GET BLOGS DATA
        this.getBlogsData();
        // END:: GET BLOGS DATA
    },
};
</script>

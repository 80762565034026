<template>
  <div class="influincer_personal_profile_outer_wrapper">
    <div class="influincer_personal_profile_wrapper">
      <Loader v-if="loadProfile" class="fadeIn"></Loader>
      <section v-else class="fadeIn">
        <div class="influincer_profile_mainInfo">
          <!-- START:: MAIN INFO -->
          <div class="profile_avatar_wrapper flex-column">
            <div class="profile_avatar mb-3">
              <img
                :src="profileData.image"
                alt="influincer Avatar"
                width="180"
                height="180"
              />
            </div>

            <div class="influincer_name influincer_name_url">
              <h2>
                <span>{{ profileData.fullname }}</span>
              </h2>
              <h3>
                {{ profileData.short_link.substring(0, 45) + "..." }}
                <!--   -->
              </h3>
            </div>
          </div>

          <div class="register_now_wraper" v-if="!token">
            <router-link to="/intro">
              {{ $t("buttons.register_now") }}
            </router-link>
          </div>

          <!-- END:: MAIN INFO -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Loader from "../../components/loader/profile.vue";
export default {
  name: "InfluincerProfile",

  data() {
    return {
      // START:: BUTTON LOADER DATA
      isWaitingRequest: false,
      // END:: BUTTON LOADER DATA

      // START:: CURRENT INFLUINCER ID DATA
      username: this.$route.params.username,
      // END:: CURRENT INFLUINCER ID DATA

      // START:: CLIENTS RATE DATA
      showClientsRate: false,
      totalRates: "",
      loaderRates: false,
      loadProfile: false,
      // END:: CLIENTS RATE DATA

      token: localStorage.getItem("inflco_user_token"),

      // START:: PROFILE DATA
      profileData: {
        rates: [],
      },
      // END:: PROFILE DATA

      // START:: RATE DATA
      rateData: [],

      // END:: RATE DATA
    };
  },
  components: {
    Loader,
  },
  methods: {
    // START:: GET PROFILE DATA
    getProfileData() {
      this.loadProfile = true;
      this.$axios
        .get(`influencer/${this.username}`, {
          headers: {
            Authorization: null,
            "Content-type": "application/json",
            "cache-control": "no-cache",
            Accept: "application/json",
            "Accept-language": localStorage.getItem("inflco_app_lang"),
          },
        })
        .then((res) => {
          this.profileData = res.data.data;
          this.loadProfile = false;
        });
    },
    // END:: GET PROFILE DATA

    // START:: SUBMIT RATE
    submitRate() {
      this.isWaitingRequest = true;
      setTimeout(() => {
        this.isWaitingRequest = false;
      }, 2000);
    },
    // END:: SUBMIT RATE
  },
  mounted() {
    this.getProfileData();
  },
};
</script>

<template>
    <v-app>
        <transition name="fade" mode="out-in">
            <router-view v-if="windowWidth >= 767" />
            <router-view :key="$route.path" v-else />
        </transition>
    </v-app>
</template>

<script>
// socket
import Echo from "laravel-echo";
export default {
    name: "App",
    data() {
        return {
            windowWidth: window.innerWidth,
            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA
            // socket
            userId: "",
        };
    },

    methods: {
        // --------- socket -----------
        receiveMessage() {
            if (localStorage.getItem("inflco_user_token") != null) {
                window.io = require("socket.io-client");
                window.Echo = new Echo({
                    broadcaster: "socket.io",
                    host: "https://backend.inflco.com:6069",
                    csrfToken:
                        "Bearer " + localStorage.getItem("inflco_user_token"),
                    auth: {
                        headers: {
                            Authorization:
                                "Bearer " +
                                localStorage.getItem("inflco_user_token"),
                        },
                    },
                });
                // -----------------
                window.Echo.private(
                    `inflco-notification.${this.userId}`
                ).notification((payload) => {
                    if (payload) {
                        this.$iziToast.info({
                            message: payload.body,
                            backgroundColor: "#F8F8FA",
                            timeout: 5000,
                            class: "note-alert",
                            titleColor: "#0E59B6",
                            position: "topRight",
                        });
                        this.$store.dispatch("getNotifications");
                        setTimeout(() => {
                            if (
                                (payload.notify_type != "new_chat" &&
                                    this.$route.path == "/contracts") ||
                                (payload.notify_type != "chat" &&
                                    this.$router.history.current.matched[1]
                                        .path == "/contract/:id") ||
                                (payload.notify_type != "new_chat" &&
                                    this.$route.path == "/contracts") ||
                                (payload.notify_type != "new_chat" &&
                                    this.$router.history.current.matched[1]
                                        .path == "/contract/:id") ||
                                (payload.notify_type != "new_chat" &&
                                    this.$route.path ==
                                        "/influincer-contracts") ||
                                (payload.notify_type != "new_chat" &&
                                    this.$router.history.current.matched[1]
                                        .path) == "/influincer-contract/:id"
                            ) {
                                location.reload();
                            }
                            if (
                                (payload.notify_type != "new_chat" &&
                                    this.$route.path == "/chats") ||
                                (payload.notify_type != "new_chat" &&
                                    this.$route.path == "/influincer-chats") ||
                                (payload.notify_type != "new_chat" &&
                                    this.$route.name == "TheChat") ||
                                payload.notify_type == "management"
                            ) {
                                location.reload();
                            }
                        }, 2000);
                        new Audio(require("./message.mp3")).play();
                    }
                });
            }
        },
    },
    mounted() {
        this.$store.dispatch("handelAppDefaultLanguage");
        if (localStorage.getItem("inflco_user_token") != null) {
            this.userId = localStorage.getItem("inflco_user_id");
            this.receiveMessage();
        }
        // this.$store.dispatch("getHomeExplore");
    },
};
</script>

<template>
  <!-- loader -->
  <div class="custom_card explore">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="profile-load">
          <div class="loader-container">
            <div class="product-shape">
              <div class="animated-background profile-image"></div>
              <div class="load-body">
                <div class="load-content">
                  <div class="animated-background pro-name-loader"></div>
                  <div class="animated-background pro-name-desc"></div>
                  <div class="animated-background pro-full-width"></div>
                  <div class="animated-background pro-full-width"></div>
                  <div class="animated-background pro-full-width"></div>
                </div>
                <div class="load-icon">
                  <div class="animated-background chat-time-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="chat-load">
          <div class="loader-container">
            <div class="product-shape">
              <div class="animated-background profile-image"></div>
              <div class="load-body">
                <div class="load-content">
                  <div class="animated-background pro-name-loader"></div>
                  <div class="animated-background pro-name-desc"></div>
                </div>
                <div class="load-icon">
                  <div class="animated-background chat-time-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="chat-load">
          <div class="loader-container">
            <div class="product-shape">
              <div class="animated-background profile-image"></div>
              <div class="load-body">
                <div class="load-content">
                  <div class="animated-background pro-name-loader"></div>
                  <div class="animated-background pro-name-desc"></div>
                </div>
                <div class="load-icon">
                  <div class="animated-background chat-time-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
  components: {},
};
</script>

<style scoped>
/*profile*/

@keyframes placeholder {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}

/*skelton*/

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1200px 100px;
  min-height: 10px;
  width: 100%;
  margin: 20px 0 20px 0;
  border-radius: 3px;
}

.profile-load {
  padding: 20px 10px;
}

.profile-load .product-shape {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile-load .profile-image {
  margin-top: 10px;
  border-radius: 50%;
  width: 120px;
  height: 115px;
}

.profile-load .load-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-load .load-content {
  width: 100%;
}

.profile-load .load-body .load-icon .profile-time-loader {
  width: 60px;
  height: 10px;
}

.profile-load .pro-name-desc {
  width: 120px;
  margin-top: 10px;
  height: 10px;
  margin: 15px auto 0 auto;
}

.profile-load .pro-name-loader {
  width: 120px;
  margin-top: 10px;
  height: 10px;
  margin: 15px auto 0 auto;
}
/*chat*/

.chat-load {
  padding: 15px 0px 0;
}

.chat-load .product-shape {
  display: flex;
  align-items: center;
}

.chat-load .profile-image {
  margin-top: 10px;
  border-radius: 50%;
  width: 95px;
  height: 70px;
  margin-inline-end: 20px;
}

.chat-load .load-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.chat-load .load-body .load-icon .chat-time-loader {
  width: 60px;
  height: 10px;
}

.chat-load .pro-name-desc {
  width: 100px;
  margin-top: 10px;
  height: 9px;
}

.chat-load .pro-name-loader {
  width: 120px;
  margin-top: 10px;
  height: 11px;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

// START:: IMPORTING VUE ROUTER & VUEX
import router from "./router";
import store from "./store";
// END:: IMPORTING VUE ROUTER & VUEX

// START:: IMPORTING I18N
import i18n from "./i18n";
// ======== START:: HANDLING SITE DIRECTION ======== //
let app_lang = localStorage.getItem("app_lang");
if (app_lang == "ar") {
    document.querySelector("body").setAttribute("dir", "rtl");
} else {
    document.querySelector("body").setAttribute("dir", "ltr");
}
// ======== END:: HANDLING SITE DIRECTION ======== //
// END:: IMPORTING I18N

// START:: IMPORTING VUE META
import VueMeta from "vue-meta";
// END:: IMPORTING VUE META

// START:: IMPORTING AXIOS
import axios from "axios";
// ======== START:: AXIOS DEFAULTS ======== //
var lang = localStorage.getItem("inflco_app_lang");
if (!lang) {
    lang = "en";
} else {
    lang = localStorage.getItem("inflco_app_lang");
}
axios.defaults.baseURL = "https://backend.inflco.com/app";
axios.defaults.headers.common = {
    "Accept-language": lang,
    Accept: "application/json",
};
// if (localStorage.getItem("inflco_user_token")) {
//   axios.defaults.headers.common["Authorization"] =
//   "Bearer" + localStorage.getItem("inflco_user_token");
// }
// ======== END:: AXIOS DEFAULTS ======== //
// END:: IMPORTING AXIOS

// START:: IMPORTING VUE GOOGLE MAPS
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDRymdCLWxCwLHFnwv36iieKAMjiwk8sdc",
        libraries: "places",
    },
});
// END:: IMPORTING VUE GOOGLE MAPS

// START:: IMPORTING VUE OWL SLIDER
import carousel from "vue-owl-carousel";
// END:: IMPORTING VUE OWL SLIDER

// START:: IMPORTING MAIN SASS STYLES FILE
import "./assets/sass/main.scss";
setTimeout(() => {
    "import './assets/js/gogleApi.js'";
}, 5000);
// END:: IMPORTING MAIN SASS STYLES FILE

// START:: IMPORTING BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
// END:: IMPORTING BOOTSTRAP

// START:: IMPORTING BOOTSTRAP VUE
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
// END:: IMPORTING BOOTSTRAP VUE

// START:: IMPORTING VUE SELECT
import vSelect from "vue-select";
// END:: IMPORTING VUE SELECT

// START:: IMPORTING VUETIFY
import vuetify from "./plugins/vuetify";
// START:: IMPORTING VUETIFY

// START:: IMPORTING FONT AWESOME
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
// END:: IMPORTING FONT AWESOME

// START:: IMPORTING IZI TOAST
import iziToast from "izitoast";
import "./assets/css/iziToast.min.css";
// END:: IMPORTING IZI TOAST

// START:: IMPORTING AOS
import AOS from "aos";
import "aos/dist/aos.css";
// END:: IMPORTING AOS

// START:: IMPORTING VUE ANIMATE
import VAnimateCss from "v-animate-css";
// END:: IMPORTING VUE ANIMATE

// START:: VUE CHAT SCROLL
import VueChatScroll from "vue-chat-scroll";
// END:: VUE CHAT SCROLL

// START:: MOMENT JS
import moment from "moment";
// END:: MOMENT JS

// START:: IMPORTING CKEDITOR
import CKEditor from "@ckeditor/ckeditor5-vue2";
// END:: IMPORTING CKEDITOR

// START:: IMPORTING SOCIAL SHARE
import VueSocialSharing from "vue-social-sharing";
// END:: IMPORTING SOCIAL SHARE

// const HelloJs = require('hellojs/dist/hello.all.min.js');
import HelloJs from "hellojs/dist/hello.all";
// const VueHello = require('vue-hellojs');
import VueHello from "vue-hellojs";

HelloJs.init(
    {
        google: "1058748273114-6srsjvvag80n2kk6mc3v6fv2i3jdlllm.apps.googleusercontent.com",
        facebook: 2112618315656643,
        youtube:
            "1058748273114-6srsjvvag80n2kk6mc3v6fv2i3jdlllm.apps.googleusercontent.com",
        vk: 7968790,
    },
    { redirect_uri: "authcallback/", scope: "email, pages_show_list" }
);
Vue.use(VueHello, HelloJs);

AOS.init();
Vue.use(VueMeta);
Vue.use(carousel);
Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(VAnimateCss);
Vue.use(iziToast);
Vue.use(VueChatScroll);
Vue.use(CKEditor);
Vue.use(VueSocialSharing);
Vue.component("vue-select", vSelect);
Vue.prototype.$iziToast = iziToast;
Vue.prototype.$axios = axios;

Vue.prototype.moment = moment;
// Vue.prototype.$moment = moment;
// else of payment
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");

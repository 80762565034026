<template>
    <div class="verification_form_wrapper">
        <div class="verification_header_wrapper">
            <div class="logo_wrapper">
                <router-link to="/">
                    <img
                        src="../../assets/logo/small_logo.svg"
                        alt="Inflico Logo"
                        width="100"
                        height="100"
                    />
                </router-link>
            </div>

            <div class="subtitle_wrapper">
                <h5>
                    {{ $t("titles.we_sent_a_code") }}
                    <span>{{ verification_phone }}</span>
                    {{ $t("titles.enter_the_code") }}.
                </h5>
            </div>

            <div class="title_wrapper">
                <router-link to="/edit-number">{{
                    $t("titles.edit_phone_number")
                }}</router-link>
            </div>
        </div>

        <div class="verification_body_wrapper">
            <form @submit.prevent="submitVerificationCOde" dir="ltr">
                <div class="wrapper">
                    <input
                        ref="first_input"
                        class="form-control"
                        type="text"
                        maxlength="1"
                        size="1"
                        @input="toggleFocus"
                        v-model="firstNumber"
                    />
                    <input
                        class="form-control"
                        type="text"
                        maxlength="1"
                        size="1"
                        @input="toggleFocus"
                        v-model="secondNumber"
                    />
                    <input
                        class="form-control"
                        type="text"
                        maxlength="1"
                        size="1"
                        @input="toggleFocus"
                        v-model="thirdNumber"
                    />
                    <input
                        class="form-control"
                        type="text"
                        maxlength="1"
                        size="1"
                        @input="toggleFocus"
                        v-model="fourthNumber"
                    />
                </div>

                <div class="timer_wrapper">
                    <h6>{{ "0 : " + timerCount }}</h6>
                    <div class="resend_btn_wrapper">
                        <button
                            id="resend_btn"
                            type="button"
                            v-if="timerCount > 0"
                            class="disabled"
                            disabled="disabled"
                        >
                            {{ $t("buttons.resend_code") }}
                        </button>

                        <button
                            id="resend_btn"
                            type="button"
                            v-else
                            @click="resendVerificationCode"
                            :class="timerCount"
                        >
                            {{ $t("buttons.resend_code") }}
                        </button>
                    </div>
                </div>

                <div class="wrapper justify-content-center">
                    <button class="btn">
                        {{ $t("buttons.send") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "PhoneVerification",

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: VERIFICATION TYPE
            verificationType: this.$route.params.type,
            // END:: VERIFICATION TYPE

            // START:: TIMER DATA
            timerCount: 60,
            // END:: TIMER DATA

            // START:: USER PHONE DATA
            verification_phone: localStorage.getItem("inflco_user_phone"),
            // END:: USER PHONE DATA

            // START:: VERIFICATION INPUTS DATA
            firstNumber: null,
            secondNumber: null,
            thirdNumber: null,
            fourthNumber: null,
            // END:: VERIFICATION INPUTS DATA

            // START:: VERIFICATION DATA
            verificationData: {
                code: null,
                phone_key: localStorage.getItem("inflco_user_phone_key"),
                phone: localStorage.getItem("inflco_user_phone_number"),
                device_token: "123456789",
                type: "ios",
            },
            // END:: VERIFICATION DATA

            // START:: FORGOTTEN PASSWORD DATA
            forgottenPassData: {
                code: null,
                phone_key: localStorage.getItem("inflco_user_phone_key"),
                phone: localStorage.getItem("inflco_user_phone_number"),
            },
            // END:: FORGOTTEN PASSWORD DATA

            // START:: RESEND CODE DATA
            resendCodeData: {
                phone_key: localStorage.getItem("inflco_user_phone_key"),
                phone: localStorage.getItem("inflco_user_phone_number"),
            },
            // END:: RESEND CODE DATA
        };
    },

    methods: {
        // START:: HANDLING INPUTS FOCUS
        toggleFocus(e) {
            if (e.currentTarget.value.length == 1) {
                if (e.currentTarget.nextSibling) {
                    e.currentTarget.blur();
                    e.currentTarget.nextSibling.focus();
                }
            } else if (e.currentTarget.value.length == 0) {
                if (e.currentTarget.previousSibling) {
                    e.currentTarget.blur();
                    e.currentTarget.previousSibling.focus();
                }
            }
        },
        // END:: HANDLING INPUTS FOCUS

        // START:: HANDLING TIMER
        countDown() {
            if (this.timerCount > 0) {
                setTimeout(() => {
                    this.timerCount -= 1;
                    this.countDown();
                }, 1000);
            }
            // if (this.timerCount > 0) {
            //   document.getElementsByTagName("resend_btn").setAttribute("disabled", "disabled");
            // } else {
            //   document.getElementsByTagName("#resend_btn").removeAttribute("disabled");
            // }
        },
        // END:: HANDLING TIMER

        // START:: RESEND VERIFICATION CODE
        resendVerificationCode() {
            this.timerCount = 60;
            this.countDown();
            this.firstNumber = null;
            this.secondNumber = null;
            this.thirdNumber = null;
            this.fourthNumber = null;

            this.$axios
                .post("auth/send_code", this.resendCodeData)
                .then((res) => {
                    if (res.data.status == "fail") {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: res.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                    } else if (res.data.status == "success") {
                        this.isWaitingRequest = false;
                        localStorage.setItem(
                            "inflco_verification_code",
                            res.data.dev_message
                        );
                        this.$iziToast.success({
                            timeout: 5000,
                            message: res.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                    }
                });
        },
        // END:: RESEND VERIFICATION CODE

        // START:: SUBMIT VERIFICATION CODE
        submitVerificationCOde() {
            this.isWaitingRequest = true;

            // START:: SET VERIFICATION DATA
            this.verificationData.code = parseInt(
                this.firstNumber +
                    this.secondNumber +
                    this.thirdNumber +
                    this.fourthNumber
            );
            // END:: SET VERIFICATION DATA
            // END:: SET VERIFICATION DATA

            if (!this.verificationData.code) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.verificationCode"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            }

            if (this.verificationType == "reset-password") {
                // START:: SET VERIFICATION DATA
                this.forgottenPassData.code = parseInt(
                    this.firstNumber +
                        this.secondNumber +
                        this.thirdNumber +
                        this.fourthNumber
                );
                // END:: SET VERIFICATION DATA
                this.$axios
                    .post("auth/check_code", this.forgottenPassData)
                    .then((res) => {
                        if (res.data.status) {
                            this.$iziToast.success({
                                timeout: 5000,
                                message: res.data.message,
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                            this.isWaitingRequest = false;
                            this.$router.replace("/reset-password-form");
                        }
                    })
                    .catch((error) => {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: error.response.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                    });
            }

            if (this.verificationType == "registeration") {
                // END:: SET VERIFICATION DATA
                this.$axios
                    .post("auth/verify", this.verificationData, {
                        headers: {
                            Authorization:
                                "Bearer" +
                                localStorage.getItem("inflco_user_token"),
                        },
                    })
                    .then((res) => {
                        if (res.data.status) {
                            this.isWaitingRequest = false;
                            localStorage.setItem(
                                "inflco_user_is_active",
                                res.data.data.is_active
                            );
                            this.$router.replace("/success-verification");
                        }
                    })
                    .catch((error) => {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: error.response.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                    });
            }

            if (this.verificationType == "success-verification-link") {
                // END:: SET VERIFICATION DATA
                this.$axios
                    .post("auth/verify", this.verificationData, {
                        headers: {
                            Authorization:
                                "Bearer" +
                                localStorage.getItem("inflco_user_token"),
                        },
                    })
                    .then((res) => {
                        if (res.data.status) {
                            this.isWaitingRequest = false;
                            localStorage.setItem(
                                "inflco_user_is_active",
                                res.data.data.is_active
                            );
                            this.$router.replace("/success-verification-link");
                        }
                    })
                    .catch((error) => {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: error.response.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                    });
            }

            // this.$axios.post("auth/verify", this.verificationData)
            // .then( res => {
            //   if( res.data.status ) {
            //     this.$iziToast.success({
            //       timeout: 5000,
            //       message: res.data.message,
            //       position: this.$t("position"),
            //       rtl: this.$t("dir"),
            //     });

            //     if (this.verificationType == "registeration") {
            //       this.isWaitingRequest = false;
            //       this.$router.replace("/success-verification");
            //     } else if (this.verificationType == "reset-password") {
            //       this.isWaitingRequest = false;
            //       this.$router.replace("/reset-password-form");
            //     } else if (this.verificationType == "success-verification-link") {
            //       this.isWaitingRequest = false;
            //       this.$router.replace("/success-verification-link");
            //     }
            //   }
            // })
            // .catch( error => {
            //   this.$iziToast.error({
            //     timeout: 5000,
            //     message: error.response.data.message,
            //     position: this.$t("position"),
            //     rtl: this.$t("dir"),
            //   });
            //   this.isWaitingRequest = false;
            // });
        },
        // END:: SUBMIT VERIFICATION CODE
    },

    created() {
        // START:: HANDLING TIMER
        this.countDown();
        // END:: HANDLING TIMER
        // START ::
        let verificationCode = localStorage.getItem("inflco_verification_code");
        if (!verificationCode) {
            this.$router.replace("/login");
        }
        // START ::
    },

    mounted() {
        this.$refs.first_input.focus();
    },
};
</script>

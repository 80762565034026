import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// START:: IMPORTING I18N
import i18n from "../i18n";
// END:: IMPORTING I18N

// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS

// START:: ROUTER
import router from "./../router/index";
// END:: ROUTER

export default new Vuex.Store({
    state: {
        // START:: LANGUAGE DATA
        app_language: localStorage.getItem("inflco_app_lang"),
        // END:: LANGUAGE DATA

        // START:: INTRO VIDEO DATA
        showVideo: false,
        // END:: INTRO VIDEO DATA

        // START:: NOTIFICATIONS DATA
        notificationsData: [],
        // END:: NOTIFICATIONS DATA
        wallet_transactions: [],
        walletDetails: "",
        pending_wallet: "",
        min_refund: "",
        max_refund: "",
        // START:: INFLUINCERS DATA
        influincersData: [],
        // END:: INFLUINCERS DATA

        // START:: FILTERED INFLUINCERS
        // START:: FILTER FORM DATA
        loadFilter: false,
        loaderCards: false,
        searchData: {
            keyword: "",
        },
        filterData: {
            country_id: "",
            city_id: "",
            sort_by: null,
            gender: null,
            age: null,
            socialMediaArea: [],
            socialMediaPlatform: [],
            selectedFollowersRange: [25000, 10000000],
        },
        theData: {},
        theDataPagnetion: {},
        currentFilterPage: 1,
        last_filter_page: "",
        filteredInfluincers: [],
        has_filter: "",
        currentSearchPage: 1,
        last_search_page: "",
        searchInfluincers: [],
        // END:: FILTER FORM DATA
        // END:: FILTERED INFLUINCERS

        // START:: CHATS LIST
        loadChat: false,
        chatsList: [],
        last_page_of_pagenation: "",
        currentPage_of_pagenation: 1,
        loadChatDots: true,
        // END:: CHATS LIST

        // START:: INVESTOR PROFILE DATA
        investorProfileData: null,
        // END: INVESTOR PROFILE DATA

        // START:: INVESTOR PROFILE SIDE MENU DATA
        investorProfileIsOpen: false,
        // START:: INVESTOR PROFILE SIDE MENU DATA

        // START:: WALLET MENU DATA
        walletMenuIsOpen: false,
        // END:: WALLET MENU DATA

        // START:: WALLET FILTER DATE DATA
        from_date: null,
        to_date: null,
        wallet_status: "completed",
        // END:: WALLET FILTER DATE DATA

        // START:: REFUND REQUEST MENU DATA
        refundRequestMenuIsOpen: false,
        // END:: REFUND REQUEST MENU DATA

        // START:: WITHDRAW MENU DATA
        withdrawMenuIsOpen: false,
        paypalWithdrawMenuIsOpen: false,
        // END:: WITHDRAW MENU DATA

        // START:: NOTIFICATIONS MENU DATA
        notificationsMenuIsOpen: false,
        loadNotifications: false,
        // END:: NOTIFICATIONS MENU DATA

        // START:: PAYMENT MENU DATA
        paymentMenuIsOpen: false,
        paymentCount: "",
        paymentId: "",
        // END:: PAYMENT MENU DATA

        // START:: ALL COUNTRIES DATA
        allCountries: [],
        // END:: ALL COUNTRIES DATA
        // START:: ALL COUNTRIES DATA
        allDurations: [],
        // END:: ALL COUNTRIES DATA
        // START:: COUNTRIES KEYS DATA
        selectedCountry: {
            flag: null,
            phonecode: null,
        },
        // END:: COUNTRIES KEYS DATA

        // START:: ALL CITIES DATA
        cities: [],
        // END:: ALL CITIES DATA

        // START:: ALL INTERESTS DATA
        interests: [],
        // END:: ALL INTERESTS DATA

        // START:: ALL SOCIAL AREA DATA
        socialArea: [],
        // END:: ALL SOCIAL AREA DATA

        // START:: SLICED SOCIAL MEDIA AREAS ARRAY
        slicesSocialAreas: [],
        // END:: SLICED SOCIAL MEDIA AREAS ARRAY

        // START:: ALL SOCIAL MEDIA PLATFORMS DATA
        socialMediaPlatforms: [],
        // END:: ALL SOCIAL MEDIA PLATFORMS DATA

        // START:: SLICED SOCIAL MEDIA PLATFORMS ARRAY
        slicesSocialPlatforms: [],
        // END:: SLICED SOCIAL MEDIA PLATFORMS ARRAY

        // START:: TOGGLE SEE MORE & SEE LESS BUTTONS DATA
        socialPlatformsMoreBtnIsActive: true,
        socialPlatformsLessBtnIsActive: false,

        socialAreasMoreBtnIsActive: true,
        socialAreasLessBtnIsActive: false,
        // END:: TOGGLE SEE MORE & SEE LESS BUTTONS DATA

        // START:: LANGUAGE ARRAY
        languageArray: [
            {
                id: 1,
                name: "English",
                key: "en",
            },
            {
                id: 2,
                name: "العربية",
                key: "ar",
            },
        ],
        notification_num: "",
        chat_num: "",
        // END:: LANGUAGE ARRAY
        phoneDigitNum: "",
        currentLocation: "",
    },

    mutations: {
        // START:: TOGGLE INTRO VIDEO
        toggleIntroVideo(state) {
            state.showVideo = false;
        },
        // START:: TOGGLE INTRO VIDEO

        // START:: TOGGLE INTRO VIDEO
        toggleAboutVideoModal(state) {
            state.showVideo = true;
        },
        // START:: TOGGLE INTRO VIDEO

        // START:: OPEN INTRO VIDEO
        openIntroVideo(state) {
            state.showVideo = true;
        },
        // START:: OPEN INTRO VIDEO

        // START:: CLOSE INTRO VIDEO
        closeIntroVideo(state) {
            state.showVideo = false;
        },
        // START:: CLOSE INTRO VIDEO

        // START:: HANDEL DEFAULT APP LANGUAGE
        handelAppDefaultLanguage(state) {
            if (!state.app_language) {
                localStorage.setItem("inflco_app_lang", "en");
                i18n.locale = "en";
            } else {
                i18n.locale = state.app_language;
            }

            if (state.app_language == "en") {
                document
                    .getElementsByTagName("BODY")[0]
                    .setAttribute("dir", "ltr");
            } else if (state.app_language == "ar") {
                document
                    .getElementsByTagName("BODY")[0]
                    .setAttribute("dir", "rtl");
            }
        },
        // END:: HANDEL DEFAULT APP LANGUAGE

        // START:: HANDLING APP LANGUAGE CHANGE
        changeAppLanguage(_, payload) {
            if (payload.selected_language == "en") {
                localStorage.setItem("inflco_app_lang", "en");
                setTimeout(() => {
                    i18n.locale = localStorage.getItem("inflco_app_lang");
                }, 2000);
                location.reload();
            } else if (payload.selected_language == "ar") {
                localStorage.setItem("inflco_app_lang", "ar");
                setTimeout(() => {
                    i18n.locale = localStorage.getItem("inflco_app_lang");
                }, 2000);
                location.reload();
            }
        },
        // END:: HANDLING APP LANGUAGE CHANGE

        // START:: SET INVESTOR PROFILE DATA
        setInvestorProfileData(state, payload) {
            // console.log( "Store Test", payload.investorData );
            state.investorProfileData = payload.investorData;
        },
        // END:: SET INVESTOR PROFILE DATA

        // START:: TOGGLE INVESTOR PROFILE SIDE MENU
        toggleInvestorProfileSideMenu(state) {
            state.investorProfileIsOpen = !state.investorProfileIsOpen;

            state.walletMenuIsOpen = false;
            state.refundRequestMenuIsOpen = false;
            state.withdrawMenuIsOpen = false;
            state.paypalWithdrawMenuIsOpen = false;
            state.notificationsMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
        },
        // END: TOGGLE INVESTOR PROFILE SIDE MENU

        // START:: TOGGLE WALLET MENU
        toggleWalletMenu(state) {
            state.walletMenuIsOpen = !state.walletMenuIsOpen;
            state.investorProfileIsOpen = false;
            state.refundRequestMenuIsOpen = false;
            state.withdrawMenuIsOpen = false;
            state.paypalWithdrawMenuIsOpen = false;
            state.notificationsMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
        },
        // END:: TOGGLE WALLET MENU

        // START:: TOGGLE REFUND REQUEST MENU
        toggleRefundRequestMenu(state) {
            state.refundRequestMenuIsOpen = !state.refundRequestMenuIsOpen;

            state.investorProfileIsOpen = false;
            state.walletMenuIsOpen = false;
            state.withdrawMenuIsOpen = false;
            state.paypalWithdrawMenuIsOpen = false;
            state.notificationsMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
        },
        // END:: TOGGLE REFUND REQUEST MENU

        // START:: TOGGLE WITHDRAW MENU
        toggleWithdrawMenu(state) {
            state.withdrawMenuIsOpen = !state.withdrawMenuIsOpen;
            state.paypalWithdrawMenuIsOpen = false;
            state.investorProfileIsOpen = false;
            state.walletMenuIsOpen = false;
            state.refundRequestMenuIsOpen = false;
            state.notificationsMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
        },
        togglePaypalWithdrawMenu(state) {
            state.paypalWithdrawMenuIsOpen = !state.paypalWithdrawMenuIsOpen;
            state.withdrawMenuIsOpen = false;
            state.investorProfileIsOpen = false;
            state.walletMenuIsOpen = false;
            state.refundRequestMenuIsOpen = false;
            state.notificationsMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
        },
        // END:: TOGGLE WITHDRAW MENU

        // START:: TOGGLE NOTIFICATIONS MENU
        toggleNotificationsMenu(state) {
            state.notificationsMenuIsOpen = !state.notificationsMenuIsOpen;
            state.investorProfileIsOpen = false;
            state.walletMenuIsOpen = false;
            state.refundRequestMenuIsOpen = false;
            state.withdrawMenuIsOpen = false;
            state.paypalWithdrawMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
            state.notification_num = "";
        },
        // END:: TOGGLE NOTIFICATIONS MENU

        // START:: TOGGLE PAYMENT MENU
        togglePaymentMenu(state, payload) {
            state.paymentMenuIsOpen = !state.paymentMenuIsOpen;
            state.paymentCount = payload.contractData.price;
            state.paymentId = payload.contractData.id;
        },
        // END:: TOGGLE PAYMENT MENU

        // START:: CLOSE SIDE MENUS
        closeSideMenus(state) {
            state.investorProfileIsOpen = false;
            state.walletMenuIsOpen = false;
            state.refundRequestMenuIsOpen = false;
            state.withdrawMenuIsOpen = false;
            state.paypalWithdrawMenuIsOpen = false;
            state.notificationsMenuIsOpen = false;
            state.paymentMenuIsOpen = false;
        },
        // START:: CLOSE SIDE MENUS

        // START:: SEE MORE FUNCTIONALITY
        seeMore(state, payload) {
            if (payload.target == "socialAreas") {
                state.slicesSocialAreas = payload.array.slice(
                    0,
                    payload.array.length
                );
            } else if (payload.target == "socialPlatforms") {
                state.slicesSocialPlatforms = payload.array.slice(
                    0,
                    payload.array.length
                );
            }
        },
        // END:: SEE MORE FUNCTIONALITY

        // START:: SEE LESS FUNCTIONALITY
        seeLess(state, payload) {
            if (payload.target == "socialAreas") {
                state.slicesSocialAreas = payload.array.slice(0, 4);
            } else if (payload.target == "socialPlatforms") {
                state.slicesSocialPlatforms = payload.array.slice(0, 4);
            }
        },
        // END:: SEE LESS FUNCTIONALITY

        // START:: TOGGLE SEE MORE & SEE LESS BUTTONS
        toggleButtons(state, payload) {
            if (payload.target == "platforms_btn") {
                state.socialPlatformsMoreBtnIsActive =
                    !state.socialPlatformsMoreBtnIsActive;
                state.socialPlatformsLessBtnIsActive =
                    !state.socialPlatformsLessBtnIsActive;
            } else if (payload.target == "areas_btn") {
                state.socialAreasMoreBtnIsActive =
                    !state.socialAreasMoreBtnIsActive;
                state.socialAreasLessBtnIsActive =
                    !state.socialAreasLessBtnIsActive;
            }
        },
        // END:: TOGGLE SEE MORE & SEE LESS BUTTONS

        // ======== START:: HANDLING API ========

        // START:: AXIOS GET NOTIFICATIONS
        getNotifications(state) {
            state.loadNotifications = true;
            state.currentPage_of_pagenation = 1;
            axios
                .get(
                    "user/notifications?page=" +
                        state.currentPage_of_pagenation,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Content-type": `application/json`,
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    state.notificationsData = res.data.data.notifications;
                    if (state.notificationsMenuIsOpen == false) {
                        state.notification_num =
                            res.data.data.unreadnotifications_count;
                    }

                    state.chat_num = res.data.unreadmessages_count;
                    state.last_page_of_pagenation = res.data.meta.last_page;
                    state.loadNotifications = false;
                    state.alertNotifi = false;
                });
        },
        makeNotifcationsZero() {
            axios.get("user/notification_count", {
                headers: {
                    Authorization:
                        "bearer" + localStorage.getItem("inflco_user_token"),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                    "Content-type": `application/json`,
                    "Accept-language": localStorage.getItem("inflco_app_lang"),
                },
            });
        },
        // START:: SET WALLET  FILTER KEYS
        setWalletFilterRange(state, payload) {
            if (payload.dateRange != null) {
                state.from_date = payload.dateRange[0];
                state.to_date = payload.dateRange[1];
            } else {
                state.from_date = null;
                state.to_date = null;
            }
        },
        // END: SET WALLET  FILTER KEYS

        walletData(state) {
            axios
                .get("wallet", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                    params: {
                        status: state.wallet_status,
                        from_date: state.from_date,
                        to_date: state.to_date,
                    },
                })
                .then((res) => {
                    state.min_refund = res.data.data.min_withdraw_amount;
                    state.max_refund = res.data.data.max_withdraw_amount;
                    state.walletDetails = res.data.data.wallet;
                    state.pending_wallet = res.data.data.pending_wallet;
                    state.wallet_transactions =
                        res.data.data.wallet_transactions;
                });
        },
        // END:: AXIOS GET NOTIFICATIONS

        // START:: AXIOS GET COUNTRIES
        getCountries(state) {
            axios.get("countries").then((res) => {
                // short_name
                state.allCountries = res.data.data;
                state.allCountries.forEach((ele) => {
                    if (state.currentLocation == ele.short_name) {
                        state.selectedCountry.flag = ele.flag;
                        state.selectedCountry.phonecode = ele.phonecode;
                        state.phoneDigitNum = ele.phone_digit;
                    }
                });
            });
        },
        // END:: AXIOS GET COUNTRIES

        // START:: SELECT COUNTRY
        selectCountry(state, payload) {
            state.selectedCountry.flag = payload.item.flag;
            state.selectedCountry.phonecode = payload.item.phonecode;
            state.phoneDigitNum = payload.item.phone_digit;
        },
        // END:: SELECT COUNTRY

        // START:: AXIOS GET CITIES
        getCities(state, payload) {
            axios.get("countries/" + payload.id + "/cities").then((res) => {
                state.cities = res.data.data;
            });
        },
        // END:: AXIOS GET CITIES

        // START:: AXIOS GET INTERESTS
        getInterests(state) {
            axios.get("interests").then((res) => {
                state.interests = res.data.data;
            });
        },
        // END:: AXIOS GET INTERESTS

        // START:: AXIOS GET SOCIAL AREA
        getSocialArea(state) {
            axios.get("social_areas").then((res) => {
                state.socialArea = res.data.data;
                state.slicesSocialAreas = res.data.data.slice(0, 4);
            });
        },
        // END:: AXIOS GET SOCIAL AREA

        // START:: AXIOS GET SOCIAL PLATFORMS
        getSocialPlatforms(state) {
            axios.get("social_platforms").then((res) => {
                // console.log("store platforms", res.data.data);
                state.socialMediaPlatforms = res.data.data;
                state.slicesSocialPlatforms = res.data.data.slice(0, 4);
            });
        },
        // END:: AXIOS GET SOCIAL PLATFORMS
        // START:: AXIOS GET DURATIONS
        getDurations(state) {
            axios.get("duration").then((res) => {
                state.allDurations = res.data.data;
            });
        },
        // END:: AXIOS GET DURATIONS
        // START:: GET CHAT LIST
        getChatsList(state) {
            state.currentPage_of_pagenation = 1;
            state.loadChat = true;
            state.loadChatDots = false;
            axios
                .get("chats?page=" + state.currentPage_of_pagenation, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    state.chatsList = res.data.data;
                    state.last_page_of_pagenation = res.data.meta.last_page;
                    state.loadChat = false;
                });
        },
        getChatsListAgain(state) {
            state.currentPage_of_pagenation = 1;
            state.loadChatDots = false;
            axios
                .get("chats?page=" + state.currentPage_of_pagenation, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    state.chatsList = res.data.data;
                });
        },
        getChatsListScroll(state) {
            state.loadChatDots = true;
            axios
                .get("chats?page=" + state.currentPage_of_pagenation, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    res.data.data.forEach((element) => {
                        state.chatsList.push(element);
                    });
                    state.loadChatDots = false;
                });
        },
        // END:: GET CHAT LIST
        // START:: SUBMIT FILTER FORM
        submitFilterForm(state) {
            state.loadFilter = true;
            state.loaderCards = true;
            // console.log(state.theData);
            // START:: APPEND FILTER DATA
            state.theData.keyword = "";
            state.theData.country_id = state.filterData.country_id;
            state.theData.city_id = state.filterData.city_id;
            state.theData.sort_by = state.filterData.sort_by;
            if (state.filterData.gender == "both") {
                state.theData.gender = null;
            } else {
                state.theData.gender = state.filterData.gender;
            }
            if (state.filterData.age == "all") {
                state.theData.age = null;
            } else {
                state.theData.age = state.filterData.age;
            }
            state.theData.followers_num_from =
                state.filterData.selectedFollowersRange[0];
            state.theData.followers_num_to =
                state.filterData.selectedFollowersRange[1];
            // this.theData.followers_num_from = 1;
            // this.theData.followers_num_to = 10000;
            if (
                state.filterData.selectedFollowersRange[0] ==
                state.filterData.selectedFollowersRange[1]
            ) {
                state.theData.followers_num_from = null;
                state.theData.followers_num_to = null;
            }
            state.filterData.socialMediaArea.forEach((item, index) => {
                state.theData[`social_area_id[${index}]`] = item;
            });
            state.filterData.socialMediaPlatform.forEach((item, index) => {
                state.theData[`social_platform_id[${index}]`] = item;
            });
            // END:: APPEND FILTER DATA
            axios
                .get("investor/filter" + "?page=" + state.currentFilterPage, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                    params: state.theData,
                })
                .then((res) => {
                    state.loadFilter = false;
                    state.loaderCards = false;
                    state.filteredInfluincers = res.data.data;
                    state.last_filter_page = res.data.meta.last_page;
                    router.push("/explore");
                    state.filterData.socialMediaArea.forEach((_, index) => {
                        state.theData[`social_area_id[${index}]`] = null;
                    });
                    state.filterData.socialMediaPlatform.forEach((_, index) => {
                        state.theData[`social_platform_id[${index}]`] = null;
                    });
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    state.loadFilter = false;
                    state.loaderCards = false;
                });
        },

        pagenationFilter(state, payload) {
            document
                .querySelector(".explore_influincers_wrapper")
                .scrollIntoView({
                    behavior: "smooth",
                });
            if (state.currentFilterPage !== payload.pageFilterNumber) {
                state.currentFilterPage = payload.pageFilterNumber;
            }
        },
        // getHomeExplore(state) {
        //     state.loadFilter = true;
        //     state.loaderCards = true;
        //     axios
        //         .get("investor/home" + "?page=" + state.currentFilterPage, {
        //             headers: {
        //                 Authorization: "bearer" +
        //                     localStorage.getItem("inflco_user_token"),
        //                 "Content-type": "application/json",
        //                 "cache-control": "no-cache",
        //                 Accept: "application/json",
        //                 "Accept-language": localStorage.getItem("inflco_app_lang"),
        //             },
        //         })
        //         .then((res) => {
        //             state.loadFilter = false;
        //             state.loaderCards = false;
        //             console.log(res.data.data)
        //                 // state.filteredInfluincers = res.data.data;
        //                 // state.has_filter = res.data.has_filter;
        //                 // state.last_filter_page = res.data.meta.last_page;
        //                 // -----------
        //                 // if (res.data.filter_data.country_id == null) {
        //                 //     state.filterData.country_id = "";
        //                 // } else {
        //                 //     // START:: AXIOS GET CITES
        //                 //     axios
        //                 //         .get(
        //                 //             "countries/" +
        //                 //             res.data.filter_data.country_id +
        //                 //             "/cities"
        //                 //         )
        //                 //         .then((res) => (state.cities = res.data.data));
        //                 //     // END:: AXIOS GET CITES
        //                 //     // state.filterData.country_id = res.data.filter_data.country_id;
        //                 // }
        //                 // if (res.data.filter_data.city_id == null) {
        //                 //     state.filterData.city_id = "";
        //                 // } else {
        //                 //     state.filterData.city_id = res.data.filter_data.city_id;
        //                 // }
        //                 // state.filterData.sort_by = res.data.filter_data.sort_by;
        //                 // state.filterData.gender = res.data.filter_data.gender;
        //                 // state.filterData.age = res.data.filter_data.age;
        //                 // state.filterData.socialMediaArea = res.data.filter_data.social_area_id;
        //                 // state.filterData.socialMediaPlatform = res.data.filter_data.social_platform_id;
        //                 // if ( res.data.filter_data.followers_num_from == null || res.data.filter_data.followers_num_to == null ) {
        //                 //     state.filterData.selectedFollowersRange = [0, 0];
        //                 // } else {
        //                 //     state.filterData.selectedFollowersRange = [
        //                 //         res.data.filter_data.followers_num_from,
        //                 //         res.data.filter_data.followers_num_to,
        //                 //     ];
        //                 // }
        //         });
        // },
        // END:: SUBMIT FILTER FORM
        // search method
        submitSearchForm(state) {
            state.loaderCards = true;
            axios
                .get(
                    "/investor/new_search?name=" +
                        state.searchData.keyword +
                        "&page=" +
                        state.currentSearchPage,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    state.loaderCards = false;
                    state.searchInfluincers = res.data.data;
                    state.last_search_page = res.data.meta.last_page;
                    router.push("/search-result");
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    state.loaderCards = false;
                });
        },
        pagenationSearch(state, payload) {
            document
                .querySelector(".explore_influincers_wrapper")
                .scrollIntoView({
                    behavior: "smooth",
                });
            if (state.currentSearchrPage !== payload.pageSearchNumber) {
                state.currentSearchPage = payload.pageSearchNumber;
            }
        },
        // ======== END:: HANDLING API ========
    },

    actions: {
        // START:: HANDEL DEFAULT APP LANGUAGE
        handelAppDefaultLanguage(context) {
            context.commit("handelAppDefaultLanguage");
        },
        // END:: HANDEL DEFAULT APP LANGUAGE

        // START:: HANDLING APP LANGUAGE CHANGE
        changeAppLanguage(context, payload) {
            context.commit("changeAppLanguage", payload);
        },
        // END:: HANDLING APP LANGUAGE CHANGE

        // START:: TOGGLE INVESTOR PROFILE SIDE MENU
        toggleInvestorProfileSideMenu(context) {
            context.commit("toggleInvestorProfileSideMenu");
        },
        // END:: TOGGLE INVESTOR PROFILE SIDE MENU

        // START:: TOGGLE WALLET MENU
        toggleWalletMenu(context) {
            context.commit("toggleWalletMenu");
            context.commit("walletData");
        },
        // END:: TOGGLE WALLET MENU

        // START:: TOGGLE REFUND REQUEST MENU
        toggleRefundRequestMenu(context) {
            context.commit("toggleRefundRequestMenu");
        },
        // end:: TOGGLE REFUND REQUEST MENU

        // START:: TOGGLE WITHDRAW MENU
        toggleWithdrawMenu(context) {
            context.commit("toggleWithdrawMenu");
        },
        togglePaypalWithdrawMenu(context) {
            context.commit("togglePaypalWithdrawMenu");
        },
        // end:: TOGGLE WITHDRAW MENU

        // START:: TOGGLE NOTIFICATIONS MENU
        toggleNotificationsMenu(context) {
            context.commit("toggleNotificationsMenu");
            context.commit("getNotifications");
        },
        // END:: TOGGLE NOTIFICATIONS MENU

        // START:: TOGGLE PAYMENT MENU
        togglePaymentMenu(context, payload) {
            context.commit("togglePaymentMenu", payload);
        },
        // END:: TOGGLE PAYMENT MENU

        // START:: CLOSE SIDE MENUS
        closeSideMenus(context) {
            context.commit("closeSideMenus");
        },
        // END:: CLOSE SIDE MENUS

        // START:: SEE MORE FUNCTIONALITY
        seeMore(context, payload) {
            context.commit("seeMore", payload);
        },
        // END:: SEE MORE FUNCTIONALITY

        // START:: SEE LESS FUNCTIONALITY
        seeLess(context, payload) {
            context.commit("seeLess", payload);
        },
        // END:: SEE LESS FUNCTIONALITY

        // START:: TOGGLE SEE MORE & SEE LESS BUTTONS
        toggleButtons(context, payload) {
            context.commit("toggleButtons", payload);
        },
        // END:: TOGGLE SEE MORE & SEE LESS BUTTONS

        // ======== START:: HANDLING API ========

        // START:: AXIOS GET NOTIFICATIONS
        getNotifications(context) {
            context.commit("getNotifications");
        },
        makeNotifcationsZero(context) {
            context.commit("makeNotifcationsZero");
        },
        walletData(context) {
            context.commit("walletData");
        },
        // end:: AXIOS GET NOTIFICATIONS

        // START:: AXIOS GET COUNTRIES
        getCountries(context) {
            context.commit("getCountries");
        },

        updateSelectedCountry(context) {
            context.commit("updateSelectedCountry");
        },
        // END:: AXIOS GET COUNTRIES

        // START:: SELECT COUNTRY
        selectCountry(context, payload) {
            context.commit("selectCountry", payload);
        },
        // END:: SELECT COUNTRY

        // START:: AXIOS GET COUNTRIES
        getCities(context, payload) {
            context.commit("getCities", payload);
        },
        // END:: AXIOS GET COUNTRIES

        // START:: AXIOS GET INTERESTS
        getInterests(context) {
            context.commit("getInterests");
        },
        // END:: AXIOS GET INTERESTS

        // START:: AXIOS GET INTERESTS
        getSocialArea(context) {
            context.commit("getSocialArea");
        },
        // END:: AXIOS GET INTERESTS

        // START:: AXIOS GET SOCIAL PLATFORMS
        getSocialPlatforms(context) {
            context.commit("getSocialPlatforms");
        },
        // END:: AXIOS GET SOCIAL PLATFORMS
        // START:: AXIOS GET DURATIONS
        getDurations(context) {
            context.commit("getDurations");
        },
        getChatsList(context) {
            context.commit("getChatsList");
        },
        getChatsListAgain(context) {
            context.commit("getChatsListAgain");
        },
        getChatsListScroll(context) {
            context.commit("getChatsListScroll");
        },
        submitFilterForm(context) {
            context.commit("submitFilterForm");
        },
        pagenationFilter(context, payload) {
            context.commit("pagenationFilter", payload);
            context.commit("submitFilterForm");
            // context.commit("getHomeExplore");
        },
        // getHomeExplore(context) {
        //     context.commit("getHomeExplore");
        // },
        submitSearchForm(context) {
            context.commit("submitSearchForm");
        },
        pagenationSearch(context, payload) {
            context.commit("pagenationSearch", payload);
            context.commit("submitSearchForm");
        },
        // ======== END:: HANDLING API ========
    },

    getters: {
        firstCountry(state) {
            return (state.selectedCountry.flag = state.allCountries[0].flag);
        },
    },
    modules: {},
});

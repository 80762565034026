<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="home_wrapper">
        <!-- START:: HEADER -->
        <TheHeader />
        <!-- END:: HEADER -->

        <!-- START:: HOME CONTENT WRAPPER -->
        <div class="row justify-content-center">
            <!-- START:: LARGE CONTENT WRAPPER -->
            <div
                id="large_content_wrapper"
                class="print_section"
                :class="reSize == false ? 'col-md-9' : 'col-md-8'"
            >
                <ContentWrapper>
                    <template #main>
                        <transition mode="out-in" name="fade">
                            <!-- START:: LARGE CONTENT WRAPPER ROUTER VIEW -->
                            <router-view
                                name="large_view"
                                v-if="userType == 'investor'"
                            ></router-view>
                            <router-view
                                name="influincer_large_view"
                                v-else
                            ></router-view>
                            <!-- END:: LARGE CONTENT WRAPPER ROUTER VIEW -->
                        </transition>
                    </template>
                </ContentWrapper>
            </div>
            <!-- END:: LARGE CONTENT WRAPPER -->

            <!-- START:: SMALL CONTENT WRAPPER -->
            <div
                id="small_content_wrapper"
                :class="reSize == false ? 'col-md-3' : 'col-md-4'"
                class="position-relative ignore_print print_section"
            >
                <ContentWrapper>
                    <template #main>
                        <transition mode="out-in" name="fade">
                            <!-- START:: SMALL CONTENT WRAPPER ROUTER VIEW -->
                            <router-view
                                name="small_view"
                                v-if="userType == 'investor'"
                            ></router-view>
                            <router-view
                                name="influincer_small_view"
                                v-else
                            ></router-view>
                            <!-- END:: SMALL CONTENT WRAPPER ROUTER VIEW -->
                        </transition>
                    </template>
                </ContentWrapper>
            </div>
            <!-- END:: SMALL CONTENT WRAPPER -->

            <!-- START:: INVESTOR SIDE MENU WEAPPER -->
            <div
                class="profile_wrapper"
                :class="{ active: $store.state.investorProfileIsOpen }"
                v-if="
                    $store.state.investorProfileData != null &&
                    ($router.history.current.matched[1].path ==
                        '/influincer-chat/:influincer_id/:payment_status?' ||
                        $router.history.current.matched[1].path ==
                            '/influincer-contract/:id')
                "
            >
                <div class="overlay"></div>
                <div class="profile_content_wrapper">
                    <div class="close_btn_wrapper">
                        <button
                            class="btn"
                            @click="toggleInvestorProfileSideMenu"
                        >
                            <XIcon />
                        </button>
                    </div>

                    <div class="investor_profile_main_Info">
                        <!-- START:: MAIN INFO -->
                        <div class="profile_avatar_wrapper">
                            <div class="profile_avatar">
                                <!-- <AvatarName
                                    :nameAvatar="$store.state.investorProfileData.fullname"
                                    v-if="
                                        $store.state.investorProfileData != null && 
                                        $store.state.investorProfileData.image.includes('backgrounds/avatar.png')
                                    "
                                /> -->
                                <img
                                    :src="
                                        $store.state.investorProfileData.image
                                    "
                                    alt="influincer Avatar"
                                    width="180"
                                    height="180"
                                />
                            </div>

                            <div class="investor_name">
                                <h2>
                                    {{
                                        $store.state.investorProfileData
                                            .fullname
                                    }}
                                </h2>
                                <!-- <h3>
                                    {{ $store.state.investorProfileData.phone }}
                                </h3> -->
                            </div>
                        </div>

                        <div class="company_info">
                            <h3 class="mb-3">
                                {{ $t("titles.company_info") }}
                            </h3>
                            <h4>
                                {{
                                    $store.state.investorProfileData
                                        .company_name
                                }}
                            </h4>
                            <h4>
                                {{
                                    $store.state.investorProfileData
                                        .company_address
                                }}
                            </h4>
                            <h4>
                                {{
                                    $store.state.investorProfileData
                                        .company_desc
                                }}
                            </h4>
                        </div>
                        <!-- END:: MAIN INFO -->
                    </div>
                    <!-- END:: NOTIFICATIONS LIST -->
                </div>
            </div>
            <!-- END:: INVESTOR SIDE MENU WEAPPER -->

            <!-- START:: WALLET SIDE MENU WRAPPER -->
            <div
                class="wallet_wraper"
                :class="{ active: $store.state.walletMenuIsOpen }"
            >
                <div class="overlay"></div>

                <!-- START:: INVESTOR WALLET -->
                <div
                    class="wallet_content_wraper"
                    v-if="userType == 'investor'"
                >
                    <div class="close_btn_wrapper">
                        <button class="btn" @click="toggleWalletMenu">
                            <XIcon />
                        </button>
                    </div>

                    <div class="title_wrapper">
                        <h3>{{ $t("titles.myWallet") }}</h3>
                    </div>

                    <div class="wallet_panel_wrapper">
                        <div class="wallet_panel">
                            <div class="panel_title">
                                <h5>{{ $t("titles.yourBalance") }}</h5>
                            </div>

                            <div class="panel_body">
                                <h2>{{ $store.state.walletDetails }} $</h2>
                            </div>
                        </div>
                    </div>

                    <div class="note_panel_wrapper">
                        <div class="note_panel">
                            <div class="title_wrapper">
                                <h6>{{ $t("frequently_words.note") }}</h6>
                            </div>

                            <div class="text_wrapper">
                                {{ $t("frequently_words.noteText") }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="refund_btn_wrapper column_gap_35"
                        v-if="$store.state.walletDetails > 0"
                    >
                        <!-- START:: BANK ACCOUNT WITHDRAW BUTTON -->
                        <button
                            type="button"
                            class="auto_width px-2"
                            @click="
                                toggleWalletMenu();
                                toggleWithdrawMenu();
                                setWithdrawType('bank');
                            "
                            :disabled="$store.state.walletDetails == 0"
                        >
                            {{ $t("buttons.bank_refund") }}
                        </button>
                        <!-- END:: BANK ACCOUNT WITHDRAW BUTTON -->

                        <!-- START:: PAYPAL WITHDRAW BUTTON -->
                        <button
                            type="button"
                            class="auto_width px-2"
                            @click="
                                toggleWalletMenu();
                                togglePaypalWithdrawMenu();
                                setWithdrawType('paypal');
                            "
                            :disabled="$store.state.walletDetails == 0"
                        >
                            {{ $t("buttons.paypal_refund") }}
                        </button>
                        <!-- END:: PAYPAL WITHDRAW BUTTON -->
                    </div>
                </div>
                <!-- END:: INVESTOR WALLET -->

                <!-- START:: INFLUINCER WALLET -->
                <div class="wallet_content_wraper" v-else>
                    <div class="close_btn_wrapper">
                        <button class="btn" @click="toggleWalletMenu">
                            <XIcon />
                        </button>
                    </div>

                    <div class="title_wrapper">
                        <h3>{{ $t("titles.myWallet") }}</h3>
                    </div>

                    <div class="wallet_tabs">
                        <v-card>
                            <v-tabs class="mb-5" v-model="tab">
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab
                                    href="#complated_tab"
                                    @click="getInfluincerWallet('completed')"
                                >
                                    {{ $t("titles.complated") }}
                                </v-tab>
                                <v-tab
                                    href="#peinding_tab"
                                    @click="getInfluincerWallet('pending')"
                                >
                                    {{ $t("titles.pending") }}
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item value="complated_tab">
                                    <v-card flat>
                                        <v-card-text>
                                            <div
                                                class="influincer_wallet_panel_wrapper"
                                            >
                                                <div class="wallet_panel">
                                                    <div class="input_wrapper">
                                                        <input
                                                            id="password"
                                                            class="form-control"
                                                            type="password"
                                                            :value="
                                                                '$ ' +
                                                                $store.state
                                                                    .walletDetails
                                                            "
                                                            readonly
                                                        />
                                                    </div>

                                                    <div class="btns_wrapper">
                                                        <button
                                                            type="button"
                                                            v-if="
                                                                passwordIsVisible
                                                            "
                                                            @click="
                                                                togglePasswordVisibility(
                                                                    'password'
                                                                )
                                                            "
                                                        >
                                                            <EyeOffIcon />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            v-else
                                                            @click="
                                                                togglePasswordVisibility(
                                                                    'password'
                                                                )
                                                            "
                                                        >
                                                            <EyeIcon />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="withdraw_btn_wrapper">
                                                <!-- START:: BANK ACCOUNT WITHDRAW BUTTON -->
                                                <button
                                                    type="button"
                                                    @click="
                                                        toggleWalletMenu();
                                                        toggleWithdrawMenu();
                                                        setWithdrawType('bank');
                                                    "
                                                    :disabled="
                                                        $store.state
                                                            .walletDetails == 0
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "buttons.bank_withdraw"
                                                        )
                                                    }}
                                                </button>
                                                <!-- END:: BANK ACCOUNT WITHDRAW BUTTON -->

                                                <!-- START:: PAYPAL WITHDRAW BUTTON -->
                                                <button
                                                    type="button"
                                                    @click="
                                                        toggleWalletMenu();
                                                        togglePaypalWithdrawMenu();
                                                        setWithdrawType(
                                                            'paypal'
                                                        );
                                                    "
                                                    :disabled="
                                                        $store.state
                                                            .walletDetails == 0
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "buttons.paypal_withdraw"
                                                        )
                                                    }}
                                                </button>
                                                <!-- END:: PAYPAL WITHDRAW BUTTON -->
                                            </div>

                                            <div class="client_transations">
                                                <div
                                                    class="head_client_transaction"
                                                >
                                                    <h3>
                                                        {{
                                                            $t(
                                                                "titles.transactionsHistory"
                                                            )
                                                        }}
                                                    </h3>
                                                    <button
                                                        @click="
                                                            seeAllTrasactions
                                                        "
                                                        type="button"
                                                        v-if="
                                                            $store.state
                                                                .wallet_transactions !=
                                                            0
                                                        "
                                                        class="btn"
                                                    >
                                                        {{
                                                            $t(
                                                                "buttons.see_all"
                                                            )
                                                        }}
                                                    </button>
                                                </div>
                                                <NoMatchedData
                                                    :noDataTitlte="
                                                        $t(
                                                            'titles.no_transactions'
                                                        )
                                                    "
                                                    v-if="
                                                        $store.state
                                                            .wallet_transactions
                                                            .length == 0
                                                    "
                                                />
                                                <div
                                                    class="client_transaction_wrapper"
                                                    v-for="client in $store.state.wallet_transactions.slice(
                                                        0,
                                                        5
                                                    )"
                                                    :key="client.id"
                                                    v-else
                                                >
                                                    <template>
                                                        <div
                                                            class="avatar_wrapper"
                                                        >
                                                            <img
                                                                v-if="
                                                                    client.transferor !=
                                                                    null
                                                                "
                                                                :src="
                                                                    client
                                                                        .transferor
                                                                        .image
                                                                "
                                                                alt="Client Avatar"
                                                                width="100"
                                                                height="100"
                                                            />
                                                            <img
                                                                v-else
                                                                src="../assets/logo/logo.png"
                                                                alt="Client Avatar"
                                                                width="100"
                                                                height="100"
                                                            />
                                                        </div>

                                                        <div
                                                            class="details_wrapper"
                                                        >
                                                            <div
                                                                class="wrapper"
                                                            >
                                                                <div
                                                                    class="name"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            client.transferor !=
                                                                            null
                                                                        "
                                                                        >{{
                                                                            client
                                                                                .transferor
                                                                                .fullname
                                                                        }}
                                                                    </span>
                                                                    <span
                                                                        v-else
                                                                    >
                                                                        Infclo
                                                                    </span>
                                                                    <div
                                                                        class="mony_amount_wrapper"
                                                                    >
                                                                        ${{
                                                                            client.amount
                                                                        }}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="fields"
                                                                >
                                                                    {{
                                                                        client.contract_name
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="info_wrapper"
                                                            >
                                                                <span
                                                                    class="time"
                                                                >
                                                                    {{
                                                                        client.ago
                                                                    }}</span
                                                                >
                                                                <span
                                                                    class="status done"
                                                                    v-if="
                                                                        client
                                                                            .status
                                                                            .key !=
                                                                            null &&
                                                                        client
                                                                            .status
                                                                            .key ==
                                                                            'transferred'
                                                                    "
                                                                >
                                                                    {{
                                                                        $t(
                                                                            "titles.pay_transferred"
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    class="status done"
                                                                    v-if="
                                                                        client
                                                                            .status
                                                                            .key !=
                                                                            null &&
                                                                        client
                                                                            .status
                                                                            .key ==
                                                                            'withdrawal'
                                                                    "
                                                                >
                                                                    {{
                                                                        $t(
                                                                            "titles.pay_withdrawal"
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    class="status refuced-text"
                                                                    v-if="
                                                                        client
                                                                            .status
                                                                            .key !=
                                                                            null &&
                                                                        client
                                                                            .status
                                                                            .key ==
                                                                            'refused'
                                                                    "
                                                                >
                                                                    {{
                                                                        $t(
                                                                            "titles.pay_refused"
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    class="status processing"
                                                                    v-if="
                                                                        client
                                                                            .status
                                                                            .key !=
                                                                            null &&
                                                                        client
                                                                            .status
                                                                            .key ==
                                                                            'pending'
                                                                    "
                                                                >
                                                                    {{
                                                                        $t(
                                                                            "titles.pay_pending"
                                                                        )
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>

                                <v-tab-item value="peinding_tab">
                                    <v-card flat>
                                        <v-card-text>
                                            <div
                                                class="influincer_wallet_panel_wrapper"
                                            >
                                                <div class="wallet_panel">
                                                    <div class="input_wrapper">
                                                        <input
                                                            id="password_pending"
                                                            class="form-control"
                                                            type="password"
                                                            :value="
                                                                '$ ' +
                                                                $store.state
                                                                    .pending_wallet
                                                            "
                                                            readonly
                                                        />
                                                    </div>

                                                    <div class="btns_wrapper">
                                                        <button
                                                            type="button"
                                                            v-if="
                                                                passwordIsVisiblePending
                                                            "
                                                            @click="
                                                                togglePasswordVisibility(
                                                                    'password_pending'
                                                                )
                                                            "
                                                        >
                                                            <EyeOffIcon />
                                                        </button>
                                                        <button
                                                            type="button"
                                                            v-else
                                                            @click="
                                                                togglePasswordVisibility(
                                                                    'password_pending'
                                                                )
                                                            "
                                                        >
                                                            <EyeIcon />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="client_transations">
                                                <div
                                                    class="head_client_transaction"
                                                >
                                                    <h3>
                                                        {{
                                                            $t(
                                                                "titles.transactionsHistory"
                                                            )
                                                        }}
                                                    </h3>
                                                    <button
                                                        @click="
                                                            seeAllTrasactions
                                                        "
                                                        type="button"
                                                        v-if="
                                                            $store.state
                                                                .wallet_transactions !=
                                                            0
                                                        "
                                                        class="btn"
                                                    >
                                                        {{
                                                            $t(
                                                                "buttons.see_all"
                                                            )
                                                        }}
                                                    </button>
                                                </div>
                                                <!-- <NoMatchedData
                          :noDataTitlte="$t('titles.no_transactions')"
                          v-if="$store.state.wallet_transactions.length == 0"
                        /> -->
                                                <div
                                                    class="client_transaction_wrapper"
                                                    v-for="client in $store.state.wallet_transactions.slice(
                                                        0,
                                                        5
                                                    )"
                                                    :key="client.id"
                                                >
                                                    <template>
                                                        <div
                                                            class="avatar_wrapper"
                                                        >
                                                            <img
                                                                v-if="
                                                                    client.transferor !=
                                                                    null
                                                                "
                                                                :src="
                                                                    client
                                                                        .transferor
                                                                        .image
                                                                "
                                                                alt="Client Avatar"
                                                                width="100"
                                                                height="100"
                                                            />
                                                            <img
                                                                v-else
                                                                src="../assets/logo/logo.png"
                                                                alt="Client Avatar"
                                                                width="100"
                                                                height="100"
                                                            />
                                                        </div>

                                                        <div
                                                            class="details_wrapper"
                                                        >
                                                            <div
                                                                class="wrapper"
                                                            >
                                                                <div
                                                                    class="name"
                                                                >
                                                                    <span
                                                                        v-if="
                                                                            client.transferor !=
                                                                            null
                                                                        "
                                                                        >{{
                                                                            client
                                                                                .transferor
                                                                                .fullname
                                                                        }}
                                                                    </span>
                                                                    <span
                                                                        v-else
                                                                    >
                                                                        Infclo
                                                                    </span>
                                                                    <div
                                                                        class="mony_amount_wrapper"
                                                                    >
                                                                        ${{
                                                                            client.amount
                                                                        }}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    class="fields"
                                                                >
                                                                    {{
                                                                        client.contract_name
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div
                                                                class="info_wrapper"
                                                            >
                                                                <span
                                                                    class="time"
                                                                >
                                                                    {{
                                                                        client.ago
                                                                    }}</span
                                                                >

                                                                <span
                                                                    class="status processing"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            "titles.pay_pending"
                                                                        )
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </div>
                </div>
                <!-- END:: INFLUINCER WALLET -->
            </div>
            <!-- END:: WALLET SIDE MENU WRAPPER -->

            <!-- START:: BANK WITHDRAW SIDE MENU WRAPPER -->
            <div
                class="refund_wrapper bank_account"
                :class="{ active: $store.state.withdrawMenuIsOpen }"
            >
                <div class="overlay"></div>
                <div class="refund_request_content_wrapper">
                    <div class="close_btn_wrapper">
                        <button class="btn" @click="toggleWithdrawMenu">
                            <XIcon />
                        </button>
                    </div>

                    <div class="title_wrapper">
                        <h3>
                            <button
                                class="btn"
                                @click="
                                    toggleWithdrawMenu();
                                    toggleWalletMenu();
                                "
                            >
                                <img
                                    src="../assets/media/icons/arrow_left.svg"
                                    alt="Icon"
                                    width="100"
                                    height="100"
                                />
                            </button>
                            {{ $t("buttons.withdraw") }}
                        </h3>
                    </div>

                    <div class="subtitle_wrapper">
                        {{ $t("frequently_words.wothddraw") }}
                    </div>

                    <div class="refund_form_wrapper">
                        <form @submit.prevent="submitreWithdraw">
                            <div class="wrapper mb-5">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('placeholders.bank_name')"
                                    v-model="withdrawtData.bankName"
                                />
                            </div>
                            <div class="wrapper mb-5">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('placeholders.bank_address')
                                    "
                                    v-model="withdrawtData.BankAddress"
                                />
                            </div>
                            <div class="wrapper mb-5">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('placeholders.account_name')
                                    "
                                    v-model="withdrawtData.accountName"
                                />
                            </div>
                            <div class="wrapper mb-5">
                                <input
                                    type="number"
                                    onwheel="this.blur()"
                                    class="form-control"
                                    :placeholder="
                                        $t('placeholders.account_number')
                                    "
                                    v-model="withdrawtData.accountNumber"
                                />
                            </div>
                            <div class="wrapper mb-5">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="$t('placeholders.iban')"
                                    v-model="withdrawtData.IbanNumber"
                                />
                            </div>
                            <div class="wrapper mb-5">
                                <input
                                    type="number"
                                    onwheel="this.blur()"
                                    class="form-control"
                                    :placeholder="$t('placeholders.amount')"
                                    v-model="withdrawtData.amount"
                                />
                            </div>

                            <div class="wrapper justify-content-center">
                                <button
                                    class="btn"
                                    :class="withDrawIsEmpty ? 'disabled' : ''"
                                >
                                    {{ $t("buttons.send") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequest"
                                    ></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- START:: MODAL -->
                <BaseModel @closeModel="show = !show" :show="show">
                    <template #modal>
                        <div class="modal_successfully">
                            <div class="image_successfully">
                                <img
                                    src="../assets/media/illustrations/successfully.svg"
                                    alt="successfully"
                                    width="100"
                                    height="100"
                                />
                            </div>
                            <div class="text_successfully">
                                <p>
                                    {{ $t("validation.requestSuccessfull") }}
                                </p>
                            </div>
                            <form>
                                <div class="wrapper justify-content-center">
                                    <button
                                        class="btn"
                                        type="button"
                                        @click="show = !show"
                                    >
                                        {{ $t("buttons.continue") }}
                                        <span
                                            class="btn_loader"
                                            v-if="isWaitingRequest"
                                        ></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </template>
                </BaseModel>
                <!-- END:: MODAL -->
            </div>
            <!-- END:: BANK WITHDRAW SIDE MENU WRAPPER -->

            <!-- START:: PAYPAL WITHDRAW SIDE MENU WRAPPER -->
            <div
                class="refund_wrapper paypal"
                :class="{ active: $store.state.paypalWithdrawMenuIsOpen }"
            >
                <div class="overlay"></div>
                <div class="refund_request_content_wrapper">
                    <div class="close_btn_wrapper">
                        <button class="btn" @click="togglePaypalWithdrawMenu">
                            <XIcon />
                        </button>
                    </div>

                    <div class="title_wrapper">
                        <h3>
                            <button
                                class="btn"
                                @click="
                                    togglePaypalWithdrawMenu();
                                    toggleWalletMenu();
                                "
                            >
                                <img
                                    src="../assets/media/icons/arrow_left.svg"
                                    alt="Icon"
                                    width="100"
                                    height="100"
                                />
                            </button>
                            {{ $t("buttons.withdraw") }}
                        </h3>
                    </div>

                    <div class="subtitle_wrapper">
                        {{ $t("frequently_words.wothddraw") }}
                    </div>

                    <div class="refund_form_wrapper">
                        <form @submit.prevent="submitreWithdraw">
                            <!-- START:: ACCOUNT TYPE-->
                            <div class="group mb-4">
                                <h6 class="mb-4">
                                    {{ $t("titles.paypal_acc_type") }}
                                </h6>
                                <div
                                    class="inner_wrapper d-flex justify-content-start column_gap_35"
                                >
                                    <div class="wrapper w-50 mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            value="personal"
                                            id="personal_account"
                                            name="paypal_account_type"
                                            v-model="
                                                paypalWithdrawtData.accountType
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="personal_account"
                                        >
                                            {{ $t("placeholders.personal") }}
                                        </label>
                                    </div>

                                    <div class="wrapper w-50 mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            value="business"
                                            id="business_account"
                                            name="paypal_account_type"
                                            v-model="
                                                paypalWithdrawtData.accountType
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="business_account"
                                        >
                                            {{ $t("placeholders.company") }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: ACCOUNT TYPE-->

                            <!-- START:: IDENTIFIER INPUT -->
                            <div class="wrapper mb-5">
                                <input
                                    type="text"
                                    class="form-control"
                                    :placeholder="
                                        $t('placeholders.paypal_identifier')
                                    "
                                    v-model.trim="
                                        paypalWithdrawtData.paypalIdentifier
                                    "
                                />
                            </div>
                            <!-- END:: IDENTIFIER INPUT -->

                            <!-- START:: AMOUNT INPUT -->
                            <div class="wrapper mb-5">
                                <input
                                    type="number"
                                    onwheel="this.blur()"
                                    class="form-control"
                                    :placeholder="$t('placeholders.amount')"
                                    v-model.trim="paypalWithdrawtData.amount"
                                />
                            </div>
                            <!-- END:: AMOUNT INPUT -->

                            <div class="wrapper justify-content-center">
                                <button
                                    class="btn"
                                    :class="
                                        paypalWithDrawIsEmpty ? 'disabled' : ''
                                    "
                                >
                                    {{ $t("buttons.send") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequest"
                                    ></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- END:: PAYPAL WITHDRAW SIDE MENU WRAPPER -->

            <!-- START:: NOTIFICATIONS SIDE MENU WEAPPER -->
            <div
                class="notifications_wrapper"
                :class="{ active: $store.state.notificationsMenuIsOpen }"
            >
                <div class="overlay"></div>
                <div
                    class="notifications_content_wrapper"
                    @scroll="scrollSection"
                >
                    <div class="close_btn_wrapper">
                        <button class="btn" @click="closeNotification">
                            <XIcon />
                        </button>
                    </div>

                    <div class="title_wrapper">
                        <h3>{{ $t("titles.notifications") }}</h3>
                    </div>
                    <Loader
                        v-if="$store.state.loadNotifications"
                        :number="6"
                        class="py-2 fadeIn"
                    ></Loader>
                    <section v-else class="fadeIn">
                        <SecondEmptyMessage
                            class="py-0"
                            v-if="$store.state.notificationsData.length == 0"
                        />
                        <!-- START:: NOTIFICATIONS LIST -->
                        <ul class="notifications_list" v-else>
                            <li
                                v-if="
                                    $store.state.notificationsMenuIsOpen == true
                                "
                                class="notifications_list_item"
                                v-for="notification in $store.state
                                    .notificationsData"
                                :key="notification.id"
                                @click="closeNotification"
                            >
                                <i
                                    class="fas fa-circle text-danger fs-12px"
                                    v-if="!notification.read_at"
                                ></i>
                                <router-link
                                    v-if="
                                        notification.contract != null &&
                                        notification.notify_type !=
                                            'campaign_status_new'
                                    "
                                    :to="
                                        userType == 'investor'
                                            ? '/contract/' +
                                              notification.contract.contract_id
                                            : '/influincer-contract/' +
                                              notification.contract.contract_id
                                    "
                                >
                                    <div class="wrapper">
                                        <div class="avatar_wrapper">
                                            <img
                                                :src="notification.avatar"
                                                width="100"
                                                height="100"
                                            />
                                        </div>
                                        <div class="notification_body_wrapper">
                                            <div class="name">
                                                <span>
                                                    {{ notification.sender }}
                                                </span>

                                                <span class="time">
                                                    {{ notification.ago }}
                                                </span>
                                            </div>

                                            <div class="note">
                                                {{ notification.body }}
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <router-link
                                    v-if="
                                        notification.contract != null &&
                                        notification.notify_type ==
                                            'campaign_status_new'
                                    "
                                    :to="
                                        userType == 'investor'
                                            ? '/chat/' +
                                              notification.contract
                                                  .influencer_id
                                            : '/influincer-chat/' +
                                              notification.contract.investor_id
                                    "
                                >
                                    <div class="wrapper">
                                        <div class="avatar_wrapper">
                                            <img
                                                :src="notification.avatar"
                                                width="100"
                                                height="100"
                                            />
                                        </div>
                                        <div class="notification_body_wrapper">
                                            <div class="name">
                                                <span>
                                                    {{ notification.sender }}
                                                </span>

                                                <span class="time">
                                                    {{ notification.ago }}
                                                </span>
                                            </div>

                                            <div class="note">
                                                {{ notification.body }}
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <div
                                    class="actions_wrapper"
                                    v-if="
                                        (notification.notify_type ==
                                            'investor_cancel_contract' &&
                                            notification.cancel_request_id !==
                                                null) ||
                                        (notification.notify_type ==
                                            'influencer_cancel_request' &&
                                            notification.cancel_request_id !==
                                                null)
                                    "
                                >
                                    <div class="rejected_actions">
                                        <button
                                            class="btn accept_contract_btn"
                                            @click="
                                                AcceptContractCancel(
                                                    notification.cancel_request_id,
                                                    notification.id
                                                )
                                            "
                                        >
                                            {{ $t("buttons.accept") }}
                                        </button>
                                        <button
                                            class="btn reject_contract_btn"
                                            @click="
                                                RejectContractCancel(
                                                    notification.cancel_request_id,
                                                    notification.id
                                                )
                                            "
                                        >
                                            {{ $t("buttons.reject") }}
                                        </button>
                                    </div>
                                </div>
                                <router-link
                                    v-if="
                                        notification.contract == null &&
                                        notification.notify_type != 'management'
                                    "
                                    :to="
                                        userType == 'investor'
                                            ? '/contracts'
                                            : '/influincer-contract'
                                    "
                                >
                                    <div class="wrapper">
                                        <div class="avatar_wrapper">
                                            <img
                                                :src="notification.avatar"
                                                width="100"
                                                height="100"
                                            />
                                        </div>
                                        <div class="notification_body_wrapper">
                                            <div class="name">
                                                <span>
                                                    {{ notification.sender }}
                                                </span>

                                                <span class="time">
                                                    {{ notification.ago }}
                                                </span>
                                            </div>

                                            <div class="note">
                                                {{ notification.body }}
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <div
                                    v-if="
                                        notification.contract == null &&
                                        notification.notify_type == 'management'
                                    "
                                >
                                    <div class="wrapper">
                                        <div class="avatar_wrapper">
                                            <img
                                                :src="notification.avatar"
                                                width="100"
                                                height="100"
                                            />
                                        </div>
                                        <div class="notification_body_wrapper">
                                            <div class="name">
                                                <span>
                                                    {{ notification.title }}
                                                </span>

                                                <span class="time">
                                                    {{ notification.ago }}
                                                </span>
                                            </div>

                                            <div class="note">
                                                {{ notification.body }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <router-link
                  v-else-if="
                    notification.contract != null &&
                    (notification.notify_type == 'new_chat' ||
                      notification.notify_type ==
                        'campaign_status_investor_accept' ||
                      notification.notify_type ==
                        'campaign_status_investor_reject' ||
                      notification.notify_type == 'campaign_status_new')
                  "
                  :to="
                    userType == 'investor'
                      ? '/chat/' + notification.contract.influencer_id
                      : '/influincer-chat/' + notification.contract.investor_id
                  "
                >
                  <div class="wrapper">
                    <div class="avatar_wrapper">
                      <img
                        :src="notification.avatar"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div class="notification_body_wrapper">
                      <div class="name">
                        <span>
                          {{ notification.sender }}
                        </span>

                        <span class="time">
                          {{ notification.ago }}
                        </span>
                      </div>

                      <div class="note">
                        {{ notification.title }}
                      </div>
                    </div>
                  </div>
                </router-link>
                <router-link
                  v-else-if="
                    notification.contract == null &&
                    (notification.notify_type == 'new_chat' ||
                      notification.notify_type ==
                        'campaign_status_investor_accept' ||
                      notification.notify_type ==
                        'campaign_status_investor_reject' ||
                      notification.notify_type == 'campaign_status_new')
                  "
                  :to="userType == 'investor' ? '/chat' : '/influincer-chat'"
                >
                  <div class="wrapper">
                    <div class="avatar_wrapper">
                      <img
                        :src="notification.avatar"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div class="notification_body_wrapper">
                      <div class="name">
                        <span>
                          {{ notification.sender }}
                        </span>

                        <span class="time">
                          {{ notification.ago }}
                        </span>
                      </div>

                      <div class="note">
                        {{ notification.title }}
                      </div>
                    </div>
                  </div>
                </router-link> -->
                            </li>
                        </ul>
                    </section>
                    <Loader v-if="loadNotificationDots" :number="1"></Loader>
                    <!-- END:: NOTIFICATIONS LIST -->
                </div>
            </div>
            <!-- END:: NOTIFICATIONS SIDE MENU WEAPPER -->

            <!-- START:: PAYMENT SIDE MENU WRAPPER -->
            <div
                class="payment_wrapper"
                :class="{ active: $store.state.paymentMenuIsOpen }"
            >
                <div class="payment_content_wrapper">
                    <div class="title_wrapper">
                        <h3>
                            <button class="btn" @click="togglePaymentMenu">
                                <img
                                    src="../assets/media/icons/arrow_left.svg"
                                    alt="Icon"
                                    width="100"
                                    height="100"
                                />
                            </button>
                            {{ $t("buttons.payNow") }}
                        </h3>
                    </div>

                    <div class="payment_panel_wrapper">
                        <div class="payment_panel">
                            <div class="panel_title">
                                <h5>{{ $t("titles.agreedContract") }}</h5>
                            </div>

                            <div class="panel_body">
                                <p>{{ $t("titles.amount_to_be_paid") }}</p>
                                <h2>{{ $store.state.paymentCount }} $</h2>
                            </div>
                        </div>
                    </div>

                    <div class="payment_card_img_wrapper">
                        <img
                            src="../assets/media/illustrations/paymentCard.svg"
                            alt="Card Image"
                            width="150"
                            height="150"
                        />
                    </div>

                    <div class="payment_form_wrapper">
                        <form @submit.prevent="submitPaymentForm">
                            <div class="wrapper mb-4">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Name On Card"
                                    v-model="paymentData.nameOnCard"
                                />
                            </div>

                            <div class="wrapper mb-4">
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Card Number"
                                    v-model="paymentData.cardNumber"
                                />
                            </div>

                            <div class="inputs_wrapper">
                                <div class="wrapper mb-4">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Expiry Date"
                                        v-model="paymentData.expiryDate"
                                    />
                                </div>

                                <div class="wrapper mb-4">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="CVV"
                                        v-model="paymentData.cvv"
                                    />
                                </div>
                            </div>

                            <div class="wrapper justify-content-center">
                                <button class="btn">
                                    {{ $t("buttons.payNow") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingRequest"
                                    ></span>
                                </button>
                            </div>
                        </form>
                    </div>

                    <!-- START:: MODAL -->
                    <BaseModel :show="showPaymentModal">
                        <template #modal>
                            <div class="modal_successfully">
                                <div class="image_successfully">
                                    <img
                                        src="../assets/media/illustrations/locked.svg"
                                        alt="locked"
                                        width="100"
                                        height="100"
                                    />
                                </div>
                                <div class="text_successfully">
                                    <p>
                                        {{
                                            $t("validation.paymentSuccessfully")
                                        }}
                                    </p>
                                </div>
                                <form>
                                    <div class="wrapper justify-content-center">
                                        <button
                                            class="btn"
                                            type="button"
                                            @click="paymentSuccess()"
                                        >
                                            {{ $t("states.done") }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </BaseModel>
                    <!-- END:: MODAL -->

                    <!-- START:: MODAL CANCEL -->
                    <BaseModel
                        @closeModel="showCancelContracts = !showCancelContracts"
                        :show="showCancelContracts"
                    >
                        <template #modal>
                            <div class="contracts_modal_report">
                                <div class="head_modal_report">
                                    <h3>
                                        {{ $t("titles.canceling_Contract") }}
                                    </h3>
                                    <p>
                                        {{
                                            $t(
                                                "frequently_words.note_canceling_Contract"
                                            )
                                        }}
                                    </p>
                                </div>
                                <!-- START:: BODY MODAL -->
                                <div class="body_modal_report">
                                    <form>
                                        <div class="group mt-5">
                                            <div class="inner_wrapper">
                                                <div class="wrapper mb-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        value="latest"
                                                        id="sort_latest"
                                                        name="sort"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="sort_latest"
                                                    >
                                                        {{
                                                            $t(
                                                                "placeholders.untrustworthy"
                                                            )
                                                        }}
                                                    </label>
                                                </div>

                                                <div class="wrapper mb-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        value="latest"
                                                        id="sort_latest"
                                                        name="sort"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="sort_latest"
                                                    >
                                                        {{
                                                            $t(
                                                                "placeholders.personal_Circumstances"
                                                            )
                                                        }}
                                                    </label>
                                                </div>

                                                <div class="wrapper mb-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        value="latest"
                                                        id="sort_latest"
                                                        name="sort"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="sort_latest"
                                                    >
                                                        {{
                                                            $t(
                                                                "placeholders.agreement"
                                                            )
                                                        }}
                                                    </label>
                                                </div>

                                                <div class="wrapper mb-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        value="latest"
                                                        id="sort_latest"
                                                        name="sort"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="sort_latest"
                                                    >
                                                        {{
                                                            $t(
                                                                "placeholders.conditions"
                                                            )
                                                        }}
                                                    </label>
                                                </div>

                                                <div class="wrapper mb-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        value="latest"
                                                        id="sort_latest"
                                                        name="sort"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="sort_latest"
                                                    >
                                                        {{
                                                            $t(
                                                                "placeholders.campaign_Canceled"
                                                            )
                                                        }}
                                                    </label>
                                                </div>

                                                <div class="wrapper mb-3">
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        value="latest"
                                                        id="sort_latest"
                                                        name="sort"
                                                    />
                                                    <label
                                                        class="form-check-label"
                                                        for="sort_latest"
                                                    >
                                                        {{
                                                            $t(
                                                                "placeholders.change"
                                                            )
                                                        }}
                                                    </label>
                                                </div>

                                                <div class="wrapper mb-3">
                                                    <textarea
                                                        :placeholder="
                                                            $t(
                                                                'placeholders.other_reasons'
                                                            )
                                                        "
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="note">
                                            <h5>
                                                {{
                                                    $t("frequently_words.note")
                                                }}
                                            </h5>
                                            <p>
                                                {{
                                                    $t(
                                                        "frequently_words.first_note_comp"
                                                    )
                                                }}
                                                <br />
                                                {{
                                                    $t(
                                                        "frequently_words.last_note_comp"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                        <!-- START:: FORM BUTTONS GROUP -->
                                        <div class="btns_group">
                                            <div class="btn_wrapper">
                                                <button
                                                    type="button"
                                                    class="btn cancel_all_button"
                                                >
                                                    {{ $t("states.cancel") }}
                                                </button>
                                            </div>

                                            <div class="btn_wrapper">
                                                <button
                                                    class="btn send_filter_btn"
                                                    type="button"
                                                    @click="sendCancel"
                                                >
                                                    {{ $t("buttons.send") }}
                                                    <span
                                                        class="btn_loader"
                                                        v-if="isWaitingRequest"
                                                    ></span>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- END:: FORM BUTTONS GROUP -->
                                    </form>
                                </div>
                            </div>
                        </template>
                    </BaseModel>

                    <BaseModel
                        @closeModel="showAreYouSure = !showAreYouSure"
                        :show="showAreYouSure"
                    >
                        <template #modal>
                            <div class="modal_successfully">
                                <div class="text_successfully">
                                    <p>
                                        {{
                                            $t("validation.requestSuccessfull")
                                        }}
                                    </p>
                                </div>
                                <form>
                                    <!-- START:: FORM BUTTONS GROUP -->
                                    <div class="btns_group">
                                        <div class="btn_wrapper">
                                            <button
                                                type="button"
                                                class="btn cancel_all_button"
                                            >
                                                {{ $t("states.cancel") }}
                                            </button>
                                        </div>

                                        <div class="btn_wrapper">
                                            <button
                                                class="btn send_filter_btn"
                                                type="button"
                                                @click="AreYouSure"
                                            >
                                                {{ $t("buttons.yes") }}
                                                <span
                                                    class="btn_loader"
                                                    v-if="isWaitingRequest"
                                                ></span>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- END:: FORM BUTTONS GROUP -->
                                </form>
                            </div>
                        </template>
                    </BaseModel>

                    <BaseModel
                        @closeModel="showFinished = !showFinished"
                        :show="showFinished"
                    >
                        <template #modal>
                            <div class="modal_successfully">
                                <div class="image_successfully">
                                    <img
                                        src="../assets/media/illustrations/deleted.svg"
                                        alt="successfully"
                                        width="100"
                                        height="100"
                                    />
                                </div>
                                <div class="text_successfully">
                                    <p>
                                        {{ $t("validation.cancel_success") }}
                                    </p>
                                </div>
                                <form>
                                    <div class="wrapper justify-content-center">
                                        <button
                                            class="btn"
                                            type="button"
                                            @click="finished"
                                        >
                                            {{ $t("buttons.continue") }}
                                            <span
                                                class="btn_loader"
                                                v-if="isWaitingRequest"
                                            ></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </BaseModel>
                    <!-- END:: MODAL CANCEL -->
                </div>
            </div>
            <!-- END:: PAYMENT SIDE MENU WRAPPER -->
        </div>
        <!-- END:: HOME CONTENT WRAPPER -->

        <!-- START:: TRANSATIONDS HISTORY MODAL -->
        <BaseModel @closeModel="seeAllTrasactions" :show="showAllTransactions">
            <template #modal>
                <div class="modal_custom">
                    <span @click="seeAllTrasactions" class="close ignore_print">
                        <i class="fas fa-times ignore_print"></i>
                    </span>
                    <!-- START:: HEAD MOADAL -->
                    <div class="head_modal ignore_print">
                        <h3
                            class="d-flex justify-content-start align-items-start"
                        >
                            <span>
                                {{ $t("titles.transactionsHistory") }}
                            </span>

                            <button class="btn print_btn" @click="printScreen">
                                <span>
                                    <img
                                        src="../assets/media/icons/print_blue.svg"
                                        width="50"
                                        height="50"
                                        alt="icon"
                                    />
                                </span>
                            </button>

                            <button
                                class="btn print_btn"
                                @click="toggleRangeInput"
                            >
                                <span>
                                    <img
                                        src="../assets/media/icons/filter.svg"
                                        width="50"
                                        height="50"
                                        alt="icon"
                                    />
                                </span>
                            </button>
                        </h3>

                        <transition name="fade" mode="out-in">
                            <form
                                class="transactions_filter_wrapper"
                                v-if="rangeInputIsOpen"
                            >
                                <date-picker
                                    placeholder="Select Filter Date Range"
                                    v-model="transactionDateRange"
                                    value-type="format"
                                    format="YYYY-MM-DD"
                                    range
                                    @change="setWalletFilterRange"
                                >
                                </date-picker>
                            </form>
                        </transition>
                    </div>
                    <!-- END:: HEAD MODAL -->

                    <!-- START:: BODY MODAL -->
                    <LoaderCards
                        v-if="loaderTransaction"
                        :number="5"
                        class="fadeIn"
                    ></LoaderCards>
                    <div class="client_transations fadeIn" v-else>
                        <img
                            src="../assets/logo/logo.svg"
                            class="show-print logo-print"
                        />
                        <h3 class="show-print title-print">
                            <span>
                                {{ $t("titles.transactionsHistory") }}
                            </span>
                        </h3>
                        <div
                            class="client_transaction_wrapper"
                            v-for="client in $store.state.wallet_transactions.slice(
                                0,
                                5
                            )"
                            :key="client.id"
                        >
                            <div class="avatar_wrapper">
                                <img
                                    v-if="client.transferor != null"
                                    :src="client.transferor.image"
                                    alt="Client Avatar"
                                    width="100"
                                    height="100"
                                />
                                <img
                                    v-else
                                    src="../assets/logo/logo.png"
                                    alt="Client Avatar"
                                    width="100"
                                    height="100"
                                />
                            </div>

                            <div class="details_wrapper">
                                <div class="wrapper">
                                    <div class="name">
                                        <span v-if="client.transferor != null"
                                            >{{ client.transferor.fullname }}
                                        </span>
                                        <span v-else> Infclo </span>
                                        <div class="mony_amount_wrapper">
                                            ${{ client.amount }}
                                        </div>
                                    </div>

                                    <div class="fields">
                                        {{ client.contract_name }}
                                    </div>
                                </div>

                                <div class="info_wrapper">
                                    <span class="time"> {{ client.ago }}</span>
                                    <span
                                        class="status done"
                                        v-if="
                                            client.status.key != null &&
                                            client.status.key == 'transferred'
                                        "
                                    >
                                        {{ $t("titles.pay_transferred") }}
                                    </span>
                                    <span
                                        class="status done"
                                        v-if="
                                            client.status.key != null &&
                                            client.status.key == 'withdrawal'
                                        "
                                    >
                                        {{ $t("titles.pay_withdrawal") }}
                                    </span>
                                    <span
                                        class="status refuced-text"
                                        v-if="
                                            client.status.key != null &&
                                            client.status.key == 'refused'
                                        "
                                    >
                                        {{ $t("titles.pay_refused") }}
                                    </span>
                                    <span
                                        class="status processing"
                                        v-if="
                                            client.status.key != null &&
                                            client.status.key == 'pending'
                                        "
                                    >
                                        {{ $t("titles.pay_pending") }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END:: BODY MODAL -->
                </div>
            </template>
        </BaseModel>
        <!-- END:: TRANSATIONDS HISTORY MODAL -->

        <!--  START:: VIDEO MODAL  -->
        <BaseModel
            @closeModel="$store.commit('toggleIntroVideo')"
            :show="$store.state.showVideo"
        >
            <template #influincer_video>
                <div class="video_wrapper">
                    <span
                        @click="$store.commit('closeIntroVideo')"
                        class="close"
                        ><i class="fas fa-times"></i
                    ></span>
                    <VuePlyr :poster="intro_video.video.thumbnail">
                        <video
                            class="video-player"
                            :src="intro_video.video.url"
                            type="video/mp4"
                            controls
                        ></video>
                    </VuePlyr>
                </div>
            </template>
        </BaseModel>
        <!--  END:: VIDEO MODAL  -->

        <!-- START:: FOOTER -->
        <TheFooter />
        <!-- END:: FOOTER -->
    </div>
</template>

<script>
// START:: IMPORTING VIDEO PLAYER
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// END:: IMPORTING VIDEO PLAYER

// START:: IMPORTING VUE DATE  PICKER
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// END:: IMPORTING VUE DATE  PICKER

// START:: IMPORTING MAIN LAYOUTS
import TheHeader from "../components/layouts/TheHeader.vue";
import TheFooter from "../components/layouts/TheFooter.vue";
import ContentWrapper from "../components/ui/ContentWrapper.vue";
// END:: IMPORTING MAIN LAYOUTS

// START:: IMPORTING MODAL
import BaseModel from "../components/ui/BaseModel.vue";
// END:: IMPORTING MODAL

import { XIcon, EyeIcon, EyeOffIcon } from "vue-feather-icons";
import SecondEmptyMessage from "../components/emptyMessages/SecondEmptyMessage.vue";

import LoaderCards from "../components/loader/explore.vue";

import Loader from "./../components/loader/chat.vue";
import NoMatchedData from "../components/emptyMessages/NoMatchedData.vue";

// import AvatarName from "../components/ui/AvatarName.vue";
export default {
    name: "Home",

    components: {
        TheHeader,
        TheFooter,
        ContentWrapper,
        BaseModel,
        XIcon,
        EyeIcon,
        EyeOffIcon,
        SecondEmptyMessage,
        LoaderCards,
        Loader,
        NoMatchedData,
        VuePlyr,
        DatePicker,
        // AvatarName
    },

    data() {
        return {
            tab: null,

            // START:: VIDEO STATUS DATA
            video_status: localStorage.getItem("video_is_played"),
            // END:: VIDEO STATUS DATA

            // START:: DATE PICKER DATA
            transactionDateRange: null,
            from_date: null,
            to_date: null,
            // END:: DATE PICKER DATA

            // START:: RANGE INPUT HANDLING DATA
            rangeInputIsOpen: false,
            // END: RANGE INPUT HANDLING DATA

            showVideo: false,
            loading: true,
            intro_video: {
                video: "",
                videoSeenStatue: null,
                thumbnail: null,
            },

            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: SHOW PASSWORD HANDLING DATA
            passwordIsVisible: false,
            passwordIsVisiblePending: false,
            // END:: SHOW PASSWORD HANDLING DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: USER TOKEN DATA
            userToken: localStorage.getItem("inflco_user_token"),
            // END:: USER TOKEN DATA

            // START:: USER ACTIVE STATUS DATA
            userIsActive: localStorage.getItem("inflco_user_is_active"),
            // END:: USER ACTIVE STATUS DATA

            // START:: WITHDRAW DATA
            withdrawType: null,

            withdrawtData: {
                bankName: "",
                BankAddress: "",
                IbanNumber: "",
                accountName: "",
                accountNumber: "",
                amount: "",
            },

            paypalWithdrawtData: {
                accountType: null,
                paypalIdentifier: null,
                amount: null,
            },
            // END:: WITHDRAW DATA

            // START:: REFUND REQUEST DATA
            refundReduestData: {
                bankName: "",
                BankAddress: "",
                IbanNumber: "",
                accountName: "",
                accountNumber: "",
                amount: "",
            },
            // END:: REFUND REQUEST DATA

            // START:: PAYMENT DATA
            paymentData: {
                nameOnCard: "",
                cardNumber: "",
                expiryDate: "",
                cvv: "",
            },
            // END:: PAYMENT DATA

            // START:: MODAL HANDLING DATA
            show: false,
            showPaymentModal: false,
            showCancelContracts: false,
            showAreYouSure: false,
            showSuccessCancel: false,
            showFinished: false,
            // END:: MODAL HANDLING DATA

            // START:: CLIENTS RATE DATA
            showAllTransactions: false,
            // END:: CLIENTS RATE DATA

            // START:: RESIZE
            reSize: false,
            // END:: RESIZE
            //START:: WALLET
            myWallet: "",
            loaderTransaction: false,
            //END:: WALLET
            loadNotificationDots: false,
        };
    },
    computed: {
        // START:: IS REGISTER DATA IS EMPTY COMPUTED PROP
        withDrawIsEmpty() {
            return (
                !this.withdrawtData.bankName ||
                !this.withdrawtData.IbanNumber ||
                !this.withdrawtData.BankAddress ||
                !this.withdrawtData.accountName ||
                !this.withdrawtData.accountNumber ||
                !this.withdrawtData.amount
            );
        },
        paypalWithDrawIsEmpty() {
            return (
                !this.paypalWithdrawtData.accountType ||
                !this.paypalWithdrawtData.paypalIdentifier ||
                !this.paypalWithdrawtData.amount
            );
        },
        withRefundIsEmpty() {
            return (
                !this.refundReduestData.bankName ||
                !this.refundReduestData.IbanNumber ||
                !this.refundReduestData.BankAddress ||
                !this.refundReduestData.accountName ||
                !this.refundReduestData.accountNumber ||
                !this.refundReduestData.amount
            );
        },
        // END:: IS REGISTER DATA IS EMPTY COMPUTED PROP
    },
    methods: {
        // START:: CLOSE VIDEO MODAL METHOD
        closeVideoModal() {
            this.showVideo = false;
            localStorage.setItem("video_is_played", "played");
        },
        // END:: CLOSE VIDEO MODAL METHOD

        //   START:: TOGGLE INVESTOR PROFILE
        toggleInvestorProfileSideMenu() {
            this.$store.dispatch("toggleInvestorProfileSideMenu");
        },
        //   END:: TOGGLE INVESTOR PROFILE

        // START:: TOGGLE RANGE INPUT
        toggleRangeInput() {
            this.rangeInputIsOpen = !this.rangeInputIsOpen;
        },
        // END:: TOGGLE RANGE INPUT

        // START:: FORMAT DATE RANGE
        setWalletFilterRange() {
            this.$store.commit("setWalletFilterRange", {
                dateRange: this.transactionDateRange,
            });
            this.$store.dispatch("walletData");
        },
        // END:: FORMAT DATE RANGE

        // START:: PRINT SCREEN
        printScreen() {
            window.print();
        },
        // END:: PRINT SCREEN

        // START:: GET VIDEOS METHODS
        getVideos() {
            this.$axios
                .get("settings/intro_video", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.intro_video.videoSeenStatue =
                        res.data.is_seen_intro_video;
                    if (this.intro_video.videoSeenStatue == false) {
                        setTimeout(() => {
                            this.$store.state.showVideo = true;
                        }, 2000);
                    }
                    if (this.userType == "investor") {
                        this.intro_video.video =
                            res.data.data.investor_intro_video;
                    } else if (
                        this.intro_video.videoSeenStatue == false &&
                        this.userType == "influincer"
                    ) {
                        this.intro_video.video =
                            res.data.data.infleuncer_intro_video;
                    } else if (
                        this.intro_video.videoSeenStatue == true &&
                        this.userType == "influincer"
                    ) {
                        this.intro_video.video =
                            res.data.data.infleuncer_about_video;
                    }
                });
        },
        // END: GET VIDEOS METHODS

        // START:: CHECK AUTH STATUS
        checkAuthStatus() {
            if (!this.userToken || this.userIsActive == "false") {
                this.$router.replace("/login");
            }

            localStorage.removeItem("inflco_verification_code");
        },
        // END:: CHECK AUTH STATUS

        // START:: TOGGLE WALLET MENU
        toggleWalletMenu() {
            this.$store.dispatch("toggleWalletMenu");
        },
        // END:: TOGGLE WALLET MENU

        // START:: TOGGLE REFUND REQUEST MENU
        toggleRefundRequestMenu() {
            this.$store.dispatch("toggleRefundRequestMenu");
        },
        // END:: TOGGLE REFUND REQUEST MENU

        // START:: TOGGLE WITHDRAW MENU
        toggleWithdrawMenu() {
            this.$store.dispatch("toggleWithdrawMenu");
        },
        togglePaypalWithdrawMenu() {
            this.$store.dispatch("togglePaypalWithdrawMenu");
        },
        // END:: TOGGLE WITHDRAW MENU

        // START:: TOGGLE PASSWORD VISIBILITY
        togglePasswordVisibility(id) {
            // TOGGLE TYPE
            let targetElement = document.getElementById(id);
            if (targetElement.type == "password") {
                targetElement.type = "text";
            } else if (targetElement.type == "text") {
                targetElement.type = "password";
            }

            // TOGGLE ICON
            if (id == "password") {
                this.passwordIsVisible = !this.passwordIsVisible;
            }
            if (id == "password_pending") {
                this.passwordIsVisiblePending = !this.passwordIsVisiblePending;
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY

        // START:: SET WITHDRAW TYPE
        setWithdrawType(selected_type) {
            this.withdrawType = selected_type;
        },
        // END:: SET WITHDRAW TYPE

        // START:: SUBMIT REFUND REQUEST FORM
        submitreWithdraw() {
            this.isWaitingRequest = true;

            // START:: SUBMIT BANK WITHDRAW FORM
            if (this.withdrawType == "bank") {
                // ===================END:: RESET REFUND REQUEST DATA ===================
                if (this.withdrawtData.amount < this.$store.state.min_refund) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.minWithDraw") +
                            this.$store.state.min_refund,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.withdrawtData.amount > this.$store.state.max_refund &&
                    this.$store.state.max_refund <
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.max_refund,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.withdrawtData.amount > this.$store.state.max_refund &&
                    this.$store.state.max_refund >
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.walletDetails,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.withdrawtData.amount >
                        this.$store.state.walletDetails &&
                    this.$store.state.max_refund >
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.walletDetails,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.withdrawtData.amount < this.$store.state.min_refund &&
                    this.$store.state.max_refund <
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.max_refund,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.withdrawtData.amount < this.$store.state.min_refund &&
                    this.$store.state.max_refund >
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.walletDetails,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else {
                    const data = new FormData();
                    data.append("type", this.withdrawType);
                    data.append("bank_name", this.withdrawtData.bankName);
                    data.append("bank_address", this.withdrawtData.BankAddress);
                    data.append("account_name", this.withdrawtData.accountName);
                    data.append(
                        "account_number",
                        this.withdrawtData.accountNumber
                    );
                    data.append("iban", this.withdrawtData.IbanNumber);
                    data.append("amount", this.withdrawtData.amount);
                    this.$axios
                        .post("bank_accounts", data, {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                                "Content-type": `multipart/form-data;`,
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        })
                        .then(() => {
                            this.isWaitingRequest = false;
                            this.$iziToast.success({
                                timeout: 5000,
                                message: this.$t("validation.requestSent"),
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                            this.$store.dispatch("toggleWithdrawMenu");
                            this.withdrawType = null;
                            this.withdrawtData.bankName = "";
                            this.withdrawtData.IbanNumber = "";
                            this.withdrawtData.BankAddress = "";
                            this.withdrawtData.accountName = "";
                            this.withdrawtData.accountNumber = "";
                            this.this.withdrawtData.amount = "";

                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        })
                        .catch(() => {
                            this.isWaitingRequest = false;
                            // this.$iziToast.error({
                            //     timeout: 5000,
                            //     message: error.response.data.status,
                            //     position: this.$t("position"),
                            //     rtl: this.$t("dir"),
                            // });
                        });
                }
                // =================== END:: SUBMIT BANK WITHDRAW FORM ===================
            } else if (this.withdrawType == "paypal") {
                if (
                    this.paypalWithdrawtData.amount <
                    this.$store.state.min_refund
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.minWithDraw") +
                            this.$store.state.min_refund,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.paypalWithdrawtData.amount >
                        this.$store.state.max_refund &&
                    this.$store.state.max_refund <
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.max_refund,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.paypalWithdrawtData.amount >
                        this.$store.state.max_refund &&
                    this.$store.state.max_refund >
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.walletDetails,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.paypalWithdrawtData.amount >
                        this.$store.state.walletDetails &&
                    this.$store.state.max_refund >
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.walletDetails,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.paypalWithdrawtData.amount <
                        this.$store.state.min_refund &&
                    this.$store.state.max_refund <
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.max_refund,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else if (
                    this.paypalWithdrawtData.amount <
                        this.$store.state.min_refund &&
                    this.$store.state.max_refund >
                        this.$store.state.walletDetails
                ) {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.validateWithDraw") +
                            this.$store.state.min_refund +
                            " - " +
                            this.$store.state.walletDetails,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                } else {
                    const data = new FormData();
                    data.append("type", this.withdrawType);
                    data.append(
                        "paypal_type",
                        this.paypalWithdrawtData.accountType
                    );
                    data.append(
                        "paypal_identifier",
                        this.paypalWithdrawtData.paypalIdentifier
                    );
                    data.append("amount", this.paypalWithdrawtData.amount);
                    this.$axios
                        .post("bank_accounts", data, {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "cache-control": "no-cache",
                                Accept: "application/json",
                                "Content-type": `multipart/form-data;`,
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        })
                        .then(() => {
                            this.isWaitingRequest = false;
                            this.$iziToast.success({
                                timeout: 5000,
                                message: this.$t("validation.requestSent"),
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                            this.$store.dispatch("togglePaypalWithdrawMenu");
                            this.withdrawType = null;
                            this.paypalWithdrawtData.accountType = null;
                            this.paypalWithdrawtData.paypalIdentifier = null;
                            this.paypalWithdrawtData.amount = null;

                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        })
                        .catch(() => {
                            this.isWaitingRequest = false;
                            // this.$iziToast.error({
                            //     timeout: 5000,
                            //     message: error.response.data.status,
                            //     position: this.$t("position"),
                            //     rtl: this.$t("dir"),
                            // });
                        });
                }
            }
        },
        // END:: SUBMIT REFUND REQUEST FORM

        // START:: SUBMIT REFUND REQUEST FORM
        submitrefundrequest() {
            this.isWaitingRequest = true;
            // END:: RESET REFUND REQUEST DATA
            const data = new FormData();
            data.append("bank_name", this.refundReduestData.bankName);
            data.append("bank_address", this.refundReduestData.BankAddress);
            data.append("account_name", this.refundReduestData.accountName);
            data.append("account_number", this.refundReduestData.accountNumber);
            data.append("iban", this.refundReduestData.IbanNumber);
            data.append("amount", this.refundReduestData.amount);
            this.$axios
                .post("bank_accounts", data, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Content-type": `multipart/form-data;`,
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.$store.dispatch("toggleRefundRequestMenu");
                    this.refundReduestData.bankName = "";
                    this.refundReduestData.IbanNumber = "";
                    this.refundReduestData.BankAddress = "";
                    this.refundReduestData.accountName = "";
                    this.refundReduestData.accountNumber = "";
                    this.refundReduestData.amount = "";
                    this.$iziToast.success({
                        timeout: 5000,
                        message: res.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                })
                .catch((error) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.status,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                });
        },
        // END:: SUBMIT REFUND REQUEST FORM

        // START:: TOGGLE NOTIFICATIONS MENU
        closeNotification() {
            this.$store.state.notificationsMenuIsOpen = false;
        },
        // END:: TOGGLE NOTIFICATIONS MENU

        // START:: TOGGLE PAYMENT MENU
        togglePaymentMenu() {
            this.$store.dispatch("togglePaymentMenu");
        },
        // END:: TOGGLE PAYMENT MENU

        // START:: SUBMIT PAYMENT FORM
        submitPaymentForm() {
            this.isWaitingRequest = true;
            const data = new FormData();
            data.append("id", this.$store.state.paymentId);
            data.append("payment_status", "accepted");
            this.$axios
                .post("investor/contract/pay", data, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Content-type": `multipart/form-data;`,
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then(() => {
                    this.isWaitingRequest = false;
                    this.showPaymentModal = !this.showPaymentModal;
                    this.$store.dispatch("togglePaymentMenu");
                })
                .catch(() => {
                    this.isWaitingRequest = false;
                });
        },
        // END:: SUBMIT PAYMENT FORM

        // START:: MODAL CANCL
        sendCancel() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showCancelContracts = !this.showCancelContracts;
                this.showAreYouSure = !this.showAreYouSure;
            }, 1500);
        },

        AreYouSure() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showAreYouSure = !this.showAreYouSure;
                this.showFinished = true;
            }, 1500);
        },

        finished() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.showFinished = false;
            }, 1500);
        },
        // END:: MODAL CANCL

        onSize() {
            let innerWidth = window.innerWidth;
            if (innerWidth < 1400) {
                this.reSize = true;
            }
        },

        // START:: HANDLE MEDIA QUERIES
        handleMediaueries() {
            if (window.matchMedia("(max-width: 767px)").matches) {
                // START:: HANDLING INVESTOR MEDIA QUIRIES
                if (this.userType == "investor") {
                    if (this.$route.path == "/home") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (this.$route.path == "/filter") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (this.$route.path == "/explore") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (this.$route.path == "/search-result") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/profile/:influincer_id/influencer"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (
                        this.$route.path == "/editProfile" ||
                        this.$route.path == "/editProfile/updatePassword"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (this.$route.path == "/chats") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/chat/:influincer_id/:payment_status?"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (this.$route.path == "/contracts") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/contract/:id"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/create-contract/:influincer_id/:chat_id"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    }
                }
                // END:: HANDLING INVESTOR MEDIA QUIRIES

                // START:: HANDLING INFLUINCER MEDIA QUIRIES
                if (this.userType == "influincer") {
                    if (this.$route.path == "/influincer-home") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (this.$route.path == "/personal-profile") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (this.$route.path == "/edit-influincer-profile") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (
                        this.$route.path == "/update-influincer-password"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (this.$route.path == "/influincer-chats") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/influincer-chat/:influincer_id/:payment_status?"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/view-created-chat/:id"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (this.$route.path == "/influincer-contracts") {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "block";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "none";
                    } else if (
                        this.$router.history.current.matched[1].path ==
                        "/influincer-contract/:id"
                    ) {
                        document.getElementById(
                            "small_content_wrapper"
                        ).style.display = "none";
                        document.getElementById(
                            "large_content_wrapper"
                        ).style.display = "block";
                    }
                }
                // END:: HANDLING INFLUINCER MEDIA QUIRIES
            }
        },
        // END:: HANDLE MEDIA QUERIES

        // get wallet

        // START:: GET MY WALLET DATA & TRANSFOROR
        paymentSuccess() {
            this.showPaymentModal = !this.showPaymentModal;
            location.reload();
        },
        seeAllTrasactions() {
            this.showAllTransactions = !this.showAllTransactions;
            this.$store.commit("setWalletFilterRange", { dateRange: null });
            this.$store.dispatch("walletData");
        },
        // END:: GET MY WALLET DATA & TRANSFOROR
        AcceptContractCancel(id, notification_id) {
            if (this.userType == "influincer") {
                this.$axios
                    .get(
                        "influencer/contract/cancel_request_approve/" +
                            id +
                            "/" +
                            notification_id,
                        {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "Content-type": "application/json",
                                "cache-control": "no-cache",
                                Accept: "application/json",
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        }
                    )
                    .then(() => {
                        location.reload();
                    });
            } else {
                this.$axios
                    .get(
                        "investor/contract/cancel_request_approve/" +
                            id +
                            "/" +
                            notification_id,
                        {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "Content-type": "application/json",
                                "cache-control": "no-cache",
                                Accept: "application/json",
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        }
                    )
                    .then(() => {
                        location.reload();
                    });
            }
        },
        RejectContractCancel(id, notification_id) {
            if (this.userType == "influincer") {
                this.$axios
                    .get(
                        "influencer/contract/cancel_request_reject/" +
                            id +
                            "/" +
                            notification_id,
                        {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "Content-type": "application/json",
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(() => {
                        location.reload();
                    });
            } else {
                this.$axios
                    .get(
                        "investor/contract/cancel_request_reject/" +
                            id +
                            "/" +
                            notification_id,
                        {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "Content-type": "application/json",
                                "cache-control": "no-cache",
                                Accept: "application/json",
                            },
                        }
                    )
                    .then(() => {
                        location.reload();
                    });
            }
        },
        scrollSection() {
            if (
                this.$store.state.currentPage_of_pagenation !=
                this.$store.state.last_page_of_pagenation
            ) {
                var chatSection = document.querySelector(
                    ".notifications_content_wrapper"
                );
                if (
                    chatSection.offsetHeight + chatSection.scrollTop >=
                    chatSection.scrollHeight
                ) {
                    this.$store.state.currentPage_of_pagenation += 1;
                    this.getNotificationScroll();
                }
            }
        },
        getNotificationScroll() {
            this.loadNotificationDots = true;
            this.$axios
                .get(
                    "user/notifications?page=" +
                        this.$store.state.currentPage_of_pagenation,
                    {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Content-type": `application/json`,
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    }
                )
                .then((res) => {
                    res.data.data.notifications.forEach((element) => {
                        this.$store.state.notificationsData.push(element);
                    });
                    this.$store.state.last_page_of_pagenation =
                        res.data.meta.last_page;
                    this.loadNotificationDots = false;
                });
        },
        sendLang() {
            const data = new FormData();
            data.append(
                "current_locale",
                localStorage.getItem("inflco_app_lang")
            );
            this.$axios.post("update_locale", data, {
                headers: {
                    Authorization:
                        "bearer" + localStorage.getItem("inflco_user_token"),
                    "cache-control": "no-cache",
                    Accept: "application/json",
                    "Content-type": `multipart/form-data;`,
                    "Accept-language": localStorage.getItem("inflco_app_lang"),
                },
            });
        },
        getInfluincerWallet(status) {
            this.$store.state.wallet_status = status;
            this.$store.dispatch("walletData");
        },
    },

    mounted() {
        // START:: VIDEO STATUS DATA
        // if (
        //     !localStorage.getItem("video_is_played") ||
        //     localStorage.getItem("video_is_played") == "not_played"
        // ) {
        //     localStorage.setItem("video_is_played", "not_played");
        //     // this.video_status = localStorage.getItem("video_is_played");
        // } else if (localStorage.getItem("video_is_played") == "played") {
        //     // localStorage.setItem("video_is_played", "played");
        //     this.video_status = localStorage.getItem("video_is_played");
        // }
        // END:: VIDEO STATUS DATA

        // START:: SHOW VIDEO MODAL
        this.getVideos();

        // END:: SHOW VIDEO MODAL

        // START:: CHECK AUTH STATUS
        this.checkAuthStatus();
        // END:: CHECK AUTH STATUS

        // START:: RESIZE CONTENT WRAPPERS
        this.onSize();
        // END:: RESIZE CONTENT WRAPPERS

        // START:: MEDIA QUERIES
        this.handleMediaueries();
        // END:: MEDIA QUERIES

        // setTimeout(() => {
        //     console.log( "Refund Max ==>", this.$store.state.max_refund );
        //     console.log( "Refund Min ==>", this.$store.state.min_refund );
        // }, 1000);

        if (localStorage.getItem("inflco_user_token")) {
            this.$store.dispatch("getNotifications");
            this.$store.dispatch("walletData");
            this.sendLang();
        }

        // if (this.userType == "investor") {
        //     this.$store.dispatch("getHomeExplore");
        // }
    },
};
</script>

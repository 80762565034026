<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="explore_influincers_wrapper">
        <div class="title_wrapper">
            <h3>{{ $t("titles.explore") }}</h3>
            <div class="btn_wrapper explore">
                <router-link to="/explore">
                    <img
                        src="../../assets/media/icons/filter.svg"
                        alt="Icon"
                        width="100"
                        height="100"
                    />
                </router-link>
            </div>

            <div class="btn_wrapper filter">
                <router-link to="/filter">
                    <img
                        src="../../assets/media/icons/filter.svg"
                        alt="Icon"
                        width="100"
                        height="100"
                    />
                </router-link>
            </div>
        </div>
        <Loader
            v-if="$store.state.loaderCards"
            :number="6"
            class="fadeIn"
        ></Loader>
        <section v-else class="fadeIn">
            <NoMatchedData
                v-if="$store.state.filteredInfluincers.length == 0"
                :noDataTitlte="$t('titles.no_matched_data')"
            />
            <div class="explore_influincers_body_content" v-else>
                <div
                    class="influincer_data_wrapper"
                    v-for="influincer in $store.state.filteredInfluincers"
                    :key="influincer.id"
                >
                    <router-link
                        :to="{
                            name: 'InfluincerProfile',
                            params: { influincer_id: influincer.id },
                        }"
                        class="profile_route"
                    >
                        <div class="avatar_wrapper">
                            <img
                                :src="influincer.image"
                                alt="Influincer Avatar"
                                width="100"
                                height="100"
                            />
                        </div>

                        <div class="details_wrappe">
                            <div class="name">
                                <span> {{ influincer.fullname }} </span>
                                <span>
                                    <i class="fas fa-star"></i>
                                    {{ influincer.rate }}
                                </span>
                            </div>

                            <div class="fields">
                                <span
                                    v-for="field in influincer.social_areas"
                                    :key="field.id"
                                >
                                    {{ field.name }}
                                </span>
                            </div>
                        </div>
                    </router-link>

                    <router-link
                        :to="{
                            name: 'TheChat',
                            params: { influincer_id: influincer.id },
                        }"
                        class="influincer_chat_route"
                    >
                        <img
                            src="../../assets/media/icons/chat_blue.svg"
                            alt="Icon"
                            width="100"
                            height="100"
                        />
                    </router-link>
                </div>
            </div>
            <!-- pagentaion  -->
            <div class="pagenation" v-if="$store.state.last_filter_page > 1">
                <ul>
                    <li
                        v-for="index in $store.state.last_filter_page"
                        :key="index"
                        v-if="
                            Math.abs($store.state.currentFilterPage - index) <
                                4 ||
                            index == $store.state.last_filter_page ||
                            index == 0
                        "
                        :class="{
                            last:
                                index == $store.state.last_filter_page &&
                                Math.abs(
                                    $store.state.currentFilterPage - index
                                ) > 4,
                        }"
                    >
                        <button
                            type="button"
                            @click="pagenationFilter(index)"
                            :class="{
                                active: index == $store.state.currentFilterPage,
                                last:
                                    $store.state.last_filter_page == index &&
                                    Math.abs(
                                        $store.state.currentFilterPage - index
                                    ) > 4,
                            }"
                        >
                            {{ index }}
                        </button>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import NoMatchedData from "../emptyMessages/NoMatchedData.vue";
import Loader from "./../loader/explore.vue";
export default {
    name: "ExploreInfluincers",
    data() {
        return {
            // START::
            influincerName: this.$route.params.name,
            // END::
        };
    },
    methods: {
        pagenationFilter(pageFilterNumber) {
            this.$store.dispatch("pagenationFilter", {
                pageFilterNumber: pageFilterNumber,
            });
        },
    },
    components: {
        NoMatchedData,
        Loader,
    },
    mounted() {},
};
</script>

<template>
    <div class="login_form_wrapper" v-if="this.$store.state.selectedCountry">
        <div class="login_header_wrapper">
            <div class="logo_wrapper">
                <router-link to="/">
                    <img
                        src="../../assets/logo/small_logo.svg"
                        alt="Inflico Logo"
                        width="100"
                        height="100"
                    />
                </router-link>
            </div>

            <div class="title_wrapper">
                <h2>{{ $t("auth.login") }}</h2>
            </div>

            <div class="subtitle_wrapper">
                <h5>
                    {{ $t("titles.enter_your_email") }}
                </h5>
            </div>
        </div>
        <div class="login_body_wrapper">
            <form @submit.prevent="validateLogin">
                <!-- START:: PHONE INPUT -->
                <div class="outer_wrapper">
                    <div class="wrapper mb-3" @click="toggleCountryKeysMenu">
                        <div class="flag_wrapper">
                            <img
                                :src="this.$store.state.selectedCountry.flag"
                            />
                        </div>
                        <div class="key_wrapper">
                            {{ this.$store.state.selectedCountry.phonecode }}
                        </div>

                        <transition mode="out-in" name="fade">
                            <div
                                class="country_keys_menu_wrapper"
                                v-if="countryKiesMenuIsOpen"
                            >
                                <ul class="country_keys_menu">
                                    <li
                                        class="menu_item"
                                        v-for="item in $store.state
                                            .allCountries"
                                        :key="item.id"
                                        @click="selectCountry(item)"
                                    >
                                        <div class="flag_wrapper">
                                            <img :src="item.flag" />
                                        </div>
                                        <div class="key_wrapper">
                                            {{ item.phonecode }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </transition>
                    </div>

                    <div class="wrapper mb-3">
                        <input
                            type="tel"
                            class="form-control"
                            :placeholder="$t('placeholders.phone')"
                            v-model="loginData.phone"
                            @focus="countryKiesMenuIsOpen = false"
                        />
                    </div>
                </div>
                <!-- END:: PHONE INPUT -->

                <!-- START:: PASSWORD INPUT -->
                <div class="wrapper password_wrapper mb-3">
                    <input
                        id="password"
                        type="password"
                        class="form-control"
                        :placeholder="$t('placeholders.password')"
                        v-model="loginData.password"
                    />
                    <div class="icon_wrapper">
                        <button
                            type="button"
                            v-if="passwordIsVisible"
                            @click="togglePasswordVisibility('password')"
                        >
                            <EyeOffIcon />
                        </button>
                        <button
                            type="button"
                            v-else
                            @click="togglePasswordVisibility('password')"
                        >
                            <EyeIcon />
                        </button>
                    </div>
                </div>
                <!-- END:: PASSWORD INPUT -->

                <!-- START:: FORGET PASSWORD ROUTE -->
                <div class="wrapper mb-3">
                    <div class="route_wrapper">
                        <router-link to="/reset-password">
                            {{ $t("auth.forgot_password") }}
                        </router-link>
                    </div>
                </div>
                <!-- END:: FORGET PASSWORD ROUTE -->

                <!-- START:: SUBMIT BUTTON -->
                <div class="wrapper justify-content-center">
                    <button class="btn">
                        {{ $t("auth.login") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON -->
            </form>
        </div>
    </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "vue-feather-icons";

export default {
    name: "Login",

    components: {
        EyeIcon,
        EyeOffIcon,
    },

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: COUNTRIES KYES MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KYES MENU HANDLING DATA

            // START:: SHOW PASSWORD HANDLING DATA
            passwordIsVisible: false,
            // END:: SHOW PASSWORD HANDLING DATA

            // START:: LOGIN DATA
            loginData: {
                phone: null,
                phone_key: null,
                password: null,
                device_token: "123456789",
                type: "ios",
            },
            // END:: LOGIN DATA
        };
    },

    methods: {
        async userLocation() {
            const {
                data: { loc },
            } = await this.$axios.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                {
                    responseType: "text",
                    transformResponse: (data) =>
                        Object.fromEntries(
                            data
                                .trim()
                                .split("\n")
                                .map((line) => line.split("="))
                        ),
                }
            );

            this.$store.state.currentLocation = loc;
            this.$store.dispatch("getCountries");
        },
        // START:: TOGGLE COUNTRIES KYES MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KYES MENU

        // START:: SELECT COUNTRY
        selectCountry(item) {
            this.$store.dispatch("selectCountry", { item });
        },
        // END:: SELECT COUNTRY

        // START:: TOGGLE PASSWORD VISIBILITY
        togglePasswordVisibility(id) {
            // TOGGLE TYPE
            let targetElement = document.getElementById(id);
            if (targetElement.type == "password") {
                targetElement.type = "text";
            } else if (targetElement.type == "text") {
                targetElement.type = "password";
            }

            // TOGGLE ICON
            if (id == "password") {
                this.passwordIsVisible = !this.passwordIsVisible;
            } else {
                this.confirmPasswordIsVisible = !this.confirmPasswordIsVisible;
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY

        // START:: SUBMIT LOGIN DATA
        validateLogin() {
            this.isWaitingRequest = true;

            // START:: SET COUNTRY KEY VALUE
            this.loginData.phone_key =
                this.$store.state.selectedCountry.phonecode;
            // END:: SET COUNTRY KEY VALUE

            // START:: FRONT-END VALIDATION
            if (this.loginData.phone == null || this.loginData.phone == "") {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.phoneValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (
                (this.loginData.phone.length !=
                this.$store.state.phoneDigitNum)
            ) {
                if (this.loginData.phone[0] == "0" ) {
                    let numberArray = [...this.loginData.phone];
                    numberArray.splice(0, 1);
                    this.loginData.phone = numberArray.join('');
                    this.submitAfterValidation();
                } else {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.phoneValidationLength") +
                            " " +
                            this.$store.state.phoneDigitNum +
                            " " +
                            this.$t("validation.digit"),
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.buttonIsDisabled = false;
                    this.isWaitingRequest = false;
                    return;
                }
            } else if (
                this.loginData.password == null ||
                this.loginData.password == ""
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidation"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (this.loginData.password.length < 6) {
                this.$iziToast.error({
                    timeout: 5000,
                    message: this.$t("validation.passwordValidationDigets"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.isWaitingRequest = false;
                return;
            } else if (
                this.loginData.phone.length != this.$store.state.phoneDigitNum
            ) {
                this.$iziToast.error({
                    timeout: 5000,
                    message:
                        this.$t("validation.phoneValidationLength") +
                        " " +
                        this.$store.state.phoneDigitNum +
                        " " +
                        this.$t("validation.digit"),
                    position: this.$t("position"),
                    rtl: this.$t("dir"),
                });
                this.buttonIsDisabled = false;
                this.isWaitingRequest = false;
                return;
            }
            // END:: FRONT-END VALIDATION
            // START:: SEND DATA
            else {
                this.submitAfterValidation();
            }

            // END:: SEND DATA
        },

        submitAfterValidation() {
            this.$axios
                    .post("auth/login", this.loginData)
                    .then((res) => {
                        localStorage.setItem(
                            "inflco_verification_code",
                            res.data.dev_message
                        );

                        if (res.data.data.is_admin_accept_data == true) {
                            if (res.data.data.is_active == false) {
                                localStorage.setItem(
                                    "inflco_user_is_active",
                                    res.data.data.is_active
                                );
                                if (res.data.data.user_type == "investor") {
                                    this.$router.replace(
                                        "/verification/registeration"
                                    );
                                } else if (
                                    res.data.data.user_type == "influencer"
                                ) {
                                    this.$router.replace(
                                        "/verification/success-verification-link"
                                    );
                                }
                                this.$iziToast.error({
                                    timeout: 5000,
                                    message: this.$t(
                                        "validation.active_your_account"
                                    ),
                                    position: this.$t("position"),
                                    rtl: this.$t("dir"),
                                });
                                this.isWaitingRequest = false;
                                return;
                            } else if (res.data.data.is_active == true) {
                                localStorage.setItem(
                                    "inflco_user_is_active",
                                    res.data.data.is_active
                                );
                            }

                            if (res.data.status == "success") {
                                this.$iziToast.success({
                                    timeout: 5000,
                                    message: this.$t(
                                        "validation.loggedin_success"
                                    ),
                                    position: this.$t("position"),
                                    rtl: this.$t("dir"),
                                });
                            }

                            if (res.data.data.user_type == "investor") {
                                localStorage.setItem(
                                    "inflco_user_type",
                                    "investor"
                                );
                                localStorage.setItem(
                                    "inflco_user_token",
                                    res.data.data.token.access_token
                                );
                                localStorage.setItem(
                                    "inflco_user_phone",
                                    this.loginData.phone_key +
                                        this.loginData.phone
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_key",
                                    this.loginData.phone_key
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_number",
                                    this.loginData.phone
                                );
                                localStorage.setItem(
                                    "inflco_user_id",
                                    res.data.data.id
                                );
                                setTimeout(() => {
                                    this.isWaitingRequest = false;
                                    location.reload();
                                }, 1000);
                            } else if (
                                res.data.data.user_type == "influencer"
                            ) {
                                localStorage.setItem(
                                    "inflco_user_type",
                                    "influincer"
                                );
                                localStorage.setItem(
                                    "inflco_user_token",
                                    res.data.data.token.access_token
                                );
                                localStorage.setItem(
                                    "inflco_user_phone",
                                    this.loginData.phone_key +
                                        this.loginData.phone
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_key",
                                    this.loginData.phone_key
                                );
                                localStorage.setItem(
                                    "inflco_user_phone_number",
                                    this.loginData.phone
                                );
                                localStorage.setItem(
                                    "inflco_user_name",
                                    res.data.data.username
                                );
                                localStorage.setItem(
                                    "inflco_user_id",
                                    res.data.data.id
                                );
                                setTimeout(() => {
                                    this.isWaitingRequest = false;
                                    location.reload();
                                }, 1000);
                            }
                            localStorage.setItem("inflco_user_login", "true");
                        } else {
                            this.$iziToast.warning({
                                timeout: 5000,
                                message: res.data.message,
                                position: this.$t("position"),
                                rtl: this.$t("dir"),
                            });
                            this.isWaitingRequest = false;
                        }
                    })
                    .catch((error) => {
                        this.$iziToast.error({
                            timeout: 5000,
                            message: error.response.data.message,
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                        this.isWaitingRequest = false;
                    });
        },
        // END:: SUBMIT LOGIN DATA
    },
    created() {
        // ======== START:: HANDLING API ========
        // START:: AXIOS GET COUNTRIES

        this.userLocation();
        // END:: AXIOS GET COUNTRIES
        // ======== END:: HANDLING API ========
    },
};
</script>

<template>
    <header>
        <div class="logo_wrapper">
            <router-link to="/">
                <img
                    src="../../assets/logo/logo.svg"
                    alt="Inflco Logo"
                    width="150"
                    height="100"
                />
            </router-link>
        </div>

        <div
            class="header_actions_list_wrapper"
            v-if="userToken && userIsActive == 'true'"
        >
            <ul class="actions_list">
                <!-- START:: SEARCH BUTTON -->
                <li
                    class="actions_list_item"
                    v-if="
                        !routePath.includes('contact') &&
                        !routePath.includes('terms') &&
                        !routePath.includes('about') &&
                        !routePath.includes('policy') &&
                        !routePath.includes('blog') &&
                        $route.path != '/' &&
                        userType == 'investor'
                    "
                >
                    <button
                        class="btn hover-icons"
                        type="button"
                        @click="openSearchBar"
                    >
                        <img
                            src="../../assets/media/icons/search.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <span> {{ $t("titles.search") }} </span>
                    </button>

                    <div
                        class="search_bar"
                        :class="{ active: searchBarIsOpen }"
                    >
                        <form @submit.prevent="submitSearch">
                            <div class="search_wrapper">
                                <input
                                    type="text"
                                    v-model="$store.state.searchData.keyword"
                                    class="form-control"
                                    :placeholder="$t('titles.search') + '.....'"
                                />
                                <button
                                    class="btn"
                                    type="button"
                                    @click="openSearchBar"
                                >
                                    <img
                                        src="../../assets/media/icons/search.svg"
                                        alt="Icon"
                                        width="50"
                                        height="50"
                                    />
                                </button>
                            </div>
                        </form>
                    </div>
                </li>
                <!-- END:: SEARCH BUTTON -->

                <!-- START:: HOME BUTTON -->
                <li class="actions_list_item" @click="closeSideMenus">
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/home'
                                : '/influincer-home'
                        "
                        class="hover-icons"
                    >
                        <img
                            src="../../assets/media/icons/curved_home.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <span> {{ $t("titles.home") }} </span>
                    </router-link>
                </li>
                <!-- END:: HOME BUTTON -->

                <!-- START:: WALLET BUTTON -->
                <li
                    class="actions_list_item"
                    v-if="
                        !routePath.includes('contact') &&
                        !routePath.includes('terms') &&
                        !routePath.includes('about') &&
                        !routePath.includes('policy') &&
                        !routePath.includes('blog') &&
                        !routePath.includes('/influencer/') &&
                        $route.path != '/'
                    "
                >
                    <button class="btn hover-icons" @click="toggleWalletMenu">
                        <img
                            src="../../assets/media/icons/wallet.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <span> {{ $t("titles.wallet") }} </span>
                    </button>
                </li>
                <!-- END:: WALLET BUTTON -->

                <!-- START:: CHATS ROUTE -->
                <li
                    class="actions_list_item"
                    @click="
                        closeSideMenus();
                        $store.state.chat_num = 0;
                    "
                    v-if="
                        !routePath.includes('contact') &&
                        !routePath.includes('terms') &&
                        !routePath.includes('about') &&
                        !routePath.includes('policy') &&
                        !routePath.includes('blog') &&
                        !routePath.includes('/influencer/') &&
                        $route.path != '/'
                    "
                >
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/chats'
                                : '/influincer-chats'
                        "
                        class="btn hover-icons"
                    >
                        <!-- number  -->
                        <div v-if="userToken && $store.state.chat_num > 0">
                            <span
                                class="number_unread"
                                v-if="$store.state.chat_num > 20"
                                >+20
                            </span>
                            <span class="number_unread" v-else>
                                {{ $store.state.chat_num }}
                            </span>
                        </div>
                        <img
                            src="../../assets/media/icons/chat.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <span> {{ $t("titles.chats") }} </span>
                    </router-link>
                </li>
                <!-- END:: CHATS ROUTE -->

                <!-- START:: CONTRACTS ROUTE -->
                <li
                    class="actions_list_item"
                    @click="closeSideMenus"
                    v-if="
                        !routePath.includes('contact') &&
                        !routePath.includes('terms') &&
                        !routePath.includes('about') &&
                        !routePath.includes('policy') &&
                        !routePath.includes('blog') &&
                        !routePath.includes('/influencer/') &&
                        $route.path != '/'
                    "
                >
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/contracts'
                                : '/influincer-contracts'
                        "
                        class="hover-icons"
                    >
                        <img
                            src="../../assets/media/icons/file.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <span> {{ $t("titles.contracts") }} </span>
                    </router-link>
                </li>
                <!-- END:: CONTRACTS ROUTE -->

                <!-- START:: NOTIFICATIONS BUTTON -->
                <li
                    class="actions_list_item"
                    v-if="
                        !routePath.includes('contact') &&
                        !routePath.includes('terms') &&
                        !routePath.includes('about') &&
                        !routePath.includes('policy') &&
                        !routePath.includes('blog') &&
                        !routePath.includes('/influencer/') &&
                        $route.path != '/'
                    "
                    @click="$store.state.walletMenuIsOpen = false"
                >
                    <button class="btn hover-icons" @click="openNotification">
                        <!-- number  -->
                        <div
                            v-if="
                                userToken && $store.state.notification_num > 0
                            "
                        >
                            <span
                                class="number_unread"
                                v-if="$store.state.notification_num > 20"
                                >+20
                            </span>
                            <span class="number_unread" v-else>
                                {{ $store.state.notification_num }}
                            </span>
                        </div>
                        <img
                            src="../../assets/media/icons/bell.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <span> {{ $t("titles.notifications") }}</span>
                    </button>
                </li>
                <!-- END:: NOTIFICATIONS BUTTON -->

                <!-- START:: ABOUT USER BUTTON -->
                <li
                    class="actions_list_item"
                    @click="$store.state.walletMenuIsOpen = false"
                >
                    <button class="btn user_info_btn" @click="toggleUserMenu">
                        <div class="img_wrapper" v-if="userData.avatar">
                            <AvatarName
                                :nameAvatar="userData.name"
                                v-if="
                                    userData.avatar.includes(
                                        'backgrounds/avatar.png'
                                    )
                                "
                            />
                            <img
                                v-else
                                :src="userData.avatar"
                                alt="User Image"
                                width="50"
                                height="50"
                            />
                        </div>

                        <div class="info_wrapper">
                            <div class="name">{{ userData.name }}</div>
                            <div class="phone">{{ userData.phone }}</div>
                        </div>

                        <div
                            class="icon_wrapper"
                            :class="{ active: userMenuIsOpen }"
                        >
                            <img
                                src="../../assets/media/icons/arrow_down_black.svg"
                                alt="User Image"
                                width="50"
                                height="50"
                            />
                        </div>
                    </button>

                    <transition mode="out-in" name="fade">
                        <div
                            class="user_info_menu_wrapper"
                            v-if="userMenuIsOpen"
                        >
                            <ul class="user_info_menu">
                                <li
                                    class="menu_item"
                                    @click="
                                        toggleUserMenu();
                                        playIntroVideo();
                                    "
                                >
                                    <button v-if="userType == 'investor'">
                                        <span>
                                            <img
                                                src="../../assets/media/icons/video.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.play_video") }}
                                    </button>

                                    <button v-else>
                                        <span>
                                            <img
                                                src="../../assets/media/icons/video.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.about_video") }}
                                    </button>
                                </li>

                                <li class="menu_item" @click="toggleUserMenu">
                                    <router-link
                                        :to="
                                            userType == 'investor'
                                                ? '/editProfile'
                                                : '/edit-influincer-profile'
                                        "
                                    >
                                        <span>
                                            <img
                                                src="../../assets/media/icons/edit.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.edit_profile") }}
                                    </router-link>
                                </li>

                                <li class="menu_item" @click="toggleUserMenu">
                                    <button @click="logout">
                                        <span>
                                            <img
                                                src="../../assets/media/icons/logout.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.logout") }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </li>
                <!-- END:: ABOUT USER BUTTON -->
            </ul>
        </div>

        <!-- START:: LANGUAGE BUTTON -->
        <div class="footer_actions_list_item head_lang" v-else>
            <button
                class="btn lang_menu_toggler"
                :class="{ active: languageMenuIsOpen }"
                @click="toggleLanguageMenu"
            >
                <img
                    src="../../assets/media/icons/global.svg"
                    alt="Icon"
                    width="50"
                    height="50"
                />

                <span v-for="lang in $store.state.languageArray" :key="lang.id">
                    <template v-if="app_language == lang.key">{{
                        lang.name
                    }}</template>
                </span>

                <img
                    src="../../assets/media/icons/arrow_down.svg"
                    alt="Icon"
                    width="50"
                    height="50"
                />
            </button>
            <transition mode="in-out" name="fade">
                <div class="lang_options_wrapper" v-if="languageMenuIsOpen">
                    <ul class="lang_options_list">
                        <li
                            class="lang_option"
                            v-for="lang in $store.state.languageArray"
                            :key="lang.id"
                        >
                            <button
                                class="btn"
                                :class="{ disabled: app_language == lang.key }"
                                @click="changeLanguage(lang.key)"
                            >
                                {{ lang.name }}
                            </button>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
        <!-- END:: LANGUAGE BUTTON -->

        <div class="small_screens_lang_wrapper">
            <!-- START:: SMALL SCREENS SEARCH BUTTON -->
            <div
                class="small_screens_search_btn small_screens_lang small_screens_menu_btn"
                v-if="
                    !routePath.includes('contact') &&
                    !routePath.includes('terms') &&
                    !routePath.includes('about') &&
                    !routePath.includes('policy') &&
                    $route.path != '/' &&
                    userType == 'investor'
                "
            >
                <button
                    class="lang_menu_toggler"
                    @click="toggleSmallScreensSearchBar"
                    style="width: 20px; height: 20px"
                >
                    <img
                        src="../../assets/media/icons/search.svg"
                        alt="Icon"
                        width="50"
                        height="50"
                    />
                </button>

                <transition mode="in-out" name="fade">
                    <div
                        class="small_screens_search_bar"
                        v-if="smallScreensSearchBarIsOpen"
                    >
                        <form @submit.prevent="submitSearch">
                            <div class="search_wrapper">
                                <input
                                    type="text"
                                    v-model="$store.state.searchData.keyword"
                                    class="form-control"
                                    :placeholder="$t('titles.search') + '.....'"
                                />
                                <button class="btn" @click="openSearchBar">
                                    <img
                                        src="../../assets/media/icons/search.svg"
                                        alt="Icon"
                                        width="50"
                                        height="50"
                                    />
                                </button>
                            </div>
                        </form>
                    </div>
                </transition>
            </div>
            <!-- END:: SMALL SCREENS SEARCH BUTTON -->

            <!-- START:: SMALL SCREENS LANGUAGE BUTTON -->
            <div class="small_screens_lang small_screens_menu_btn">
                <button
                    class="lang_menu_toggler"
                    :class="{ active: languageMenuIsOpen }"
                    @click="toggleLanguageMenu"
                    style="width: 20px; height: 20px"
                >
                    <img
                        src="../../assets/media/icons/global.svg"
                        alt="Icon"
                        width="50px"
                        height="50px"
                    />
                </button>
                <transition mode="in-out" name="fade">
                    <div class="lang_options_wrapper" v-if="languageMenuIsOpen">
                        <ul class="lang_options_list">
                            <li
                                class="lang_option"
                                v-for="lang in $store.state.languageArray"
                                :key="lang.id"
                            >
                                <button
                                    class="btn"
                                    :class="{
                                        disabled: app_language == lang.key,
                                    }"
                                    @click="changeLanguage(lang.key)"
                                >
                                    {{ lang.name }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
            <!-- END:: SMALL SCREENS LANGUAGE BUTTON -->

            <div class="small_screens_menu_btn">
                <button
                    :class="{ active: smallScreensMenuIsOpen }"
                    @click="toggleSmallScreensMenu"
                >
                    <AlignRightIcon />
                </button>
            </div>
        </div>

        <div
            class="small_screens_menu_wrapper"
            :class="{ active: smallScreensMenuIsOpen }"
        >
            <div class="close_btn_wrapper">
                <button @click="toggleSmallScreensMenu">
                    <XIcon />
                </button>
            </div>

            <ul class="small_screens_menu">
                <li
                    class="small_screens_menu_item"
                    @click="toggleSmallScreensMenu"
                >
                    <router-link to="/about">
                        {{ $t("static_pages.about_us") }}
                    </router-link>
                </li>
                <li
                    class="small_screens_menu_item"
                    @click="toggleSmallScreensMenu"
                >
                    <router-link to="/terms">
                        {{ $t("static_pages.terms_conditions") }}
                    </router-link>
                </li>
                <li
                    class="small_screens_menu_item"
                    @click="toggleSmallScreensMenu"
                >
                    <router-link to="/Police">
                        {{ $t("static_pages.privacy_police") }}
                    </router-link>
                </li>
                <li
                    class="small_screens_menu_item"
                    @click="toggleSmallScreensMenu"
                >
                    <router-link to="/blog">
                        {{ $t("static_pages.blog") }}
                    </router-link>
                </li>
                <li
                    class="small_screens_menu_item"
                    @click="toggleSmallScreensMenu"
                >
                    <router-link to="/contact">
                        {{ $t("static_pages.contact_us") }}
                    </router-link>
                </li>
                <li
                    class="small_screens_menu_item"
                    @click="toggleSmallScreensMenu"
                    v-if="userToken"
                >
                    <router-link to="/all-faqs">
                        {{ $t("titles.faqs") }}
                    </router-link>
                </li>
            </ul>

            <ul class="social_menu">
                <li class="social_menu_item" @click="toggleSmallScreensMenu">
                    <a href="#" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/facebook.svg"
                            alt="Facebook Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>

                <li class="social_menu_item" @click="toggleSmallScreensMenu">
                    <a href="#" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/Twitter.svg"
                            alt="Twitter Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>

                <li class="social_menu_item" @click="toggleSmallScreensMenu">
                    <a href="#" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/instagram.svg"
                            alt="Instagram Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>

                <li class="social_menu_item" @click="toggleSmallScreensMenu">
                    <a href="#" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/youtube.svg"
                            alt="Youtube Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
import { AlignRightIcon, XIcon } from "vue-feather-icons";
import AvatarName from "../ui/AvatarName.vue";
export default {
    name: "TheHeader",

    components: {
        AlignRightIcon,
        XIcon,
        AvatarName,
    },

    data() {
        return {
            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: USER TOKEN
            userToken: localStorage.getItem("inflco_user_token"),
            // END:: USER TOKEN

            // START:: INFLCO USER IS ACTIVE
            userIsActive: localStorage.getItem("inflco_user_is_active"),
            // END:: INFLCO USER IS ACTIVE

            // START:: VIDEO STATUS DATA
            video_status: localStorage.getItem("video_is_played"),
            // END:: VIDEO STATUS DATA

            // START:: USER DATA
            userData: {
                image: "",
                name: "",
                phone: "",
                username: "",
            },
            // END:: USER DATA

            // START:: Note DATA
            noteShow: false,
            noteMessage: "",
            // End:: Note DATA

            // START:: SMALL SCREENS MENU HANDLING DATA
            smallScreensMenuIsOpen: false,
            // END:: SMALL SCREENS MENU HANDLING DATA

            // START:: SEARCH BAR HANDLING DATA
            searchBarIsOpen: false,
            smallScreensSearchBarIsOpen: false,
            // END:: SEARCH BAR HANDLING DATA

            // START:: USER MENU HANDLING DATA
            userMenuIsOpen: false,
            // END:: USER MENU HANDLING DATA

            // START:: ROUTE DATH DATA
            routePath: this.$route.path,
            // END:: ROUTE DATH DATA

            // START:: APPLICATION LANGUAGE DATA
            app_language: localStorage.getItem("inflco_app_lang"),
            // END:: APPLICATION LANGUAGE DATA

            // START:: LANGUAGE MENU DATA
            languageMenuIsOpen: false,
            // END:: LANGUAGE MENU DATA

            // START::
            logoutMethod: {
                device_token: "123456789",
                type: "ios",
            },
        };
    },

    methods: {
        // START:: GET USER PROFILE INFO
        getUserProfileInfo() {
            if (this.userType == "investor") {
                this.$axios
                    .get("investor", {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    })
                    .then((res) => {
                        this.userData.avatar = res.data.data.image;
                        this.userData.name = res.data.data.fullname;
                        this.userData.phone =
                            res.data.data.phone_key + res.data.data.phone;
                    });
            } else if (this.userType == "influincer") {
                this.$axios
                    .get(`influencer/profile`, {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    })
                    .then((res) => {
                        this.userData.avatar = res.data.data.image;
                        this.userData.name = res.data.data.fullname;
                        this.userData.phone =
                            res.data.data.phone_key + res.data.data.phone;
                        this.userData.username = res.data.data.username;
                    });
            }
        },
        // END:: GET USER PROFILE INFO

        // START:: PLAY INTRO VIDEO METHOD
        playIntroVideo() {
            if (this.userType == "investor") {
                this.$router.push("/home");
            } else {
                this.$router.push("/influincer-home");
            }
            setTimeout(() => {
                this.$store.commit("toggleAboutVideoModal");
            }, 500);
        },
        // END:: PLAY INTRO VIDEO METHOD

        // START:: LOGOUT
        logout() {
            const data = new FormData();
            data.append("device_token", this.logoutMethod.device_token);
            data.append("type", this.logoutMethod.type);
            this.$axios
                .post("auth/logout", data, {
                    headers: {
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("auth.logout"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                    }
                    this.$router.replace("/");
                    localStorage.removeItem("inflco_user_type");
                    localStorage.removeItem("inflco_user_id");
                    localStorage.removeItem("inflco_user_token");
                    localStorage.removeItem("inflco_user_phone");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    localStorage.removeItem("inflco_user_name");
                    localStorage.removeItem("inflco_user_is_active");
                    localStorage.removeItem("inflco_user_login");
                    localStorage.removeItem("video_is_played");
                    location.reload();
                })
                .catch(() => {
                    this.$router.replace("/");
                    localStorage.removeItem("inflco_user_type");
                    localStorage.removeItem("inflco_user_id");
                    localStorage.removeItem("inflco_user_token");
                    localStorage.removeItem("inflco_user_phone");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    localStorage.removeItem("inflco_user_name");
                    localStorage.removeItem("inflco_user_is_active");
                    localStorage.removeItem("inflco_user_login");
                    localStorage.removeItem("video_is_played");
                    location.reload();
                });
        },
        // END:: LOGOUT

        // START:: TOGGLE SMALL SCREENS MENU
        toggleSmallScreensMenu() {
            this.smallScreensMenuIsOpen = !this.smallScreensMenuIsOpen;
        },
        // END:: TOGGLE SMALL SCREENS MENU

        // START:: OPEN SEARCH BAR
        openSearchBar() {
            this.searchBarIsOpen = !this.searchBarIsOpen;
        },
        // END:: OPEN SEARCH BAR

        // START:: CLOSE SEARCH BAR
        closeSearchBar() {
            this.searchBarIsOpen = false;
        },
        // END:: CLOSE SEARCH BAR

        // START:: TOGGLE USER MENU
        toggleUserMenu() {
            this.userMenuIsOpen = !this.userMenuIsOpen;
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.userMenuIsOpen = false;
                this.searchBarIsOpen = false;
                this.languageMenuIsOpen = false;
            }
        },
        // END:: TOGGLE USER MENU

        // START:: SUBMIT SEARCH FORM
        submitSearch() {
            //  ======== SUBMIT SEARCH LOGIC WILL GO HERE ========= //
            this.closeSearchBar();
            this.$store.state.currentSearchPage = 1;
            this.$router.push("/search-result");
            this.$store.dispatch("submitSearchForm");
        },
        // END:: SUBMIT SEARCH FORM

        // START:: TOGGLE WALLET MENU
        toggleWalletMenu() {
            // alert("Test");
            this.$store.dispatch("toggleWalletMenu");
        },
        // END:: TOGGLE WALLET MENU

        // START:: TOGGLE NOTIFICATIONS MENU
        openNotification() {
            this.$store.dispatch("toggleNotificationsMenu");
            this.$store.dispatch("makeNotifcationsZero");
        },
        // END:: TOGGLE NOTIFICATIONS MENU

        // START:: TOGGLE LANGUAGE MENU
        toggleSmallScreensSearchBar() {
            this.smallScreensSearchBarIsOpen =
                !this.smallScreensSearchBarIsOpen;
        },
        // END:: TOGGLE LANGUAGE MENU

        // START:: TOGGLE LANGUAGE MENU
        toggleLanguageMenu() {
            this.languageMenuIsOpen = !this.languageMenuIsOpen;
        },
        // END:: TOGGLE LANGUAGE MENU

        // START:: CLOSE SIDE MENUS
        closeSideMenus() {
            this.$store.dispatch("closeSideMenus");
        },
        // END:: CLOSE SIDE MENUS

        // START:: HANDEL DEFAULT APP LANGUAGE
        changeLanguage(lang) {
            this.$store.dispatch("changeAppLanguage", {
                selected_language: lang,
            });
        },
        // END:: HANDEL DEFAULT APP LANGUAGE
    },
    beforeDestroy() {
        document.removeEventListener("click", this.close);
    },
    created() {
        // ======== START:: HANDLING API ========

        // START:: AXIOS GET USER PROFILE INFO
        this.getUserProfileInfo();
        // END:: AXIOS GET USER PROFILE INFO

        // ======== END:: HANDLING API ========
        document.addEventListener("click", this.close);
        this.$store.dispatch("getChatsList");
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <div class="faqs">
        <div class="vector_shape_wrapper" v-if="faqs.length == 0">
            <img
                src="../../assets/media/illustrations/Blob.svg"
                width="200"
                height="200"
            />

            <div class="note">
                <h3> {{ $t("titles.no_data") }} </h3>
            </div>
        </div>

        <template v-else>
            <!-- START:: SECTION TITLE -->
            <div class="title_section">
                <h4 v-if="user_type == 'investor'">
                    {{ $t("titles.investor_faqs") }}
                </h4>
                <h4 v-else>
                    {{ $t("titles.influincer_faqs") }}
                </h4>
            </div>
            <!-- END:: SECTION TITLE -->

            <!--  START:: ACCORDION -->
            <Loader v-if="loadPage" class="fadeIn heigth-50vh"></Loader>
            <div class="accordion fadeIn" v-else id="faqs">
                <!-- START:: ACCORDION ITEM -->
                <div
                    v-for="(question, index) in faqs"
                    :key="question.id"
                    class="accordion-item mb-3"
                    :class="index == 0 ? 'active_item' : ''"
                >
                    <!-- START:: ACCORDION ITEM HEADER -->
                    <h2 class="accordion-header" :id="'heading_' + question.id">
                        <button
                            class="accordion-button"
                            :class="index != 0 ? 'collapsed' : ''"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#collapse_' + question.id"
                            :aria-expanded="index == 0 ? 'true ' : 'false'"
                            :aria-controls="'collapse_' + question.id"
                            @click="targetParent"
                        >
                            {{ question.question }}
                        </button>
                    </h2>
                    <!-- START:: ACCORDION ITEM HEADER -->

                    <!-- START:: ACCORDION ITEM BODY -->
                    <div
                        :id="'collapse_' + question.id"
                        class="accordion-collapse collapse"
                        :class="index == 0 ? 'show' : ''"
                        :aria-labelledby="'heading_' + question.id"
                        data-bs-parent="#faqs"
                    >
                        <div class="accordion-body" v-html="question.answer"></div>
                    </div>
                    <!-- START:: ACCORDION ITEM BODY -->
                </div>
                <!-- END:: ACCORDION ITEM -->
            </div>
            <!--  END:: ACCORDION -->
        </template>
    </div>
</template>

<script>
import Loader from "./../../components/loader/main-loader.vue";
export default {
    name: "Faqs",

    data() {
        return {
            // START:: USER TYPE
            user_type: this.$route.params.type,
            // END:: USER TYPE

            //  START:: GET  FAQS DATA
            faqs: null,
            influener_faqs: null,
            loadPage: false,
            //  END:: GET  FAQS DATA
        };
    },
    components: {
        Loader,
    },
    methods: {
        // START:: GET FAQS DATA METHODS
        getFaqsData() {
            this.loadPage = true;
            // START:: INVESTOR FAQS REQUEST
            if (this.user_type == "investor") {
                this.$axios
                    .get("questions", {
                        params: {
                            type: "investor",
                        },
                    })
                    .then((res) => {
                        this.faqs = res.data.data;
                        this.loadPage = false;
                    });
            }
            // END:: INVESTOR FAQS REQUEST

            // START:: INFLUENCER FAQS REQUEST
            else if (this.user_type == "influincer") {
                this.$axios
                    .get("questions", {
                        params: {
                            type: "influencer",
                        },
                    })
                    .then((res) => {
                        this.faqs = res.data.data;
                        this.loadPage = false;
                    });
            }
            // END:: INFLUENCER FAQS REQUEST
        },
        // START:: GET FAQS DATA METHODS

        // START:: TARGET PARENT ELEMENT METHOD
        targetParent(e) {
            let activeItem = e.target.parentElement.parentElement;
            // let allItems = Array.from(e.target.parentElement.parentElement.parentElement.children);
            // console.log(allItems);

            activeItem.classList.toggle("active_item");

            activeItem.nextElementSibling.classList.remove("active_item");
            activeItem.previousElementSibling.classList.remove("active_item");
        },
        // END:: TARGET PARENT ELEMENT METHOD
    },

    mounted() {
        this.getFaqsData();
    },
};
</script>

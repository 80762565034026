<template>
  <!-- loader -->
  <div>
    <div class="custom_card explore" v-for="item in number" :key="item">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="pro-load">
            <div class="loader-container">
              <div class="product-shape">
                <div class="animated-background profile-image"></div>
                <div class="load-body">
                  <div class="load-content">
                    <div class="animated-background pro-name-loader"></div>
                    <div class="animated-background pro-name-loader"></div>
                  </div>
                  <div class="load-icon">
                    <div class="animated-background pro-icon-loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["number"],
  name: "App",
  mounted() {},
  components: {},
};
</script>

<style scoped>
@keyframes placeholder {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}

/*skelton*/

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1200px 100px;
  min-height: 10px;
  width: 100%;
  margin: 20px 0 20px 0;
  border-radius: 3px;
}

.pro-load {
  padding: 15px 0px 0;
}

.pro-load .product-shape {
  display: flex;
  align-items: center;
}

.pro-load .profile-image {
  margin-top: 0px;
  border-radius: 50%;
  width: 90px;
  height: 80px;
  margin-right: 20px;
}

.pro-load .load-body {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pro-load .load-body .load-icon .pro-icon-loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.pro-load .pro-name-loader {
  width: 200px;
  margin-top: 10px;
  height: 10px;
}
.pro-load .pro-name-loader:nth-child(2) {
  width: 100px;
}
.pro-load .pro-full-width {
  width: 100%;
  margin-top: 10px;
  height: 10px;
}
</style>

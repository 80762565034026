<template>
    <div class="staticPages">
        <div class="page_title">
            <h1>{{ $t("titles.terms") }}</h1>
        </div>
        <Loader v-if="loadPage" class="fadeIn heigth-50vh"></Loader>
        <div class="row fadeIn" v-else>
            <div class="col-md-12">
                <div class="text_about" v-html="terms"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "./../../components/loader/main-loader.vue";
export default {
    name: "Terms",

    data() {
        return {
            terms: "",
            loadPage: false,
        };
    },
    components: {
        Loader,
    },
    methods: {
        getStaticContent() {
            this.loadPage = true;
            this.$axios
                .get("settings/terms", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.terms = res.data.data.terms;
                    this.loadPage = false;
                });
        },
    },

    mounted() {
        this.getStaticContent();
    },
};
</script>

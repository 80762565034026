<template>
    <div class="influincer_personal_profile_wrapper">
        <Loader v-if="loadProfile" class="fadeIn"></Loader>
        <section v-else class="fadeIn">
            <div class="influincer_profile_mainInfo">
                <!-- START:: MAIN INFO -->
                <div class="profile_avatar_wrapper">
                    <div class="profile_avatar">
                        <img
                            :src="profileData.image"
                            alt="influincer Avatar"
                            width="180"
                            height="180"
                        />
                    </div>

                    <div class="influincer_name">
                        <h2>
                            <span> {{ $t("frequently_words.welcome") }} </span>

                            <div class="influincer_chat">
                                <router-link to="/edit-influincer-profile">
                                    <img
                                        src="../../assets/media/icons/edit_main.svg"
                                        width="20px"
                                        height="20px"
                                    />
                                </router-link>
                            </div>
                        </h2>
                        <h3>{{ profileData.fullname }}</h3>
                    </div>
                </div>

                <div class="generated_link_wrapper">
                    <div class="link">
                        <input
                            ref="profileLink"
                            :value="profileData.short_link"
                            readonly
                        />
                    </div>

                    <div class="btn_wrapper">
                        <button @click="copyLink">
                            <img
                                src="../../assets/media/icons/copy.svg"
                                alt="icon"
                                width="20"
                                height="20"
                            />
                        </button>
                    </div>
                </div>

                <div class="influincer_data_wrapper">
                    <div class="single_data_wrapper">
                        <div class="single_data">
                            <img
                                src="../../assets/media/icons/star.svg"
                                width="20"
                                height="20"
                                alt="icon"
                            />
                            <h6>{{ $t("titles.total_rates") }}</h6>
                            <h5>{{ profileData.rate_avg.toFixed(1) }}</h5>
                        </div>
                    </div>

                    <div class="single_data_wrapper">
                        <div class="single_data">
                            <img
                                src="../../assets/media/icons/wallet.svg"
                                width="20"
                                height="20"
                                alt="icon"
                            />
                            <h6>{{ $t("titles.total_profits") }}</h6>
                            <h5>${{ profileData.wallet }}</h5>
                        </div>
                    </div>

                    <div class="single_data_wrapper">
                        <div class="single_data">
                            <img
                                src="../../assets/media/icons/file.svg"
                                width="20"
                                height="20"
                                alt="icon"
                            />
                            <h6>{{ $t("titles.total_contracts") }}</h6>
                            <h5>{{ profileData.contracts }}</h5>
                        </div>
                    </div>
                </div>

                <!-- END:: MAIN INFO -->
                <div class="client_rate">
                    <div class="head_client_rate">
                        <h3>
                            {{ $t("titles.clients_rate") }}
                            <span
                                ><i class="fas fa-star"></i>
                                {{ profileData.rate_avg.toFixed(1) }}</span
                            >
                        </h3>

                        <button
                            @click="showAllRates(profileData.rate_avg)"
                            type="button"
                            class="btn"
                            v-if="profileData.rates.length != 0"
                        >
                            {{ $t("buttons.see_all") }}
                        </button>
                    </div>

                    <div
                        class="client_rate_wrapper"
                        v-for="client in profileData.rates.slice(0, 3)"
                        :key="client.id"
                    >
                        <div class="avatar_wrapper">
                            <img
                                :src="client.user.image"
                                :alt="client.user.fullname"
                                width="100"
                                height="100"
                            />
                        </div>

                        <div class="details_wrapper">
                            <div class="wrapper">
                                <div class="name">
                                    <span>{{ client.user.fullname }} </span>
                                    <div class="wrapper_rate">
                                        <RatingStars :rate="client.rate" />
                                    </div>
                                </div>

                                <div
                                    class="fields"
                                    v-if="client.review != null"
                                >
                                    <span> {{ client.review }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- START:: RATE MODAL -->
        <BaseModel
            @closeModel="showClientsRate = !showClientsRate"
            :show="showClientsRate"
        >
            <template #modal>
                <div class="modal_custom">
                    <span
                        @click="showClientsRate = !showClientsRate"
                        class="close"
                    >
                        <i class="fas fa-times"></i>
                    </span>
                    <!-- START:: HEAD MOADAL -->
                    <div class="head_modal">
                        <h3>
                            {{ $t("titles.clients_rate") }}
                            <span
                                ><i class="fas fa-star"></i>
                                {{ totalRates.toFixed(1) }}</span
                            >
                        </h3>
                    </div>
                    <!-- END:: HEAD MODAL -->

                    <!-- START:: BODY MODAL -->
                    <LoaderCards v-if="loaderRates" :number="3"></LoaderCards>
                    <div class="client_rate" v-else>
                        <div
                            class="client_rate_wrapper"
                            v-for="client in allRates"
                            :key="client.id"
                        >
                            <div class="avatar_wrapper">
                                <img
                                    :src="client.user.image"
                                    alt="Client Avatar"
                                    width="100"
                                    height="100"
                                />
                            </div>

                            <div class="details_wrapper">
                                <div class="wrapper">
                                    <div class="name">
                                        <span>{{ client.user.fullname }} </span>
                                        <div class="wrapper_rate">
                                            <RatingStars :rate="client.rate" />
                                        </div>
                                    </div>

                                    <div
                                        class="fields"
                                        v-if="client.review != null"
                                    >
                                        <span> {{ client.review }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END:: BODY MODAL -->
                </div>
            </template>
        </BaseModel>
        <!-- END:: RATE MODAL -->
    </div>
</template>

<script>
import BaseModel from "../ui/BaseModel.vue";
import RatingStars from "../ui/RatingStars.vue";
import LoaderCards from "./../loader/explore.vue";
import Loader from "./../loader/profile.vue";
export default {
    name: "InfluincerProfile",

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: CURRENT INFLUINCER ID DATA
            influincerId: this.$route.params.id,
            // END:: CURRENT INFLUINCER ID DATA

            // START:: CLIENTS RATE DATA
            showClientsRate: false,
            totalRates: "",
            loaderRates: false,
            loadProfile: false,
            // END:: CLIENTS RATE DATA

            token: localStorage.getItem("inflco_user_token"),

            // START:: PROFILE DATA
            profileData: {
                rates: [],
            },
            // END:: PROFILE DATA

            // START:: RATE DATA
            rateData: [],

            // END:: RATE DATA
        };
    },
    components: {
        BaseModel,
        RatingStars,
        LoaderCards,
        Loader,
    },
    methods: {
        // START:: GET PROFILE DATA
        getProfileData() {
            this.loadProfile = true;
            this.$axios
                .get(`influencer/profile`, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.profileData = res.data.data;
                    this.loadProfile = false;
                });
        },
        // END:: GET PROFILE DATA

        // START:: GET PROFILE DATA
        showAllRates(rates) {
            this.totalRates = rates;
            this.showClientsRate = !this.showClientsRate;
            this.loaderRates = true;
            this.$axios
                .get(`influencer/my_rates`, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.allRates = res.data.data;
                    this.loaderRates = false;
                });
        },
        // END:: GET PROFILE DATA

        // START:: SUBMIT RATE
        submitRate() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
            }, 2000);
        },
        // END:: SUBMIT RATE

        // START:: COPY LINK
        copyLink() {
            let targetLink = this.$refs.profileLink;
            targetLink.select();
            document.execCommand("copy");
            this.$iziToast.success({
                timeout: 5000,
                message: "Copied",
                position: "topRight",
                rtl: true,
            });
        },
        // END:: COPY LINK
    },
    mounted() {
        this.getProfileData();
    },
};
</script>

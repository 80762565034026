<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <footer>
        <div class="copy_rights_wrapper" :class="{ footerToken: userToken }">
            <span>
                {{ $t("titles.copyright") }} &copy; {{ currentYear }} Inflco
                Portal Co</span
            >
            <span>
                <img src="../../assets/media/icons/payment_wayes.svg" />
            </span>
        </div>

        <div class="footer_navbar_wrapper" :class="{ footerToken: userToken }">
            <ul class="footer_navbar">
                <li class="footer_navbar_item">
                    <router-link to="/about">
                        {{ $t("static_pages.about_us") }}
                    </router-link>
                </li>

                <li class="footer_navbar_item">
                    <router-link to="/terms">
                        {{ $t("static_pages.terms_conditions") }}
                    </router-link>
                </li>

                <li class="footer_navbar_item">
                    <router-link to="/Police">
                        {{ $t("static_pages.privacy_police") }}
                    </router-link>
                </li>

                <li class="footer_navbar_item">
                    <router-link to="/blog">
                        {{ $t("static_pages.blog") }}
                    </router-link>
                </li>

                <li class="footer_navbar_item">
                    <router-link to="/contact">
                        {{ $t("static_pages.contact_us") }}
                    </router-link>
                </li>

                <li class="footer_navbar_item" v-if="userToken">
                    <router-link to="/all-faqs">
                        {{ $t("titles.faqs") }}
                    </router-link>
                </li>
            </ul>
        </div>

        <div
            class="footer_actions_list_wrapper"
            :class="{ footerToken: userToken }"
        >
            <ul class="footer_actions_list">
                <!-- START:: LANGUAGE BUTTON -->
                <li class="footer_actions_list_item" v-if="userToken">
                    <button
                        class="btn lang_menu_toggler"
                        :class="{ active: languageMenuIsOpen }"
                        @click="toggleLanguageMenu"
                    >
                        <img
                            src="../../assets/media/icons/global.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />

                        <span
                            v-for="lang in $store.state.languageArray"
                            :key="lang.id"
                        >
                            <template v-if="app_language == lang.key">{{
                                lang.name
                            }}</template>
                        </span>

                        <img
                            src="../../assets/media/icons/arrow_down.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                    </button>
                    <transition mode="in-out" name="fade">
                        <div
                            class="lang_options_wrapper"
                            v-if="languageMenuIsOpen"
                        >
                            <ul class="lang_options_list">
                                <li
                                    class="lang_option"
                                    v-for="lang in $store.state.languageArray"
                                    :key="lang.id"
                                >
                                    <button
                                        class="btn"
                                        :class="{
                                            disabled: app_language == lang.key,
                                        }"
                                        @click="changeLanguage(lang.key)"
                                    >
                                        {{ lang.name }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </li>
                <!-- END:: LANGUAGE BUTTON -->

                <li class="footer_actions_list_item">
                    <a :href="socialFooter.facebook" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/facebook.svg"
                            alt="Facebook Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>

                <li class="footer_actions_list_item">
                    <a :href="socialFooter.twitter" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/Twitter.svg"
                            alt="Twitter Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>

                <li class="footer_actions_list_item">
                    <a :href="socialFooter.instagram" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/instagram.svg"
                            alt="Instagram Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>

                <li class="footer_actions_list_item">
                    <a :href="socialFooter.youtube" target="_blanck">
                        <img
                            src="../../assets/media/icons/social/youtube.svg"
                            alt="Youtube Icon"
                            width="50"
                            height="50"
                        />
                    </a>
                </li>
            </ul>
        </div>

        <div class="small_screens_actions_list_wrapper" v-if="userToken">
            <ul class="small_screens_actions_list">
                <li class="small_screens_actions_list_item">
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/home'
                                : '/influincer-home'
                        "
                    >
                        <img
                            src="../../assets/media/icons/curved_home.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <p>{{ $t("titles.home") }}</p>
                    </router-link>
                </li>

                <li class="small_screens_actions_list_item">
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/chats'
                                : '/influincer-chats'
                        "
                    >
                        <!-- number  -->
                        <div v-if="userToken && $store.state.chat_num > 0">
                            <span
                                class="number_unread"
                                v-if="$store.state.chat_num > 20"
                                >+20
                            </span>
                            <span class="number_unread" v-else>
                                {{ $store.state.chat_num }}
                            </span>
                        </div>
                        <img
                            src="../../assets/media/icons/chat.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <p>{{ $t("titles.chats") }}</p>
                    </router-link>
                </li>

                <li class="small_screens_actions_list_item">
                    <router-link
                        :to="
                            userType == 'investor'
                                ? '/contracts'
                                : '/influincer-contracts'
                        "
                    >
                        <img
                            src="../../assets/media/icons/file.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                        <p>{{ $t("titles.contracts") }}</p>
                    </router-link>
                </li>

                <li
                    class="small_screens_actions_list_item"
                    v-if="this.$route.path != '/'"
                >
                    <button class="btn" @click="toggleWalletMenu">
                        <img
                            src="../../assets/media/icons/wallet.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                    </button>
                </li>

                <li
                    class="small_screens_actions_list_item"
                    v-if="this.$route.path != '/'"
                >
                    <button class="btn" @click="toggleNotificationsMenu">
                        <!-- number  -->
                        <div
                            v-if="
                                userToken && $store.state.notification_num > 0
                            "
                        >
                            <span
                                class="number_unread"
                                v-if="$store.state.notification_num > 20"
                                >+20
                            </span>
                            <span class="number_unread" v-else>
                                {{ $store.state.notification_num }}
                            </span>
                        </div>
                        <img
                            src="../../assets/media/icons/bell.svg"
                            alt="Icon"
                            width="50"
                            height="50"
                        />
                    </button>
                </li>

                <li class="small_screens_actions_list_item">
                    <button class="btn" @click="toggleUserMenu">
                        <img :src="userData.image" width="50" height="50" />
                    </button>

                    <transition mode="out-in" name="fade">
                        <div
                            class="user_info_menu_wrapper"
                            :class="{
                                user_info_menu_wrapper_influincer:
                                    userType != 'investor',
                            }"
                            v-if="userMenuIsOpen"
                        >
                            <ul class="user_info_menu">
                                <li
                                    class="menu_item"
                                    @click="
                                        toggleUserMenu();
                                        playIntroVideo();
                                    "
                                >
                                    <button v-if="userType == 'investor'">
                                        <span>
                                            <img
                                                src="../../assets/media/icons/video.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.play_video") }}
                                    </button>

                                    <button v-else>
                                        <span>
                                            <img
                                                src="../../assets/media/icons/video.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.about_video") }}
                                    </button>
                                </li>

                                <li class="menu_item" @click="toggleUserMenu">
                                    <router-link
                                        :to="
                                            userType == 'investor'
                                                ? '/editProfile'
                                                : '/edit-influincer-profile'
                                        "
                                    >
                                        <span>
                                            <img
                                                src="../../assets/media/icons/edit.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.edit_profile") }}
                                    </router-link>
                                </li>

                                <li class="menu_item" @click="toggleUserMenu">
                                    <button @click="logout">
                                        <span>
                                            <img
                                                src="../../assets/media/icons/logout.svg"
                                                alt="icon"
                                                width="50"
                                                height="50"
                                            />
                                        </span>
                                        {{ $t("buttons.logout") }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
    name: "TheFooter",

    data() {
        return {
            // START:: CURRENT YEAR DATA
            currentYear: new Date().getFullYear(),
            // END:: CURRENT YEAR DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: APPLICATION LANGUAGE DATA
            app_language: localStorage.getItem("inflco_app_lang"),
            // END:: APPLICATION LANGUAGE DATA
            // START:: USER TOKEN
            userToken: localStorage.getItem("inflco_user_token"),
            // END:: USER TOKEN
            // START:: LANGUAGE MENU DATA
            languageMenuIsOpen: false,
            // END:: LANGUAGE MENU DATA

            // START:: USER MENU HANDLING DATA
            userMenuIsOpen: false,
            // END:: USER MENU HANDLING DATA

            // START:: SOCIAL DATA
            socialFooter: {},
            // END:: SOCIAL DATA

            // START:: USER DATA
            userData: {
                image: "",
                name: "",
                phone: "",
                username: "",
            },
            // START::
            logoutMethod: {
                device_token: "123456789",
                type: "ios",
            },
            // END:: USER DATA
        };
    },

    methods: {
        // START:: PLAY INTRO VIDEO METHOD
        playIntroVideo() {
            if (this.userType == "investor") {
                this.$router.push("/home");
            } else {
                this.$router.push("/influincer-home");
            }

            setTimeout(() => {
                this.$store.commit("toggleAboutVideoModal");
            }, 500);
        },
        // END:: PLAY INTRO VIDEO METHOD

        // START:: LOGOUT
        logout() {
            const data = new FormData();
            data.append("device_token", this.logoutMethod.device_token);
            data.append("type", this.logoutMethod.type);
            this.$axios
                .post("auth/logout", data, {
                    headers: {
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("auth.logout"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                    }
                    this.$router.replace("/");
                    localStorage.removeItem("inflco_user_type");
                    localStorage.removeItem("inflco_user_id");
                    localStorage.removeItem("inflco_user_token");
                    localStorage.removeItem("inflco_user_phone");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    localStorage.removeItem("inflco_user_name");
                    localStorage.removeItem("inflco_user_is_active");
                    localStorage.removeItem("inflco_user_login");
                    localStorage.removeItem("video_is_played");
                    location.reload();
                })
                .catch(() => {
                    this.$router.replace("/");
                    localStorage.removeItem("inflco_user_type");
                    localStorage.removeItem("inflco_user_id");
                    localStorage.removeItem("inflco_user_token");
                    localStorage.removeItem("inflco_user_phone");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    localStorage.removeItem("inflco_user_name");
                    localStorage.removeItem("inflco_user_is_active");
                    localStorage.removeItem("inflco_user_login");
                    localStorage.removeItem("video_is_played");
                    location.reload();
                });
        },
        // END:: LOGOUT

        // START:: TOGGLE WALLET MENU
        toggleWalletMenu() {
            this.$store.dispatch("toggleWalletMenu");
        },
        // END:: TOGGLE WALLET MENU

        // START:: TOGGLE NOTIFICATIONS MENU
        toggleNotificationsMenu() {
            this.$store.dispatch("toggleNotificationsMenu");
            this.$store.dispatch("makeNotifcationsZero");
        },
        // END:: TOGGLE NOTIFICATIONS MENU

        // START:: TOGGLE LANGUAGE MENU
        toggleLanguageMenu() {
            this.languageMenuIsOpen = !this.languageMenuIsOpen;
        },
        // END:: TOGGLE LANGUAGE MENU

        // START:: TOGGLE USER MENU
        toggleUserMenu() {
            this.userMenuIsOpen = !this.userMenuIsOpen;
        },
        // END:: TOGGLE USER MENU

        // START:: HANDEL DEFAULT APP LANGUAGE
        changeLanguage(lang) {
            this.$store.dispatch("changeAppLanguage", {
                selected_language: lang,
            });
        },
        // END:: HANDEL DEFAULT APP LANGUAGE

        // START:: GET SOCIAL MEDIA LINKS
        getSocialMedia() {
            this.$axios
                .get("settings/social_info", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.socialFooter = res.data.data;
                });
        },
        // END:: GET SOCIAL MEDIA LINKS

        // START:: GET USER PROFILE INFO
        getUserProfileInfo() {
            if (this.userType == "investor") {
                this.$axios
                    .get("investor", {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    })
                    .then((res) => {
                        this.userData = res.data.data;
                    });
            } else if (this.userType == "influincer") {
                this.$axios
                    .get(
                        `influencer/${localStorage.getItem(
                            "inflco_user_name"
                        )}`,
                        {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "Content-type": "application/json",
                                "cache-control": "no-cache",
                                Accept: "application/json",
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        }
                    )
                    .then((res) => {
                        this.userData = res.data.data;
                    });
            }
        },
        // END:: GET USER PROFILE INFO
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.languageMenuIsOpen = false;
                this.userMenuIsOpen = false;
            }
        },
    },

    beforeDestroy() {
        document.removeEventListener("click", this.close);
    },

    mounted() {
        this.getSocialMedia();
        this.getUserProfileInfo();
        document.addEventListener("click", this.close);
    },
};
</script>

<template>
  <div class="home_wrapper">
    <!-- START:: HEADER -->
    <TheHeader />
    <!-- END:: HEADER -->

    <div class="row justify-content-center">
      <!-- START:: LARGE CONTENT WRAPPER -->
      <div
        id="large_content_wrapper"
        :class="reSize == false ? 'col-md-9' : 'col-md-8'"
      >
        <ContentWrapper>
          <template #main>
            <InfluincerSocialAccounts />
          </template>
        </ContentWrapper>
      </div>
      <!-- END:: LARGE CONTENT WRAPPER -->

      <!-- START:: SMALL CONTENT WRAPPER -->
      <div
        id="small_content_wrapper"
        :class="reSize == false ? 'col-md-3' : 'col-md-4'"
        class="position-relative"
      >
        <ContentWrapper>
          <template #main>
            <InfluincerProfileForVisitor />
          </template>
        </ContentWrapper>
      </div>
      <!-- END:: SMALL CONTENT WRAPPER -->
    </div>

    <!-- START:: FOOTER -->
    <TheFooter />
    <!-- END:: FOOTER -->
  </div>
</template>

<script>
import InfluincerProfileForVisitor from "./sharedProfile/InfluincerProfileForVisitor.vue";
import InfluincerSocialAccounts from "./sharedProfile/InfluincerSocialAccounts.vue";
import ContentWrapper from "../components/ui/ContentWrapper.vue";
import TheHeader from "../components/layouts/TheHeader.vue";
import TheFooter from "../components/layouts/TheFooter.vue";
export default {
  name: "influincerProfileByURL",

  data() {
    return {
      // START:: RESIZE
      reSize: false,
      // END:: RESIZE
    };
  },

  components: {
    InfluincerProfileForVisitor,
    InfluincerSocialAccounts,
    ContentWrapper,
    TheHeader,
    TheFooter,
  },
  methods: {
    onSize() {
      let innerWidth = window.innerWidth;
      if (innerWidth < 1400) {
        this.reSize = true;
      }
    },
  },
  mounted() {
    // START:: RESIZE CONTENT WRAPPERS
    this.onSize();
    // END:: RESIZE CONTENT WRAPPERS
  },
};
</script>

<template>
  <div class="success_verification_wrapper">
    <div class="success_header_wrapper">
      <div class="logo_wrapper">
        <img
          src="../../assets/logo/small_logo.svg"
          alt="Inflico Logo"
          width="100"
          height="100"
        />
      </div>

      <div class="img_wrapper">
        <img
          src="../../assets/media/illustrations/password.svg"
          alt="image"
          width="100"
          height="100"
        />
      </div>

      <div class="subtitle_wrapper">
        <h5>{{ $t("titles.account_created_successfully") }}</h5>
      </div>

      <div class="btn_wrapper">
        <button @click="continueToHome">{{ $t("buttons.continue") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessVerification",

  data() {
    return {
      // START:: USER TYPE DATA
      userType: localStorage.getItem("inflco_user_type"),
      // END:: USER TYPE DATA
    };
  },

  methods: {
    // START:: CONTINUE TO HOME
    continueToHome() {
      this.$router.replace("/login");
      localStorage.removeItem("inflco_user_token");
      localStorage.removeItem("inflco_user_id");
      localStorage.removeItem("inflco_user_phone_key");
      localStorage.removeItem("inflco_user_phone_number");
      localStorage.removeItem("inflco_user_phone");
      localStorage.removeItem("inflco_user_type");
      localStorage.removeItem("inflco_verification_code");
      localStorage.removeItem("inflco_user_is_active");
      localStorage.removeItem("inflco_user_name");

      // if (this.userType == "investor") {
      //   location.reload();
      //   localStorage.setItem("inflco_user_login", "true");
      // } else if (this.userType == "influincer") {
      //   location.reload();
      //   localStorage.setItem("inflco_user_login", "true");
      // }
    },
    // END:: CONTINUE TO HOME
  },

  mounted() {},
};
</script>

<template>
    <div class="filter_wrapper">
        <div class="title_wrapper">
            <h3>{{ $t("titles.filter") }}</h3>
        </div>
        <div class="filter_form_wrapper">
            <form>
                <!-- START:: FILTER BY COUNTRY & CITY -->
                <div class="group">
                    <h6>{{ $t("titles.country") }}</h6>
                    <div class="wrapper mb-3">
                        <select
                            class="form-select"
                            aria-label="country select"
                            v-model="$store.state.filterData.country_id"
                            @change="getCitiesByCountryID()"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.country") }}
                            </option>
                            <option
                                v-for="country in $store.state.allCountries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{ country.name }}
                            </option>
                        </select>
                    </div>

                    <div class="wrapper">
                        <select
                            class="form-select"
                            aria-label="city select"
                            v-model="$store.state.filterData.city_id"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.city") }}
                            </option>
                            <option
                                v-for="city in $store.state.cities"
                                :key="city.id"
                                :value="city.id"
                            >
                                {{ city.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- END:: FILTER BY COUNTRY & CITY -->

                <!-- START:: FILTER BY SORT -->
                <div class="group mt-5">
                    <h6>{{ $t("titles.sort_by") }}</h6>
                    <div class="inner_wrapper">
                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="latest"
                                id="sort_latest"
                                name="sort"
                                v-model="$store.state.filterData.sort_by"
                            />
                            <label class="form-check-label" for="sort_latest">
                                {{ $t("placeholders.latest") }}
                            </label>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="top"
                                id="top_rated"
                                name="sort"
                                v-model="$store.state.filterData.sort_by"
                            />
                            <label class="form-check-label" for="top_rated">
                                {{ $t("placeholders.Top_rated") }}
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END:: FILTER BY SORT -->

                <!-- START:: FILTER BY GENDER -->
                <div class="group mt-5">
                    <h6>{{ $t("titles.gender") }}</h6>
                    <div class="inner_wrapper">
                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="male"
                                id="male"
                                name="gender"
                                v-model="$store.state.filterData.gender"
                            />
                            <label class="form-check-label" for="male">
                                {{ $t("placeholders.males") }}
                            </label>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="female"
                                id="female"
                                name="gender"
                                v-model="$store.state.filterData.gender"
                            />
                            <label class="form-check-label" for="female">
                                {{ $t("placeholders.females") }}
                            </label>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="both"
                                id="both"
                                name="gender"
                                v-model="$store.state.filterData.gender"
                            />
                            <label class="form-check-label" for="both">
                                {{ $t("placeholders.both") }}
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END:: FILTER BY GENDER -->

                <!-- START:: FILTER BY AGE -->
                <div class="group mt-5">
                    <h6>{{ $t("titles.age") }}</h6>
                    <div class="inner_wrapper">
                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="after_twenty  "
                                id="under_20"
                                name="age"
                                v-model="$store.state.filterData.age"
                            />
                            <label class="form-check-label" for="under_20">
                                {{ $t("placeholders.under_20") }}
                            </label>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="before_twenty "
                                id="above_20"
                                name="age"
                                v-model="$store.state.filterData.age"
                            />
                            <label class="form-check-label" for="above_20">
                                {{ $t("placeholders.above_20") }}
                            </label>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="all"
                                id="all"
                                name="age"
                                v-model="$store.state.filterData.age"
                            />
                            <label class="form-check-label" for="all">
                                {{ $t("placeholders.all") }}
                            </label>
                        </div>
                    </div>
                </div>
                <!-- END:: FILTER BY AGE -->

                <!-- START:: FILTER BY SOCIAL MEDIA AREA -->
                <div class="group mt-5">
                    <h6>{{ $t("titles.social_area") }}</h6>
                    <div class="row">
                        <div
                            class="col-lg-6 wrapper mb-3"
                            v-for="socialArea in $store.state.slicesSocialAreas"
                            :key="socialArea.id"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :value="socialArea.id"
                                :id="socialArea.id"
                                name="socialMediaArea"
                                v-model="
                                    $store.state.filterData.socialMediaArea
                                "
                            />
                            <label
                                class="form-check-label"
                                :for="socialArea.id"
                            >
                                {{ socialArea.name }}
                            </label>
                        </div>
                    </div>

                    <div
                        class="wrapper"
                        v-if="$store.state.socialArea.length > 4"
                    >
                        <button
                            class="load_more_btn"
                            type="button"
                            @click="
                                $store.dispatch('seeMore', {
                                    array: $store.state.socialArea,
                                    target: 'socialAreas',
                                });
                                $store.dispatch('toggleButtons', {
                                    target: 'areas_btn',
                                });
                            "
                            v-if="$store.state.socialAreasMoreBtnIsActive"
                        >
                            {{ $t("buttons.load_more") }}
                        </button>
                        <button
                            class="load_more_btn"
                            type="button"
                            @click="
                                $store.dispatch('seeLess', {
                                    array: $store.state.socialArea,
                                    target: 'socialAreas',
                                });
                                $store.dispatch('toggleButtons', {
                                    target: 'areas_btn',
                                });
                            "
                            v-if="$store.state.socialAreasLessBtnIsActive"
                        >
                            {{ $t("buttons.see_less") }}
                        </button>
                    </div>
                </div>
                <!-- END:: FILTER BY SOCIAL MEDIA AREA -->

                <!-- START:: FILTER BY SOCIAL MEDIA PLATFORM -->
                <div class="group mt-5">
                    <h6>{{ $t("titles.social_platform") }}</h6>

                    <div class="row">
                        <div
                            class="col-lg-6 wrapper mb-3"
                            v-for="socialPlatform in $store.state
                                .slicesSocialPlatforms"
                            :key="socialPlatform.id"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :value="socialPlatform.id"
                                :id="socialPlatform.id"
                                name="socialMediaPlatform"
                                v-model="
                                    $store.state.filterData.socialMediaPlatform
                                "
                            />
                            <label
                                class="form-check-label"
                                :for="socialPlatform.id"
                            >
                                {{ socialPlatform.name }}
                            </label>
                        </div>
                    </div>

                    <div
                        class="wrapper"
                        v-if="$store.state.socialMediaPlatforms.length > 4"
                    >
                        <button
                            class="load_more_btn"
                            type="button"
                            @click="
                                $store.dispatch('seeMore', {
                                    array: $store.state.socialMediaPlatforms,
                                    target: 'socialPlatforms',
                                });
                                $store.dispatch('toggleButtons', {
                                    target: 'platforms_btn',
                                });
                            "
                            v-if="$store.state.socialPlatformsMoreBtnIsActive"
                        >
                            {{ $t("buttons.load_more") }}
                        </button>
                        <button
                            class="load_more_btn"
                            type="button"
                            @click="
                                $store.dispatch('seeLess', {
                                    array: $store.state.socialMediaPlatforms,
                                    target: 'socialPlatforms',
                                });
                                $store.dispatch('toggleButtons', {
                                    target: 'platforms_btn',
                                });
                            "
                            v-if="$store.state.socialPlatformsLessBtnIsActive"
                        >
                            {{ $t("buttons.see_less") }}
                        </button>
                    </div>
                </div>
                <!-- END:: FILTER BY SOCIAL MEDIA PLATFORM -->

                <!-- START:: FILTER BY NUMBER OF FOLLOWERS -->
                <div class="group mt-5">
                    <h6 class="slider_range_title">
                        {{ $t("titles.number_of_followers") }}
                    </h6>
                    <div
                        class="wrapper d-flex align-items-center justify-content-center"
                        dir="ltr"
                        @click="disSlider = false"
                    >
                        <span class="slider_min">
                            {{ sliceLowerFollowersNumber }}
                        </span>

                        <v-range-slider
                            :min="followersRange[0]"
                            :max="followersRange[1]"
                            v-model="followersSlider"
                            thumb-label
                            :disabled="disSlider"
                            @input="changeRangeSlider"
                            dir="ltr"
                        >
                            <template v-slot:thumb-label="item">
                                <span
                                    v-if="
                                        item.value.toString().length == 4 ||
                                        item.value.toString().length < 7
                                    "
                                >
                                    {{ (item.value / 1000).toFixed() }}K</span
                                >
                                <span
                                    v-else-if="
                                        item.value.toString().length == 7 ||
                                        item.value.toString().length < 10
                                    "
                                >
                                    {{
                                        (item.value / 1000000).toFixed()
                                    }}M</span
                                >
                            </template>
                        </v-range-slider>
                        <span class="slider_max">
                            {{ sliceHeigherFollowersNumber }}
                        </span>
                    </div>

                    <div class="inner_wrapper followers_filter">
                        <div class="wrapper">
                            <span class="range_title">
                                {{ $t("placeholders.from") }}
                            </span>
                            <!-- <span class="range_value_wrapper">
                                {{ sliceSelectedLowerFollowersNumber }}
                            </span> -->
                            <input
                                type="number"
                                class="range_value_wrapper"
                                onwheel="this.blur()"
                                v-model="min_followrs_input"
                                @change="setDefultFollowrs"
                            />
                        </div>

                        <div class="wrapper">
                            <span class="range_title">
                                {{ $t("placeholders.to") }}
                            </span>
                            <!-- <span class="range_value_wrapper">
                                {{ sliceSelectedHeigherFollowersNumber }}
                            </span> -->
                            <input
                                type="number"
                                class="range_value_wrapper"
                                onwheel="this.blur()"
                                v-model="max_followrs_input"
                                @change="setDefultFollowrs"
                            />
                        </div>
                    </div>
                </div>
                <!-- END:: FILTER BY NUMBER OF FOLLOWERS -->

                <!-- START:: FORM BUTTONS GROUP -->
                <div class="btns_group">
                    <div class="btn_wrapper">
                        <button
                            type="button"
                            class="btn clear_all_button"
                            @click="clearAllFilterdata"
                        >
                            {{ $t("buttons.clear_all") }}
                        </button>
                    </div>

                    <div class="btn_wrapper">
                        <button
                            class="btn apply_filter_btn"
                            type="button"
                            :class="{ disabled: emptyFilter }"
                            @click="submitFilterForm"
                        >
                            {{ $t("buttons.apply") }}
                            <span
                                class="btn_loader"
                                v-if="$store.state.loadFilter"
                            ></span>
                        </button>
                    </div>
                </div>
                <!-- END:: FORM BUTTONS GROUP -->
            </form>
        </div>
        <!-- <router-link to="/explore"> Explore </router-link> -->
    </div>
</template>

<script>
export default {
    name: "TheFilter",

    data() {
        return {
            // START:: FOLLOWERS RANGE DATA
            followersRange: [25000, 10000000],
            // END:: FOLLOWERS RANGE DATA
            followersSlider: [25000, 10000000],
            stepRange: [
                25000, 150000, 250000, 500000, 750000, 1000000, 2000000,
                3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000,
                10000000,
            ],
            disSlider: false,
            min_followrs_input: null,
            max_followrs_input: null,
        };
    },
    computed: {
        sliceLowerFollowersNumber() {
            let lowerFollowersNumber = this.followersRange[0].toString();
            if (
                lowerFollowersNumber.length == 4 ||
                lowerFollowersNumber.length < 7
            ) {
                return `${lowerFollowersNumber.slice(0, -3)}K`;
            } else if (
                lowerFollowersNumber.length == 7 ||
                lowerFollowersNumber.length < 10
            ) {
                return `${lowerFollowersNumber.slice(0, -6)}M`;
            } else {
                return `${lowerFollowersNumber}`;
            }
        },

        sliceSelectedLowerFollowersNumber() {
            let selectedLowerFollowersNumber =
                this.$store.state.filterData.selectedFollowersRange[0].toString();
            if (
                selectedLowerFollowersNumber.length == 4 ||
                selectedLowerFollowersNumber.length < 7
            ) {
                return `${selectedLowerFollowersNumber.slice(0, -3)}K`;
            } else if (
                selectedLowerFollowersNumber.length == 7 ||
                selectedLowerFollowersNumber.length < 10
            ) {
                return `${selectedLowerFollowersNumber.slice(0, -6)}M`;
            } else {
                return `${selectedLowerFollowersNumber}`;
            }
        },

        sliceHeigherFollowersNumber() {
            let heigherFollowersNumber = this.followersRange[1].toString();
            if (
                heigherFollowersNumber.length == 4 ||
                heigherFollowersNumber.length < 7
            ) {
                return `${heigherFollowersNumber.slice(0, -3)}K`;
            } else if (
                heigherFollowersNumber.length == 7 ||
                heigherFollowersNumber.length < 10
            ) {
                return `${heigherFollowersNumber.slice(0, -6)}M`;
            } else {
                return `${heigherFollowersNumber}`;
            }
        },

        sliceSelectedHeigherFollowersNumber() {
            let selectedHeigherFollowersNumber =
                this.$store.state.filterData.selectedFollowersRange[1].toString();
            if (
                selectedHeigherFollowersNumber.length == 4 ||
                selectedHeigherFollowersNumber.length < 7
            ) {
                return `${selectedHeigherFollowersNumber.slice(0, -3)}K`;
            } else if (
                selectedHeigherFollowersNumber.length == 7 ||
                selectedHeigherFollowersNumber.length < 10
            ) {
                return `${selectedHeigherFollowersNumber.slice(0, -6)}M`;
            } else {
                return `${selectedHeigherFollowersNumber}`;
            }
        },
        emptyFilter() {
            if (
                this.$store.state.filterData.country_id == "" ||
                this.$store.state.filterData.sort_by == null ||
                this.$store.state.filterData.gender == null ||
                this.$store.state.filterData.age == null ||
                this.$store.state.filterData.socialMediaArea.length == 0 ||
                this.$store.state.filterData.socialMediaPlatform.length == 0
            ) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        async userLocation() {
            const {
                data: { loc },
            } = await this.$axios.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                {
                    responseType: "text",
                    transformResponse: (data) =>
                        Object.fromEntries(
                            data
                                .trim()
                                .split("\n")
                                .map((line) => line.split("="))
                        ),
                }
            );

            this.$store.state.currentLocation = loc;
            this.$store.dispatch("getCountries");
        },
        // START:: GET CITIES BY COUNTRY ID
        getCitiesByCountryID() {
            this.$store.dispatch("getCities", {
                id: this.$store.state.filterData.country_id,
            });
        },
        // START:: CLEAR ALL FILTERS INPUS
        clearAllFilterdata() {
            this.$store.state.last_filter_page = 0;
            this.$store.state.filteredInfluincers = [];
            this.$store.state.loadFilter = false;
            this.$store.state.filterData.country_id = "";
            this.$store.state.filterData.city_id = "";
            this.$store.state.filterData.sort_by = null;
            this.$store.state.filterData.gender = null;
            this.$store.state.filterData.age = null;
            this.$store.state.filterData.socialMediaArea = [];
            this.$store.state.filterData.socialMediaPlatform = [];
            this.$store.state.filterData.selectedFollowersRange = [
                25000, 10000000,
            ];
            this.disSlider = false;
            this.followersSlider = [25000, 10000000];
            this.min_followrs_input = null;
            this.max_followrs_input = null;
            this.$store.state.theData = {};
            this.$store.state.searchData.keyword = "";
            this.$store.state.filterData.socialMediaArea.forEach((_, index) => {
                this.$store.state.theData[`social_area_id[${index}]`] = null;
            });
            this.$store.state.filterData.socialMediaPlatform.forEach(
                (_, index) => {
                    this.$store.state.theData[`social_platform_id[${index}]`] =
                        null;
                }
            );
        },
        // END:: GET CITIES BY COUNTRY ID
        submitFilterForm() {
            this.$store.state.currentFilterPage = 1;
            this.$store.dispatch("submitFilterForm");
        },

        // END:: CLEAR ALL FILTERS INPUS
        changeRangeSlider() {
            this.min_followrs_input = null;
            this.max_followrs_input = null;
            this.followersSlider[1] = this.stepRange.reduce((a, b) => {
                return Math.abs(b - this.followersSlider[1]) <
                    Math.abs(a - this.followersSlider[1])
                    ? b
                    : a;
            });
            this.followersSlider[0] = this.stepRange.reduce((a, b) => {
                return Math.abs(b - this.followersSlider[0]) <
                    Math.abs(a - this.followersSlider[0])
                    ? b
                    : a;
            });
            this.$store.state.filterData.selectedFollowersRange =
                this.followersSlider;
        },
        setDefultFollowrs() {
            var finalArray = [];
            this.followersSlider = [25000, 10000000];
            this.disSlider = true;
            if (+this.min_followrs_input < 25000) {
                this.min_followrs_input = 25000;
            }
            if (+this.min_followrs_input > +this.max_followrs_input) {
                this.max_followrs_input = this.min_followrs_input;
            }
            finalArray.push(+this.min_followrs_input, +this.max_followrs_input);
            this.$store.state.filterData.selectedFollowersRange = finalArray;
        },
    },

    created() {
        // ======== START:: HANDLING API ========

        // START:: AXIOS GET COUNTRIES
        this.userLocation();
        // END:: AXIOS GET COUNTRIES

        // START:: AXIOS GET SOCIAL MEDIA AREAS
        this.$store.dispatch("getSocialArea");
        // END:: AXIOS GET SOCIAL MEDIA AREAS

        // START:: AXIOS GET SOCIAL PLATFORMS
        this.$store.dispatch("getSocialPlatforms");
        this.clearAllFilterdata();
    },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth_wrapper"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"small_routes_wrapper"},[_c('div',{staticClass:"wrapper"},[_c('router-link',{class:{
                'router-link-exact-active':
                  _vm.$route.name == 'Register' ||
                  _vm.$route.name == 'CompanyRegister' ||
                  _vm.$route.path == '/verification/registeration' ||
                  _vm.$route.name == 'SuccessVerification' ||
                  _vm.$route.name == 'RegisterWorkInfo' ||
                  _vm.$route.name == 'socialAccounts' ||
                  _vm.$route.path == '/verification/success-verification-link' ||
                  _vm.$route.path == '/success-verification-link' ||
                  _vm.$route.path == '/edit-number',
              },attrs:{"to":"/intro"}},[_vm._v(" "+_vm._s(_vm.$t("auth.sign_up"))+" ")])],1),_c('div',{staticClass:"wrapper"},[_c('router-link',{class:{
                'router-link-exact-active':
                  _vm.$route.name == 'Login' ||
                  _vm.$route.name == 'ResetPasswordPhoneNumber' ||
                  _vm.$route.name == 'ResetPasswordForm' ||
                  _vm.$route.name == 'SuccessReset' ||
                  _vm.$route.path == '/verification/reset-password',
              },attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("auth.login"))+" ")])],1)]),_c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.lang == 'en' ? _vm.fadeInLeftBig : _vm.fadeInRightBig),expression:"lang == 'en' ? fadeInLeftBig : fadeInRightBig"}],staticClass:"auth_intro_wrapper"},[_c('img',{attrs:{"src":require("../assets/media/imgs/auth_intro.png"),"alt":"Authentication Intro Image","width":"200","height":"200"}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"overlay_header_wrapper"},[_c('div',{staticClass:"icon_wrapper"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/logo/logo.svg"),"alt":"Inflco Logo","width":"100","height":"100"}})])],1),_c('div',{staticClass:"title_wrapper"},[_vm._v(" "+_vm._s(_vm.$t("titles.auth_intro"))+" ")]),_c('div',{staticClass:"animated_titles_wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.$t("titles.free_register")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("titles.choose")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("titles.target")))]),_c('h3',[_vm._v(_vm._s(_vm.$t("titles.go")))])]),_c('div',{staticClass:"routes_wrapper"},[_c('div',{staticClass:"wrapper"},[_c('router-link',{class:{
                      'router-link-exact-active':
                        _vm.$route.name == 'Register' ||
                        _vm.$route.name == 'CompanyRegister' ||
                        _vm.$route.path == '/verification/registeration' ||
                        _vm.$route.name == 'SuccessVerification' ||
                        _vm.$route.name == 'RegisterWorkInfo' ||
                        _vm.$route.name == 'socialAccounts' ||
                        _vm.$route.path ==
                          '/verification/success-verification-link' ||
                        _vm.$route.path == '/success-verification-link' ||
                        _vm.$route.path == '/edit-number',
                    },attrs:{"to":"/intro"}},[_vm._v(" "+_vm._s(_vm.$t("auth.sign_up"))+" ")])],1),_c('div',{staticClass:"wrapper"},[_c('router-link',{class:{
                      'router-link-exact-active':
                        _vm.$route.name == 'Login' ||
                        _vm.$route.name == 'ResetPasswordPhoneNumber' ||
                        _vm.$route.name == 'ResetPasswordForm' ||
                        _vm.$route.name == 'SuccessReset' ||
                        _vm.$route.path == '/verification/reset-password',
                    },attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("auth.login"))+" ")])],1)])])])])]),_c('div',{staticClass:"col-md-5"},[_c('div',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.lang == 'en' ? _vm.fadeInRightBig : _vm.fadeInLeftBig),expression:"lang == 'en' ? fadeInRightBig : fadeInLeftBig"}],staticClass:"auth_content_wrapper"},[_c('transition',{attrs:{"mode":"out-in","name":"slither"}},[_c('router-view')],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="register_form_wrapper company" v-if="editProfileData">
        <div class="register_header_wrapper">
            <div class="close_edit">
                <!-- :to=" userType == 'investor' ? '/editProfile/updatePassword' : '/update-influincer-password' " -->
                <router-link
                    :to="userType == 'investor' ? '/home' : '/influincer-home'"
                >
                    <i class="fas fa-times"></i>
                </router-link>

                <!-- <button type="button" @click="$router.back()">
                <i class="fas fa-times"></i>
            </button> -->
            </div>

            <div class="title_wrapper edit">
                <h3>{{ $t("titles.edit_profile") }}</h3>

                <div class="button_wrapper">
                    <button
                        class="waiting_acc_btn"
                        v-if="editProfileData.is_sent_deactivate"
                    >
                        <img
                            src="../../assets/media/icons/clock.svg"
                            alt="icon"
                            width="16px"
                            height="16px"
                        />
                        <span> {{ $t("buttons.delete_acc") }} </span>
                    </button>

                    <button
                        class="delete_acc_btn"
                        @click="showDeleteAccModal = !showDeleteAccModal"
                        v-else
                    >
                        <img
                            src="../../assets/media/icons/CurvedDelete.svg"
                            alt="icon"
                            width="16px"
                            height="16px"
                        />
                        <span> {{ $t("buttons.delete_acc") }} </span>
                    </button>
                </div>
            </div>

            <div class="title_wrapper edit">
                <h5>{{ $t("titles.personal_informations") }}</h5>
            </div>

            <UploadImage
                :profileAvatar="editProfileData.image"
                @inputChanged="inputChanged"
                v-if="editProfileData.image"
            />

            <div class="form_wrapper">
                <form @submit.prevent="validateEditProfileForm">
                    <!-- START:: NAME INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.name')"
                            v-model="editProfileData.fullname"
                        />
                    </div>
                    <!-- END:: NAME INPUT -->

                    <!-- START:: PHONE INPUT -->
                    <div class="outer_wrapper">
                        <div
                            class="wrapper mb-3"
                            @click="toggleCountryKeysMenu"
                        >
                            <div class="flag_wrapper">
                                <img
                                    :src="
                                        this.$store.state.selectedCountry.flag
                                    "
                                />
                            </div>
                            <div class="key_wrapper">
                                {{
                                    this.$store.state.selectedCountry.phonecode
                                }}
                            </div>

                            <transition mode="out-in" name="fade">
                                <div
                                    class="country_keys_menu_wrapper"
                                    v-if="countryKiesMenuIsOpen"
                                >
                                    <ul class="country_keys_menu">
                                        <li
                                            class="menu_item"
                                            v-for="item in $store.state
                                                .allCountries"
                                            :key="item.phonecode"
                                            @click="selectCountry(item)"
                                        >
                                            <div class="flag_wrapper">
                                                <img :src="item.flag" />
                                            </div>
                                            <div class="key_wrapper">
                                                {{ item.phonecode }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>

                        <div class="wrapper mb-3">
                            <input
                                type="tel"
                                class="form-control"
                                :placeholder="$t('placeholders.phone')"
                                v-model="editProfileData.phone"
                            />
                        </div>
                    </div>
                    <!-- END:: PHONE INPUT -->

                    <!-- START:: GENDER SELECT INPUT -->
                    <div class="wrapper mb-3">
                        <select
                            class="form-select"
                            aria-label="gender select"
                            v-model="editProfileData.gender"
                            v-if="userType == 'influincer'"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.gender") }}
                            </option>

                            <option value="male">
                                {{ $t("placeholders.male") }}
                            </option>

                            <option value="female">
                                {{ $t("placeholders.female") }}
                            </option>
                        </select>
                    </div>
                    <!-- END:: GENDER SELECT INPUT -->

                    <!-- START:: EMAIL INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.email')"
                            v-model="editProfileData.email"
                            @change="validEmail"
                            v-if="userType == 'investor'"
                        />
                    </div>
                    <!-- END:: EMAIL INPUT -->

                    <!-- START:: COUNTRY SELECT INPUT -->
                    <div class="wrapper mb-3">
                        <select
                            class="form-select"
                            aria-label="country select"
                            v-model="editProfileData.country_id"
                            @change="getCitiesByCountryID()"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.country") }}
                            </option>
                            <option
                                v-for="country in this.$store.state
                                    .allCountries"
                                :key="country.id"
                                :value="country.id"
                            >
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <!-- END:: COUNTRY SELECT INPUT -->

                    <!-- START:: CITY SELECT INPUT -->
                    <div class="wrapper mb-3">
                        <select
                            class="form-select"
                            aria-label="city select"
                            v-model="editProfileData.city_id"
                        >
                            <option selected disabled value="">
                                {{ $t("placeholders.city") }}
                            </option>
                            <option
                                v-for="city in this.$store.state.cities"
                                :key="city.id"
                                :value="city.id"
                            >
                                {{ city.name }}
                            </option>
                        </select>
                    </div>
                    <!-- END:: CITY SELECT INPUT -->

                    <!-- START:: COUSTOM SELECT SOCIAL AREA -->
                    <div
                        class="custom_select mb-5"
                        v-if="userType == 'influincer'"
                    >
                        <div
                            class="head_custom_select"
                            @click="showDropDownFun"
                        >
                            <button type="button" class="btn">
                                <h6
                                    class="fadeIn"
                                    v-if="selectedSocial != null"
                                >
                                    {{ selectedSocialName }}
                                </h6>
                                <h6 class="fadeIn" v-else>
                                    {{ $t("placeholders.select_social_area") }}
                                </h6>
                                <img
                                    src="../../assets/media/icons/arrow_down.svg"
                                    alt="Icon"
                                    width="14"
                                    height="auto"
                                    :class="showDropDown ? 'rotate' : ''"
                                />
                            </button>
                        </div>
                        <transition
                            name="fade"
                            mode="out-in"
                            v-if="showDropDown"
                        >
                            <div class="body_custom_select">
                                <ul>
                                    <li
                                        class="fadeIn"
                                        v-for="(social, key) in this.$store
                                            .state.socialArea"
                                        :key="social.id"
                                        @click="showDropDown = false"
                                    >
                                        <input
                                            type="radio"
                                            name="customSelect"
                                            :id="key"
                                            :value="social.id"
                                            v-model="selectedSocial"
                                            @click="
                                                selectedSocialName = social.name
                                            "
                                        />
                                        <label :for="key">
                                            <span>{{ social.name }}</span>
                                            <span class="icon"> </span>
                                        </label>
                                    </li>
                                    <!-- 15,123 -->
                                </ul>

                                <div
                                    class="wrapper wrapper_other mb-3"
                                    v-if="editProfileData.areas.length == 0"
                                >
                                    <div class="otherSocialArea">
                                        <button
                                            type="button"
                                            class="btn add_btn"
                                            @click="addOther()"
                                        >
                                            <img
                                                src="../../assets/media/icons/plus.svg"
                                                width="25"
                                                height="20"
                                            />
                                            <span>{{
                                                $t("frequently_words.add_other")
                                            }}</span>
                                        </button>
                                    </div>

                                    <div class="input_tags">
                                        <input
                                            type="text"
                                            class="form-control"
                                            :placeholder="
                                                $t('placeholders.add_other')
                                            "
                                            v-model="enterSocialLink"
                                        />
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>

                    <div
                        class="tags mb-5"
                        v-if="editProfileData.areas.length != 0"
                    >
                        <div class="title_intersts">
                            <h6>{{ $t("titles.other_social_area") }} :</h6>
                        </div>
                        <div
                            class="tag fadeIn"
                            v-for="area in editProfileData.areas"
                            :key="area"
                        >
                            <input
                                type="text"
                                name="customSelect"
                                v-model="customSocialArea"
                                id="flexCheckChecked"
                                class="d-none"
                                checked
                            />

                            <label for="flexCheckChecked">{{ area }}</label>
                            <span @click="removeTag(area)"
                                ><i class="fas fa-times"></i
                            ></span>
                        </div>
                    </div>
                    <!-- END:: COUSTOM SELECT SOCIAL AREA -->

                    <!-- START:: BIO INPUT -->
                    <div class="wrapper mb-3" v-if="userType == 'influincer'">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.bio')"
                            v-model="editProfileData.bio"
                        />
                    </div>
                    <!-- END:: BIO INPUT -->

                    <!-- START:: ABOUT VIDEO INPUT -->
                    <div
                        class="wrapper flex-column mb-3"
                        v-if="userType == 'influincer'"
                    >
                        <label
                            for="about_video"
                            class="form-label upload_label"
                        >
                            <span v-if="selectedFileName == null">
                                {{ $t("buttons.add_video_about_you") }}
                            </span>
                            <span v-else> {{ selectedFileName }} </span>

                            <img
                                src="../../assets/media/icons/upload.svg"
                                alt="icon"
                                width="100"
                                height="100"
                            />
                        </label>
                        <input
                            class="form-control"
                            type="file"
                            id="about_video"
                            accept="video/*"
                            @change="handleUploadFile"
                        />
                        <p
                            class="fs-1rem waiting-text my-1"
                            v-if="editProfileData.is_edit_data"
                        >
                            {{ $t("states.waiting_for_accept") }}
                        </p>
                    </div>
                    <!-- END:: ABOUT VIDEO INPUT -->

                    <!-- START:: PASSWRORD AREA -->
                    <div class="password_area mb-3">
                        <router-link
                            :to="
                                userType == 'investor'
                                    ? '/editProfile/updatePassword'
                                    : '/update-influincer-password'
                            "
                        >
                            <span>{{ $t("placeholders.password") }}</span>
                            <img
                                src="../../assets/media/icons/edit_main.svg"
                                alt="edit"
                                width="20"
                                height="20"
                            />
                        </router-link>
                    </div>
                    <!-- END:: PASSWRORD AREA -->

                    <!-- START:: COMPANY DATA INPUTS -->
                    <div class="company" v-if="userType == 'investor'">
                        <div class="title_wrapper edit">
                            <h5>{{ $t("titles.company_informations") }}</h5>
                        </div>

                        <!-- START:: COMPANY NAME INPUT -->
                        <div class="wrapper mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('placeholders.company_name')"
                                v-model="editProfileData.company_name"
                            />
                        </div>
                        <!-- END:: COMPANY NAME INPUT -->

                        <!-- START:: COMPANY ADDRESS INPUT -->
                        <div class="wrapper mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="
                                    $t('placeholders.company_address')
                                "
                                v-model="editProfileData.company_address"
                            />
                        </div>
                        <!-- END:: COMPANY ADDRESS INPUT -->

                        <!-- START:: COMMERCIAL REGISTER INPUT -->
                        <div class="wrapper mb-3 flex-column">
                            <label
                                for="Commercial_Register"
                                class="form-label upload_label"
                            >
                                <span v-if="selectedFileName == null">
                                    {{
                                        $t(
                                            "placeholders.company_commercial_register"
                                        )
                                    }}
                                </span>
                                <span v-else> {{ selectedFileName }} </span>

                                <img
                                    src="../../assets/media/icons/upload.svg"
                                    alt="icon"
                                    width="100"
                                    height="100"
                                />
                            </label>
                            <input
                                class="form-control"
                                type="file"
                                id="Commercial_Register"
                                @change="handleUploadFile"
                            />
                            <p
                                class="fs-1rem waiting-text my-1"
                                v-if="this.editProfileData.is_edit_data"
                            >
                                {{ $t("states.waiting_for_accept") }}
                            </p>
                        </div>
                        <!-- END:: COMMERCIAL REGISTER INPUT -->

                        <!-- START:: ABOUT COMPANY INPUT -->
                        <div class="wrapper mb-3">
                            <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('placeholders.about_company')"
                                v-model="editProfileData.company_desc"
                            />
                        </div>
                        <!-- END:: ABOUT COMPANY INPUT -->
                    </div>
                    <!-- END:: COMPANY DATA INPUTS -->

                    <!-- START:: INTERESTS  -->
                    <div class="title_intersts" v-if="userType == 'influincer'">
                        <h6>{{ $t("titles.interests") }}</h6>
                    </div>

                    <div
                        class="interests_check"
                        v-if="userType == 'influincer'"
                    >
                        <div
                            class="single_interests"
                            v-for="interest in this.$store.state.interests"
                            :key="interest.id"
                        >
                            <input
                                type="checkbox"
                                name="interest"
                                :value="interest.id"
                                :id="interest.id"
                                v-model="selectedInterests"
                            />
                            <label :for="interest.id">{{
                                interest.name
                            }}</label>
                        </div>
                    </div>
                    <!-- START:: INTERESTS  -->

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="wrapper justify-content-center">
                        <button class="btn">
                            {{ $t("buttons.save") }}
                            <span
                                class="btn_loader"
                                v-if="isWaitingRequest"
                            ></span>
                        </button>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </form>
            </div>
        </div>

        <!-- START:: DELETE ACCOUNT MODAL -->
        <BaseModel
            @closeModel="showDeleteAccModal = !showDeleteAccModal"
            :show="showDeleteAccModal"
        >
            <template #edit_social>
                <div class="contracts_modal_report delete_acc_modal">
                    <div class="head_modal_report">
                        <h3>{{ $t("modals.delete_acc_title") }}</h3>
                        <p>{{ $t("modals.delete_acc_subtitle") }}</p>
                    </div>
                    <!-- START:: BODY MODAL -->
                    <div class="body_modal_report">
                        <form @submit.prevent="confirmDeleteAcc">
                            <div class="group mt-5">
                                <div class="inner_wrapper">
                                    <div
                                        class="wrapper mb-3"
                                        v-for="reason in deletingReasons"
                                        :key="reason.id"
                                    >
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            :id="reason.id"
                                            name="reason"
                                            :value="reason.id"
                                            v-model="
                                                deletingData.delete_reason_id
                                            "
                                            @change="
                                                (showOtherReason = false),
                                                    (deletingData.note = null)
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="reason.id"
                                        >
                                            {{ reason.reason }}
                                        </label>
                                    </div>

                                    <div class="wrapper mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            id="addOther"
                                            name="reason"
                                            :value="true"
                                            v-model="showOtherReason"
                                            @click="
                                                deletingData.delete_reason_id =
                                                    null
                                            "
                                        />
                                        <label
                                            class="form-check-label"
                                            for="addOther"
                                        >
                                            {{
                                                $t("placeholders.other_reason")
                                            }}
                                        </label>
                                    </div>

                                    <div
                                        class="wrapper fadeIn mb-3"
                                        v-if="showOtherReason"
                                    >
                                        <textarea
                                            :placeholder="
                                                $t('placeholders.other_reason')
                                            "
                                            v-model="deletingData.note"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="note">
                                <h5>{{ $t("modals.note") }}</h5>
                                <p>
                                    {{ $t("modals.delete_acc_note") }}
                                </p>
                            </div>
                            <!-- START:: FORM BUTTONS GROUP -->
                            <div class="btns_group">
                                <button
                                    class="cancel_btn"
                                    type="button"
                                    @click="showDeleteAccModal = false"
                                >
                                    {{ $t("buttons.cancel") }}
                                </button>
                                <button
                                    class="delete_acc_btn"
                                    @click="getUserProfileInfo"
                                >
                                    {{ $t("buttons.delete") }}
                                    <span
                                        class="btn_loader"
                                        v-if="isWaitingDeleteAccRequest"
                                    ></span>
                                </button>
                            </div>
                            <!-- END:: FORM BUTTONS GROUP -->
                        </form>
                    </div>
                </div>

                <!-- <div class="delete_acc_modal">
          <div class="image_wrapper">
            <img
              src="../../assets/media/illustrations/deleted.svg"
              width="100"
              height="100"
              alt="Trash Image"
            />
          </div>

          <div class="text_wrapper">
            <div class="text">
              {{ $t("titles.delete_your_acc") }}
            </div>
          </div>

          <div class="btns_group">
            <button class="cancel_btn" @click="showDeleteAccModal = false">
              {{ $t("buttons.cancel") }}
            </button>
            <button
              class="delete_acc_btn"
              @click="
                confirmDeleteAcc();
                getUserProfileInfo();
              "
            >
              {{ $t("buttons.delete") }}
              <span class="btn_loader" v-if="isWaitingDeleteAccRequest"></span>
            </button>
          </div>
        </div> -->
            </template>
        </BaseModel>
        <!-- END:: DELETE ACCOUNT MODAL -->

        <!-- START:: ACTION MESSAGE MODAL -->
        <BaseModel :show="showActionMessageModal">
            <template #modal>
                <div class="modal_successfully">
                    <div class="image_successfully">
                        <img
                            src="../../assets/media/illustrations/successfully1.svg"
                            alt="successfully"
                            width="100"
                            height="100"
                        />
                    </div>

                    <div class="text_successfully">
                        <p>{{ $t("modals.delete_acc_sent") }}</p>
                    </div>

                    <form>
                        <div class="wrapper justify-content-center">
                            <button
                                class="btn"
                                type="button"
                                @click="showActionMessageModal = false"
                            >
                                {{ $t("buttons.ok") }}
                            </button>
                        </div>
                    </form>
                </div>
            </template>
        </BaseModel>
        <!-- END:: ACTION MESSAGE MODAL -->
    </div>
</template>

<script>
import UploadImage from "./UploadImage.vue";
import BaseModel from "../ui/BaseModel.vue";

export default {
    name: "Register",

    components: {
        UploadImage,
        BaseModel,
    },

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            isWaitingDeleteAccRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: DELETE ACCOUNT MODAL DATA
            showDeleteAccModal: false,
            // END:: DELETE ACCOUNT MODAL DATA

            // START:: ACTION MESSAGE MODAL DATA
            showActionMessageModal: false,
            // END:: ACTION MESSAGE MODAL DATA

            // START:: USER TYPE DATA
            userType: localStorage.getItem("inflco_user_type"),
            // END:: USER TYPE DATA

            // START:: COUNTRIES KYES MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KYES MENU HANDLING DATA

            // START:: DELETE ACCOUNT REASONS
            deletingReasons: null,
            // END:: DELETE ACCOUNT REASONS

            // START:: DELETING DATA
            deletingData: {
                delete_reason_id: null,
                note: null,
            },
            // END:: DELETING DATA

            // START:: OTER REASONS TEXT AREA HANDLING DATA
            showOtherReason: false,
            // END:: OTER REASONS TEXT AREA HANDLING DATA

            // START:: REGISTER DATA
            editProfileData: {
                image: "",
                fullname: "",
                phone: "",
                phone_key: null,
                gender: "",
                email: "",
                country_id: "",
                city_id: "",
                company_name: "",
                company_address: "",
                company_desc: "",
                bio: "",
                areas: [],
                about_video: "",
                about_video_data: {},
                is_sent_deactivate: false,
                is_edit_data: null,
            },
            // END:: REGISTER DATA

            // START:: CHANGE PASSWORD DATA
            changePasswordData: {
                password: "",
                confirmPassword: "",
            },
            // END:: CHANGE PASSWORD DATA

            // START:: SELECTED INTERSTS ID
            selectedInterests: [],
            // END:: SELECTED INTERSTS ID

            // START:: FORM DATA
            theData: new FormData(),
            // END:: FORM DATA

            // START:: HANDLING UPLOADED FILE DATA
            selectedFile: null,
            selectedFileName: null,
            // END:: HANDLING UPLOADED FILE DATA

            // START:: SOCIAL MEDIA AREA
            enterSocialLink: "",
            showDropDown: false,
            // END:: SOCIAL MEDIA AREA

            // START:: CUSTOM SELECT
            selectedSocial: null,
            customSocialArea: null,
            selectedSocialName: "",
            // START:: CUSTOM SELECT

            // START:: LOGOUT DATA
            logoutData: {
                device_token: "123456789",
                type: "ios",
            },
            // END:: LOGOUT DATA
        };
    },

    methods: {
        // START:: GET USER PROFILE INFO
        async userLocation() {
            const {
                data: { loc },
            } = await this.$axios.get(
                "https://www.cloudflare.com/cdn-cgi/trace",
                {
                    responseType: "text",
                    transformResponse: (data) =>
                        Object.fromEntries(
                            data
                                .trim()
                                .split("\n")
                                .map((line) => line.split("="))
                        ),
                }
            );

            this.$store.state.currentLocation = loc;
            this.$store.dispatch("getCountries");
        },
        getUserProfileInfo() {
            this.showDeleteAccModal = false;
            this.showActionMessageModal = false;

            if (this.userType == "investor") {
                this.$axios
                    .get("investor", {
                        headers: {
                            Authorization:
                                "bearer" +
                                localStorage.getItem("inflco_user_token"),
                            "Content-type": "application/json",
                            "cache-control": "no-cache",
                            Accept: "application/json",
                            "Accept-language":
                                localStorage.getItem("inflco_app_lang"),
                        },
                    })
                    .then((res) => {
                        this.$store.state.allCountries.forEach((element) => {
                            if (element.phonecode == res.data.data.phone_key) {
                                this.$store.state.selectedCountry.flag =
                                    element.flag;
                                this.$store.state.selectedCountry.phonecode =
                                    element.phonecode;
                            }
                        });

                        this.editProfileData.image = res.data.data.image;
                        this.editProfileData.fullname = res.data.data.fullname;
                        this.editProfileData.phone = res.data.data.phone;
                        this.$store.state.selectedCountry.phonecode =
                            res.data.data.phone_key;
                        this.editProfileData.email = res.data.data.email;
                        this.editProfileData.country_id =
                            res.data.data.country.id;
                        this.editProfileData.city_id = res.data.data.city.id;
                        this.editProfileData.company_name =
                            res.data.data.company_name;
                        this.editProfileData.company_address =
                            res.data.data.company_address;
                        this.editProfileData.company_desc =
                            res.data.data.company_desc;
                        this.selectedFileName =
                            res.data.data.company_register_data.name;
                        this.editProfileData.is_sent_deactivate =
                            res.data.data.is_sent_deactivate;
                        this.editProfileData.is_edit_data =
                            res.data.data.is_edit_data;
                        this.getCitiesByCountryID(res.data.data.country.id);
                    });
            } else if (this.userType == "influincer") {
                this.$axios
                    .get(
                        `influencer/${localStorage.getItem(
                            "inflco_user_name"
                        )}`,
                        {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                "Content-type": "application/json",
                                "cache-control": "no-cache",
                                Accept: "application/json",
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        }
                    )
                    .then((res) => {
                        // console.log( res.data );
                        this.$store.state.allCountries.forEach((element) => {
                            if (element.phonecode == res.data.data.phone_key) {
                                this.$store.state.selectedCountry.flag =
                                    element.flag;
                                this.$store.state.selectedCountry.phonecode =
                                    element.phonecode;
                            }
                        });

                        this.editProfileData.image = res.data.data.image;
                        this.editProfileData.fullname = res.data.data.fullname;
                        this.editProfileData.phone = res.data.data.phone;
                        this.editProfileData.gender = res.data.data.gender;
                        this.editProfileData.about_video =
                            res.data.data.about_video;
                        this.selectedFileName =
                            res.data.data.about_video_data.name;
                        this.$store.state.selectedCountry.phonecode =
                            res.data.data.phone_key;
                        this.editProfileData.country_id =
                            res.data.data.country.id;
                        this.editProfileData.city_id = res.data.data.city.id;
                        this.editProfileData.bio = res.data.data.bio;
                        this.editProfileData.is_sent_deactivate =
                            res.data.data.is_sent_deactivate;
                        this.selectedSocial = res.data.data.social_areas[0].id;
                        this.selectedSocialName =
                            res.data.data.social_areas[0].name;

                        res.data.data.interests.forEach((element) => {
                            this.selectedInterests.push(element.id);
                        });

                        this.$store.state.socialArea.push(
                            res.data.data.social_areas[0]
                        );

                        this.editProfileData.is_edit_data =
                            res.data.data.is_edit_data;

                        this.getCitiesByCountryID(res.data.data.country.id);
                    });
            }
        },
        // END:: GET USER PROFILE INFO

        // START:: GET CITIES BY COUNTRY ID
        getCitiesByCountryID(selectedId) {
            this.$store.dispatch("getCities", {
                id: selectedId,
            });
        },
        // END:: GET CITIES BY COUNTRY ID

        // START:: VALIDAE EMAIL
        validEmail(e) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(e.target.value);
        },
        // END:: VALIDAE EMAIL

        // START:: GET DELETING REASONS
        getDeletingReasos() {
            this.$axios
                .get("delete_account_reasons", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.deletingReasons = res.data.data;
                });
        },
        // END:: GET DELETING REASONS

        // START:: SUBMIT REGISTER FORM
        validateEditProfileForm() {
            this.isWaitingRequest = true;
            if (
                (this.editProfileData.phone.length !=
                this.$store.state.phoneDigitNum)
            ) {
                if (this.editProfileData.phone[0] == "0" ) {
                    let numberArray = [...this.editProfileData.phone];
                    numberArray.splice(0, 1);
                    this.editProfileData.phone = numberArray.join('');
                    this.submitAfterValidation();
                } else {
                    this.$iziToast.error({
                        timeout: 5000,
                        message:
                            this.$t("validation.phoneValidationLength") +
                            " " +
                            this.$store.state.phoneDigitNum +
                            " " +
                            this.$t("validation.digit"),
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.buttonIsDisabled = false;
                    this.isWaitingRequest = false;
                    return;
                }
            }else {
                // START:: APPEND FORM DATA
                this.theData.append("fullname", this.editProfileData.fullname);
                this.theData.append("phone", this.editProfileData.phone);
                this.theData.append("email", this.editProfileData.email);
                this.theData.append(
                    "country_id",
                    this.editProfileData.country_id
                );
                this.theData.append("city_id", this.editProfileData.city_id);
                // END:: APPEND FORM DATA

                this.editProfileData.phone_key =
                    this.$store.state.selectedCountry.phonecode;
                this.theData.append(
                    "phone_key",
                    this.editProfileData.phone_key
                );

                if (this.userType == "investor") {
                    // START:: APPEND INVESTOR FORM DATA
                    this.theData.append(
                        "company_name",
                        this.editProfileData.company_name
                    );
                    this.theData.append(
                        "company_address",
                        this.editProfileData.company_address
                    );
                    this.theData.append(
                        "company_desc",
                        this.editProfileData.company_desc
                    );
                    // END:: APPEND INVESTOR FORM DATA
                    this.submitAfterValidation();
                } else if (this.userType == "influincer") {
                    // START:: APPENDING FORM DATA
                    this.theData.append("gender", this.editProfileData.gender);
                    this.theData.append("bio", this.editProfileData.bio);
                    this.selectedInterests.forEach((item, index) => {
                        this.theData.append(`interests_ids[${index}]`, item);
                    });
                    // END:: APPENDING FORM DATA

                    if (this.customSocialArea == null) {
                        this.theData.append(
                            "social_area_ids[0]",
                            this.selectedSocial
                        );
                    } else {
                        this.theData.append("areas[0]", this.customSocialArea);
                    }

                    // ====================================================
                    // this.selectedSocial.forEach((item, index) => {
                    //   this.theData.append(`social_area_ids[${index}]`, item);
                    // });
                    // ====================================================

                    this.submitAfterValidation();
                }
            }
        },

        submitAfterValidation() {
            if( this.userType == "investor" ) {
                                    this.$axios
                        .post("investor/update_profile", this.theData, {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                Accept: "application/json",
                                "Content-type": `multipart/form-data;`,
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        })
                        .then((res) => {
                            if (res.data.status == "success") {
                                this.$iziToast.success({
                                    timeout: 5000,
                                    message: this.$t(
                                        "validation.profile_updated"
                                    ),
                                    position: this.$t("position"),
                                    rtl: this.$t("dir"),
                                });
                                this.isWaitingRequest = false;
                                location.reload();
                            }
                        })
                        .catch(() => {
                            this.isWaitingRequest = false;
                        });
            } else if ( this.userType == "influincer" ) {
                    this.$axios
                        .post("influencer/update_profile", this.theData, {
                            headers: {
                                Authorization:
                                    "bearer" +
                                    localStorage.getItem("inflco_user_token"),
                                Accept: "application/json",
                                "Content-type": `multipart/form-data;`,
                                "Accept-language":
                                    localStorage.getItem("inflco_app_lang"),
                            },
                        })
                        .then((res) => {
                            if (res.data.status == "success") {
                                this.$iziToast.success({
                                    timeout: 5000,
                                    message: this.$t(
                                        "validation.profile_updated"
                                    ),
                                    position: this.$t("position"),
                                    rtl: this.$t("dir"),
                                });
                                this.isWaitingRequest = false;
                                location.reload();
                            }
                        })
                        .catch(() => {
                            this.isWaitingRequest = false;
                        });
            }
        },
        // END:: SUBMIT REGISTER FORM

        // START:: TOGGLE COUNTRIES KYES MENU
        toggleCountryKeysMenu() {
            this.countryKiesMenuIsOpen = !this.countryKiesMenuIsOpen;
        },
        // END:: TOGGLE COUNTRIES KYES MENU

        // START:: SELECT COUNTRY
        selectCountry(item) {
            this.$store.dispatch("selectCountry", { item });
        },
        // END:: SELECT COUNTRY

        // Img Uplode
        inputChanged(img_obj) {
            this.theData.append("image", img_obj);
        },

        // START:: SUBMIT REGISTER FORM
        submitCompanyRegisterForm() {
            this.isWaitingRequest = true;
            setTimeout(() => {
                this.isWaitingRequest = false;
                this.$router.push("/verification/registeration");
            }, 1500);
        },
        // END:: SUBMIT REGISTER FORM

        // START:: HANDLE UPLOADE FILE
        handleUploadFile(e) {
            this.selectedFile = e.currentTarget.files[0];
            this.selectedFileName = e.currentTarget.files[0].name;

            if (this.userType == "investor") {
                // this.theData.append( "company_register",e.currentTarget.files[0]);
                this.theData.append(
                    "company_register_request",
                    e.currentTarget.files[0]
                );
            } else if (this.userType == "influincer") {
                // this.theData.append("about_video", e.currentTarget.files[0]);
                this.theData.append(
                    "about_video_request",
                    e.currentTarget.files[0]
                );
            }
        },
        // END:: HANDLE UPLOADE FILE

        // START:: CUSTOM SELECT
        showDropDownFun() {
            this.showDropDown = !this.showDropDown;
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.showDropDown = false;
            }
        },
        addOther() {
            if (this.enterSocialLink == "") {
                return;
            } else {
                this.editProfileData.areas.push(
                    this.enterSocialLink.toLowerCase()
                );
                this.customSocialArea = this.enterSocialLink;
            }
        },
        // END:: CUSTOM SELECT

        // START:: SEND DELETE ACCOUNT REQUEST
        confirmDeleteAcc() {
            this.isWaitingDeleteAccRequest = true;

            const deletingData = new FormData();
            if (this.deletingData.delete_reason_id !== null) {
                deletingData.append(
                    "del_reason_id",
                    this.deletingData.delete_reason_id
                );
            }
            deletingData.append("other_note", this.deletingData.note);

            this.$axios
                .post("auth/deactivate", deletingData, {
                    headers: {
                        Authorization:
                            "Bearer " +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        this.deletingData.delete_reason_id = null;
                        this.deletingData.note = null;
                        // // START:: CALLING LOGOUT
                        this.logout();
                        // // END:: CALLING LOGOUT

                        // START:: TOGGLE MODALS
                        setTimeout(() => {
                            this.isWaitingDeleteAccRequest = false;
                            this.showActionMessageModal = true;
                        }, 500);
                        // END:: TOGGLE MODALS
                    }
                    this.isWaitingDeleteAccRequest = false;
                    this.showDeleteAccModal = false;
                })
                .catch(() => {
                    this.showDeleteAccModal = false;
                    this.isWaitingDeleteAccRequest = false;
                });

            // this.$axios
            //   .get("auth/deactivate", {
            //     headers: {
            //       Authorization: "Bearer" + localStorage.getItem("inflco_user_token"),
            //       Accept: "application/json",
            //     },
            //   })
            //   .then((res) => {
            //     console.log(res.data);
            //     this.isWaitingDeleteAccRequest = false;
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //     this.isWaitingDeleteAccRequest = false;
            //   });
        },
        // END:: SEND DELETE ACCOUNT REQUEST

        // START:: REMOVE TAG METHOD
        removeTag(name) {
            this.editProfileData.areas.splice(name, 1);
            this.enterSocialLink = null;
            this.customSocialArea = null;
        },
        // END:: REMOVE TAG METHOD

        // START:: LOGOUT
        logout() {
            const data = new FormData();
            data.append("device_token", this.logoutData.device_token);
            data.append("type", this.logoutData.type);
            this.$axios
                .post("auth/logout", data, {
                    headers: {
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": `multipart/form-data;`,
                        Accept: "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status == "success") {
                        this.$iziToast.success({
                            timeout: 5000,
                            message: this.$t("auth.logout"),
                            position: this.$t("position"),
                            rtl: this.$t("dir"),
                        });
                    }
                    this.$router.replace("/");
                    localStorage.removeItem("inflco_user_type");
                    localStorage.removeItem("inflco_user_id");
                    localStorage.removeItem("inflco_user_token");
                    localStorage.removeItem("inflco_user_phone");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    localStorage.removeItem("inflco_user_name");
                    localStorage.removeItem("inflco_user_is_active");
                    localStorage.removeItem("inflco_user_login");
                    location.reload();
                })
                .catch(() => {
                    this.$router.replace("/");
                    localStorage.removeItem("inflco_user_type");
                    localStorage.removeItem("inflco_user_id");
                    localStorage.removeItem("inflco_user_token");
                    localStorage.removeItem("inflco_user_phone");
                    localStorage.removeItem("inflco_user_phone_key");
                    localStorage.removeItem("inflco_user_phone_number");
                    localStorage.removeItem("inflco_user_name");
                    localStorage.removeItem("inflco_user_is_active");
                    localStorage.removeItem("inflco_user_login");
                    location.reload();
                });
        },
        // END:: LOGOUT
    },

    created() {
        // ======== START:: HANDLING API ========

        // START:: AXIOS GET INTERESTS
        this.$store.dispatch("getInterests");
        // END:: AXIOS GET INTERESTS

        // START:: AXIOS GET SOCIAL AREAS
        this.$store.dispatch("getSocialArea");
        // END:: AXIOS GET SOCIAL AREAS

        // START:: AXIOS GET COUNTRIES

        // END:: AXIOS GET COUNTRIES

        // START:: AXIOS GET USER PROFILE INFO
        this.getUserProfileInfo();
        // END:: AXIOS GET USER PROFILE INFO

        // ======== END:: HANDLING API ========
    },

    mounted() {
        document.addEventListener("click", this.close);
        this.userLocation();
        // START:: GET DELETING REASONS
        this.getDeletingReasos();
        // END:: GET DELETING REASONS
    },

    beforeDestroy() {
        document.removeEventListener("click", this.close);
    },
};
</script>

<template>
    <div class="staticPages">
        <div class="page_title">
            <h1>{{ $t("titles.contact_us") }}</h1>
        </div>
        <div class="map_wrapper">
            <GmapMap
                :center="{
                    lat: parseInt(contactData.lat),
                    lng: parseInt(contactData.lng),
                }"
                :zoom="7"
                map-type-id="terrain"
                style="width: 100%; height: 400px"
            >
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center = m.position"
                />
            </GmapMap>
        </div>

        <div class="cards_wrapper">
            <div class="row">
                <div class="col-md-4">
                    <a
                        style="text-decoration: none"
                        :href="
                            'https://www.google.com/maps?q=' +
                            contactData.lat +
                            ',' +
                            contactData.lng +
                            '&hl=es;z%3D14&amp'
                        "
                        target="_blank"
                    >
                        <div class="contact_card address">
                            <h5>
                                <span>
                                    <img
                                        src="../../assets/media/icons/mapMark.svg"
                                        alt="icon"
                                        width="20"
                                        height="20"
                                    />
                                </span>

                                <span>
                                    {{ $t("titles.address") }}:
                                    {{ contactData.address }}
                                </span>
                            </h5>
                        </div>
                    </a>
                </div>

                <div class="col-md-4">
                    <a
                        :href="
                            'https://api.whatsapp.com/send?phone=' +
                            contactData.whatsapp
                        "
                        style="text-decoration: none"
                        target="_blank"
                    >
                        <div class="contact_card phone">
                            <h5>
                                <span>
                                    <img
                                        src="../../assets/media/icons/whatsapp.svg"
                                        alt="icon"
                                        width="20"
                                        height="20"
                                    />
                                </span>

                                <span> {{ contactData.whatsapp }} </span>
                            </h5>
                        </div>
                    </a>
                </div>

                <div class="col-md-4">
                    <a
                        :href="'mailto:' + contactData.email"
                        style="text-decoration: none"
                        target="_blank"
                    >
                        <div class="contact_card mail">
                            <h5>
                                <span>
                                    <img
                                        src="../../assets/media/icons/invelop.svg"
                                        alt="icon"
                                        width="20"
                                        height="20"
                                    />
                                </span>

                                <span> {{ contactData.email }} </span>
                            </h5>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactUs",

    data() {
        return {
            contactData: {
                email: "",
                phones: [],
                facebook: "",
                twitter: "",
                instagram: "",
                tiktok: "",
                youtube: "",
                whatsapp: "",
                address: "",
                lat: "",
                lng: "",
                location: "",
            },
        };
    },

    methods: {
        getStaticContent() {
            this.$axios
                .get("settings/social_info", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.contactData.email = res.data.data.email;
                    this.contactData.facebook = res.data.data.facebook;
                    this.contactData.twitter = res.data.data.twitter;
                    this.contactData.instagram = res.data.data.instagram;
                    this.contactData.tiktok = res.data.data.tiktok;
                    this.contactData.youtube = res.data.data.youtube;
                    this.contactData.address = res.data.data.address;
                    this.contactData.lat = res.data.data.lat;
                    this.contactData.lng = res.data.data.lng;
                    this.contactData.location = res.data.data.location;
                    this.contactData.phones = res.data.data.phones[0];
                    this.contactData.whatsapp = res.data.data.whatsapp_phone;
                });
        },
    },

    mounted() {
        this.getStaticContent();
    },
};
</script>

<template>
    <div class="register_form_wrapper">
        <div class="register_header_wrapper">
            <div class="logo_wrapper">
                <img
                    src="../../assets/logo/small_logo.svg"
                    alt="Inflico Logo"
                    width="100"
                    height="100"
                />
            </div>

            <div class="title_wrapper">
                <h2 v-if="userType == 'investor'">
                    {{ $t("titles.investor_sign_up") }}
                </h2>
                <h2 v-if="userType == 'influincer'">
                    {{ $t("titles.influincer_sign_up") }}
                </h2>
            </div>

            <div class="slogan_wrapper">
                <h5>{{ $t("titles.sign_up_description") }}</h5>
            </div>

            <div class="title_wrapper">
                <h5>{{ $t("titles.work_information") }}</h5>
            </div>

            <div class="form_wrapper">
                <form @submit.prevent="submitRegisterForm">
                    <!-- START:: UPLOAD IMAGE -->
                    <UploadImage @inputChanged="inputChanged" />
                    <!-- END:: UPLOAD IMAGE -->

                    <!-- START:: SOCIAL AREA -->
                    <!-- <div
            class="wrapper wrapper_multiselect mb-4 fadeIn"
            v-for="social in socialArray"
            :key="social.id"
          >
            <select
              class="form-select"
              aria-label="country select"
              v-model="social.id"
            >
              <option selected disabled>Select Social Plateform</option>
              <option
                :value="social.id"
                v-for="social in $store.state.socialArea"
                :key="social.id"
              >
                {{ social.name }}
              </option>
            </select> -->

                    <!-- <button
              type="button"
              class="btn add_btn"
              @click="addSocialArea()"
              v-if="index == 0"
            >
              <img
                src="../../assets/media/icons/plus.svg"
                width="25"
                height="20"
              />
            </button> -->

                    <!-- <button
              type="button"
              class="btn add_btn"
              @click="deleteSocialArea(index)"
              v-else
            >
              <img
                src="../../assets/media/icons/remove.svg"
                width="25"
                height="20"
              />
            </button> -->
                    <!-- </div> -->
                    <!-- END:: SOCIAL AREA -->

                    <!-- START:: ADD OTHER -->

                    <!-- <div
            class="wrapper tags_wrapper fadeIn mb-3"
            v-if="workInformation.areas.length > 0"
          >
            <div class="tags">
              <div
                class="tag fadeIn"
                v-for="area in workInformation.areas"
                :key="area"
              >
                <p>{{ area }}</p>
                <span><i class="fas fa-times"></i></span>
              </div>
            </div>
          </div> -->
                    <!-- END:: ADD OTHER -->

                    <!-- START:: COUSTOM SELECT SOCIAL AREA -->
                    <div class="custom_select mb-5">
                        <div
                            class="head_custom_select"
                            @click="showDropDownFun"
                        >
                            <button type="button" class="btn">
                                <h6
                                    class="fadeIn"
                                    v-if="selectedSocial != null"
                                >
                                    {{ selectedSocialName }}
                                </h6>
                                <h6 class="fadeIn" v-else>
                                    {{ $t("titles.select_social_area") }}
                                </h6>
                                <img
                                    src="../../assets/media/icons/arrow_down.svg"
                                    alt="Icon"
                                    width="14"
                                    height="auto"
                                    :class="showDropDown ? 'rotate' : ''"
                                />
                            </button>
                        </div>
                        <transition
                            name="fade"
                            mode="out-in"
                            v-if="showDropDown"
                        >
                            <div class="body_custom_select">
                                <ul>
                                    <li
                                        class="fadeIn"
                                        v-for="(social, key) in this.$store
                                            .state.socialArea"
                                        :key="social.id"
                                        @click="showDropDown = false"
                                    >
                                        <input
                                            type="radio"
                                            name="customSelect"
                                            :id="key"
                                            :value="social.id"
                                            v-model="selectedSocial"
                                            @click="
                                                selectedSocialName = social.name
                                            "
                                        />
                                        <label :for="key">
                                            <span>{{ social.name }}</span>
                                            <span class="icon"> </span>
                                        </label>
                                    </li>
                                    <!-- 15,123 -->
                                </ul>

                                <!-- <div
                                    class="wrapper wrapper_other mb-3"
                                    v-if="workInformation.areas.length == 0"
                                >
                                    <div class="otherSocialArea">
                                        <button
                                            type="button"
                                            class="btn add_btn"
                                            @click="addOther()"
                                        >
                                            <img
                                                src="../../assets/media/icons/plus.svg"
                                                width="25"
                                                height="20"
                                            />
                                            <span>{{
                                                $t("frequently_words.add_other")
                                            }}</span>
                                        </button>
                                    </div>

                                    <div class="input_tags">
                                        <input
                                            type="text"
                                            class="form-control"
                                            :placeholder="
                                                $t('placeholders.add_other')
                                            "
                                            v-model="enterSocialLink"
                                        />
                                    </div>
                                </div> -->
                            </div>
                        </transition>
                    </div>
                    <!-- END:: COUSTOM SELECT SOCIAL AREA -->

                    <div
                        class="tags mb-5"
                        v-if="workInformation.areas.length != 0"
                    >
                        <div class="title_intersts">
                            <h6>
                                {{ $t("titles.other_social_media_area") }} :
                            </h6>
                        </div>
                        <div
                            class="tag fadeIn"
                            v-for="area in workInformation.areas"
                            :key="area"
                        >
                            <input
                                type="text"
                                name="customSelect"
                                v-model="customSocialArea"
                                id="flexCheckChecked"
                                class="d-none"
                                checked
                            />

                            <label for="flexCheckChecked">{{ area }}</label>
                            <span @click="removeTag(area)"
                                ><i class="fas fa-times"></i
                            ></span>
                        </div>
                    </div>

                    <!-- START:: COMMERCIAL REGISTER INPUT -->
                    <div class="wrapper mb-3">
                        <label
                            for="Commercial_Register"
                            class="form-label upload_label"
                        >
                            <span v-if="selectedFileName == null">
                                {{ $t("placeholders.video") }}
                            </span>
                            <span v-else> {{ selectedFileName }} </span>

                            <img
                                src="../../assets/media/icons/upload.svg"
                                alt="icon"
                                width="100"
                                height="100"
                            />
                        </label>
                        <input
                            class="form-control"
                            type="file"
                            id="Commercial_Register"
                            accept="video/*"
                            @change="handleUploadFile"
                        />
                        <video
                            id="test_input"
                            class="d-none"
                            controls
                            :src="videoSrc"
                        ></video>
                    </div>
                    <!-- END:: COMMERCIAL REGISTER INPUT -->

                    <!-- START:: BIO INPUT -->
                    <div class="wrapper mb-3">
                        <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('placeholders.bio')"
                            v-model="workInformation.bio"
                        />
                    </div>
                    <!-- END:: BIO INPUT -->

                    <!-- START:: INTERESTS  -->
                    <div class="title_intersts">
                        <h6>{{ $t("titles.interests") }}</h6>
                    </div>

                    <div class="interests_check">
                        <div
                            class="single_interests"
                            v-for="interest in this.$store.state.interests"
                            :key="interest.id"
                        >
                            <input
                                type="checkbox"
                                name="interest"
                                :value="interest.id"
                                :id="interest.id"
                                v-model="selectedInterests"
                            />
                            <label :for="interest.id">{{
                                interest.name
                            }}</label>
                        </div>
                    </div>
                    <!-- START:: INTERESTS  -->

                    <!-- START:: SUBMIT BUTTON -->
                    <div class="wrapper mt-5 justify-content-center">
                        <button
                            class="btn"
                            :class="{ disabled: buttonIsDisabled }"
                        >
                            {{ $t("buttons.next") }}
                            <span
                                class="btn_loader"
                                v-if="isWaitingRequest"
                            ></span>
                        </button>
                    </div>
                    <!-- END:: SUBMIT BUTTON -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import UploadImage from "../profile/UploadImage.vue";

export default {
    name: "Register",

    components: {
        UploadImage,
    },

    data() {
        return {
            // START:: BUTTON LOADER DATA
            isWaitingRequest: false,
            // END:: BUTTON LOADER DATA

            // START:: USER TYPE DATA
            userType: this.$route.params.type,
            // END:: USER TYPE DATA

            // START:: HANDLING UPLOADED FILE DATA
            selectedFile: null,
            selectedFileName: null,
            videoSrc: null,
            // END:: HANDLING UPLOADED FILE DATA

            // START:: SELECTED INTERSTS ID
            selectedInterests: [],
            // END:: SELECTED INTERSTS ID

            // START:: REGISTER DATA
            workInformation: {
                user_id: localStorage.getItem("inflco_user_id"),
                image: null,
                social_area_ids: [],
                about_video: null,
                interests_ids: [],
                areas: [],
                bio: null,
            },
            enterSocialLink: "",
            // END:: REGISTER DATA

            // START:: SOCIAL MEDIA AREA
            socialArray: [
                {
                    id: null,
                },
            ],
            showDropDown: false,
            // END:: SOCIAL MEDIA AREA

            // START:: LANGUAGE MENU DATA
            languageMenuIsOpen: false,
            // END:: LANGUAGE MENU DATA

            // START:: CUSTOM SELECT
            selectedSocial: null,
            customSocialArea: null,
            selectedSocialName: "",
            // START:: CUSTOM SELECT

            // START:: BUTTON DISABLED
            buttonIsDisabled: false,
            // END:: BUTTON DISABLED
        };
    },

    methods: {
        removeTag(name) {
            this.workInformation.areas.splice(name, 1);
            this.enterSocialLink = null;
            this.customSocialArea = null;
        },
        // START:: SUBMIT REGISTER FORM
        submitRegisterForm() {
            this.isWaitingRequest = true;
            this.buttonIsDisabled = true;
            const data = new FormData();

            data.append("user_id", localStorage.getItem("inflco_user_id"));
            data.append("image", this.workInformation.image);
            data.append("bio", this.workInformation.bio);
            data.append("about_video", this.workInformation.about_video);
            // ====================================================
            if (this.customSocialArea == null) {
                data.append("social_area_ids[0]", this.selectedSocial);
            } else {
                data.append("areas[0]", this.customSocialArea);
            }

            // ====================================================
            // this.selectedSocial.forEach((item, index) => {
            //   data.append(`social_area_ids[${index}]`, item);
            // });
            // ====================================================

            this.selectedInterests.forEach((item, index) => {
                data.append(`interests_ids[${index}]`, item);
            });
            this.$axios
                .post("influencer/signup_second_step", data, {
                    headers: {
                        "Content-type": `multipart/form-data;`,
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                    },
                })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.buttonIsDisabled = false;
                    if (res.data.status == "success") {
                        // this.$iziToast.success({
                        //   timeout: 5000,
                        //   message: "Done",
                        //   position: this.$t("position"),
                        //   rtl: this.$t("dir"),
                        // });
                        localStorage.setItem(
                            "inflco_user_token",
                            res.data.data.token.access_token
                        );
                        this.$router.replace("/social-accounts");
                    }
                    // else {
                    //   this.isWaitingRequest = false;
                    //   this.buttonIsDisabled = false;
                    //   this.$iziToast.error({
                    //     timeout: 5000,
                    //     message:" res.data.message",
                    //     position: this.$t("position"),
                    //     rtl: this.$t("dir"),
                    //   });

                    // }
                })
                .catch((error) => {
                    this.$iziToast.error({
                        timeout: 5000,
                        message: error.response.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.isWaitingRequest = false;
                    this.buttonIsDisabled = false;
                });
        },
        // END:: SUBMIT REGISTER FORM

        // START:: SOCIAL MEDIA AREA
        addSocialArea() {
            this.selectedSocial.push();
        },

        deleteSocialArea: function (index) {
            this.socialArray.splice(index, 1);
        },
        // END:: SOCIAL MEDIA AREA

        // START:: UPLOAD IMAGE
        inputChanged(img_obj) {
            this.workInformation.image = img_obj;
        },
        // END:: UPLOAD IMAGE

        // START:: HANDLE UPLOADE FILE
        handleUploadFile(e) {
            this.workInformation.about_video = e.currentTarget.files[0];
            this.selectedFile = e.currentTarget.files[0];
            this.selectedFileName = e.currentTarget.files[0].name;
            this.videoSrc = URL.createObjectURL(
                this.workInformation.about_video
            );

            var that = this;
            let my_video = document.getElementById("test_input");
            my_video.ondurationchange = function () {
                if (this.duration > 30) {
                    that.selectedFile = null;
                    that.selectedFileName = null;
                    that.videoSrc = null;

                    that.$iziToast.info({
                        timeout: 5000,
                        message: that.$t("validation.video"),
                        position: that.$t("position"),
                        rtl: that.$t("dir"),
                    });
                }
            };

            // if (this.duration > 30) {
            //     this.$iziToast.error({
            //       timeout: 5000,
            //       message: "Error",
            //       position: this.$t("position"),
            //       rtl: this.$t("dir"),
            //     });
            //   } else {
            //     this.$iziToast.success({
            //       timeout: 5000,
            //       message: "Success",
            //       position: this.$t("position"),
            //       rtl: this.$t("dir"),
            //     });
            //   }
        },
        // END:: HANDLE UPLOADE FILE

        // START:: SOCIAL MEDIA AREA
        addOther() {
            if (this.enterSocialLink == "") {
                return;
            } else {
                this.workInformation.areas.push(
                    this.enterSocialLink.toLowerCase()
                );
                this.customSocialArea = this.enterSocialLink;
            }
        },
        // END:: SOCIAL MEDIA AREA

        // START:: CUSTOM SELECT
        showDropDownFun() {
            this.showDropDown = !this.showDropDown;
        },
        close(e) {
            if (!this.$el.contains(e.target)) {
                this.showDropDown = false;
            }
        },

        // START:: GET TIME VIDEO
        // END:: GET TIME VIDEO
    },

    beforeDestroy() {
        document.removeEventListener("click", this.close);
    },

    mounted() {
        // ======== START:: HANDLING API ========

        this.$store.dispatch("getInterests");
        this.$store.dispatch("getSocialArea");
        document.addEventListener("click", this.close);

        // ======== END:: HANDLING API ========
    },
};
</script>

<template>
  <div class="empty_content_wrapper">
    <div class="empty_content_wrapper small_wrapper no_matched_data">
      <div class="vector_shape_wrapper">
        <img
          src="../../assets/media/illustrations/path.svg"
          width="150"
          height="150"
        />

        <div class="note">
          <span> {{ noDataTitlte }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoMatchedData",

  props: ["noDataTitlte"],
};
</script>

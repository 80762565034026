<template>
  <div class="uplodeImage">
    <div class="chooseImage">
      <transition name="fadeInUp" mode="out-in">
        <img v-if="image.img_src" :src="image.img_src" />
        <img
          v-if="profile_avatar.includes('backgrounds/avatar.jpg')"
          src="../../assets/media/icons/avatar_placeholer.svg"
          width="180"
          height="180"
        />
        <img v-else :src="profile_avatar" width="180" height="180" />
      </transition>
      <div class="icon_edit">
        <img
          src="../../assets/media/icons/edit_w.svg"
          alt="edit"
          width="20"
          height="20"
        />
      </div>
      <input
        ref="uplode_file"
        class="file_input"
        type="file"
        name="imageToUplode"
        @change="chooseImage"
        accept="image/png, image/jpeg, image/jpg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadImage",

  emits: ["inputChanged"],
  props: ["profileAvatar"],

  data() {
    return {
      image: {
        img_file: null,
        img_src: "",
      },
    };
  },

  computed: {
    profile_avatar() {
      if (this.profileAvatar) {
        return this.profileAvatar;
      } else {
        return require("../../assets/media/icons/avatar_placeholer.svg");
      }
    },
  },

  methods: {
    chooseImage(e) {
      this.image.img_file = e.target.files[0];

      if (this.image.img_file) {
        this.image.img_src = URL.createObjectURL(this.image.img_file);
        this.uplodeDisabled = false;
      }

      this.$emit("inputChanged", this.image.img_file);
      e.target.value = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.uplodeImage {
  .chooseImage {
    position: relative;
    width: 130px;
    height: 130px;
    text-align: center;
    cursor: pointer;
    margin: 1rem auto;
    transition: all 2s ease-in-out;

    img {
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      font-size: 1.5rem;
      text-transform: uppercase;
      cursor: pointer;
      border-radius: 50%;
      object-fit: cover;
    }

    .file_input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: inline-block;
      opacity: 0;
      cursor: pointer;
    }
    .icon_edit {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      img {
        width: 20px;
        object-fit: unset;
      }
    }
    &:hover {
      .icon_edit {
        opacity: 1;
      }
    }
  }

  .image {
    width: 50%;
    margin: 2rem auto;

    @media (max-width: 900px) {
      width: 70%;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      min-height: 30rem;
      max-height: 30rem;
    }
  }
}
</style>

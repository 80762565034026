<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="explore_influincers_wrapper">
    <div class="title_wrapper">
      <h3>{{ $t("titles.search_result") }}</h3>
      <div class="btn_wrapper explore">
        <router-link to="/explore">
          <img
            src="../../assets/media/icons/filter.svg"
            alt="Icon"
            width="100"
            height="100"
          />
        </router-link>
      </div>

      <div class="btn_wrapper filter">
        <router-link to="/filter">
          <img
            src="../../assets/media/icons/filter.svg"
            alt="Icon"
            width="100"
            height="100"
          />
        </router-link>
      </div>
    </div>
    <Loader v-if="$store.state.loaderCards" :number="6" class="fadeIn"></Loader>
    <section v-else class="fadeIn">
      <NoMatchedData
        :noDataTitlte="$t('titles.no_matched_data')"
        v-if="$store.state.searchInfluincers.length == 0"
      />
      <div class="explore_influincers_body_content" v-else>
        <div
          class="influincer_data_wrapper"
          v-for="influincer in $store.state.searchInfluincers"
          :key="influincer.id"
        >
          <router-link
            :to="{
              name: 'InfluincerProfile',
              params: { influincer_id: influincer.id },
            }"
            class="profile_route"
          >
            <div class="avatar_wrapper">
              <img
                :src="influincer.image"
                alt="Influincer Avatar"
                width="100"
                height="100"
              />
            </div>

            <div class="details_wrappe">
              <div class="name">
                <span> {{ influincer.fullname }} </span>
                <span>
                  <i class="fas fa-star"></i>
                  {{ influincer.rate }}
                </span>
              </div>

              <div class="fields">
                <span v-for="field in influincer.social_areas" :key="field.id">
                  {{ field.name }}
                </span>
              </div>
            </div>
          </router-link>

          <router-link
            :to="{ name: 'TheChat', params: { influincer_id: influincer.id } }"
            class="influincer_chat_route"
          >
            <img
              src="../../assets/media/icons/chat_blue.svg"
              alt="Icon"
              width="100"
              height="100"
            />
          </router-link>
        </div>
      </div>
      <!-- pagentaion  -->
      <div class="pagenation" v-if="$store.state.last_search_page > 1">
        <ul>
          <li
            v-for="index in $store.state.last_search_page"
            :key="index"
            v-if="
              Math.abs($store.state.currentSearchPage - index) < 4 ||
              index == $store.state.last_search_page ||
              index == 0
            "
            :class="{
              last:
                index == $store.state.last_search_page &&
                Math.abs($store.state.currentSearchPage - index) > 4,
            }"
          >
            <button
              type="button"
              @click="pagenationSearch(index)"
              :class="{
                active: index == $store.state.currentSearchPage,
                last:
                  $store.state.last_search_page == index &&
                  Math.abs($store.state.currentSearchPage - index) > 4,
              }"
            >
              {{ index }}
            </button>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import NoMatchedData from "../emptyMessages/NoMatchedData.vue";
import Loader from "./../loader/explore.vue";
export default {
  name: "SearchResult",
  data() {
    return {
      // START::
      influincerName: this.$route.params.name,
      // END::
    };
  },
  methods: {
    pagenationSearch(pageSearchNumber) {
      this.$store.dispatch("pagenationSearch", {
        pageSearchNumber: pageSearchNumber,
      });
    },
  },
  components: {
    NoMatchedData,
    Loader,
  },
};
</script>

<template>
    <!-- <teleport to="body"> -->
    <transition mode="in-out" name="fade">
        <div v-if="show" class="model_container">
            <div class="main_body">
                <slot name="influincer_video"></slot>
                <slot name="modal"></slot>
                <slot name="edit_social"></slot>
            </div>
            <div class="modal_overlay" @click="closeModelMedia"></div>
            <!--  @click="closeModel" -->
        </div>
    </transition>

    <!-- </teleport> -->
</template>

<script>
export default {
    name: "Model",

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    emits: ["closeModel"],

    methods: {
        closeModel() {
            this.$emit("closeModel");
        },
        closeModelMedia() {
            this.$emit("closeModelMedia");
        },
    },
};
</script>

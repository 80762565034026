<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="influincer_social_accounts_wrapper">
        <div class="title_wrapper">
            <h3>{{ $t("titles.social_accounts") }}</h3>

            <router-link to="/personal-profile">
                <img
                    src="../../assets/media/icons/user.svg"
                    alt="icon"
                    width="25"
                    height="25"
                />
            </router-link>

            <div class="button_wrapper">
                <button @click="show = !show">
                    <img
                        src="../../assets/media/icons/edit_main.svg"
                        alt="icon"
                        width="20"
                        height="20"
                    />
                    <span> {{ $t("buttons.manage_accounts") }} </span>
                </button>
            </div>
        </div>

        <div class="subtitle">
            {{ $t("titles.social_account_subtitle") }}
        </div>

        <div class="social_accounts">
            <Loader v-if="loadCards" class="fadeIn heigth-50vh"></Loader>
            <div class="container-fluid p-0 fadeIb" v-else>
                <div
                    class="row justify-content-center"
                    v-if="social_links.length != 0"
                >
                    <div
                        v-for="account in social_links"
                        :key="account.id"
                        class="col-md-4 my-3"
                    >
                        <div class="social_account_card">
                            <div class="title_wrapper">
                                <!-- START:: SOCIAL MEDIA ICONS -->
                                <img
                                    :src="account.social_platform.icon"
                                    alt="icon"
                                    width="25"
                                    height="25"
                                />
                                <!-- START:: SOCIAL MEDIA ICONS -->

                                <!-- <span
                  :class="account.link.includes('facebook') ? 'facebook' : ''"
                  v-if="account.link.includes('facebook')"
                >
                  {{ account.total_followers }}
                </span>

                <span
                  :class="account.link.includes('twitter') ? 'twitter' : ''"
                  v-if="account.link.includes('twitter')"
                >
                  {{ account.total_followers }}
                </span>

                <span
                  :class="account.link.includes('instagram') ? 'instagram' : ''"
                  v-if="account.link.includes('instagram')"
                >
                  {{ account.total_followers }}
                </span>
                <span
                  :class="account.link.includes('tiktok') ? 'tiktok' : ''"
                  v-if="account.link.includes('tiktok')"
                >
                  {{ account.total_followers }}
                </span>
                <span
                  :class="account.link.includes('snapchat') ? 'snapchat' : ''"
                  v-if="account.link.includes('snapchat')"
                >
                  {{ account.total_followers }}
                </span> -->
                                <!-- <span
                  :class="account.link.includes('youtube') ? 'youtube' : ''"
                  v-if="account.link.includes('youtube')"
                >
                  {{ account.total_followers }}
                </span> -->
                                <span
                                    :class="
                                        account.social_platform.key_name.toLowerCase()
                                    "
                                    v-if="
                                        account.total_followers.toString()
                                            .length < 4
                                    "
                                >
                                    {{ account.total_followers.toString() }}
                                </span>
                                <span
                                    :class="
                                        account.social_platform.key_name.toLowerCase()
                                    "
                                    v-else-if="
                                        account.total_followers.toString()
                                            .length == 4 ||
                                        account.total_followers.toString()
                                            .length < 7
                                    "
                                >
                                    {{
                                        (
                                            account.total_followers / 1000
                                        ).toFixed()
                                    }}K
                                </span>
                                <span
                                    :class="
                                        account.social_platform.key_name.toLowerCase()
                                    "
                                    v-else-if="
                                        account.total_followers.toString()
                                            .length == 7 ||
                                        account.total_followers.toString()
                                            .length < 10
                                    "
                                >
                                    {{
                                        (
                                            account.total_followers / 1000000
                                        ).toFixed(1)
                                    }}M
                                </span>
                            </div>

                            <div class="social_card_body">
                                <div class="platform w-100 d-flex justify-content-between">
                                    {{ account.social_platform.name }}

                                    <span class="social_account_condition">
                                        <i class="fas fa-check accept-text" v-show="account.status == 'accepted'"></i>
                                        <i class="far fa-clock waiting-text" v-show="account.status == 'pending'"></i>
                                        <i class="fas fa-times refuced-text" v-show="account.status == 'rejected'"></i>
                                    </span>
                                </div>
                                <a
                                    :href="account.link"
                                    class="platform_link"
                                    target="_blank"
                                    dir="ltr"
                                    v-if="account.link.length >= 30  && account.status == 'accepted'"
                                >
                                    {{ account.link.substring(0, 30) + "..." }}
                                </a>
                                <a
                                    :href="account.link"
                                    class="platform_link"
                                    target="_blank"
                                    dir="ltr"
                                    v-if="account.link.length <= 30  && account.status == 'accepted'"
                                >
                                    {{ account.link }}
                                </a>

                                <a
                                    href="javascript:;"
                                    class="platform_link"
                                    dir="ltr"
                                    v-if="account.link.length >= 30  && (account.status == 'pending' || account.status == 'rejected')"
                                >
                                    {{ account.link.substring(0, 30) + "..." }}
                                </a>
                                <a
                                    href="javascript:;"
                                    class="platform_link"
                                    dir="ltr"
                                    v-if="account.link.length <= 30  && (account.status == 'pending' || account.status == 'rejected')"
                                >
                                    {{ account.link }}
                                </a>

                                <a
                                    href="javascript:;"
                                    class="d-block text-decoration-none fs-8rem"
                                    v-if="
                                        account.social_platform
                                            .has_edit_request != false
                                    "
                                >
                                    <span class="d-block mb-1">
                                        <img
                                            src="../../assets/media/icons/clock.svg"
                                            alt="icon"
                                            width="13px"
                                            height="13px"
                                            class="mr-1"
                                        />
                                        <a
                                            :href="
                                                account.social_platform
                                                    .edit_request_link
                                            "
                                            target="_blank"
                                            v-if="
                                                account.social_platform
                                                    .edit_request_link != null
                                            "
                                        >
                                            {{
                                                account.social_platform.edit_request_link.substring(
                                                    0,
                                                    30
                                                ) + "..."
                                            }}
                                        </a>
                                    </span>
                                    <span
                                        v-if="
                                            account.social_platform
                                                .edit_total_followers != null
                                        "
                                    >
                                        <img
                                            src="../../assets/media/icons/clock.svg"
                                            alt="icon"
                                            width="13px"
                                            height="13px"
                                            class="mr-1"
                                        />
                                        <span
                                            v-if="
                                                account.social_platform.edit_total_followers.toString()
                                                    .length < 4
                                            "
                                            class="font-bold"
                                        >
                                            {{
                                                account.social_platform.edit_total_followers.toString()
                                            }}
                                        </span>
                                        <span
                                            v-else-if="
                                                account.social_platform.edit_total_followers.toString()
                                                    .length == 4 ||
                                                account.social_platform.edit_total_followers.toString()
                                                    .length < 7
                                            "
                                            class="font-bold"
                                        >
                                            {{
                                                (
                                                    account.social_platform
                                                        .edit_total_followers /
                                                    1000
                                                ).toFixed()
                                            }}
                                            K
                                        </span>
                                        <span
                                            v-else-if="
                                                account.social_platform.edit_total_followers.toString()
                                                    .length == 7 ||
                                                account.social_platform.edit_total_followers.toString()
                                                    .length < 10
                                            "
                                            class="font-bold"
                                        >
                                            {{
                                                (
                                                    account.social_platform
                                                        .edit_total_followers /
                                                    1000000
                                                ).toFixed(1)
                                            }}
                                            M
                                        </span>
                                    </span>
                                </a>
                            </div>

                            <!-- START:: SOCIAL MEDIA CARD SHAPES -->
                            <div
                                class="imaeg_animate"
                                v-if="account.link.includes('facebook')"
                            >
                                <img
                                    class="card_shape facebook"
                                    src="../../assets/media/shapes/social_cards/facebookLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div
                                class="imaeg_animate"
                                v-else-if="account.link.includes('twitter')"
                            >
                                <img
                                    class="card_shape"
                                    src="../../assets/media/shapes/social_cards/twitterLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    :class="
                                        account.link.includes('twitter')
                                            ? 'twitter'
                                            : ''
                                    "
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div
                                class="imaeg_animate"
                                v-else-if="account.link.includes('instagram')"
                            >
                                <img
                                    class="card_shape"
                                    src="../../assets/media/shapes/social_cards/instagramLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    :class="
                                        account.link.includes('instagram')
                                            ? 'instagram'
                                            : ''
                                    "
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div
                                class="imaeg_animate"
                                v-else-if="account.link.includes('likee')"
                            >
                                <img
                                    class="card_shape"
                                    src="../../assets/media/shapes/social_cards/instagramLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    :class="
                                        account.link.includes('likee')
                                            ? 'likee'
                                            : ''
                                    "
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div
                                class="imaeg_animate"
                                v-else-if="account.link.includes('tiktok')"
                            >
                                <img
                                    class="card_shape"
                                    src="../../assets/media/shapes/social_cards/tiktokLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    :class="
                                        account.link.includes('tiktok')
                                            ? 'tiktok'
                                            : ''
                                    "
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div
                                class="imaeg_animate"
                                v-else-if="account.link.includes('youtube')"
                            >
                                <img
                                    class="card_shape"
                                    src="../../assets/media/shapes/social_cards/youtubeLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    :class="
                                        account.link.includes('youtube')
                                            ? 'youtube'
                                            : ''
                                    "
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div
                                class="imaeg_animate"
                                v-else-if="account.link.includes('snapchat')"
                            >
                                <img
                                    class="card_shape"
                                    src="../../assets/media/shapes/social_cards/snapchatLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    :class="
                                        account.link.includes('snapchat')
                                            ? 'snapchat'
                                            : ''
                                    "
                                    v-animate-css="slideInUp"
                                />
                            </div>

                            <div class="imaeg_animate" v-else>
                                <img
                                    class="card_shape facebook"
                                    src="../../assets/media/shapes/social_cards/facebookLine.png"
                                    alt="line shape"
                                    width="100"
                                    height="100"
                                    v-animate-css="slideInUp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <SecomdEmptyMessage v-else />
            </div>
        </div>

        <BaseModel @closeModel="show = !show" :show="show">
            <template #edit_social>
                <EditSocialAccount
                    @closeModel="show = !show"
                    @getSocialData="getSocialData"
                ></EditSocialAccount>
            </template>
        </BaseModel>
    </div>
</template>

<script>
import BaseModel from "../ui/BaseModel.vue";
import SecomdEmptyMessage from "../emptyMessages/SecondEmptyMessage.vue";
import Loader from "./../loader/main-loader.vue";
import EditSocialAccount from "./editSocialAccount.vue";
export default {
    name: "SocialAccounts",

    components: {
        BaseModel,
        SecomdEmptyMessage,
        Loader,
        EditSocialAccount,
    },

    data() {
        return {
            isWaitingRequest: false,
            // START:: SOCIAL
            social_links: [],
            // END:: SOCIAL
            show: false,
            slideInUp: {
                classes: "slideInUp",
                duration: 2500,
            },
            // START:: REGISTER DATA
            enterSocialLink: "",
            links: [],
            social_ids_Array: {
                socila_platform_ids: [],
            },
            linkCheck: null,
            accValidationMessage: null,
            // END:: REGISTER DATA
            loadCards: false,
            loadAddLink: false,
        };
    },

    methods: {
        // START:: GET SOCIAL DATA
        getSocialData() {
            this.loadCards = true;
            this.$axios
                .get("influencer/social_platforms", {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.social_links = res.data.data;
                    this.loadCards = false;
                });
        },
        // END:: GET SOCIAL DATA

        textToLowerCase(val) {
            this.enterSocialLink = val.toLowerCase();
        },
        // END:: SUBMIT REGISTER FORM

        // START:: REMOVE SOCIAL LINK
        removeSocialLink(id) {
            this.$axios
                .delete("influencer/influencer_social_platform/" + id, {
                    headers: {
                        Authorization:
                            "Bearer" +
                            localStorage.getItem("inflco_user_token"),
                    },
                })
                .then((res) => {
                    this.$iziToast.success({
                        timeout: 5000,
                        message: res.data.message,
                        position: this.$t("position"),
                        rtl: this.$t("dir"),
                    });
                    this.getSocialData();
                    this.show = false;
                });
        },
        // END:: REMOVE SOCIAL LINK
    },
    mounted() {
        this.getSocialData();
    },
};
</script>

<template>
    <div class="staticPages blog_article">
        <Loader v-if="loadPage" class="fadeIn heigth-50vh"></Loader>
        <div class="fadeIn" v-else>
            <!-- START:: ARTICLE SLIDER -->
            <div
                id="article_slider"
                class="carousel slide"
                data-bs-touch="true"
                data-bs-ride="carousel"
                v-if="articleData"
            >
                <div
                    class="carousel-indicators"
                    v-if="articleData.images.length > 1"
                >
                    <button
                        type="button"
                        data-bs-target="#article_slider"
                        v-for="(slide, index) in articleData.images"
                        :key="slide.id"
                        :class="index == 0 ? 'active' : ''"
                        :aria-current="index == 0 ? 'true' : ''"
                        :data-bs-slide-to="index"
                    ></button>
                </div>

                <div class="carousel-inner">
                    <div
                        v-for="(slide, index) in articleData.images"
                        :key="slide.id"
                        class="carousel-item"
                        :class="index == 0 ? 'active' : ''"
                    >
                        <img
                            :src="slide.image"
                            class="d-block w-100"
                            alt="Article image"
                        />
                    </div>
                </div>

                <!-- <img
                    class="main_blog_image"
                    :src="articleData.main_image"
                    alt="Main Article Image"
                    width="100"
                    height="100"
                > -->
            </div>
            <!-- END:: ARTICLE SLIDER -->

            <div class="container p-0">
                <div class="row">
                    <!-- START:: ARTICLE BODY -->
                    <div class="col-lg-8">
                        <div class="article_body_wrapper">
                            <div class="article_title">
                                <h1>{{ articleData.name }}</h1>

                                <div class="share_btn_wrapper">
                                    <button
                                        class="share_btn"
                                        @click="toggleShareBtns"
                                    >
                                        <span>
                                            <Share2Icon size="1.6x" />
                                        </span>
                                    </button>

                                    <transition name="fade" mode="out-in">
                                        <ul
                                            class="share_btns"
                                            v-if="shareBtnsMenuIsOpen"
                                        >
                                            <li
                                                class="single_share_btn"
                                                v-if="articleData"
                                            >
                                                <button
                                                    @click="toggleShareBtns"
                                                >
                                                    <ShareNetwork
                                                        network="facebook"
                                                        :url="
                                                            'https://inflco.com/#/' +
                                                            this.$route.path
                                                        "
                                                        :title="
                                                            articleData.name
                                                        "
                                                        :description="
                                                            articleData.desc
                                                        "
                                                        hashtags="Inflco"
                                                    >
                                                        <img
                                                            src="../../assets/media/icons/social/facebook.svg"
                                                            alt="Facebook"
                                                            width="30"
                                                            height="30"
                                                        />
                                                    </ShareNetwork>
                                                </button>
                                            </li>

                                            <li
                                                class="single_share_btn"
                                                v-if="articleData"
                                            >
                                                <button
                                                    @click="toggleShareBtns"
                                                >
                                                    <ShareNetwork
                                                        network="twitter"
                                                        :url="
                                                            'https://inflco.com/#/' +
                                                            this.$route.path
                                                        "
                                                        :title="
                                                            articleData.name
                                                        "
                                                        :description="
                                                            articleData.desc
                                                        "
                                                        hashtags="Inflco"
                                                    >
                                                        <img
                                                            src="../../assets/media/icons/social/Twitter.svg"
                                                            alt="Twitter"
                                                            width="30"
                                                            height="30"
                                                        />
                                                    </ShareNetwork>
                                                </button>
                                            </li>

                                            <li
                                                class="single_share_btn"
                                                v-if="articleData"
                                            >
                                                <button
                                                    @click="toggleShareBtns"
                                                >
                                                    <ShareNetwork
                                                        network="whatsapp"
                                                        :url="
                                                            'https://inflco.com/#/' +
                                                            this.$route.path
                                                        "
                                                        :title="
                                                            articleData.name
                                                        "
                                                        :description="
                                                            articleData.desc
                                                        "
                                                        hashtags="Inflco"
                                                    >
                                                        <img
                                                            src="../../assets/media/icons/social/whatsapp.svg"
                                                            alt="Twitter"
                                                            width="30"
                                                            height="30"
                                                        />
                                                    </ShareNetwork>
                                                </button>
                                            </li>
                                        </ul>
                                    </transition>
                                </div>
                            </div>

                            <div class="article_publish_date">
                                <h6>{{ articleData.created_at }}</h6>
                            </div>

                            <div
                                class="article_content"
                                v-html="articleData.desc"
                            ></div>
                        </div>
                    </div>
                    <!-- END:: ARTICLE BODY -->

                    <!-- START:: RELATED ARTICLES -->
                    <div class="col-lg-4">
                        <div class="related_articles_wrapper">
                            <div class="title_wrapper">
                                <h4>{{ $t("titles.related_blogs") }}</h4>
                            </div>

                            <!-- START:: RELATED ARTICLE CARD -->
                            <div
                                v-for="relatedArticle in articleData.related_blogs"
                                :key="relatedArticle.id"
                            >
                                <router-link
                                    class="related_article_wrapper"
                                    :to="'/blog/' + relatedArticle.id"
                                >
                                    <div class="image_wrapper">
                                        <img
                                            :src="relatedArticle.main_image"
                                            alt="Article Image"
                                            width="100"
                                            height="100"
                                        />
                                    </div>

                                    <div class="related_blog_title">
                                        <h5>
                                            {{ relatedArticle.name }}
                                        </h5>
                                        <div class="publish_date_wrapper">
                                            {{ relatedArticle.created_at }}
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <!-- END:: RELATED ARTICLE CARD -->
                        </div>
                    </div>
                    <!-- END:: RELATED ARTICLES -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Share2Icon } from "vue-feather-icons";
import Loader from "./../../components/loader/main-loader.vue";
export default {
    name: "BlogArticle",

    components: {
        Share2Icon,
        Loader,
    },

    data() {
        return {
            // START:: SHARE BTNS VISIBILITY DATA
            shareBtnsMenuIsOpen: false,
            // END:: SHARE BTNS VISIBILITY DATA

            // START:: ARTICLE ID DATA
            articleId: this.$route.params.id,
            // END:: ARTICLE ID DATA

            // START:: ARTICLE DETAILS DATA
            articleData: null,
            metaDescription: "Article Description",
            // END:: ARTICLE DETAILS DATA
            loadPage: false,
            articleTitle: "",
        };
    },

    methods: {
        // START:: TOGGLE SHARE BTNS METHOD
        toggleShareBtns() {
            this.shareBtnsMenuIsOpen = !this.shareBtnsMenuIsOpen;
        },
        // END:: TOGGLE SHARE BTNS METHOD

        // START:: GET ARTICLE DATA METHOD
        getArticleData() {
            this.loadPage = true;
            this.$axios
                .get(`blogs/${this.articleId}`, {
                    headers: {
                        Authorization:
                            "bearer" +
                            localStorage.getItem("inflco_user_token"),
                        "Content-type": "application/json",
                        "cache-control": "no-cache",
                        Accept: "application/json",
                        "Accept-language":
                            localStorage.getItem("inflco_app_lang"),
                    },
                })
                .then((res) => {
                    this.articleData = res.data.data;
                    this.articleTitle = res.data.data.name;
                    this.metaDescription = res.data.data.meta;
                    this.loadPage = false;
                });
        },
        // END:: GET ARTICLE DATA METHOD
    },

    mounted() {
        // START:: GET ARTICLE DATA METHOD
        this.getArticleData();
        // END:: GET ARTICLE DATA METHOD
    },
    // START:: VUE-META
    metaInfo() {
        return {
            title: this.articleTitle,
            meta: [
                {
                    name: "description",
                    content:
                        this.metaDescription +
                        " , " +
                        this.articleTitle +
                        " , " +
                        "Inflco - Infilco - infilco - inflco  - انفكلو - إنفلكو - انفكلوا - إنفكلوا",
                },
            ],
        };
    },
    // END:: VUE-META
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"influincer_social_accounts_wrapper"},[_c('div',{staticClass:"title_wrapper"},[_c('h3',[_vm._v(_vm._s(_vm.$t("titles.social_accounts")))]),_c('router-link',{attrs:{"to":"/personal-profile"}},[_c('img',{attrs:{"src":require("../../assets/media/icons/user.svg"),"alt":"icon","width":"25","height":"25"}})]),_c('div',{staticClass:"button_wrapper"},[_c('button',{on:{"click":function($event){_vm.show = !_vm.show}}},[_c('img',{attrs:{"src":require("../../assets/media/icons/edit_main.svg"),"alt":"icon","width":"20","height":"20"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("buttons.manage_accounts"))+" ")])])])],1),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("titles.social_account_subtitle"))+" ")]),_c('div',{staticClass:"social_accounts"},[(_vm.loadCards)?_c('Loader',{staticClass:"fadeIn heigth-50vh"}):_c('div',{staticClass:"container-fluid p-0 fadeIb"},[(_vm.social_links.length != 0)?_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.social_links),function(account){return _c('div',{key:account.id,staticClass:"col-md-4 my-3"},[_c('div',{staticClass:"social_account_card"},[_c('div',{staticClass:"title_wrapper"},[_c('img',{attrs:{"src":account.social_platform.icon,"alt":"icon","width":"25","height":"25"}}),(
                                    account.total_followers.toString()
                                        .length < 4
                                )?_c('span',{class:account.social_platform.key_name.toLowerCase()},[_vm._v(" "+_vm._s(account.total_followers.toString())+" ")]):(
                                    account.total_followers.toString()
                                        .length == 4 ||
                                    account.total_followers.toString()
                                        .length < 7
                                )?_c('span',{class:account.social_platform.key_name.toLowerCase()},[_vm._v(" "+_vm._s(( account.total_followers / 1000 ).toFixed())+"K ")]):(
                                    account.total_followers.toString()
                                        .length == 7 ||
                                    account.total_followers.toString()
                                        .length < 10
                                )?_c('span',{class:account.social_platform.key_name.toLowerCase()},[_vm._v(" "+_vm._s(( account.total_followers / 1000000 ).toFixed(1))+"M ")]):_vm._e()]),_c('div',{staticClass:"social_card_body"},[_c('div',{staticClass:"platform w-100 d-flex justify-content-between"},[_vm._v(" "+_vm._s(account.social_platform.name)+" "),_c('span',{staticClass:"social_account_condition"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(account.status == 'accepted'),expression:"account.status == 'accepted'"}],staticClass:"fas fa-check accept-text"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(account.status == 'pending'),expression:"account.status == 'pending'"}],staticClass:"far fa-clock waiting-text"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(account.status == 'rejected'),expression:"account.status == 'rejected'"}],staticClass:"fas fa-times refuced-text"})])]),(account.link.length >= 30  && account.status == 'accepted')?_c('a',{staticClass:"platform_link",attrs:{"href":account.link,"target":"_blank","dir":"ltr"}},[_vm._v(" "+_vm._s(account.link.substring(0, 30) + "...")+" ")]):_vm._e(),(account.link.length <= 30  && account.status == 'accepted')?_c('a',{staticClass:"platform_link",attrs:{"href":account.link,"target":"_blank","dir":"ltr"}},[_vm._v(" "+_vm._s(account.link)+" ")]):_vm._e(),(account.link.length >= 30  && (account.status == 'pending' || account.status == 'rejected'))?_c('a',{staticClass:"platform_link",attrs:{"href":"javascript:;","dir":"ltr"}},[_vm._v(" "+_vm._s(account.link.substring(0, 30) + "...")+" ")]):_vm._e(),(account.link.length <= 30  && (account.status == 'pending' || account.status == 'rejected'))?_c('a',{staticClass:"platform_link",attrs:{"href":"javascript:;","dir":"ltr"}},[_vm._v(" "+_vm._s(account.link)+" ")]):_vm._e(),(
                                    account.social_platform
                                        .has_edit_request != false
                                )?_c('a',{staticClass:"d-block text-decoration-none fs-8rem",attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"d-block mb-1"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/media/icons/clock.svg"),"alt":"icon","width":"13px","height":"13px"}}),(
                                            account.social_platform
                                                .edit_request_link != null
                                        )?_c('a',{attrs:{"href":account.social_platform
                                                .edit_request_link,"target":"_blank"}},[_vm._v(" "+_vm._s(account.social_platform.edit_request_link.substring( 0, 30 ) + "...")+" ")]):_vm._e()]),(
                                        account.social_platform
                                            .edit_total_followers != null
                                    )?_c('span',[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/media/icons/clock.svg"),"alt":"icon","width":"13px","height":"13px"}}),(
                                            account.social_platform.edit_total_followers.toString()
                                                .length < 4
                                        )?_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(account.social_platform.edit_total_followers.toString())+" ")]):(
                                            account.social_platform.edit_total_followers.toString()
                                                .length == 4 ||
                                            account.social_platform.edit_total_followers.toString()
                                                .length < 7
                                        )?_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(( account.social_platform .edit_total_followers / 1000 ).toFixed())+" K ")]):(
                                            account.social_platform.edit_total_followers.toString()
                                                .length == 7 ||
                                            account.social_platform.edit_total_followers.toString()
                                                .length < 10
                                        )?_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(( account.social_platform .edit_total_followers / 1000000 ).toFixed(1))+" M ")]):_vm._e()]):_vm._e()]):_vm._e()]),(account.link.includes('facebook'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape facebook",attrs:{"src":require("../../assets/media/shapes/social_cards/facebookLine.png"),"alt":"line shape","width":"100","height":"100"}})]):(account.link.includes('twitter'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape",class:account.link.includes('twitter')
                                        ? 'twitter'
                                        : '',attrs:{"src":require("../../assets/media/shapes/social_cards/twitterLine.png"),"alt":"line shape","width":"100","height":"100"}})]):(account.link.includes('instagram'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape",class:account.link.includes('instagram')
                                        ? 'instagram'
                                        : '',attrs:{"src":require("../../assets/media/shapes/social_cards/instagramLine.png"),"alt":"line shape","width":"100","height":"100"}})]):(account.link.includes('likee'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape",class:account.link.includes('likee')
                                        ? 'likee'
                                        : '',attrs:{"src":require("../../assets/media/shapes/social_cards/instagramLine.png"),"alt":"line shape","width":"100","height":"100"}})]):(account.link.includes('tiktok'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape",class:account.link.includes('tiktok')
                                        ? 'tiktok'
                                        : '',attrs:{"src":require("../../assets/media/shapes/social_cards/tiktokLine.png"),"alt":"line shape","width":"100","height":"100"}})]):(account.link.includes('youtube'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape",class:account.link.includes('youtube')
                                        ? 'youtube'
                                        : '',attrs:{"src":require("../../assets/media/shapes/social_cards/youtubeLine.png"),"alt":"line shape","width":"100","height":"100"}})]):(account.link.includes('snapchat'))?_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape",class:account.link.includes('snapchat')
                                        ? 'snapchat'
                                        : '',attrs:{"src":require("../../assets/media/shapes/social_cards/snapchatLine.png"),"alt":"line shape","width":"100","height":"100"}})]):_c('div',{staticClass:"imaeg_animate"},[_c('img',{directives:[{name:"animate-css",rawName:"v-animate-css",value:(_vm.slideInUp),expression:"slideInUp"}],staticClass:"card_shape facebook",attrs:{"src":require("../../assets/media/shapes/social_cards/facebookLine.png"),"alt":"line shape","width":"100","height":"100"}})])])])}),0):_c('SecomdEmptyMessage')],1)],1),_c('BaseModel',{attrs:{"show":_vm.show},on:{"closeModel":function($event){_vm.show = !_vm.show}},scopedSlots:_vm._u([{key:"edit_social",fn:function(){return [_c('EditSocialAccount',{on:{"closeModel":function($event){_vm.show = !_vm.show},"getSocialData":_vm.getSocialData}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }